import { Link as RouterLink, useNavigate, useSearchParams } from 'react-router-dom';
import { Typography, Button, TextField, Box } from '@mui/material';
import { Icon } from '@iconify/react';
import Stack from '@mui/material/Stack';
import plusFill from '@iconify/icons-eva/plus-fill';
import { useUser } from 'src/layouts/dashboard/DashboardLayout';
import { useState } from 'react';

interface TopBarProps {
    title: string,
    link: string,
    buttonText: string
}

export default function TopBar({ title, link, buttonText }: TopBarProps) {
    const { adminUser } = useUser();
    const [searchParams] = useSearchParams();
    var searchDefault: any = searchParams.get("search");
    const [search, setSearch] = useState<string>(searchDefault);
    const navigate = useNavigate();

    const searchProject = (e: any) => {
        e.preventDefault();
        navigate(`/admin/project`, { state: search })
    }

    return (
        <Box>
            <Stack direction="row" alignItems="center" justifyContent="space-between" mb={2}>
                <Typography sx={{ ml: 1 }} variant="h5">
                    {title}
                </Typography>
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <form onSubmit={(e) => searchProject(e)}>
                        <TextField
                            label="Search Project"
                            onChange={(e) => setSearch(e.target.value)}
                            variant="standard"
                            defaultValue={searchDefault}
                            sx={{ mr: 2 }}
                        />
                    </form>
                    {link && <Button
                        disabled={adminUser?.role === 'SUBSCRIBER' ||adminUser?.role === 'BDE' }
                        variant="contained"
                        component={RouterLink}
                        to={link}
                        startIcon={<Icon icon={plusFill} />}
                    >
                        {buttonText}
                    </Button>}
                </Box>
            </Stack>
        </Box>
    );
}
