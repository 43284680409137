import { supplierMapActionTypes } from "src/types/actionTypes";
import { SupplierMap, SupplierMapList } from "src/types/supplierMapTypes";
import { ErrorAction, SuccessAction } from "src/types/responseTypes";

export type supplierMapAction = SuccessAction<SupplierMapList> | SuccessAction<SupplierMap> | SuccessAction<any> | ErrorAction
export const defaultSupplierMapState: SupplierMapList = { loading:true, redirectTo: '',supplierMaps:[], supplierMap: {}, data: [], metadata: {} };

const reducer = (state: SupplierMapList = defaultSupplierMapState, action: supplierMapAction): SupplierMapList => {
    switch (action.type) {
        case supplierMapActionTypes.SUPPLIER_MAP_REQUEST:
            state = { loading:true,redirectTo: '',supplierMaps:[], supplierMap: {}, data: [], metadata: {}  }
            return state
        case supplierMapActionTypes.FETCH_SUPPLIER_MAP_SUCCESS:
            action = <SuccessAction<SupplierMapList>>action
            state = { ...state,loading:false, redirectTo: '', supplierMaps: action.payload }
            return state
        case supplierMapActionTypes.FETCH_SUPPLIER_MAP_ALL_SUCCESS:
            action = <SuccessAction<SupplierMapList>>action
            state = { ...state,loading:false, supplierMaps: action.payload }
            return state
        case supplierMapActionTypes.UPDATE_SUPPLIER_MAP_SUCCESS:
            action = <SuccessAction<any>>action
            state = { ...state,loading:false, supplierMap:{}, supplierMaps: state.supplierMaps?.map(dat => [ dat._id ==action.payload._id && Object.assign(dat ,action.payload)].find(o => o._id == dat._id) || dat) }
            return state
        case supplierMapActionTypes.FETCH_SINGLE_SUPPLIER_MAP_SUCCESS:
            action = <SuccessAction<SupplierMap>>action
            state = { ...state,loading:false, supplierMap: action.payload }
            return state
        case supplierMapActionTypes.DELETE_SUPPLIER_MAP_SUCCESS:
            action = <SuccessAction<any>>action
            state = { ...state,loading:false, data: state.data?.filter(dat => dat._id !== action.payload._id), metadata: { ...state.metadata, limit: Number(state.metadata?.limit) - 1, total: Number(state.metadata?.total) - 1 } }
            return state
        case supplierMapActionTypes.ADD_SUPPLIER_MAP_SUCCESS:
            action = <SuccessAction<SupplierMap>>action
            state = { ...state ,loading:false, supplierMaps: [...state.supplierMaps,action.payload ] }
            return state
        case supplierMapActionTypes.SUPPLIER_MAP_FAILURE:
            action = <ErrorAction>action
            return { ...state,loading:false, }
        default:
            return state
    }
}
export default reducer