import { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Container, Typography, Box, Tooltip, Tab } from '@mui/material';
import { useDispatch } from 'react-redux';
import { useSelector } from 'src/reducers';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { MoreMenu, DataGridRender } from 'src/components/table';
import { ProjectInvoiceAllRequest, ProjectGroupExportRequest, ProjectGroupSupplierMapExportRequest, ProjectSupplierAllRequest } from 'src/actions/projectActions';
import TopBar from 'src/components/shared/TopBar';
import { SupplierSingleRequest } from 'src/actions/serviceActions';
export default function ProjectSupplierList() {

   const { supplierid }: any = useParams<any>();
  const [limit, setLimit] = useState(20);
  const [pageNo, setPageNo] = useState(1);
  const [status] = useState(false);
  const [filters, setFilters] = useState<any>({});

  const dispatch = useDispatch();
  const { loading, metadata, data } = useSelector(state => state.project);
  const { supplier } = useSelector(state => state.supplier);

  const navigate = useNavigate();


  useEffect(() => {
    dispatch(SupplierSingleRequest(supplierid,{}));
  }, [supplierid]);

  useEffect(() => {
    dispatch(ProjectSupplierAllRequest( supplierid,{filters, pageNo, limit, status}))
  }, [pageNo,supplierid, limit, filters, status]);

  const editAction = (id: string) => {
    navigate(`/admin/invoice/${id}/edit`, { replace: true });
  }
  const exportAction = (id: string, name: string) => {
    dispatch(ProjectGroupExportRequest({ projectId: id }, name));
  }
  const exportSupplierMap = (id: string, name: string) => {
    dispatch(ProjectGroupSupplierMapExportRequest({ projectId: id }, name));
  }
  const reloadAction = () => {
    dispatch(ProjectInvoiceAllRequest(filters, pageNo, limit, status));
  }

  const columns = [
    {
      field: 'projectCode', type: "string", headerName: 'Project #', filterable: false, headerAlign: "center", align: 'center', width: 100, pinnable: true, renderCell: ({ id, value }: any) => {
        return <Link to={`/admin/project/${id}/childs`}>
          <Typography color="primary" >{value}</Typography>
        </Link>
      }
    },
    {
      field: 'projectName', type: "string", headerName: 'Project Title', filterable: false, headerAlign: "left", align: 'left', flex: 1, renderCell: ({ id, value }: any) => {
        return <Tooltip title={value}>
          <Typography variant="subtitle2">{value}</Typography>
        </Tooltip>
      }
    },
    {
      field: 'supplierCPI', type: "string", headerName: 'Supplier CPI', filterable: false, headerAlign: "center", align: 'center', width: 150, renderCell: ({ value }: any) => {
        return <Tooltip title={value}>
          <Typography variant="subtitle2">{value}</Typography>
        </Tooltip>
      }
    },
    {
      field: 'totalAccepted', type: "string", headerName: 'Total Accepted', filterable: false, width: 150, headerAlign: "center", align: 'center', valueGetter: ({ row }: any) => {
        return `${row['totalAccepted']}`;
      },
    },
    {
      field: 'totalCost', type: "string", headerName: 'Total Cost', filterable: false, width: 150, headerAlign: "center", align: 'center', valueGetter: ({ row }: any) => {
        return `$ ${row['totalCost']}`;
      },
    },
    { field: 'actions', headerName: '', width: 60, type: 'actions', getActions: ({ id, row }: any) => [<MoreMenu id={id} name={row['projectCode'] + "_" + row['projectName']} projectStatus={row['projectStatus']} exportFileName="Project" reconsileAction="" cloneAction='' addAction='' editAction={editAction} deleteAction="" exportSupplierMap={exportSupplierMap} exportAction={exportAction} />] },
  ];

  return (
    <Container maxWidth={false}>
      <Box sx={{ mb: 1 }}>
        <Link style={{ display: 'flex', alignContent: 'center' }} to="/admin/invoice/supplier">
          <KeyboardBackspaceIcon fontSize='medium' color="primary" />
          <Typography sx={{ ml: 1 }} color="primary" variant="h5">Supplier</Typography>
        </Link>
      </Box>
      <TopBar title={supplier?.supplierName || ''} link={``} buttonText="" />
      <DataGridRender
        reloadAction={reloadAction}
        getRowClassName={(params: any) => params.row['complete'] && params.row['complete'] >= params.row['sampleSize'] && params.row['sampleSize'] ? `data-grid-warning` : 'super-app-theme'}
        loading={loading}
        filterTypes={['client', 'supplier', 'user', 'export', 'search']}
        data={data}
        exportFileName="Projects"
        metadata={metadata}
        limit={limit}
        filters={filters}
        pageNo={pageNo}
        columns={columns}
        setPageNo={setPageNo}
        setLimit={setLimit}
        setFilters={setFilters}
      />
    </Container>
  );
}
