import { useEffect, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Container, Typography, Box, Tooltip } from '@mui/material';
import { useDispatch } from 'react-redux';
import { useSelector } from 'src/reducers';
import { sentenceCase } from 'change-case';
import { MoreMenu, DataGridRender } from 'src/components/table';
import Label from 'src/components/Label';
import {  ProjectSupplierExportRequest, ProjectSupplierMappedRequest } from 'src/actions/projectActions';
import { ProjectStatus } from 'src/constants';
import TopBar from 'src/components/shared/TopBar';
import ReactCountryFlag from 'react-country-flag';

export default function SupplierProjectList() {

  const [pageNo, setPageNo] = useState(1);
  const [limit, setLimit] = useState(10);
  const [status] = useState(false);
  const [filters, setFilters] = useState<any>({});

  const dispatch = useDispatch();
  const { loading, metadata, data } = useSelector(state => state.project);


  useEffect(() => {
    dispatch(ProjectSupplierMappedRequest(filters, pageNo, limit, status));
  }, [pageNo, limit, status, filters]);

  const exportAction = (id: string ,name:string) => {
    dispatch(ProjectSupplierExportRequest({ supplierMapId:id,projectId:id} ,name));
  }


  const columns = [
    {
      field: 'projectCode', type: "string", headerName: 'Project #', filterable: false, headerAlign: "center", align: 'center', width: 150, pinnable: true, renderCell: ({ id, value }: any) => {
        return <RouterLink to={`/admin/project/${id}/childs`}>
          <Typography color="primary" >{value}</Typography>
        </RouterLink>
      }
    },
    {
      field: 'projectName', type: "string", headerName: 'Project Name', filterable: false, headerAlign: "center", align: 'center', flex: 1, renderCell: ({ value }: any) => {
        return <Tooltip title={value}>
          <Typography variant="subtitle2">{value}</Typography>
        </Tooltip>
      }
    },
    { field: 'countryName', type: "string", headerName: 'Region', hide: true, filterable: false, width: 100, headerAlign: "center", align: 'center' },
    { field: 'countryCode', type: "string", headerName: 'Region', width: 150, headerAlign: "left", align: 'left',
      renderCell: (params: any) => {
        return <Box sx={{ display: 'flex', justifyContent: 'flex', alignItems: 'center' }}>
          <ReactCountryFlag className="emojiFlag" countryCode={`${params.value}`} /><Tooltip title={params.row.countryName}>
            <Typography variant="subtitle2">{params.row.countryName}</Typography>
          </Tooltip>
        </Box>
      },
    },
    { field: 'complete', type: "string", headerName: 'Complete', filterable: false, width: 100, headerAlign: "center", align: 'center', valueGetter: ({ row }: any) => {
        return `${row['complete']}/${row['sampleSize']}`;
      },
    },
    { field: 'terminate', type: "string", headerName: 'ST', filterable: false, width: 100, headerAlign: "center", align: 'center' },
    { field: 'overQuota', type: "string", headerName: 'OQ', filterable: false, width: 100, headerAlign: "center", align: 'center' },
    { field: 'qualityTerm', type: "string", headerName: 'QT', filterable: false, width: 100, headerAlign: "center", align: 'center' },
    { field: 'drop', type: "string", headerName: 'DO', filterable: false, width: 100, headerAlign: "center", align: 'center' },
    {
      field: 'projectStatus', headerName: 'Status', type: "boolean", width: 100, renderCell: (params: any) => {
        var status: any = ProjectStatus.find(status => status.value == params.value);
        return <Label
          variant="ghost"
          color={status?.color}
        >
          {sentenceCase(status.label)}
        </Label>
      }
    },
    { field: 'actions', headerName: '', width: 60, type: 'actions', getActions: ({ row }: any) => [<MoreMenu id={row['supplierMapId']} name={row['projectCode'] + "_" + row['projectName']} exportFileName="Supplier" cloneAction='' addAction='' editAction={''} deleteAction={""} exportAction={exportAction} />] },
  ];


  return (
    <Container maxWidth={false}>
      <TopBar title="Projects" link="" buttonText="New Project" />
      <DataGridRender
        filters={filters}
        filterTypes={[ 'export', 'search']}
        data={data}
        loading={loading}
        metadata={metadata}
        limit={limit}
        pageNo={pageNo}
        columns={columns}
        setPageNo={setPageNo}
        setLimit={setLimit}
        setFilters={setFilters}
        exportFileName="Supplier"
      />
    </Container>
  );
}
