import { qualificationActionTypes } from "src/types/actionTypes";
import { Qualification, QualificationList } from "src/types/qualificationTypes";
import { ErrorAction, SuccessAction } from "src/types/responseTypes";

export type qualificationAction = SuccessAction<QualificationList> | SuccessAction<Qualification> | SuccessAction<any> | ErrorAction
export const defaultQuestionState: QualificationList = { loading:true, redirectTo: "",  qualifications: [], qualification: {}, data: [], metadata: {} };

const reducer = (state: QualificationList = defaultQuestionState, action: qualificationAction): QualificationList => {
    switch (action.type) {
        case qualificationActionTypes.QUALIFICATION_REQUEST:
            state = { loading:true,redirectTo: "", qualifications: [], qualification: {}, data: [], metadata: {} }
            return state
        case qualificationActionTypes.FETCH_QUALIFICATION_SUCCESS:
            action = <SuccessAction<QualificationList>>action
            state = { ...state,loading:false, data: action.payload.qualifications, metadata: action.payload.metadata }
            return state
        case qualificationActionTypes.FETCH_QUALIFICATION_ALL_SUCCESS:
            action = <SuccessAction<QualificationList>>action
            state = { ...state,loading:false, qualifications: action.payload }
            return state
        case qualificationActionTypes.UPDATE_QUALIFICATION_SUCCESS:
            action = <SuccessAction<any>>action
            state = { ...state,loading:false, qualifications: state.qualifications?.map(dat => [dat._id == action.payload._id && Object.assign(dat, action.payload)].find(o => o._id == dat._id) || dat) }
            return state
        case qualificationActionTypes.FETCH_SINGLE_QUALIFICATION_SUCCESS:
            action = <SuccessAction<Qualification>>action
            state = { ...state,loading:false, qualification: action.payload }
            return state
        case qualificationActionTypes.DELETE_QUALIFICATION_SUCCESS:
            action = <SuccessAction<any>>action
            state = { ...state,loading:false, qualifications: state.qualifications?.filter(dat => dat._id !== action.payload._id), metadata: { ...state.metadata, limit: Number(state.metadata?.limit) - 1, total: Number(state.metadata?.total) - 1 } }
            return state
        case qualificationActionTypes.ADD_QUALIFICATION_SUCCESS:
            action = <SuccessAction<Qualification>>action
            state = { ...state,loading:false, qualifications: [...state.qualifications, action.payload] }
            return state
        case qualificationActionTypes.QUALIFICATION_FAILURE:
            action = <ErrorAction>action
            return { ...state,loading:false, }
        default:
            return state
    }
}
export default reducer