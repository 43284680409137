import { Delete as DeleteIcon, Edit as EditIcon } from '@mui/icons-material';
import { ListItem, ListItemAvatar, ListItemText, Avatar, IconButton, Box } from '@mui/material';
import { Qualification } from 'src/types/qualificationTypes';
import swal from 'sweetalert';

export interface QualificationProps {
  qualification: Qualification,
  index: number,
  key: string,
  deleteQualification: any,
  setEditQualification: any,
}

const QualificationListItem = ({ qualification, index, setEditQualification, deleteQualification }: QualificationProps) => {

  const deleteActionAlert = (id: string) => {
    swal({
      title: "Are you sure?",
      icon: "warning",
      dangerMode: true,
      buttons: ["No!", true],
    })
      .then((willDelete: any) => {
        if (willDelete) {
          deleteQualification(id);
        } else {

        }
      });

  }
  return (
    <ListItem
      secondaryAction={
        <Box>
          <IconButton edge="start" aria-label="edit" onClick={() => setEditQualification(qualification)}>
            <EditIcon />
          </IconButton>
          <IconButton edge="end" aria-label="delete" onClick={() => deleteActionAlert(qualification._id || '')}>
            <DeleteIcon />
          </IconButton>
        </Box>
      }
    >
      <ListItemAvatar>
        <Avatar>
          {index + 1}
        </Avatar>
      </ListItemAvatar>
      <ListItemText primary={qualification.qualificationName} secondary={qualification.qualificationKey} />
    </ListItem>
  );
};

export default QualificationListItem;
