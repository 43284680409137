import { useEffect, useState } from 'react';
import * as Yup from 'yup'
import { styled } from '@mui/material/styles';
import { Card, Stack, TextField, Grid, List, ListItem ,Checkbox ,ListItemText} from '@mui/material';
import { Form, Formik, FormikProps } from 'formik';
import { LoadingButton } from '@mui/lab';
import { useSelector } from 'src/reducers';
import { useNavigate } from 'react-router-dom';
import { Bid } from 'src/types/bidTypes';
import { useDispatch } from 'react-redux';
import Autocomplete from '@mui/material/Autocomplete';
import { ClientAllRequestData } from 'src/actions/clientActions';
import { BidAllContactRequestData } from 'src/actions/bidActions';

const CardStyle = styled(Card)(({ }) => ({
    width: '100%',
    padding: 24
}));

export interface SubmitFormValues {
    initialValues: Bid,
    submitForm: any,
}

export default function BidForm({ initialValues, submitForm }: SubmitFormValues) {

    const dispatch = useDispatch();
    const [clientId, setClientId] = useState<string>('');
    const SchemaBidForm = Yup.object().shape({
        bidTitle: Yup.string().min(4).trim().required('Bid title  is required'),
        bidContact: Yup.string().trim().required('Bid contact  is required'),
        bidFieldTime: Yup.string().trim().required('Bid field time  is required'),
        bidPmf: Yup.number().required('Bid pmf  is required'),
        clientId: Yup.string().trim().required('Client is required')
        
    });
    const navigate = useNavigate();

    const { redirectTo, contacts } = useSelector(state => state.bid);
    const { clients } = useSelector(state => state.client);

    useEffect(() => {
        if (redirectTo) navigate(redirectTo, { replace: true })
    }, [redirectTo])


    useEffect(() => {
        dispatch(ClientAllRequestData(''))
    }, [])

    const setClient = (formikProps: any, value: string) => {
        setClientId(value);
        formikProps.setFieldValue('clientId',value);
    }

    useEffect(() => {
        if(clientId)
        dispatch(BidAllContactRequestData(clientId))
    }, [clientId])


    return (
        <CardStyle>
            <Formik
                onSubmit={(values, { setSubmitting }) => {
                    submitForm(values, setSubmitting);
                }}
                validateOnBlur={false}
                validateOnChange={true}
                enableReinitialize={true}
                validationSchema={SchemaBidForm}
                initialValues={{
                    bidTitle: initialValues?.bidTitle || '',
                    bidContact: initialValues?.bidContact || '',
                    bidPmf: initialValues?.bidPmf || 0,
                    bidFieldTime: initialValues?.bidFieldTime || '5 - 7 Days',
                    bidPass: initialValues?.bidPass || false,
                    clientId: initialValues?.clientId || '',
                }}
            >
                {(formikProps: FormikProps<Bid>) => (
                    <Form autoComplete="off" noValidate onSubmit={formikProps.handleSubmit}>
                        <Stack spacing={3}>
                            <Grid
                                container
                                spacing={3}
                            >
                                <Grid
                                    item
                                    md={4}
                                    xs={12}
                                >
                                    <TextField
                                        error={Boolean(formikProps.touched.bidTitle && formikProps.errors.bidTitle)}
                                        fullWidth
                                        helperText={formikProps.touched.bidTitle && formikProps.errors.bidTitle}
                                        label="Bid Title"
                                        variant="standard"
                                        {...formikProps.getFieldProps('bidTitle')}
                                    />
                                </Grid>
                                <Grid
                                    item
                                    md={4}
                                    xs={12}
                                >
                                    <TextField
                                        id="standard-select-currency-native"
                                        select
                                        label="Client Name"
                                        error={Boolean(formikProps.touched.clientId && formikProps.errors.clientId)}
                                        fullWidth
                                        {...formikProps.getFieldProps('clientId')}
                                        onChange={(e) => setClient(formikProps, e.target.value)}
                                        helperText={formikProps.touched.clientId && formikProps.errors.clientId}
                                        SelectProps={{
                                            native: true,
                                        }}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        variant="standard"
                                    >
                                        <option key="" value="">
                                            {"Select Client"}
                                        </option>
                                        {clients.map((client: any) => (
                                            <option key={client._id} value={client._id}>
                                                {client.label}
                                            </option>
                                        ))}
                                    </TextField>
                                </Grid>
                                <Grid
                                    item
                                    md={4}
                                    xs={12}
                                >
                                    <Autocomplete
                                        freeSolo
                                        id="free-solo-2-demo"
                                        disableClearable
                                        {...formikProps.getFieldProps('bidContact')}
                                        options={contacts.map((option: any) => option.bidContact)}
                                        onChange={(e,value)=> formikProps.setFieldValue('bidContact',value)}
                                        disabled={formikProps.values.clientId ? false:true}    
                                        renderInput={(params) => (
                                            <TextField
                                            
                                               {...formikProps.getFieldProps('bidContact')}
                                                {...params}
                                                variant="standard"
                                                label="Search Contact"
                                            />
                                        )}
                                    />
                                </Grid> 
                                <Grid
                                    item
                                    md={4}
                                    xs={12}
                                >
                                    <TextField
                                        error={Boolean(formikProps.touched.bidPmf && formikProps.errors.bidPmf)}
                                        fullWidth
                                        helperText={formikProps.touched.bidPmf && formikProps.errors.bidPmf}
                                        label="Bid PMF/Setup Fees"
                                        variant="standard"
                                        {...formikProps.getFieldProps('bidPmf')}
                                    />
                                </Grid>
                                <Grid
                                    item
                                    md={4}
                                    xs={12}
                                >
                                    <TextField
                                        error={Boolean(formikProps.touched.bidFieldTime && formikProps.errors.bidFieldTime)}
                                        fullWidth
                                        helperText={formikProps.touched.bidFieldTime && formikProps.errors.bidFieldTime}
                                        label="Bid Field Time"
                                        variant="standard"
                                        {...formikProps.getFieldProps('bidFieldTime')}
                                    />
                                </Grid>
                                <Grid
                                    item
                                    md={2}
                                    xs={12}
                                >
                                    <List>
                                        <ListItem dense>
                                            <Checkbox
                                                edge="start"
                                                tabIndex={-1}
                                                disableRipple
                                                checked={formikProps.values.bidPass}
                                                {...formikProps.getFieldProps('bidPass')}
                                            />
                                            <ListItemText primary={`Bid Pass`} />
                                        </ListItem>
                                    </List>
                                </Grid>
                            </Grid>
                            <Grid item xs={2}>
                                <LoadingButton
                                    size="medium"
                                    type="submit"
                                    variant="contained"
                                    loading={formikProps.isSubmitting}
                                >
                                    {initialValues ? 'Save Bid' : 'Create Bid'}
                                </LoadingButton>
                            </Grid>
                        </Stack>
                    </Form>
                )}
            </Formik>
        </CardStyle>
    );
}
