import {  Container } from '@mui/material';
import { useDispatch } from 'react-redux';
import MessageContext from 'src/context/MessageContext';
import { useContext } from 'react';
import TopBarForm from 'src/components/shared/TopBarForm';
import { ScheduleJobForm } from 'src/components/admin/_dashboard/service/schedule';
import { ScheduleJobAddRequest } from 'src/actions/serviceActions';
import { ScheduleJob } from 'src/types/scheduleJobTypes';

export default function AddScheduleJob() {
    const { setMessageHandler } = useContext<any>(MessageContext);
    const dispatch = useDispatch();
    const initialValues: any = null;

    const submitForm = async (values: ScheduleJob, setSubmitting: any) => {
        setTimeout(() => dispatch(ScheduleJobAddRequest(values, setMessageHandler, setSubmitting)), 200);
    }

    return (
        <Container maxWidth={false}>
            <TopBarForm title="Add Schedule Job"  link="/admin/support/job"/>
            <ScheduleJobForm initialValues={initialValues} submitForm={submitForm} />
        </Container>
    );
}
