import { useContext } from 'react';
import { Container } from '@mui/material';
import { useDispatch } from 'react-redux';
import { ProjectUpdateRequest, ProjectSingleRequest } from 'src/actions';
import {  useParams } from 'react-router-dom';
import { useEffect } from 'react';
import { useSelector } from 'src/reducers';
import { Project } from 'src/types/projectTypes';
import MessageContext from 'src/context/MessageContext';
import TopBarForm from 'src/components/shared/TopBarForm';
import { InvoiceProjectForm } from 'src/components/admin/_dashboard/invoice';

export default function EditProjectInvoice() {

    const { setMessageHandler } = useContext<any>(MessageContext);
    const dispatch = useDispatch();
    const { projectid }: any = useParams<any>();
    const submitForm = async (values: Project, setSubmitting: any) => {
        setTimeout(() => dispatch(ProjectUpdateRequest(projectid, values, setMessageHandler, setSubmitting)), 200);
    }
    const { project } = useSelector(state => state.project);
    const initialValues: any = project || null;

    useEffect(() => {
        dispatch(ProjectSingleRequest(projectid));
    }, [projectid])

    return (
        <Container maxWidth={false}>
            <TopBarForm title="Edit Project" link="/admin/invoice" />
            <InvoiceProjectForm submitForm={submitForm} initialValues={initialValues} />
        </Container>
    );
}
