import { Stack, TextField, Grid } from '@mui/material';
import { Form, Formik, FormikProps } from 'formik';
import * as Yup from 'yup';
import { Supplier } from 'src/types/supplierTypes';
import { LoadingButton } from '@mui/lab';

export interface FormValues {
    initialValues: Supplier,
    submitForm: any,
}

export default function SupplerInventoryForm({ initialValues, submitForm }: FormValues) {

    const SchemaCategoryForm = Yup.object().shape({
        redirectComplete: Yup.string().trim().matches(/((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+(:[0-9]+)?|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)/, "Invalid Url").matches(/=\[.?sid\]/g, "Invalid Url"),
        redirectQuotaFull: Yup.string().trim().matches(/((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+(:[0-9]+)?|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)/, "Invalid Url").matches(/=\[.?sid\]/g, "Invalid Url"),
        redirectTerminate: Yup.string().trim().matches(/((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+(:[0-9]+)?|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)/, "Invalid Url").matches(/=\[.?sid\]/g, "Invalid Url"),
        redirectQualityTerm: Yup.string().trim().matches(/((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+(:[0-9]+)?|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)/, "Invalid Url").matches(/=\[.?sid\]/g, "Invalid Url"),
        redirectSurveyClose: Yup.string().trim().matches(/((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+(:[0-9]+)?|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)/, "Invalid Url").matches(/=\[.?sid\]/g, "Invalid Url"),
    });

    return (
        <Formik
            onSubmit={(values, { setSubmitting }) => {
                submitForm(values, setSubmitting);
            }}
            validateOnBlur={false}
            validateOnChange={true}
            enableReinitialize={true}
            validationSchema={SchemaCategoryForm}
            initialValues={{
                isUrlUpdated:true,
                redirectComplete: initialValues?.redirectComplete || '',
                redirectQuotaFull: initialValues?.redirectQuotaFull || '',
                redirectTerminate: initialValues?.redirectTerminate || '',
                redirectQualityTerm: initialValues?.redirectQualityTerm || '',
                redirectSurveyClose: initialValues?.redirectSurveyClose || '',
            }}
        >
            {(formikProps: FormikProps<Supplier>) => (
                <Form autoComplete="off" noValidate onSubmit={formikProps.handleSubmit}>
                    <Stack spacing={3}>
                        <Grid
                            container
                            spacing={3}
                        >
                            <Grid
                                item
                                md={12}
                                xs={12}
                            >
                                <TextField
                                    error={Boolean(formikProps.touched.redirectComplete && formikProps.errors.redirectComplete)}
                                    fullWidth
                                    helperText={(formikProps.touched.redirectComplete && formikProps.errors.redirectComplete) || 'Link eg. https://app.wolfinsights.com/thanks?status=c&sid=[sid]&pid=[pid]'}
                                    label="Complete"
                                    {...formikProps.getFieldProps('redirectComplete')}
                                    type="text"
                                    variant="standard"
                                />
                            </Grid>

                            <Grid
                                item
                                md={12}
                                xs={12}
                            >
                                <TextField
                                    error={Boolean(formikProps.touched.redirectTerminate && formikProps.errors.redirectTerminate)}
                                    fullWidth
                                    helperText={(formikProps.touched.redirectTerminate && formikProps.errors.redirectTerminate) || 'Link eg. https://app.wolfinsights.com/thanks?status=t&sid=[sid]&pid=[pid]'}
                                    label="Terminate"
                                    {...formikProps.getFieldProps('redirectTerminate')}
                                    type="text"
                                    variant="standard"
                                />
                            </Grid>


                            <Grid
                                item
                                md={12}
                                xs={12}
                            >
                                <TextField
                                    error={Boolean(formikProps.touched.redirectQuotaFull && formikProps.errors.redirectQuotaFull)}
                                    fullWidth
                                    helperText={(formikProps.touched.redirectQuotaFull && formikProps.errors.redirectQuotaFull) || 'Link eg. https://app.wolfinsights.com/thanks?status=q&sid=[sid]&pid=[pid]'}
                                    label="Over Quota"
                                    {...formikProps.getFieldProps('redirectQuotaFull')}
                                    type="text"
                                    variant="standard"
                                />
                            </Grid>


                            <Grid
                                item
                                md={12}
                                xs={12}
                            >
                                <TextField
                                    error={Boolean(formikProps.touched.redirectQualityTerm && formikProps.errors.redirectQualityTerm)}
                                    fullWidth
                                    helperText={(formikProps.touched.redirectQualityTerm && formikProps.errors.redirectQualityTerm) || 'Link eg. https://app.wolfinsights.com/thanks?status=f&sid=[sid]&pid=[pid]'}
                                    label="Quality Term"
                                    {...formikProps.getFieldProps('redirectQualityTerm')}
                                    type="text"
                                    variant="standard"
                                />
                            </Grid>
                            <Grid
                                item
                                md={12}
                                xs={12}
                            >
                                <TextField
                                    error={Boolean(formikProps.touched.redirectSurveyClose && formikProps.errors.redirectSurveyClose)}
                                    fullWidth
                                    helperText={(formikProps.touched.redirectSurveyClose && formikProps.errors.redirectSurveyClose) || 'Link eg. https://app.wolfinsights.com/thanks?status=qf&sid=[sid]&pid=[pid]'}
                                    label="Survey Close"
                                    {...formikProps.getFieldProps('redirectSurveyClose')}
                                    type="text"
                                    variant="standard"
                                />
                            </Grid>
                            <Grid
                                item
                                md={12}
                                xs={12}
                            >
                                <TextField
                                    disabled
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    fullWidth
                                    label="Survey URL"
                                    value={initialValues?.surveyProjectUrl}
                                    type="text"
                                    variant="standard"
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <LoadingButton
                                    size="medium"
                                    type="submit"
                                    variant="contained"
                                    loading={formikProps.isSubmitting}
                                >
                                    Start Project
                                </LoadingButton>
                            </Grid>
                        </Grid>
                    </Stack>
                </Form>
            )}
        </Formik>
    );
}
