import {  Stack, Grid,TextField, FormControl, InputLabel,Input,MenuItem,Checkbox, Select, ListItemText } from '@mui/material';
import { Form, Formik, FormikProps } from 'formik';
import { LoadingButton } from '@mui/lab';
import { useSelector } from 'src/reducers';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { ProjectQuestionsGroupRequest } from 'src/actions/surveyActions';


export interface FormValues {
    submitForm: any,
    title:string,
    projectId: string
}

const ITEM_HEIGHT: number = 48;
const ITEM_PADDING_TOP: number = 8;

const MenuProps: any = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};


export default function PrescreenForm({title, submitForm, projectId }: FormValues) {

    const dispatch = useDispatch();
    const { questions } = useSelector(state => state.question);

    useEffect(()=>{
        dispatch(ProjectQuestionsGroupRequest(projectId))
    },[]);
    
    return (
            <Formik
                onSubmit={(values, { setSubmitting }) => {
                    submitForm(projectId,title,values, setSubmitting);
                }}
                validateOnBlur={false}
                validateOnChange={true}
                enableReinitialize={true}
                initialValues={{
                    userIdentifier: '',
                    questionIndex:[]
                }}
            >
                {(formikProps: FormikProps<any>) => (
                    <Form autoComplete="off" noValidate onSubmit={formikProps.handleSubmit}>
                        <Stack spacing={3}>
                            <Grid
                                container
                                spacing={3}
                            >
                                <Grid
                                    item
                                    md={12}
                                    xs={12}
                                >
                                    <TextField
                                        multiline
                                        minRows={5}
                                        error={Boolean(formikProps.touched.userIdentifier && formikProps.errors.userIdentifier)}
                                        fullWidth
                                        helperText={formikProps.touched.userIdentifier && formikProps.errors.userIdentifier}
                                        label="Hash Identifier"
                                        {...formikProps.getFieldProps('userIdentifier')}
                                        variant="standard"
                                    />
                                </Grid>
                                <Grid
                                    item
                                    md={12}
                                    xs={12}
                                >
                            <FormControl sx={{width:"100%", maxWidth: 800 }}>
                                <InputLabel id="demo-multiple-checkbox-label">Questions</InputLabel>
                                <Select
                                    labelId="demo-multiple-checkbox-label"
                                    id="demo-multiple-checkbox"
                                    multiple
                                    value={formikProps.values.questionIndex}
                                    onChange={(e) =>  formikProps.setFieldValue('questionIndex', e.target.value)}
                                    input={<Input placeholder="Region" />}
                                    renderValue={(selected) => {
                                        return selected.length+' Questions Selected';
                                    }}
                                    MenuProps={MenuProps}
                                >
                                    {questions.map((region: any) => (
                                        <MenuItem key={region.value} value={region.value}>
                                            <>
                                                <Checkbox                                                      checked={
                                                        formikProps.values.questionIndex.findIndex((rid: string) => rid === region.value) >= 0
                                                    }/>
                                                <ListItemText primary={region.label} />
                                            </>
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                                </Grid>
                            </Grid>
                            <Grid item xs={4}>
                                <LoadingButton
                                    size="medium"
                                    type="submit"
                                    variant="contained"
                                    loading={formikProps.isSubmitting}
                                >
                                    {'Download'}
                                </LoadingButton>
                            </Grid>
                        </Stack>
                    </Form>
                )}
            </Formik>
    );
}
