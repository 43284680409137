import SecureStorage from 'src/helpers/SecureStorage';
import { adminActionTypes } from 'src/types/actionTypes';
import { ErrorAction, SuccessAction } from 'src/types/responseTypes';
import { adminPayload, adminInfoProps } from 'src/types/adminTypes';

export type adminAction = SuccessAction<adminPayload> | ErrorAction
    | SuccessAction<adminInfoProps>
    | SuccessAction<undefined>
export const defaultAuthState: adminPayload = {
    admin: {}, accessToken: ''
}
const reducer = (state: adminPayload = defaultAuthState, action: adminAction): adminPayload => {
    switch (action.type) {
        case adminActionTypes.LOGIN_REQUEST:
            state = { ...state, admin: {} }
            return state
        case adminActionTypes.LOGIN_SUCCESS:
            action = <SuccessAction<adminPayload>>action
            state = { ...state, admin: { ...action.payload.admin } }
            SecureStorage.setItem('accessToken', action.payload.accessToken);
            return state
        case adminActionTypes.LOGIN_FAILURE:
            action = <ErrorAction>action
            return { ...state, admin: {} }
        case adminActionTypes.LOGOUT_SUCCESS:
            SecureStorage.removeItem('accessToken');
            return { ...state, admin: {} }
        case adminActionTypes.LOGOUT_FAILURE:
            action = <ErrorAction>action
            return state
        case adminActionTypes.REGISTER_REQUEST:
            state = { ...state, admin: {}, }
            return state
        case adminActionTypes.ADMIN_USER_SUCCESS:
            state = { ...state, admin: {}, }
            return state
        case adminActionTypes.REGISTER_SUCCESS:
            action = <SuccessAction<adminPayload>>action
            state = { ...state, admin: { ...action.payload.admin } }
            SecureStorage.setItem('accessToken', action.payload.accessToken);
            return state;
        case adminActionTypes.REGISTER_FAILURE:
            action = <ErrorAction>action
            return state
        default:
            return state
    }
}
export default reducer