export default function Drawer(theme:any) {
    return {
      MuiDrawer: {
        defaultProps: {
          borderRadius:8,
          paddingTop:10
        },
        styleOverrides: {
          root: {
            '.MuiDrawer-paper': {
                borderRadius:8,
                paddingTop:20
            },
            backgroundImage: 'none',
            borderRadius:8,
            paddingTop:10
          }
        }
      }
    };
  }
  