import { createStore, applyMiddleware, compose } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import rootReducer from 'src/reducers/index';
import thunk from 'redux-thunk';

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['admin']
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const middlewares = [thunk];
const enhancers = [applyMiddleware(...middlewares)];
export const store = createStore(persistedReducer, compose(...enhancers));
export const persistor = persistStore(store);
