import { useEffect, useState } from 'react';
import { Container, Tab } from '@mui/material';
import TabLayout from 'src/components/tab/TabLayout';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useSelector } from 'src/reducers';
import { DataGridRender } from 'src/components/table';
import { SystemLogRequest } from 'src/actions/serviceActions';
import Label from 'src/components/Label';
import TopBar from 'src/components/shared/TopBar';

export default function SystemLog() {

  const [pageNo, setPageNo] = useState(1);
  const [limit, setLimit] = useState(10);
  const [filters, setFilters] = useState<any>([]);
  const [search, setSearch] = useState<string>('');

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { loading, data, metadata } = useSelector(state => state.systemLog);


  useEffect(() => {
    dispatch(SystemLogRequest(filters));
  }, [filters]);

  const columns = [
    { field: 'createdAt', type: "string", headerName: 'Date/Time', filterable: false, headerAlign: "center", align: 'center', width: 250 },
    { field: 'name', type: "string", headerName: 'User', filterable: false, headerAlign: "left", align: 'left', flex: 1 },
    { field: 'activity', type: "string", headerName: 'Activity', filterable: false, headerAlign: "left", align: 'left', flex: 1 },
    { field: 'ipAddress', type: "string", headerName: 'IP Address', filterable: false, headerAlign: "left", align: 'left', flex: 1 },
    { field: 'device', type: "string", headerName: 'Device', filterable: false, headerAlign: "left", align: 'left', flex: 1 },
    { field: 'city', type: "string", headerName: 'City', filterable: false, headerAlign: "left", align: 'left', flex: 1 },
    { field: 'country', type: "string", headerName: 'Country', filterable: false, headerAlign: "left", align: 'left', flex: 1 },
    {
      field: 'module', type: "string", headerName: 'Module', filterable: false, headerAlign: "center", align: 'center', width: 300, renderCell: (params: any) => {
        return <Label
          variant="ghost"
          color='primary'
        >
          {params?.value}
        </Label>
      }
    },
  ];


  return (
    <Container maxWidth={false}>
      <TopBar title="Support" link="" buttonText="" />
      <TabLayout handleChange="" tabValue={3}>
        <Tab label="Identifiers" onClick={() => navigate("/admin/support")} value={1} />
        <Tab label="ShortCodes" onClick={() => navigate("/admin/support/shortcode")} value={2} />
        <Tab label="System Logs" onClick={() => navigate("/admin/support/log")} value={3} />
        <Tab label="Scheduled Jobs" onClick={() => navigate("/admin/support/job")} value={4} />
      </TabLayout>
      <DataGridRender
        loading={loading}
        filterTypes={['export', 'search']}
        limit={limit}
        search={search}
        filters={filters}
        pageNo={pageNo}
        columns={columns}
        data={data}
        setPageNo={setPageNo}
        setSearch={setSearch}
        metadata={metadata}
        setLimit={setLimit}
        setFilters={setFilters}
        exportFileName="Systemlogs"
      />
    </Container>
  );
}
