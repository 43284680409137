import AXIOS from 'src/helpers/Axios';
import { ThunkAction, ThunkDispatch } from "redux-thunk";
import Prefix from 'src/helpers/ApiPrefix';
import { adminActionTypes } from 'src/types/actionTypes';
import { ErrorAction, SuccessAction } from 'src/types/responseTypes';
import { adminLoginWithEmail, adminPayload } from 'src/types/adminTypes';
import { adminAction } from 'src/reducers/adminReducer';
import Headers from 'src/helpers/Headers';


export const LoginFailure = (message: string): ErrorAction => {
    return {
        type: adminActionTypes.LOGIN_FAILURE,
        payload: { message: message }
    }
}
export const LoginSuccess = (payload: adminPayload): SuccessAction<adminPayload> => {
    return {
        type: adminActionTypes.LOGIN_SUCCESS,
        payload: payload
    }
}

export const AdminUsersSuccess = (payload: adminPayload): SuccessAction<adminPayload> => {
    
    return {
        type: adminActionTypes.ADMIN_USER_SUCCESS,
        payload: payload,
    }
}


export const LoginRequest = (admin: adminLoginWithEmail, setMessageHandler: any, setSubmitting: any):
    ThunkAction<Promise<void>, {}, {}, adminAction> => {
    setMessageHandler({
        hasMessage: false,
        type: '',
        message: '',
    });
    return (dispatch: ThunkDispatch<{}, {}, adminAction>) => {
        return AXIOS.post(`${Prefix.api}/admin/login`, admin).then(res => {
            if (res) {
                const { data } = res;
                const admin: adminPayload = {
                    admin: {
                        logined: true,
                        adminInfo: data.result.admin,
                    },
                    accessToken: data.result.accessToken
                };
                dispatch(LoginSuccess(admin))
                setMessageHandler({
                    hasMessage: true,
                    type: 'success',
                    message: data.message,
                });
            }
        }).catch((e) => {
            if (e.response) {
                setSubmitting(false);
                setMessageHandler({
                    hasMessage: true,
                    type: 'error',
                    message: e.response.data.message,
                });
            }
            else {
                setSubmitting(false);
                setMessageHandler({
                    hasMessage: true,
                    type: 'error',
                    message: e.message,
                });
            }
        })
    }
}

export const LogoutRequest = (setMessageHandler: any):
    ThunkAction<Promise<void>, {}, {}, adminAction> => {
    setMessageHandler({
        hasMessage: false,
        type: '',
        message: '',
    });
    return (dispatch: ThunkDispatch<{}, {}, adminAction>) => {
    return AXIOS.post(`${Prefix.api}/admin/logout`, {},{ headers: Headers() }).then(res => {
        if (res) {
            dispatch({
                type: adminActionTypes.LOGOUT_SUCCESS,
                payload: {}
            })
            setMessageHandler({
                hasMessage: true,
                type: 'success',
                message: 'Logout Successfully',
            });
        }
    }).catch((e) => {
        if (e.response) {
            setMessageHandler({
                hasMessage: true,
                type: 'error',
                message: e.response.data.message,
            });
        }
        else {
            setMessageHandler({
                hasMessage: true,
                type: 'error',
                message: e.message,
            });
        }
    })
}
}

export const LogoutRequestOut = ():any => {
    return async (dispatch: ThunkDispatch<{}, {}, adminAction>) => {
        try {
            dispatch({
                type: adminActionTypes.LOGOUT_SUCCESS,
                payload: {}
            })
        } catch (e) {
            dispatch({
                type: adminActionTypes.LOGOUT_FAILURE,
                payload: {
                    message: 'Can not logout now!'
                }
            })
        }
    }
}

export const AdminUserAllRequestData = ():
    ThunkAction<Promise<void>, {}, {}, adminAction> => {
    return (dispatch: ThunkDispatch<{}, {}, adminAction>) => {
        return AXIOS.post(`${Prefix.api}/admin/adminusers`,{}, { headers: Headers() }).then(res => {
            if (res) {
                const { data } = res;
                const projects: adminPayload = data.result
                dispatch(AdminUsersSuccess(projects))
            }
        }).catch((e) => {
            if (e.response) {
                dispatch(LoginFailure(e.response.data.message));
            }
            else {
                dispatch(LoginFailure(e.message));
            }
        })
    }
}



export const RegisterFailure = (e: string): ErrorAction => {
    return {
        payload: {
            message: e
        },
        type: adminActionTypes.REGISTER_FAILURE
    }
}

export const RegisterSuccess = (e: string): ErrorAction => {
    return {
        payload: {
            message: e
        },
        type: adminActionTypes.REGISTER_SUCCESS
    }
}

