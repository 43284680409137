import { clientActionTypes } from "src/types/actionTypes";
import { Client, ClientList } from "src/types/clientTypes";
import { ErrorAction, SuccessAction } from "src/types/responseTypes";

export type clientAction = SuccessAction<ClientList> | SuccessAction<Client> | SuccessAction<any> | ErrorAction
export const defaultClientState: ClientList = { loading:true, redirectTo: '',clients:[], client: {}, data: [], metadata: {} };

const reducer = (state: ClientList = defaultClientState, action: clientAction): ClientList => {
    switch (action.type) {
        case clientActionTypes.CLIENT_REQUEST:
            state = { ...state,loading: true }
            return state
        case clientActionTypes.FETCH_CLIENT_SUCCESS:
            action = <SuccessAction<ClientList>>action
            state = { ...state,loading: false, redirectTo: '', data: action.payload.data, metadata: action.payload.metadata }
            return state
        case clientActionTypes.FETCH_CLIENT_ALL_SUCCESS:
            action = <SuccessAction<ClientList>>action
            state = { ...state,loading: false, clients: action.payload }
            return state
        case clientActionTypes.UPDATE_CLIENT_SUCCESS:
            action = <SuccessAction<any>>action
            state = { ...state,loading: false, redirectTo: '/admin/client', data: state.data?.map(dat => [ dat._id ==action.payload._id && Object.assign(dat ,action.payload)].find(o => o._id == dat._id) || dat) }
            return state
        case clientActionTypes.FETCH_SINGLE_CLIENT_SUCCESS:
            action = <SuccessAction<Client>>action
            state = { ...state,loading: false, client: action.payload }
            return state
        case clientActionTypes.DELETE_CLIENT_SUCCESS:
            action = <SuccessAction<any>>action
            state = { ...state,loading: false, data: state.data?.filter(dat => dat._id !== action.payload._id), metadata: { ...state.metadata, limit: Number(state.metadata?.limit) - 1, total: Number(state.metadata?.total) - 1 } }
            return state
        case clientActionTypes.ADD_CLIENT_SUCCESS:
            action = <SuccessAction<Client>>action
            state = { ...state,loading: false, redirectTo: '/admin/client', data: state.data?.concat(action.payload) }
            return state
        case clientActionTypes.CLIENT_FAILURE:
            action = <ErrorAction>action
            return { ...state ,loading: false, }
        default:
            return state
    }
}
export default reducer