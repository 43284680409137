import {  Container } from '@mui/material';
import { useDispatch } from 'react-redux';
import MessageContext from 'src/context/MessageContext';
import { useContext } from 'react';
import { CategoryAddRequest } from 'src/actions/categoryActions';
import { Category } from 'src/types/categoryTypes';
import { CategoryForm } from 'src/components/admin/_dashboard/service/category';
import TopBarForm from 'src/components/shared/TopBarForm';

export default function AddCategory() {
    const { setMessageHandler } = useContext<any>(MessageContext);
    const dispatch = useDispatch();
    const initialValues: any = null;

    const submitForm = async (values: Category, setSubmitting: any) => {
        setTimeout(() => dispatch(CategoryAddRequest(values, setMessageHandler, setSubmitting)), 200);
    }

    return (
        <Container maxWidth={false}>
            <TopBarForm title="Add Category"  link="/admin/library"/>
            <CategoryForm initialValues={initialValues} submitForm={submitForm} />
        </Container>
    );
}
