import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Quota, QuotaOption } from 'src/types/quotaTypes';
import { TextField, IconButton, Typography, Box } from '@mui/material';
import { QualificationOption } from 'src/types/qualificationTypes';
import { Formik, FieldArray } from 'formik';
import SaveIcon from '@mui/icons-material/Save';

export interface FormValues {
    quota: Quota,
    sampleSize?: Number,
    updateQuotaSample: any,
    removeQuota: any,
    quotaValue: QuotaOption[],
    quotaOptions: QualificationOption[],
}



export default function QuotaSizeRender({ quota, sampleSize, quotaValue, quotaOptions, updateQuotaSample }: FormValues) {

    const getQuotaOptionLabel = (quotaOptions: QualificationOption[], quotaOptionId: string | undefined) => {
        var option = quotaOptions.find(quotaOpt => quotaOptionId == quotaOpt._id)
        return option?.text;
    }
    const getTotalQuotaOptionSize = (quotaValue: any) => {
        var total = 0;
        quotaValue.map((qu: any) => total += qu.quotaSize);
        return total;
    }

    return (
        <TableContainer component={Paper}>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Quota Name</TableCell>
                        <TableCell align="right">Quota Size</TableCell>
                        <TableCell align="right">%</TableCell>
                    </TableRow>
                </TableHead>
                <Formik
                    initialValues={{ quotaValue }}
                    onSubmit={(values, { setSubmitting }) => {
                        updateQuotaSample(quota._id, values, setSubmitting);
                    }}
                    render={formikProps => (
                        <>
                            <FieldArray name="quotaValue" >
                                {({ push, remove }) => (
                                    <TableBody>
                                        {formikProps.values.quotaValue.length > 0 &&
                                            formikProps.values.quotaValue.map((field: any, index: number) => (
                                                <TableRow
                                                    key={field.quotaOptionId}
                                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                >
                                                    <TableCell component="th" scope="row">
                                                        {getQuotaOptionLabel(quotaOptions, field.quotaOptionId)}
                                                    </TableCell>
                                                    <TableCell align="right"><TextField
                                                        sx={{ maxWidth: 50 }}
                                                        variant="standard"
                                                        label=""
                                                        name={`quotaValue.${index}.quotaSize`}
                                                        onChange={formikProps.handleChange}
                                                        type="number"
                                                        defaultValue={field.quotaSize}
                                                        placeholder="" /></TableCell>
                                                    <TableCell align="right">
                                                        {((Number(field.quotaSize) / Number(sampleSize)) * 100).toFixed(2) + ' %'}
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                    </TableBody>
                                )}
                            </FieldArray>
                            <Box sx={{ display: 'flex', alignItems: 'center', mr: 2, position: 'absolute', right: 20 }}>
                                <Typography color={getTotalQuotaOptionSize(formikProps.values.quotaValue) !== sampleSize ? 'error' : 'black'} variant="h6">{getTotalQuotaOptionSize(formikProps.values.quotaValue)}</Typography>
                                <IconButton
                                    sx={{ ml: 32 }}
                                    onClick={() => formikProps.submitForm()}
                                    disabled={formikProps.isSubmitting}
                                >
                                    <SaveIcon />
                                </IconButton>
                            </Box>
                        </>
                    )}
                />
            </Table>
        </TableContainer>
    );
}
