import { useContext, useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Container, Typography, Box, Tooltip, Tab, IconButton } from '@mui/material';
import { useDispatch } from 'react-redux';
import { useSelector } from 'src/reducers';
import swal from 'sweetalert';
import { MoreMenu, DataGridRender } from 'src/components/table';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { ProjectApiCircleRequest, ProjectCircleAddFromFetchRequest } from 'src/actions/projectActions';
import TopBar from 'src/components/shared/TopBar';
import { MessageContext } from 'src/context';
import TabLayout from 'src/components/tab/TabLayout';
import ResponsiveDialog from 'src/components/dialog/ResponsiveDialog';

export default function FullCircleProjectList() {

  const [searchParams] = useSearchParams();
  const { setMessageHandler } = useContext<any>(MessageContext);
  var pageNo: any = searchParams.get("p") || 1
  const [limit, setLimit] = useState(20);
  const [filters, setFilters] = useState<any>({});
  const [dialog, setDialog] = useState<string>('');
  const [dialogQualification, setDialogQualification] = useState<string>('');

  const dispatch = useDispatch();
  const { loading, fetchProjects, metadata } = useSelector(state => state.project);

  const navigate = useNavigate();

  useEffect(() => {
    dispatch(ProjectApiCircleRequest(filters));
  }, [filters]);



  const addToProject = (project: any) => {
    swal({
      title: "This action will create a new project",
      icon: "warning",
      dangerMode: false,
      buttons: ["No!", true],
    })
      .then((willDelete) => {
        if (willDelete) {
          project.clientId = filters.client;
          dispatch(ProjectCircleAddFromFetchRequest(project, setMessageHandler));
        } else {
        }
      });
  }


  const setPageNo = (page: any) => {
    navigate(`/admin/project?p=${page}`);
  }


  const reloadAction = () => {
    dispatch(ProjectApiCircleRequest(filters));
  }

  const columnsRedirectQuotas = [
    {
      field: 'remaining', type: "string", headerName: 'Requirement', filterable: false, headerAlign: "left", align: 'left', width: 200, renderCell: ({ value }: any) => {
        return <Tooltip title={value}>
          <Typography variant="subtitle2">{value}</Typography>
        </Tooltip>
      }
    },
    {
      field: 'conditions', type: "string", headerName: 'Condition', filterable: false, headerAlign: "left", align: 'left', flex: 1, renderCell: ({ value }: any) => {
        return value.map((val: any,index:any) =>
          <Box>
            <Typography sx={{mr:2}}>{` (${index+1}) QuestionName : ${val.question} , Options : ${val.options} , type : ${val.type} `}</Typography>
          </Box>
        );
      }
    },
  ];


  const columnsRedirectQualifications = [
    {
      field: 'question', type: "string", headerName: 'Question Name', filterable: false, headerAlign: "left", align: 'left', width: 200, renderCell: ({ value }: any) => {
        return <Tooltip title={value}>
          <Typography variant="subtitle2">{value}</Typography>
        </Tooltip>
      }
    },
    {
      field: 'type', type: "string", headerName: 'Question Type', filterable: false, headerAlign: "left", align: 'left', width: 200, renderCell: ({ value }: any) => {
        return <Tooltip title={value}>
          <Typography variant="subtitle2">{value}</Typography>
        </Tooltip>
      }
    },
    {
      field: 'options', type: "string", headerName: 'Question Option', filterable: false, headerAlign: "left", align: 'left', flex: 1, renderCell: ({ value }: any) => {
        return <Tooltip title={value}>
          <Typography variant="subtitle2">{value+" "}</Typography>
        </Tooltip>
      }
    },
  ];



  const dialogActionsQuotas = (projectRedirects: any, id: string, name: string, title: string) => {
    var data=projectRedirects.length ? projectRedirects.forEach((pro:any,index:any) =>  pro.id = index+1) : []
    return <Box>
      <IconButton onClick={() => setDialog(id)}>
        <VisibilityIcon />
      </IconButton>
      <ResponsiveDialog openDialog={dialog == id} setOpenDialog={setDialog} title={title} subTitle={name}  >
          <DataGridRender
            height={400}
            width={1160}
            filterTypes={['export']}
            data={projectRedirects}
            filters="{}"
            exportFileName="Quotas"
            limit={limit}
            pageNo={pageNo}
            columns={columnsRedirectQuotas}
            setPageNo={setPageNo}
            setLimit={setLimit}
            setFilters={setFilters}
            hideFooterPagination={true}
          />
      </ResponsiveDialog>
    </Box>
  }


  const dialogActionsQualification = (projectRedirects: any, id: string, name: string, title: string) => {
    return <Box>
      <IconButton onClick={() => setDialogQualification(id)}>
        <VisibilityIcon />
      </IconButton>
      <ResponsiveDialog openDialog={dialogQualification == id} setOpenDialog={setDialogQualification} title={title} subTitle={name}  >
          <DataGridRender
            height={400}
            width={1160}
            filterTypes={['export']}
            data={projectRedirects}
            filters="{}"
            exportFileName="Quotas"
            limit={limit}
            pageNo={pageNo}
            columns={columnsRedirectQualifications}
            setPageNo={setPageNo}
            setLimit={setLimit}
            setFilters={setFilters}
            hideFooterPagination={true}
          />
      </ResponsiveDialog>
    </Box>
  }

  const columns = [
    {
      field: 'id', type: "string", headerName: 'Project ID', filterable: false, headerAlign: "left", align: 'left', width: 100
    },
    {
      field: 'name', type: "string", headerName: 'Project Title', filterable: false, headerAlign: "left", align: 'left', flex: 1
    },
    {
      field: 'locale', type: "string", headerName: 'Country', filterable: false, headerAlign: "center", align: 'center', width: 120
    },

    {
      field: 'remaining', type: "string", headerName: 'Requirement', filterable: false, headerAlign: "center", align: 'center', width: 120,
    },
    {
      field: 'ir', type: "string", headerName: 'IR', filterable: false, headerAlign: "left", align: 'left', width: 100, renderCell: ({ row }: any) => {
        return row['IR'] || row['ir'];
      }
    },
    {
      field: 'loi', type: "string", headerName: 'LOI', filterable: false, headerAlign: "left", align: 'left', width: 100,
    },
    { field: 'loi', type: "string", headerName: 'LOI', filterable: false, width: 100, headerAlign: "center", align: 'center' },
    {
      field: 'cpi', type: "string", headerName: 'Reward', filterable: false, width: 100, headerAlign: "center", align: 'center'
    },
    {
      field: 'quotas', type: "string", headerName: 'Quotas', disableExport: true, filterable: false, width: 100, headerAlign: "center", align: 'center', renderCell: ({ row }: any) => {
        return dialogActionsQuotas(row.quotas , row.id , row.name , 'Quotas');
      },
    },
    {
      field: 'qualifications', type: "string", headerName: 'Qualifications', disableExport: true, filterable: false, width: 120, headerAlign: "center", align: 'center', renderCell: ({ row }: any) => {
        return dialogActionsQualification(row.qualifications, row.id , row.name , 'Qualification');
      },
    },
    { field: 'actions', headerName: '', width: 60, type: 'actions', getActions: ({ id,row }: any) => [<MoreMenu id={id} project={row} name={row['projectCode'] + "_" + row['projectName']} projectStatus={row['projectStatus']} exportFileName="Project" addToProject={addToProject} />] },
  ];

  return (
    <Container maxWidth={false}>
      <TopBar title="Projects" link="" buttonText="Add Project" />
      <TabLayout handleChange="" tabValue={4}>
        <Tab label="Precision" onClick={() => navigate("/admin/api")} value={1} />
        <Tab label="Logit" onClick={() => navigate("/admin/api/logit")} value={2} />
        <Tab label="Toulna" onClick={() => navigate("/admin/api/toluna")} value={3} />
        <Tab label="Circle" onClick={() => navigate("/admin/api/circle")} value={4} />
        <Tab label="UniMrkt" onClick={() => navigate("/admin/api/mrkt")} value={5} />
      </TabLayout>
      <DataGridRender
        reloadAction={reloadAction}
        getRowClassName={(params: any) => params.row['complete'] && params.row['complete'] >= params.row['sampleSize'] && params.row['sampleSize'] ? `data-grid-warning` : 'super-app-theme'}
        loading={loading}
        filterTypes={['export', 'countries']}
        data={fetchProjects}
        exportFileName="Projects"
        metadata={metadata}
        filters={filters}
        limit={limit}
        hideFooterPagination={true}
        pageNo={pageNo}
        columns={columns}
        setPageNo={setPageNo}
        setLimit={setLimit}
        setFilters={setFilters}
      />
    </Container>
  );
}
