import { useContext, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Container, Typography, Box, Tooltip, Tab, Button } from '@mui/material';
import { useDispatch } from 'react-redux';
import { useSelector } from 'src/reducers';
import { sentenceCase } from 'change-case';
import swal from 'sweetalert';
import { MoreMenu, DataGridRender } from 'src/components/table';
import Label from 'src/components/Label';
import { ProjectInvoiceAllRequest, ProjectGroupExportRequest, ProjectGroupSupplierMapExportRequest, ProjectRevokeRequest } from 'src/actions/projectActions';
import { ProjectStatus } from 'src/constants';
import TopBar from 'src/components/shared/TopBar';
import { fShortenNumber } from 'src/utils/formatNumber';
import TabLayout from 'src/components/tab/TabLayout';
import { MessageContext } from 'src/context';
import { Icon } from '@iconify/react';
import plusFill from '@iconify/icons-eva/plus-fill';

export default function InvoiceProjectList() {

  const { setMessageHandler } = useContext<any>(MessageContext);

  const [limit, setLimit] = useState(20);
  const [pageNo, setPageNo] = useState(1);
  const [status] = useState(false);
  const [filters, setFilters] = useState<any>({});

  const dispatch = useDispatch();
  const { loading, metadata, data } = useSelector(state => state.project);

  const navigate = useNavigate();

  useEffect(() => {
    dispatch(ProjectInvoiceAllRequest(filters, pageNo, limit, status))
  }, [pageNo, limit, filters, status]);

  const editAction = (id: string) => {
    navigate(`/admin/invoice/${id}/edit`, { replace: true });
  }
  const exportAction = (id: string, name: string) => {
    dispatch(ProjectGroupExportRequest({ projectId: id }, name));
  }
  const exportSupplierMap = (id: string, name: string) => {
    dispatch(ProjectGroupSupplierMapExportRequest({ projectId: id }, name));
  }
  const reloadAction = () => {
    dispatch(ProjectInvoiceAllRequest(filters, pageNo, limit, status));
  }


  const revokeProject = (id: string) => {
    swal({
      title: "Are you sure?",
      icon: "warning",
      dangerMode: true,
      buttons: ["No!", true],
    })
      .then((willDelete) => {
        if (willDelete) {
          dispatch(ProjectRevokeRequest(id, setMessageHandler));
        } else {
        }
      });
  }

  const columns = [
    {
      field: 'projectCode', type: "string", headerName: 'Project #', filterable: false, headerAlign: "center", align: 'center', width: 100, pinnable: true, renderCell: ({ id, value }: any) => {
        return <Link to={`/admin/project/${id}/childs`}>
          <Typography color="primary" >{value}</Typography>
        </Link>
      }
    },
    {
      field: 'projectName', type: "string", headerName: 'Project Title', filterable: false, headerAlign: "left", align: 'left', flex: 1, renderCell: ({ id, value }: any) => {
        return <Tooltip title={value}>
          <Typography variant="subtitle2">{value}</Typography>
        </Tooltip>
      }
    },
    {
      field: 'clienName', type: "string", headerName: 'Client Name', filterable: false, headerAlign: "left", align: 'left', width: 150, renderCell: ({ value }: any) => {
        return <Tooltip title={value}>
          <Typography variant="subtitle2">{value}</Typography>
        </Tooltip>
      }
    },
    {
      field: 'approved', type: "string", headerName: 'Approved', filterable: false, width: 120, headerAlign: "center", align: 'center', valueGetter: ({ row }: any) => {
        return `${row['approved']}/${row['supplierApproved']}`;
      },
    },
    {
      field: 'cpi', type: "string", headerName: 'Project CPI', filterable: false, width: 120, headerAlign: "center", align: 'center', valueGetter: ({ row }: any) => {
        return `${fShortenNumber(row['cpi'])}`;
      },
    },
    {
      field: 'total', type: "string", headerName: 'Amount', filterable: false, width: 150, headerAlign: "center", align: 'center', valueGetter: ({ row }: any) => {
        return `$ ${(row['approved'] * row['cpi']).toFixed(2)}`;
      },
    },
    {
      field: 'projectStatus', headerName: 'Status', type: "number", width: 120, headerAlign: "center", align: 'center', valueFormatter: ({ value }: any) => ProjectStatus.find(status => status.value == value)?.label,
      renderCell: (params: any) => {
        var status: any = ProjectStatus.find(status => status.value == params.value);
        return <Label
          variant="ghost"
          color={status?.color}
        >
          {sentenceCase(status.label)}
        </Label>
      }
    },
    { field: 'actions', headerName: '', width: 60, type: 'actions', getActions: ({ id, row }: any) => [<MoreMenu id={id} name={row['projectCode'] + "_" + row['projectName']} projectStatus={row['projectStatus']} exportFileName="Project" reconsileAction="" cloneAction='' addAction='' editAction={editAction} deleteAction="" revokeProject={revokeProject} exportSupplierMap={exportSupplierMap} exportAction={exportAction} />] },
  ];

  return (
    <Container maxWidth={false}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Box>
          <Typography sx={{ ml: 1 }} variant="h5">
            Accounts
          </Typography>
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <TopBar title="" link="/admin/invoice/generate" buttonText="Generate Invoice" />
          <Box sx={{ height: 30, ml: 1, mt: 1 }}>
            <Button
              startIcon={<Icon icon={plusFill} />}
              variant="contained"
              size="medium"
              onClick={() => navigate('/admin/invoice/generate/manual')}
            >
              Generate Invoice Manual
            </Button>
          </Box>
        </Box>
      </Box>
      <TabLayout handleChange="" tabValue={1}>
        <Tab label="Ready To Invoice" href="/admin/invoice" value={1} />
        <Tab label="Archieved" href="/admin/invoice/archieved" value={3} />
        <Tab label="Invoices" href="/admin/invoice/list" value={2} />
        <Tab label="Finances" href="/admin/invoice/supplier" value={4} />
        <Tab label="Purchase Order" href="/admin/invoice/supplier/list" value={5} />
      </TabLayout>
      <DataGridRender
        reloadAction={reloadAction}
        getRowClassName={(params: any) => params.row['complete'] && params.row['complete'] >= params.row['sampleSize'] && params.row['sampleSize'] ? `data-grid-warning` : 'super-app-theme'}
        loading={loading}
        filterTypes={['client', 'supplier', 'user', 'export', 'search']}
        data={data}
        exportFileName="Projects"
        metadata={metadata}
        limit={limit}
        filters={filters}
        pageNo={pageNo}
        columns={columns}
        setPageNo={setPageNo}
        setLimit={setLimit}
        setFilters={setFilters}
      />
    </Container>
  );
}
