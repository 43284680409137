import { ThunkAction, ThunkDispatch } from "redux-thunk";
import Prefix from 'src/helpers/ApiPrefix';
import AXIOS from 'src/helpers/Axios';
import Headers from 'src/helpers/Headers';
import { supplierAction } from "src/reducers/supplierReducer";
import { supplierActionTypes } from "src/types/actionTypes";
import { Supplier, SupplierList } from "src/types/supplierTypes";
import { ErrorAction, SuccessAction } from "src/types/responseTypes";

export const SupplierFailure = (message: string): ErrorAction => {
    return {
        type: supplierActionTypes.SUPPLIER_FAILURE,
        payload: { message: message }
    }
}
export const SupplierSuccess = (payload: SupplierList): SuccessAction<SupplierList> => {
    return {
        type: supplierActionTypes.FETCH_SUPPLIER_SUCCESS,
        payload: payload,
    }
}
export const SupplierAllSuccess = (payload: SupplierList): SuccessAction<SupplierList> => {
    return {
        type: supplierActionTypes.FETCH_SUPPLIER_ALL_SUCCESS,
        payload: payload,
    }
}

export const SupplierAddSuccess = (payload: Supplier): SuccessAction<Supplier> => {
    return {
        type: supplierActionTypes.ADD_SUPPLIER_SUCCESS,
        payload: payload,
    }
}
export const SupplierSingleSuccess = (payload: Supplier): SuccessAction<Supplier> => {
    return {
        type: supplierActionTypes.FETCH_SINGLE_SUPPLIER_SUCCESS,
        payload: payload,
    }
}

export const SupplierUpdateSuccess = (payload: any): SuccessAction<any> => {
    return {
        type: supplierActionTypes.UPDATE_SUPPLIER_SUCCESS,
        payload: payload,
    }
}


export const SupplierDeleteSuccess = (payload: any): SuccessAction<any> => {
    return {
        type: supplierActionTypes.DELETE_SUPPLIER_SUCCESS,
        payload: payload,
    }
}


export const SupplierAllRequest = (filter: any, search: string, pageNo: number, limit: number, status: boolean):
    ThunkAction<Promise<void>, {}, {}, supplierAction> => {
    return (dispatch: ThunkDispatch<{}, {}, supplierAction>) => {
        dispatch({ type:supplierActionTypes.SUPPLIER_REQUEST,payload: "" });
        return AXIOS.post(`${Prefix.api}/admin/suppliers`, { filter, search, pageNo, limit, status }, { headers: Headers() }).then(res => {
            if (res) {
                const { data } = res;
                const suppliers: SupplierList = data.result
                dispatch(SupplierSuccess(suppliers))

            }
        }).catch((e) => {
            if (e.response) {
                dispatch(SupplierFailure(e.response.data.message));
            }
            else {
                dispatch(SupplierFailure(e.message));
            }
        })
    }
}

export const SupplierAllRequestData = ():
    ThunkAction<Promise<void>, {}, {}, supplierAction> => {
    return (dispatch: ThunkDispatch<{}, {}, supplierAction>) => {
        return AXIOS.get(`${Prefix.api}/admin/suppliers/all`, { headers: Headers() }).then(res => {
            if (res) {
                const { data } = res;
                const suppliers: SupplierList = data.result
                dispatch(SupplierAllSuccess(suppliers))
            }
        }).catch((e) => {
            if (e.response) {
                dispatch(SupplierFailure(e.response.data.message));
            }
            else {
                dispatch(SupplierFailure(e.message));
            }
        })
    }
}


export const SupplierFinancesRequestData = (filter: any, pageNo: number, limit: number, status: boolean):
    ThunkAction<Promise<void>, {}, {}, supplierAction> => {
    return (dispatch: ThunkDispatch<{}, {}, supplierAction>) => {
        return AXIOS.post(`${Prefix.api}/admin/supplier/finances/all`, { filter, pageNo, limit, status } ,{ headers: Headers() }).then(res => {
            if (res) {
                const { data } = res;
                const suppliers: SupplierList = data.result
                dispatch(SupplierAllSuccess(suppliers))
            }
        }).catch((e) => {
            if (e.response) {
                dispatch(SupplierFailure(e.response.data.message));
            }
            else {
                dispatch(SupplierFailure(e.message));
            }
        })
    }
}




export const SupplierAddRequest = (supplier: Supplier, setMessageHandler: any, setSubmitting: any):
    ThunkAction<Promise<void>, {}, {}, supplierAction> => {
    setMessageHandler({
        hasMessage: false,
        type: '',
        message: '',
    });
    return (dispatch: ThunkDispatch<{}, {}, supplierAction>) => {
        return AXIOS.post(`${Prefix.api}/admin/supplier/add`, supplier, { headers: Headers() }).then(res => {
            if (res) {
                const { data } = res;
                const supplier: Supplier = data.result;
                dispatch(SupplierAddSuccess(supplier))
                setSubmitting(false);
                setMessageHandler({
                    hasMessage: true,
                    type: 'success',
                    message: data.message,
                });
            }
        }).catch((e) => {
            if (e.response) {
                setSubmitting(false);
                dispatch(SupplierFailure(e.response.data.message));
                setMessageHandler({
                    hasMessage: true,
                    type: 'error',
                    message: e.response.data.message,
                });
            }
            else {
                setSubmitting(false);
                dispatch(SupplierFailure(e.message));
                setMessageHandler({
                    hasMessage: true,
                    type: 'error',
                    message: e.message,
                });
            }
        })
    }
}




export const SupplierUpdateRequest = (catid: string, supplier: Supplier, setMessageHandler: any, setSubmitting: any):
    ThunkAction<Promise<void>, {}, {}, supplierAction> => {
    setMessageHandler({
        hasMessage: false,
        type: '',
        message: '',
    });
    return (dispatch: ThunkDispatch<{}, {}, supplierAction>) => {
        return AXIOS.put(`${Prefix.api}/admin/supplier/update/${catid}`, supplier, { headers: Headers() }).then(res => {
            if (res) {
                const { data } = res;
                const supplier: Supplier = data.result;
                dispatch(SupplierUpdateSuccess(supplier))
                setSubmitting(false);
                setMessageHandler({
                    hasMessage: true,
                    type: 'success',
                    message: data.message,
                });
            }
        }).catch((e) => {
            if (e.response) {
                setSubmitting(false);
                setMessageHandler({
                    hasMessage: true,
                    type: 'error',
                    message: e.response.data.message,
                });
                dispatch(SupplierFailure(e.response.data.message));
            }
            else {
                setSubmitting(false);
                setMessageHandler({
                    hasMessage: true,
                    type: 'error',
                    message: e.message,
                });
                dispatch(SupplierFailure(e.message));
            }
        })
    }
}

export const SupplierStatusUpdateRequest = (supplierid: string, supplier: Supplier, setMessageHandler: any):
    ThunkAction<Promise<void>, {}, {}, supplierAction> => {
    setMessageHandler({
        hasMessage: false,
        type: '',
        message: '',
    });
    return (dispatch: ThunkDispatch<{}, {}, supplierAction>) => {
        return AXIOS.put(`${Prefix.api}/admin/supplier/update/${supplierid}`, supplier, { headers: Headers() }).then(res => {
            if (res) {
                const { data } = res;
                const supplier: Supplier = data.result;
                dispatch(SupplierUpdateSuccess(supplier))
                setMessageHandler({
                    hasMessage: true,
                    type: 'success',
                    message: data.message,
                });
            }
        }).catch((e) => {
            if (e.response) {
                setMessageHandler({
                    hasMessage: true,
                    type: 'error',
                    message: e.response.data.message,
                });
                dispatch(SupplierFailure(e.response.data.message));
            }
            else {
                setMessageHandler({
                    hasMessage: true,
                    type: 'error',
                    message: e.message,
                });
                dispatch(SupplierFailure(e.message));
            }
        })
    }
}


export const SupplierSingleRequest = (catid: string,body:any):
    ThunkAction<Promise<void>, {}, {}, supplierAction> => {
    return (dispatch: ThunkDispatch<{}, {}, supplierAction>) => {
        return AXIOS.post(`${Prefix.api}/admin/supplier/${catid}`,{}, { headers: Headers() }).then(res => {
            if (res) {
                const { data } = res;
                const supplier: Supplier = data.result;
                dispatch(SupplierSingleSuccess(supplier));
            }
        }).catch((e) => {
            if (e.response) {
                dispatch(SupplierFailure(e.response.data.message));
            }
            else {
                dispatch(SupplierFailure(e.message));
            }
        })
    }
}


export const SupplierDeleteRequest = (id: string, body: Supplier, setMessageHandler: any):
    ThunkAction<Promise<void>, {}, {}, supplierAction> => {
    setMessageHandler({
        hasMessage: false,
        type: '',
        message: '',
    });
    return (dispatch: ThunkDispatch<{}, {}, supplierAction>) => {
        return AXIOS.put(`${Prefix.api}/admin/supplier/update/${id}`, body, { headers: Headers() }).then(res => {
            if (res) {
                const { data } = res;
                const supplier: Supplier = data.result;
                dispatch(SupplierDeleteSuccess(supplier))
                setMessageHandler({
                    hasMessage: true,
                    type: 'success',
                    message: data.message,
                });
            }
        }).catch((e) => {
            if (e.response) {
                dispatch(SupplierFailure(e.response.data.message));
                setMessageHandler({
                    hasMessage: true,
                    type: 'error',
                    message: e.response.data.message,
                });
            }
            else {
                dispatch(SupplierFailure(e.message));
                setMessageHandler({
                    hasMessage: true,
                    type: 'error',
                    message: e.message,
                });
            }
        })
    }
}










