import { styled } from '@mui/material/styles';
import { Card, Typography } from '@mui/material';


const RootStyle = styled(Card)(({ theme }) => ({
  boxShadow: 'none',
  textAlign: 'center',
  padding: theme.spacing(2, 0),
  color: theme.palette.error.dark,
  backgroundColor: theme.palette.error.light
}));



export default function AppBugReports({title , value }:any) {
  return (
    <RootStyle>
      <Typography variant="h3">{value}</Typography>
      <Typography variant="subtitle2" sx={{ opacity: 0.72 }}>
        {title}
      </Typography>
    </RootStyle>
  );
}
