import { useEffect } from 'react';
import { matchPath, Outlet, useLocation, useNavigate, useOutletContext } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import DashboardNavbar from './DashboardNavbar';
import { useSelector } from 'src/reducers';
import { adminRoutes } from 'src/config/routes';
import { adminInfoProps } from 'src/types/adminTypes';

const APP_BAR_MOBILE = 64;
const APP_BAR_DESKTOP = 92;

const RootStyle = styled('div')({
  display: 'flex',
  minHeight: '100%',
  overflow: 'hidden'
});

const MainStyle = styled('div')(({ theme }) => ({
  flexGrow: 1,
  overflow: 'auto',
  minHeight: '100%',
  paddingTop: APP_BAR_MOBILE + 20,
  paddingBottom: theme.spacing(10),
  [theme.breakpoints.up('lg')]: {
    paddingTop: APP_BAR_DESKTOP,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  }
}));

type ContextType = { adminUser:adminInfoProps | null };

export default function DashboardLayout() {

  const adminData = useSelector(state => state.admin);
  const navigate = useNavigate();
  const logined = !!adminData?.admin?.adminInfo;
  const adminUser = adminData?.admin?.adminInfo;
  const role =adminUser?.role;
  const { pathname } = useLocation();
  const match = (path:string) => (path ? !!matchPath({ path, end: true }, pathname) : false);
  var currentRoute = adminRoutes.find(adminroute => match(adminroute.path) == true );
  var routeActive = currentRoute?.roles.includes(role || '');

  useEffect(() => {
    if (!routeActive) navigate('/404')
  }, [routeActive]);


  useEffect(() => {
    if (!logined) navigate('/login')
  }, [logined]);

  return (
    <RootStyle>
      <DashboardNavbar adminUser={adminUser} adminRoutes={adminRoutes} />
      <MainStyle>
        <Outlet context={{ adminUser }} />
      </MainStyle>
    </RootStyle>
  );
}

export function useUser() {
  return useOutletContext<ContextType>();
}
