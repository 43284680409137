import { useContext } from 'react';
import {  Container } from '@mui/material';
import { useDispatch } from 'react-redux';
import { SupplierUpdateRequest, SupplierSingleRequest } from 'src/actions';
import { SupplierForm } from 'src/components/admin/_dashboard/supplier';
import { useParams } from 'react-router-dom';
import { useEffect } from 'react';
import { useSelector } from 'src/reducers';
import { Supplier } from 'src/types/supplierTypes';
import MessageContext from 'src/context/MessageContext';
import TopBarForm from 'src/components/shared/TopBarForm';

export default function EditSupplier() {

    const { setMessageHandler } = useContext<any>(MessageContext);
    const dispatch = useDispatch();
    const { supplierid }: any = useParams<any>();
    const submitForm = async (values: Supplier, setSubmitting: any) => {
        setTimeout(() => dispatch(SupplierUpdateRequest(supplierid, values, setMessageHandler, setSubmitting)), 200);
    }
    const { supplier } = useSelector(state => state.supplier);
    const initialValues: Supplier = supplier

    useEffect(() => {
        dispatch(SupplierSingleRequest(supplierid,{}));
    }, [supplierid])
    return (
        <Container maxWidth={false}>
            <TopBarForm title="Edit Supplier" link="/admin/supplier" />
            {initialValues._id && <SupplierForm submitForm={submitForm} initialValues={initialValues} />}
        </Container>
    );
}
