import {  Container } from '@mui/material';
import { useDispatch } from 'react-redux';
import { BidForm } from 'src/components/admin/_dashboard/bid';
import { Bid } from 'src/types/bidTypes';
import MessageContext from 'src/context/MessageContext';
import { useContext } from 'react';
import { BidAddRequest } from 'src/actions/bidActions';
import TopBarForm from 'src/components/shared/TopBarForm';

export default function AddBid() {
    const { setMessageHandler } = useContext<any>(MessageContext);
    const dispatch = useDispatch();
    const initialValues: any = null;

    const submitForm = async (values: Bid, setSubmitting: any) => {
        setTimeout(() => dispatch(BidAddRequest(values, setMessageHandler, setSubmitting)), 200);
    }

    return (
        <Container maxWidth={false}>
            <TopBarForm title="Add Bid" link="/admin/bid" />
            <BidForm initialValues={initialValues} submitForm={submitForm} />
        </Container>
    );
}
