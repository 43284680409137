import { styled } from '@mui/material/styles';
import { Card, Stack, TextField, Grid } from '@mui/material';
import { Form, Formik, FormikProps } from 'formik';
import * as Yup from 'yup';
import { useEffect } from 'react';
import { useSelector } from 'src/reducers';
import { useNavigate } from 'react-router-dom';
import { LoadingButton } from '@mui/lab';
import { Category } from 'src/types/categoryTypes';

const CardStyle = styled(Card)(({ }) => ({
    width: '100%',
    padding: 24
}));

export interface FormValues {
    initialValues: Category,
    submitForm: any,
}

export default function CategoryForm({ initialValues, submitForm }: FormValues) {

    const SchemaCategoryForm = Yup.object().shape({
        categoryTitle: Yup.string().trim().required('Category title  is required'),
        categoryDesc: Yup.string().trim().required('Category Description  is required'),
    });
    const navigate = useNavigate();
    const { redirectTo } = useSelector(state => state.category);

    useEffect(() => {
        if (redirectTo) navigate(redirectTo, { replace: true })
    }, [redirectTo])


    return (
        <CardStyle>
            <Formik
                onSubmit={(values, { setSubmitting }) => {
                    submitForm(values, setSubmitting);
                }}
                validateOnBlur={false}
                validateOnChange={true}
                enableReinitialize={true}
                validationSchema={SchemaCategoryForm}
                initialValues={{
                    categoryTitle: initialValues?.categoryTitle || '',
                    categoryDesc: initialValues?.categoryDesc || '',
                }}
            >
                {(formikProps: FormikProps<Category>) => (
                    <Form autoComplete="off" noValidate onSubmit={formikProps.handleSubmit}>
                        <Stack spacing={3}>
                            <Grid
                                container
                                spacing={3}
                            >
                                <Grid
                                    item
                                    md={5}
                                    xs={12}
                                >
                                    <TextField
                                        error={Boolean(formikProps.touched.categoryTitle && formikProps.errors.categoryTitle)}
                                        fullWidth
                                        helperText={formikProps.touched.categoryTitle && formikProps.errors.categoryTitle}
                                        label="Category name"
                                        {...formikProps.getFieldProps('categoryTitle')}
                                        variant="standard"
                                    />
                                </Grid>
                                <Grid
                                    item
                                    md={7}
                                    xs={12}
                                >
                                    <TextField
                                        error={Boolean(formikProps.touched.categoryDesc && formikProps.errors.categoryDesc)}
                                        fullWidth
                                        helperText={formikProps.touched.categoryDesc && formikProps.errors.categoryDesc}
                                        label="Category Description"
                                        {...formikProps.getFieldProps('categoryDesc')}
                                        variant="standard"
                                    />
                                </Grid>
                            </Grid>
                            <Grid item xs={2}>
                                    <LoadingButton
                                        size="medium"
                                        type="submit"
                                        variant="contained"
                                        loading={formikProps.isSubmitting}
                                    >
                                        {initialValues ? 'Save Category' : 'Add Category'}
                                    </LoadingButton>
                                </Grid>
                        </Stack>
                    </Form>
                )}
            </Formik>
        </CardStyle>
    );
}
