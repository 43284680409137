import {  Grid, Container } from '@mui/material';
import {  DashboardProjectStatusSection } from 'src/components/admin/_dashboard/app/Dashboard';
import DashboardBoxSection from 'src/components/admin/_dashboard/app/Dashboard/DashboardBoxSection';
import DashboardProjectRevenueSection from 'src/components/admin/_dashboard/app/Dashboard/DashboardProjectReveneSection';
import DashboardProjectExpenseSection from 'src/components/admin/_dashboard/app/Dashboard/DashboardProjectExpenseSection';
import DashboardProjectManagerSection from 'src/components/admin/_dashboard/app/Dashboard/DashboardProjectManagerSection';
import DashboardProjectTrafficSection from 'src/components/admin/_dashboard/app/Dashboard/DashboardProjectTrafficSection';
import DashboardProjectSecuritySection from 'src/components/admin/_dashboard/app/Dashboard/DashboardProjectSecuritySection';
import DashboardProjectReveneExpenseSection from 'src/components/admin/_dashboard/app/Dashboard/DashboardProjectReveneExpenseSection';
import { ClientAllRequestData } from 'src/actions/clientActions';
import { SupplierAllRequestData } from 'src/actions/serviceActions';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import TopBar from 'src/components/shared/TopBar';
import DashboardProjectInvoiceSection from 'src/components/admin/_dashboard/app/Dashboard/DashboardProjectInvoiceSection';

export default function DashboardApp() {

  const dispatch =useDispatch();

  useEffect(() => {
    dispatch(ClientAllRequestData(''))
  }, [])

  useEffect(() => {
    dispatch(SupplierAllRequestData())
  }, [])

  

  return (
    <Container maxWidth="xl">
      <TopBar title="Dashboard" link="" buttonText=""/>
       <Grid container spacing={3}>
        <DashboardBoxSection />
        <DashboardProjectManagerSection />
        <DashboardProjectStatusSection />
        <DashboardProjectRevenueSection />
        <DashboardProjectExpenseSection />
        <DashboardProjectTrafficSection />
        <DashboardProjectSecuritySection />
        <DashboardProjectReveneExpenseSection />
        <DashboardProjectInvoiceSection/>
      </Grid>
    </Container>
  );
}
