import { ThunkAction, ThunkDispatch } from "redux-thunk";
import Prefix from 'src/helpers/ApiPrefix';
import AXIOS from 'src/helpers/Axios';
import Headers from 'src/helpers/Headers';
import { categoryAction } from "src/reducers/categoryReducer";
import { categoryActionTypes } from "src/types/actionTypes";
import { Category, CategoryList } from "src/types/categoryTypes";
import { ErrorAction, SuccessAction } from "src/types/responseTypes";

export const CategoryFailure = (message: string): ErrorAction => {
    return {
        type: categoryActionTypes.CATEGORY_FAILURE,
        payload: { message: message }
    }
}
export const CategorySuccess = (payload: CategoryList): SuccessAction<CategoryList> => {
    return {
        type: categoryActionTypes.FETCH_CATEGORY_SUCCESS,
        payload: payload,
    }
}
export const CategoryAllSuccess = (payload: CategoryList): SuccessAction<CategoryList> => {
    return {
        type: categoryActionTypes.FETCH_CATEGORY_ALL_SUCCESS,
        payload: payload,
    }
}

export const CategoryAddSuccess = (payload: Category): SuccessAction<Category> => {
    return {
        type: categoryActionTypes.ADD_CATEGORY_SUCCESS,
        payload: payload,
    }
}
export const CategorySingleSuccess = (payload: Category): SuccessAction<Category> => {
    return {
        type: categoryActionTypes.FETCH_SINGLE_CATEGORY_SUCCESS,
        payload: payload,
    }
}

export const CategoryUpdateSuccess = (payload: any): SuccessAction<any> => {
    return {
        type: categoryActionTypes.UPDATE_CATEGORY_SUCCESS,
        payload: payload,
    }
}


export const CategoryDeleteSuccess = (payload: any): SuccessAction<any> => {
    return {
        type: categoryActionTypes.DELETE_CATEGORY_SUCCESS,
        payload: payload,
    }
}


export const CategoryAllRequest = (filter: any, search: string, pageNo: number, limit: number, status: boolean):
    ThunkAction<Promise<void>, {}, {}, categoryAction> => {
    return (dispatch: ThunkDispatch<{}, {}, categoryAction>) => {
        return AXIOS.post(`${Prefix.api}/admin/categories`, { filter, search, pageNo, limit, status }, { headers: Headers() }).then(res => {
            if (res) {
                const { data } = res;
                const categories: CategoryList = data.result
                dispatch(CategorySuccess(categories))

            }
        }).catch((e) => {
            if (e.response) {
                dispatch(CategoryFailure(e.response.data.message));
            }
            else {
                dispatch(CategoryFailure(e.message));
            }
        })
    }
}

export const CategoryAllRequestData = ():
    ThunkAction<Promise<void>, {}, {}, categoryAction> => {
    return (dispatch: ThunkDispatch<{}, {}, categoryAction>) => {
        return AXIOS.get(`${Prefix.api}/admin/categories/all`, { headers: Headers() }).then(res => {
            if (res) {
                const { data } = res;
                const categories: CategoryList = data.result
                dispatch(CategoryAllSuccess(categories))
            }
        }).catch((e) => {
            if (e.response) {
                dispatch(CategoryFailure(e.response.data.message));
            }
            else {
                dispatch(CategoryFailure(e.message));
            }
        })
    }
}




export const CategoryAddRequest = (category: Category, setMessageHandler: any, setSubmitting: any):
    ThunkAction<Promise<void>, {}, {}, categoryAction> => {
    setMessageHandler({
        hasMessage: false,
        type: '',
        message: '',
    });
    return (dispatch: ThunkDispatch<{}, {}, categoryAction>) => {
        return AXIOS.post(`${Prefix.api}/admin/category/add`, category, { headers: Headers() }).then(res => {
            if (res) {
                const { data } = res;
                const category: Category = data.result;
                dispatch(CategoryAddSuccess(category))
                setSubmitting(false);
                setMessageHandler({
                    hasMessage: true,
                    type: 'success',
                    message: data.message,
                });
            }
        }).catch((e) => {
            if (e.response) {
                setSubmitting(false);
                dispatch(CategoryFailure(e.response.data.message));
                setMessageHandler({
                    hasMessage: true,
                    type: 'error',
                    message: e.response.data.message,
                });
            }
            else {
                setSubmitting(false);
                dispatch(CategoryFailure(e.message));
                setMessageHandler({
                    hasMessage: true,
                    type: 'error',
                    message: e.message,
                });
            }
        })
    }
}


export const CategoryUpdateRequest = (catid: string, category: Category, setMessageHandler: any, setSubmitting: any):
    ThunkAction<Promise<void>, {}, {}, categoryAction> => {
    setMessageHandler({
        hasMessage: false,
        type: '',
        message: '',
    });
    return (dispatch: ThunkDispatch<{}, {}, categoryAction>) => {
        return AXIOS.put(`${Prefix.api}/admin/category/update/${catid}`, category, { headers: Headers() }).then(res => {
            if (res) {
                const { data } = res;
                const category: Category = data.result;
                dispatch(CategoryUpdateSuccess(category))
                setSubmitting(false);
                setMessageHandler({
                    hasMessage: true,
                    type: 'success',
                    message: data.message,
                });
            }
        }).catch((e) => {
            if (e.response) {
                setSubmitting(false);
                setMessageHandler({
                    hasMessage: true,
                    type: 'error',
                    message: e.response.data.message,
                });
                dispatch(CategoryFailure(e.response.data.message));
            }
            else {
                setSubmitting(false);
                setMessageHandler({
                    hasMessage: true,
                    type: 'error',
                    message: e.message,
                });
                dispatch(CategoryFailure(e.message));
            }
        })
    }
}

export const CategoryStatusUpdateRequest = (catid: string, category: Category, setMessageHandler: any):
    ThunkAction<Promise<void>, {}, {}, categoryAction> => {
    setMessageHandler({
        hasMessage: false,
        type: '',
        message: '',
    });
    return (dispatch: ThunkDispatch<{}, {}, categoryAction>) => {
        return AXIOS.put(`${Prefix.api}/admin/category/update/${catid}`, category, { headers: Headers() }).then(res => {
            if (res) {
                const { data } = res;
                const category: Category = data.result;
                dispatch(CategoryUpdateSuccess(category))
                setMessageHandler({
                    hasMessage: true,
                    type: 'success',
                    message: data.message,
                });
            }
        }).catch((e) => {
            if (e.response) {
                setMessageHandler({
                    hasMessage: true,
                    type: 'error',
                    message: e.response.data.message,
                });
                dispatch(CategoryFailure(e.response.data.message));
            }
            else {
                setMessageHandler({
                    hasMessage: true,
                    type: 'error',
                    message: e.message,
                });
                dispatch(CategoryFailure(e.message));
            }
        })
    }
}


export const CategorySingleRequest = (catid: string):
    ThunkAction<Promise<void>, {}, {}, categoryAction> => {

    return (dispatch: ThunkDispatch<{}, {}, categoryAction>) => {
        return AXIOS.get(`${Prefix.api}/admin/category/${catid}`, { headers: Headers() }).then(res => {
            if (res) {
                const { data } = res;
                const category: Category = data.result;
                dispatch(CategorySingleSuccess(category));
            }
        }).catch((e) => {
            if (e.response) {
                dispatch(CategoryFailure(e.response.data.message));
            }
            else {
                dispatch(CategoryFailure(e.message));
            }
        })
    }
}


export const CategoryDeleteRequest = (id: string, body: Category, setMessageHandler: any):
    ThunkAction<Promise<void>, {}, {}, categoryAction> => {
    setMessageHandler({
        hasMessage: false,
        type: '',
        message: '',
    });
    return (dispatch: ThunkDispatch<{}, {}, categoryAction>) => {
        return AXIOS.put(`${Prefix.api}/admin/category/update/${id}`, body, { headers: Headers() }).then(res => {
            if (res) {
                const { data } = res;
                const category: Category = data.result;
                dispatch(CategoryDeleteSuccess(category))
                setMessageHandler({
                    hasMessage: true,
                    type: 'success',
                    message: data.message,
                });
            }
        }).catch((e) => {
            if (e.response) {
                dispatch(CategoryFailure(e.response.data.message));
                setMessageHandler({
                    hasMessage: true,
                    type: 'error',
                    message: e.response.data.message,
                });
            }
            else {
                dispatch(CategoryFailure(e.message));
                setMessageHandler({
                    hasMessage: true,
                    type: 'error',
                    message: e.message,
                });
            }
        })
    }
}





