import { messageActionTypes } from "src/types/actionTypes";
import { ErrorAction, SuccessAction } from "src/types/responseTypes";
import { Message, MessageList } from "src/types/messageTypes";

export type messageAction = SuccessAction<MessageList> | SuccessAction<Message> | SuccessAction<any> | ErrorAction
export const defaultMessageState: MessageList = { loading:false,message:{}, data: [],redirectTo: '',messages:[], metadata:{} };

const reducer = (state: MessageList = defaultMessageState, action: messageAction): MessageList => {
    switch (action.type) {
        case messageActionTypes.MESSAGE_REQUEST:
            state = { ...state ,loading:true,redirectTo:''  }
            return state
        case messageActionTypes.FETCH_MESSAGE_SUCCESS:
            action = <SuccessAction<MessageList>>action
            state = { ...state,loading:false,redirectTo:'' , messages: action.payload.messages,metadata:  action.payload.metadata }
            return state
        case messageActionTypes.FETCH_SINGLE_MESSAGE_SUCCESS:
            action = <SuccessAction<Message>>action
            state = { ...state,loading:false, message: action.payload,redirectTo:'' }
            return state
        case messageActionTypes.UPDATE_MESSAGE_SUCCESS:
            action = <SuccessAction<any>>action
            state = { ...state,loading:false,redirectTo: '/admin/library/message', message:{}, messages: state.messages?.map(dat => [ dat._id ==action.payload._id && Object.assign(dat ,action.payload)].find(o => o._id == dat._id) || dat) }
            return state
        case messageActionTypes.DELETE_MESSAGE_SUCCESS:
            action = <SuccessAction<any>>action
            state = { ...state, messages: state.messages?.filter(dat => dat._id !== action.payload._id), metadata: { ...state.metadata, limit: Number(state.metadata?.limit) - 1, total: Number(state.metadata?.total) - 1 } }
            return state
        case messageActionTypes.ADD_MESSAGE_SUCCESS:
            action = <SuccessAction<any>>action
            state = { ...state,loading:false,redirectTo: '/admin/library/message', message:{}, messages: state.messages?.concat(action.payload)  }
            return state
        case messageActionTypes.MESSAGE_FAILURE:
            action = <ErrorAction>action
            return { ...state }
        default:
            return state
    }
}

export default reducer