import { Box, Container, styled, Card, Typography, Radio, Divider, Button, List, ListItemButton, TextField } from '@mui/material';
import { useDispatch } from 'react-redux';
import MessageContext from 'src/context/MessageContext';
import { useContext, useEffect, useState } from 'react';
import TopBarForm from 'src/components/shared/TopBarForm';
import { useSelector } from 'src/reducers';
import plusFill from '@iconify/icons-eva/plus-fill';
import { Icon } from '@iconify/react';
import { ClientAllInvoiceRequestData } from 'src/actions/clientActions';
import ResponsiveDialog from 'src/components/dialog/ResponsiveDialog';
import { AddonAddress, Client } from 'src/types/clientTypes';
import { GenerateInvoiceForm } from 'src/components/admin/_dashboard/invoice';
import { Invoice, InvoiceAddress } from 'src/types/invoiceTypes';
import { InvoiceAddRequest, InvoiceCodeRequest } from 'src/actions/invoiceActions';
import { useNavigate } from 'react-router-dom';

const CardStyle = styled(Card)(({ }) => ({
    width: '100%',
}));


export default function GenerateInvoice() {

    const [dialog, setDialog] = useState<string>('');
    const [searchClient, setSearchClient] = useState<string>('');
    const [client, setClient] = useState<any>('');
    const [clientData, setClientData] = useState<any>('');
    const [invoiceAddress, setInvoiceAddress] = useState<any>('');
    const { setMessageHandler } = useContext<any>(MessageContext);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { clients } = useSelector(state => state.client);
    const { redirectTo, invoice } = useSelector(state => state.invoice);

    const [selectedValue, setSelectedValue] = useState<any>(1);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>, input: Client, address: InvoiceAddress) => {
        setSelectedValue(event.target.value);
        setClientData(input)
        setInvoiceAddress(address);
    };

    const setClientAction = (client: Client, address: InvoiceAddress) => {
        setClient(client)
        setClientData(client);
        setInvoiceAddress(address);
        setDialog('')
    }

    const submitForm = async (values: Invoice, setSubmitting: any) => {
        values.invoiceAddress = invoiceAddress;
        setTimeout(() => dispatch(InvoiceAddRequest(values, setMessageHandler, setSubmitting)), 200);
    }


    useEffect(() => {
        navigate(redirectTo)
    }, [redirectTo])

    useEffect(() => {
        dispatch(ClientAllInvoiceRequestData(searchClient))
        dispatch(InvoiceCodeRequest())
    }, [searchClient])

    const ClientListDialog = () => {
        return <Box>
            <ResponsiveDialog openDialog={dialog == 'client'} subTitle="" setOpenDialog={setDialog} title="Clients" >
                <TextField
                    fullWidth
                    label="Search.."
                    size="small"
                    onChange={(e) => setSearchClient(e.target.value)}
                    variant="outlined"
                />
                <List
                    sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}
                    component="nav"
                    aria-labelledby="nested-list-subheader"
                >
                    {clients.map(client => <ListItemButton key={client._id} onClick={() => setClientAction(client, { countryName: client.countryId && client.countryId.name, company: client.company, address1: client.address1, address2: client.address2, city: client.city, state: client.state, zipCode: client.zipCode })} disableGutters={true} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', mb: 1 }}>
                        <Typography variant="subtitle2">{client.clientName}</Typography>
                        <Typography variant="body2">{client.clientEmail}</Typography>
                    </ListItemButton>
                    )}
                </List>
            </ResponsiveDialog>
        </Box>
    }
    var initialValues: any = {};

    const addOnAddress = (client: Client, address: AddonAddress, index: number) => {

        return <Box sx={{ display: "flex", justifyContent: 'space-between' }}>
            <Box>
                <Typography variant="h6">{client.clientName + "( " + address.countryId.name + " )"}</Typography>
                <Typography variant="body2">{address.company}</Typography>
                <Typography variant="body2">{address.address1 + ',' + address.address2 + ',' + address.city + ',' + address.zipCode + ',' + address.countryId.name}</Typography>
                <Typography variant="body2">Phone: {client.clientPhoneNumber}</Typography>
            </Box>
            <Box>
                <Radio
                    checked={selectedValue == index + 2}
                    onChange={(e) => handleChange(e, client, { countryName: address.countryId.name, company: address.company, address1: address.address1, address2: address.address2, city: address.city, state: address.state, zipCode: address.zipCode })}
                    value={index + 2}
                    name="radio-buttons"
                    inputProps={{ 'aria-label': 'A' }}
                />
            </Box>
        </Box>
    }

    return (
        <Container maxWidth={false}>
            <TopBarForm title="Generate Invoice" link={`/admin/invoice`} />
            <CardStyle>
                <Box sx={{ display: 'flex', flexDirection: 'row', padding: 3 }}>
                    <Box sx={{ width: '100%' }}>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', color: "rgb(145, 158, 171)" }}>
                            <Typography variant="h6">To:</Typography>
                            <Button
                                onClick={() => setDialog('client')}
                                variant="text"
                                startIcon={<Icon icon={plusFill} />}
                            >
                                {client ? 'Update Client' : 'Add Client'}
                            </Button>
                        </Box>
                        {ClientListDialog()}
                        {client &&
                            <Box sx={{ display: "flex", justifyContent: 'space-between' }}>
                                <Box>
                                    <Typography variant="h6">{client.clientName + "( " + client.countryId.name + " )"}</Typography>
                                    <Typography variant="body2">{client.company}</Typography>
                                    <Typography variant="body2">{client.address1 + ',' + client.address2 + ',' + client.city + ',' + client.zipCode + ',' + client.countryId.name}</Typography>
                                    <Typography variant="body2">Phone: {client.clientPhoneNumber}</Typography>
                                </Box>
                                <Box>
                                    <Radio
                                        checked={selectedValue == 1}
                                        onChange={(e) => handleChange(e, client, { countryName: client.countryId.name, company: client.company, address1: client.address1, address2: client.address2, city: client.city, state: client.state, zipCode: client.zipCode })}
                                        value={1}
                                        name="radio-buttons"
                                        inputProps={{ 'aria-label': 'A' }}
                                    />
                                </Box>
                            </Box>
                        }
                        {client && client.addOnAddress.map((addRss: AddonAddress, index: number) => addOnAddress(client, addRss, index))}
                    </Box>
                    <Divider orientation="vertical" flexItem sx={{ margin: '0px 40px 0px 40px' }} />
                    <Box sx={{ width: '100%' }}>
                        <Box sx={{ color: "rgb(145, 158, 171)" }}>
                            <Typography variant="h6">From:</Typography>
                        </Box>
                        {client && client.wolfAccount == 'wcanada' ? <Box>
                            <Typography variant="h6">Wolf Insights Inc.</Typography>
                            <Typography variant="body2">#308-391 Maj Mackenzie Dr E,Richmond Hill,L4C 1H8,Canada</Typography>
                            <Typography variant="body2">Phone: +91 (896) 818-2409</Typography>
                        </Box> : <Box>
                            <Typography variant="h6">Wolf Insights Private Limited</Typography>
                            <Typography variant="body2">Ground Floor, Plot No. 57,Industrial Area, Phase 1,Chandigarh,CH,160001,IN</Typography>
                            <Typography variant="body2">Phone: +91 (896) 818-2409</Typography>
                        </Box>
                        }
                    </Box>
                </Box>
                <Box>
                    {client._id && invoice.invoiceCode && <GenerateInvoiceForm invoiceCode={invoice.invoiceCode} initialValues={initialValues} submitForm={submitForm} client={clientData} invoiceAddress={invoiceAddress} />}
                </Box>
            </CardStyle>
        </Container>
    );
}
