import { FieldArray } from 'formik'
import { styled } from '@mui/material/styles';
import { Grid, IconButton, TextField, Checkbox, FormControl, FormLabel, FormGroup, FormControlLabel } from '@mui/material';
import { Remove as RemoveIcon, Add as AddIcon } from "@mui/icons-material";
import { QualificationOption } from 'src/types/qualificationTypes';

const TextFieldStyle = styled(TextField)(({ }) => ({
    marginTop: 0
}));

const QualificationOptionRender = ({ formikProps, icon }: any) => {
    return (
        <FieldArray name="qualificationOptions" >
            {({ push, remove }) => (
                <div>
                    {formikProps.values.qualificationOptions.length > 0 &&
                        formikProps.values.qualificationOptions.map((field: QualificationOption, index: number) => (
                            <Grid key={index} container rowSpacing={0} spacing={2}>
                                <Grid item xs={10} sm={10} md={10} display="flex" justifyContent="center" alignItems="center">
                                    {icon}
                                    <TextFieldStyle
                                        fullWidth
                                        label="Add Option"
                                        margin="normal"
                                        value={field.text}
                                        error={Boolean(formikProps.touched.qualificationOptions && formikProps.touched.qualificationOptions[index] && formikProps.touched.qualificationOptions[index].title && formikProps.errors.qualificationOptions && formikProps.errors.qualificationOptions[index] && formikProps.errors.qualificationOptions[index].title)}
                                        helperText={formikProps.touched.qualificationOptions && formikProps.touched.qualificationOptions[index] && formikProps.touched.qualificationOptions[index].title && formikProps.errors.qualificationOptions && formikProps.errors.qualificationOptions[index] && formikProps.errors.qualificationOptions[index].title}
                                        name={`qualificationOptions.${index}.text`}
                                        onBlur={formikProps.handleBlur}
                                        onChange={formikProps.handleChange}
                                        type="text"
                                        variant="standard"
                                    />
                                </Grid>
                                <Grid item xs={1} sm={1} md={1} display="flex" justifyContent="center" alignItems="center">
                                    <FormControl component="fieldset">
                                        <FormLabel component="legend" sx={{ fontSize: 13, textAlign: 'center' }}>Enable</FormLabel>
                                        <FormGroup aria-label="position" row>
                                            <FormControlLabel
                                                sx={{ fontSize: 1 }}
                                                value="top"
                                                control={<Checkbox name={`qualificationOptions.${index}.isActive`} checked={field.isActive} onChange={formikProps.handleChange} value={field.isActive} />}
                                                label=""
                                                labelPlacement="top"
                                            />
                                        </FormGroup>
                                    </FormControl>
                                </Grid>
                                <Grid display="flex" justifyContent="center" alignItems="center" item xs={1} sm={1} md={0.5}>
                                    <IconButton onClick={() => push({ text:"",subText:"",isActive:true })} autoFocus color="primary">
                                        <AddIcon />
                                    </IconButton>
                                    <IconButton disabled={formikProps.values.qualificationOptions.length < 3} onClick={() => remove(index)} autoFocus color="primary">
                                        <RemoveIcon />
                                    </IconButton>
                                </Grid>
                            </Grid>
                        ))}
                </div>
            )}
        </FieldArray>
    );
};

export default QualificationOptionRender;
