import {  Grid } from '@mui/material';
import { useDispatch } from 'react-redux';
import { useSelector } from 'src/reducers';
import { useEffect, useState } from 'react';
import { DashboardProjectStatusDataRequest } from 'src/actions/dashboardActions';
import RenderGraph from './RenderGraph';

export default function DashboardProjectStatusSection() {

  const [ filter, setFilter] = useState<any>({ type:1 });
  const dispatch = useDispatch();

  const dashboardData = useSelector(state => state.dashboard);
  const {  dashboardProjectStatusSectionData } = dashboardData;

  var dates:any =[];
  var live:any =[];
  var paused:any =[];
  var closed:any =[];
  var invoiced:any =[];
  var active:any =[];
  dashboardProjectStatusSectionData.map((projectStatus:any) => 
   dates.push(projectStatus.date) && live.push(projectStatus.live) &&  paused.push(projectStatus.paused)  &&
   closed.push(projectStatus.closed) && invoiced.push(projectStatus.invoiced) &&  active.push(projectStatus.active) 
  );
 
  useEffect(() => {
      dispatch(DashboardProjectStatusDataRequest(filter))
  }, [filter])


  const CHART_DATA = [
    {
      name: 'New Projects',
      data: live
    },
    {
      name: 'Paused',
      data: paused
    },
    {
      name: 'Closed',
      data: closed
    },
    {
      name: 'Invoiced',
      data: invoiced
    },
  ];

var options:any = {
  chart: {
    type: 'bar',
    height: 350
  },
  plotOptions: {
    bar: {
      horizontal: false,
      columnWidth: '55%',
      endingShape: 'rounded'
    },
  },
  dataLabels: {
    enabled: false
  },
  stroke: {
    show: true,
    width: 2,
    colors: ['transparent']
  },
  xaxis: {
    categories: dates
  },
  fill: {
    opacity: 1
  },
  tooltip: {
    shared: true,
    intersect: false,
    y: {
      formatter: (y:number) => {
        if (typeof y !== 'undefined') {
          return `${y}`;
        }
        return y;
      }
    }
  },
};

  return (
    <Grid item xs={12} md={6} lg={6}>
      <RenderGraph filter={filter} filterType={['client']} setFilter={setFilter} title="Project Status" subheader="This Year"  chartdata={CHART_DATA} options={options} type="bar"/>
    </Grid>
  );
}
