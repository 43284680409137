import { TextField, Grid, Typography, Box, Tooltip, Accordion, AccordionSummary, AccordionDetails, Button, List, ListItem, ListItemText, Checkbox,Switch } from '@mui/material';
import { Form, Formik, FormikProps } from 'formik';
import { LoadingButton } from '@mui/lab';
import * as Yup from 'yup';
import { DataGridRender, MoreMenu } from 'src/components/table';
import { useSelector } from 'src/reducers';
import { useDispatch } from 'react-redux';
import { useContext, useEffect, useState } from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import { useParams } from 'react-router-dom';
import MessageContext from 'src/context/MessageContext';
import plusFill from '@iconify/icons-eva/plus-fill';
import { Icon } from '@iconify/react';
import { ProjectDeleteExcludeRequest, ProjectExcludesAllRequest,ProjectSingleExcludeRequest, ProjectAddExcludeRequest, ProjectChildsSearchRequest } from 'src/actions/projectActions';
import { Exclude } from 'src/types/projectTypes';
import { ProjectUpdateExcludeRequest } from 'src/actions/projectActions';

export default function ChildProjectExcludeForm() {

    const { projectid }: any = useParams<any>();
    const [expanded, setExpanded] = useState<string | false>(false);
    const [pageNo, setPageNo] = useState(1);
    const [limit, setLimit] = useState(10);
    const [filters, setFilters] = useState<any>({});
    const [search, setSearch] = useState<string>('');
    const [searchpro, setSearchPro] = useState<string>('');
    const { setMessageHandler } = useContext<any>(MessageContext);

    const SchemaCategoryForm = Yup.object().shape({
        excludedProjectId: Yup.string().required('Project is required'),
    });

    const dispatch = useDispatch();
    const {exclude, excludeProjects, excludes } = useSelector(state => state.project);

    useEffect(() => {
        dispatch(ProjectExcludesAllRequest(projectid, search))
    }, [projectid, search]);

    useEffect(() => {
        dispatch(ProjectChildsSearchRequest({ search: searchpro }))
    }, [searchpro])

    const deleteAction = (id: string) => {
        dispatch(ProjectDeleteExcludeRequest(id, { isDeleted: true }, setMessageHandler));
    }
    const submitSupplier = (values: Exclude, setSubmitting: any, resetForm: any) => {
        setTimeout(() => dispatch(ProjectAddExcludeRequest(values, setMessageHandler, setSubmitting, resetForm)), 200);
    }
    const handleChange = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
        setExpanded(isExpanded ? panel : false);
    };

    const columns = [
        { field: 'excludedProjectId.projectCode', type: "string", headerName: '#', filterable: false, headerAlign: "center", align: 'center', width: 100, pinnable: true, renderCell: ({ row }: any) => {
                return <Typography color="primary" >{row['excludedProjectId']['projectCode']}</Typography>
            }
        },
        { field: 'projectName', type: "string", headerName: 'Project Name', filterable: false, headerAlign: "center", align: 'center', flex: 1, renderCell: ({ row }: any) => {
                return <Tooltip title={row['excludedProjectId']['projectName']}><Typography color="primary" >{row['excludedProjectId']['projectName']}</Typography></Tooltip>
            }
        },
        {  field: 'complete', type: "string", headerName: 'Complete', filterable: false, width: 140, headerAlign: "center", align: 'center', renderCell: (params: any) => {
                return <Switch checked={params.value} onChange={() => dispatch(ProjectUpdateExcludeRequest(params.id, { complete: !params.value }, setMessageHandler))} />
            }
        },
        {
            field: 'terminate', type: "string", headerName: 'Terminate', filterable: false, width: 140, headerAlign: "center", align: 'center', renderCell: (params: any) => {
                return <Switch checked={params.value} onChange={() => dispatch(ProjectUpdateExcludeRequest(params.id, { terminate: !params.value }, setMessageHandler))} />
            }
        },
        {
            field: 'overQuota', type: "boolean", headerName: 'OverQuota', filterable: false, width: 140, headerAlign: "center", align: 'center', renderCell: (params: any) => {
                return <Switch checked={params.value} onChange={() => dispatch(ProjectUpdateExcludeRequest(params.id, { overQuota: !params.value }, setMessageHandler))} />
            }
        },
        {
            field: 'dropout', headerName: 'Dropped', type: "boolean", width: 140, renderCell: (params: any) => {
                return <Switch checked={params.value} onChange={() => dispatch(ProjectUpdateExcludeRequest(params.id, { dropout: !params.value }, setMessageHandler))} />
            }
        },
        {
            field: 'isExcluded', headerName: 'Excluded', type: "boolean", width: 140, renderCell: (params: any) => {
                return <Switch checked={params.value} onChange={() => dispatch(ProjectUpdateExcludeRequest(params.id, { isExcluded: !params.value }, setMessageHandler))} />
            }
        },
        { field: 'actions', headerName: '', width: 100, type: 'actions', getActions: ({ id }: any) => [<MoreMenu name="" id={id} cloneAction='' addAction='' editAction="" deleteAction={deleteAction} exportAction={""} />] },
    ];
    return (
        <Formik
            onSubmit={(values, { setSubmitting, resetForm }) => {
                submitSupplier(values, setSubmitting, resetForm);
            }}
            validateOnBlur={false}
            validateOnChange={true}
            enableReinitialize={true}
            validationSchema={SchemaCategoryForm}
            initialValues={{
                excludedProjectId:exclude?.excludedProjectId,
                isExcluded: true,
                projectId: projectid,
                complete:  true,
                terminate:  true,
                overQuota:   true,
                dropout:  true,
            }}
        >
            {(formikProps: FormikProps<Exclude>) => (
                <>
                    <DataGridRender
                        height={400}
                        filterTypes={[]}
                        data={excludes}
                        metadata=""
                        limit={limit}
                        search={search}
                        filters={filters}
                        pageNo={pageNo}
                        columns={columns}
                        hideFooterPagination={true}
                        setPageNo={setPageNo}
                        setSearch={setSearch}
                        setLimit={setLimit}
                        setFilters={setFilters}
                    />
                    <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                        <AccordionSummary sx={{ width: 250 }} >
                            <Button
                                variant="text"
                                startIcon={<Icon icon={plusFill} />}
                            >
                                {'Add Exclusion/Inclusion'}
                            </Button>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Form autoComplete="off" noValidate onSubmit={formikProps.handleSubmit}>
                                <Grid
                                    container
                                    spacing={3}
                                >
                                    <Grid
                                        item
                                        md={4}
                                        xs={12}
                                    >
                                        <Autocomplete
                                            freeSolo
                                            id="free-solo-2-demo"
                                            disableClearable
                                            onChange={(event: any, newValue: any | null)=>{
                                                formikProps.setFieldValue('excludedProjectId',newValue._id)
                                            }}
                                            getOptionLabel={(option) => option.projectCode + "_" + option.projectName}
                                            options={excludeProjects}
                                            renderInput={(params:any) => (
                                                <TextField
                                                    {...params}
                                                    variant="standard"
                                                    onChange={(e)=>setSearchPro(e.target.value)}
                                                    label="Search Project.."
                                                    InputProps={{
                                                        ...params.InputProps,
                                                        type: 'search',
                                                    }}
                                                />
                                            )}
                                        />
                                    </Grid>
                                    {/* <Grid
                                        item
                                        md={1.5}
                                        xs={12}
                                    >
                                        <List>
                                            <ListItem dense>
                                                <Checkbox
                                                    edge="start"
                                                    tabIndex={-1}
                                                    disableRipple
                                                    checked={formikProps.values.complete}
                                                    {...formikProps.getFieldProps('complete')}
                                                />
                                                <ListItemText primary={`Complete`} />
                                            </ListItem>
                                        </List>
                                    </Grid>
                                    <Grid
                                        item
                                        md={1.5}
                                        xs={12}
                                    >
                                        <List>
                                            <ListItem dense>
                                                <Checkbox
                                                    edge="start"
                                                    tabIndex={-1}
                                                    disableRipple
                                                    checked={formikProps.values.terminate}
                                                    {...formikProps.getFieldProps('terminate')}
                                                />
                                                <ListItemText primary={`Terminate`} />
                                            </ListItem>
                                        </List>
                                    </Grid>
                                    <Grid
                                        item
                                        md={1.5}
                                        xs={12}
                                    >
                                        <List>
                                            <ListItem dense>
                                                <Checkbox
                                                    edge="start"
                                                    tabIndex={-1}
                                                    disableRipple
                                                    checked={formikProps.values.overQuota}
                                                    {...formikProps.getFieldProps('overQuota')}
                                                />
                                                <ListItemText primary={`OverQuota`} />
                                            </ListItem>
                                        </List>
                                    </Grid>
                                    <Grid
                                        item
                                        md={1.5}
                                        xs={12}
                                    >
                                        <List>
                                            <ListItem dense>
                                                <Checkbox
                                                    edge="start"
                                                    tabIndex={-1}
                                                    disableRipple
                                                    checked={formikProps.values.dropout}
                                                    {...formikProps.getFieldProps('dropout')}
                                                />
                                                <ListItemText primary={`DropOut`} />
                                            </ListItem>
                                        </List>
                                    </Grid> */}
                                    <Grid
                                        item
                                        md={1.5}
                                        xs={12}
                                    >
                                        <TextField
                                            select
                                            label="Type"
                                            error={Boolean(formikProps.touched.isExcluded && formikProps.errors.isExcluded)}
                                            fullWidth
                                            helperText={formikProps.touched.isExcluded && formikProps.errors.isExcluded}
                                            {...formikProps.getFieldProps('isExcluded')}
                                            SelectProps={{
                                                native: true,
                                            }}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            variant="standard"
                                        >
                                            <option key="" value="true">
                                                {"Exclude"}
                                            </option>
                                            <option key="" value="false">
                                                {"Include"}
                                            </option>
                                        </TextField>
                                    </Grid>
                                    <Grid item md={2} >
                                        <Box sx={{ display: 'flex', justifyContent: 'left', alignContent: 'right', mt: 2 }}>
                                            <LoadingButton
                                                size="medium"
                                                type="submit"
                                                variant="contained"
                                                loading={formikProps.isSubmitting}
                                            >
                                                Add
                                            </LoadingButton>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Form>
                        </AccordionDetails>
                    </Accordion>
                </>
            )
            }
        </Formik >
    );
}
