import { projectActionTypes } from "src/types/actionTypes";
import { Project, ProjectList } from "src/types/projectTypes";
import { ErrorAction, SuccessAction } from "src/types/responseTypes";

export type projectAction = SuccessAction<ProjectList> | SuccessAction<Project> | SuccessAction<any> | ErrorAction
export const defaultProjectState: any = { loading: false, questions: [], quotas: [], fetchProjects: [], excludeProjects: [], exclude: {}, redirectTo: '', excludes: [], resMessage: '', projects: null, childProjects: [], reportProjects: [], project: {}, data: [], metadata: {} };

const reducer = (state: ProjectList = defaultProjectState, action: projectAction): ProjectList => {
    switch (action.type) {
        case projectActionTypes.PROJECT_REQUEST:
            state = { ...state, loading: true, projects: action.payload.projects }
            return state
        case projectActionTypes.FETCH_PROJECT_SUCCESS:
            action = <SuccessAction<ProjectList>>action
            state = { ...state, loading: false, redirectTo: '', data: action.payload.data, metadata: action.payload.metadata }
            return state
        case projectActionTypes.FETCH_PROJECT_API_SUCCESS:
            action = <SuccessAction<ProjectList>>action
            state = { ...state, loading: false, redirectTo: '', fetchProjects: action.payload }
            return state
        case projectActionTypes.FETCH_PROJECT_QUOTA_API_SUCCESS:
            action = <SuccessAction<ProjectList>>action
            state = { ...state, loading: false, redirectTo: '', quotas: action.payload }
            return state
        case projectActionTypes.FETCH_PROJECT_QUESTION_API_SUCCESS:
            action = <SuccessAction<ProjectList>>action
            state = { ...state, loading: false, redirectTo: '', questions: action.payload }
            return state
        case projectActionTypes.FETCH_CHILD_PROJECT_SUCCESS:
            action = <SuccessAction<ProjectList>>action
            state = { ...state, loading: false, redirectTo: '', childProjects: action.payload }
            return state
        case projectActionTypes.FETCH_CHILD_SEARCH_SUCCESS:
            action = <SuccessAction<ProjectList>>action
            state = { ...state, loading: false, redirectTo: '', excludeProjects: action.payload }
            return state
        case projectActionTypes.FETCH_REPORT_PROJECT_SUCCESS:
            action = <SuccessAction<ProjectList>>action
            state = { ...state, loading: false, redirectTo: '', reportProjects: action.payload }
            return state
        case projectActionTypes.FETCH_PROJECT_EXCLUDE_SUCCESS:
            action = <SuccessAction<ProjectList>>action
            state = { ...state, loading: false, excludes: action.payload }
            return state
        case projectActionTypes.ADD_PROJECT_EXCLUDE_SUCCESS:
            action = <SuccessAction<ProjectList>>action
            state = { ...state, excludes: [...state.excludes, action.payload] }
            return state
        case projectActionTypes.FETCH_SINGLE_EXCLUDE_PROJECT_SUCCESS:
            action = <SuccessAction<ProjectList>>action
            state = { ...state, exclude: action.payload }
            return state
        case projectActionTypes.DELETE_PROJECT_EXCLUDE_SUCCESS:
            action = <SuccessAction<ProjectList>>action
            state = { ...state, excludes: state.excludes?.filter(dat => dat._id !== action.payload._id), metadata: { ...state.metadata, limit: Number(state.metadata?.limit) - 1, total: Number(state.metadata?.total) - 1 } }
            return state
        case projectActionTypes.UPDATE_PROJECT_EXCLUDE_SUCCESS:
            action = <SuccessAction<ProjectList>>action
            state = { ...state, excludes: state.excludes?.map(dat => [dat._id == action.payload._id && Object.assign(dat, action.payload)].find(o => o._id == dat._id) || dat) }
            return state
        case projectActionTypes.FETCH_PROJECT_ALL_SUCCESS:
            action = <SuccessAction<ProjectList>>action
            state = { ...state, loading: false, projects: action.payload }
            return state
        case projectActionTypes.UPDATE_PROJECT_SUCCESS:
            action = <SuccessAction<any>>action
            state = { ...state, loading: false, redirectTo: '/admin/project', data: state.data?.map(dat => [dat._id == action.payload._id && Object.assign(dat, action.payload)].find(o => o._id == dat._id) || dat) }
            return state
        case projectActionTypes.UPDATE_CHILD_PROJECT_SUCCESS:
            action = <SuccessAction<any>>action
            state = { ...state, loading: false, project: action.payload, childProjects: state.childProjects?.map(dat => [dat._id == action.payload._id && Object.assign(dat, action.payload)].find(o => o._id == dat._id) || dat), }
            return state
        case projectActionTypes.FETCH_SINGLE_PROJECT_SUCCESS:
            action = <SuccessAction<Project>>action
            state = { ...state, loading: false, project: action.payload }
            return state
        case projectActionTypes.DELETE_PROJECT_SUCCESS:
            action = <SuccessAction<any>>action
            state = { ...state, loading: false, childProjects: state.childProjects?.filter(dat => dat._id !== action.payload._id), data: state.data?.filter(dat => dat._id !== action.payload._id), metadata: { ...state.metadata, limit: Number(state.metadata?.limit) - 1, total: Number(state.metadata?.total) - 1 } }
            return state
        case projectActionTypes.ADD_PROJECT_SUCCESS:
            action = <SuccessAction<Project>>action
            state = { ...state, loading: false, redirectTo: action.payload.projectType == 1 ? '/admin/project' : `/admin/project/${action.payload.parentProjectId}/childs`, data: [...state.data, action.payload] }
            return state
        case projectActionTypes.ADD_CHILD_PROJECT_SUCCESS:
            action = <SuccessAction<Project>>action
            state = { ...state, loading: false, redirectTo: action.payload.projectType == 1 ? '/admin/project' : `/admin/project/${action.payload.parentProjectId}/childs`, childProjects: [...state.childProjects, action.payload] }
            return state
        case projectActionTypes.PROJECT_PRESCREEN_SUCCESS:
            action = <SuccessAction<Project>>action
            state = { ...state, loading: false, project: action.payload.project, redirectTo: action.payload.redirectTo, resMessage: action.payload.resMessage }
            return state
        case projectActionTypes.PROJECT_FAILURE:
            action = <ErrorAction>action
            return { ...state, loading: false }
        case projectActionTypes.PROJECT_RESET:
            action = <SuccessAction<any>>action
            return { ...state, childProjects: [], reportProjects: [] }
        default:
            return state
    }
}
export default reducer