import { useState } from 'react';
import { Grid } from '@mui/material';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'src/reducers';
import { DashboardProjectManagerDataRequest } from 'src/actions';
import { DataGridRender } from 'src/components/table';

export default function DashboardProjectManagerSection() {

    const [pageNo, setPageNo] = useState(1);
    const [limit, setLimit] = useState(10);
    const [filters, setFilters] = useState<any>({});
    const [search, setSearch] = useState<string>('');

    const dispatch = useDispatch();
    const dashboardData = useSelector(state => state.dashboard);
    const { loading, dashboardProjectManagerSectionData } = dashboardData;

    useEffect(() => {
        dispatch(DashboardProjectManagerDataRequest(filters))
    }, [filters])

    const columns = [
        { field: 'name', type: "string", headerName: 'User', filterable: false, flex: 1, headerAlign: "left", align: 'left' },
        { field: 'live', type: "string", headerName: 'Live', filterable: false, width: 60, headerAlign: "center", align: 'center' },
        { field: 'paused', type: "string", headerName: 'Paused', filterable: false, width: 80, headerAlign: "center", align: 'center' },
        { field: 'closed', type: "string", headerName: 'Closed', filterable: false, width: 80, headerAlign: "center", align: 'center' },
        { field: 'invoiced', type: "string", headerName: 'Invoiced', filterable: false,  width: 100, headerAlign: "center", align: 'center' },
        { field: 'total', type: "string", headerName: 'Total', filterable: false, width: 80, headerAlign: "center", align: 'center' },
        { field: 'active', type: "string", headerName: 'Active', filterable: false,  width: 80, headerAlign: "center", align: 'center' },
      ];
    

    return (
        <Grid item xs={12} md={6} lg={6}>
            <DataGridRender
                height={470}
                filterTypes={['export']}
                exportFileName={"Clients"}
                filters={filters}
                data={dashboardProjectManagerSectionData}
                limit={limit}
                loading={loading}
                search={search}
                pageNo={pageNo}
                columns={columns}
                setPageNo={setPageNo}
                setSearch={setSearch}
                setLimit={setLimit}
                setFilters={setFilters}
                hideFooterPagination={true}
            />
        </Grid>
    );
}
