import {  Container } from '@mui/material';
import { useDispatch } from 'react-redux';
import { ClientForm } from 'src/components/admin/_dashboard/client';
import { Client } from 'src/types/clientTypes';
import MessageContext from 'src/context/MessageContext';
import { useContext } from 'react';
import { ClientAddRequest } from 'src/actions/clientActions';
import TopBarForm from 'src/components/shared/TopBarForm';

export default function AddClient() {
    const { setMessageHandler } = useContext<any>(MessageContext);
    const dispatch = useDispatch();
    const initialValues: any = null;

    const submitForm = async (values: Client, setSubmitting: any) => {
        setTimeout(() => dispatch(ClientAddRequest(values, setMessageHandler, setSubmitting)), 200);
    }

    return (
        <Container maxWidth={false}>
            <TopBarForm title="Add Client" link="/admin/client" />
            <ClientForm initialValues={initialValues} submitForm={submitForm} />
        </Container>
    );
}
