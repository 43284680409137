import { questionActionTypes } from "src/types/actionTypes";
import { Question, QuestionList } from "src/types/surveyTypes";
import { ErrorAction, SuccessAction } from "src/types/responseTypes";

export type questionAction = SuccessAction<QuestionList> | SuccessAction<Question> | SuccessAction<any> | ErrorAction
export const defaultQuestionState: QuestionList = { loading:true,    postQuestions:[], redirectTo: "", categoryQuestions: [], questions: [], question: {}, data: [], metadata: {} };

const reducer = (state: QuestionList = defaultQuestionState, action: questionAction): QuestionList => {
    switch (action.type) {
        case questionActionTypes.QUESTION_REQUEST:
            state = { loading:true,redirectTo: "",postQuestions:[], categoryQuestions: [], questions: [], question: {}, data: [], metadata: {} }
            return state
        case questionActionTypes.FETCH_QUESTION_SUCCESS:
            action = <SuccessAction<QuestionList>>action
            state = { ...state,loading:false, data: action.payload.questions, metadata: action.payload.metadata }
            return state
        case questionActionTypes.FETCH_QUESTION_ALL_SUCCESS:
            action = <SuccessAction<QuestionList>>action
            state = { ...state,loading:false, questions: action.payload }
            return state
        case questionActionTypes.FETCH_POST_QUESTION_ALL_SUCCESS:
            action = <SuccessAction<QuestionList>>action
            state = { ...state,loading:false, postQuestions: action.payload }
            return state
        case questionActionTypes.FETCH_CATEGORY_QUESTION_SUCCESS:
            action = <SuccessAction<QuestionList>>action
            state = { ...state,loading:false, categoryQuestions: action.payload }
            return state
        case questionActionTypes.UPDATE_QUESTION_SUCCESS:
            action = <SuccessAction<any>>action
            state = { ...state,loading:false, questions: state.questions?.map(dat => [dat._id == action.payload._id && Object.assign(dat, action.payload)].find(o => o._id == dat._id) || dat) }
            return state
        case questionActionTypes.FETCH_SINGLE_QUESTION_SUCCESS:
            action = <SuccessAction<Question>>action
            state = { ...state,loading:false, question: action.payload }
            return state
        case questionActionTypes.DELETE_QUESTION_SUCCESS:
            action = <SuccessAction<any>>action
            state = { ...state,loading:false, questions: state.questions?.filter(dat => dat._id !== action.payload._id), metadata: { ...state.metadata, limit: Number(state.metadata?.limit) - 1, total: Number(state.metadata?.total) - 1 } }
            return state
        case questionActionTypes.ADD_QUESTION_SUCCESS:
            action = <SuccessAction<Question>>action
            state = { ...state,loading:false, questions: [...state.questions, action.payload] }
            return state
        case questionActionTypes.QUESTION_FAILURE:
            action = <ErrorAction>action
            return { ...state,loading:false, }
        default:
            return state
    }
}
export default reducer