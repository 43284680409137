import { Link as RouterLink } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { Card, Stack, Link, Container, Typography } from '@mui/material';
import Page from 'src/components/Page';
import { Form, Formik, FormikProps } from 'formik'
import { Icon } from '@iconify/react';
import * as yup from 'yup'
import eyeFill from '@iconify/icons-eva/eye-fill';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';
import { Checkbox, TextField, IconButton, InputAdornment, FormControlLabel } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { LoginRequest } from 'src/actions';
import MessageContext from 'src/context/MessageContext';
import { useContext } from 'react';

const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex'
  }
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: '100%',
  maxWidth: 464,
  display: 'flex',
  flexDirection: 'column',
  alignItems:'center',
  justifyContent: 'center',
  margin: theme.spacing(2, 0, 2, 2)
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  display: 'flex',
  minHeight: '100vh',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(12, 0)
}));

export interface LoginFormValues {
  email: string,
  password: string,
}


export default function Login() {
  const dispatch = useDispatch();

  const [showPassword, setShowPassword] = useState<boolean>(false);
  const { setMessageHandler } = useContext<any>(MessageContext);

  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };

  const submitLogin = async (values: LoginFormValues,setSubmitting:any) => {
    setTimeout(async() => await dispatch(LoginRequest(values,setMessageHandler ,setSubmitting)), 800);
  }
  const SchemaLoginForm = yup.object().shape({
    email: yup.string().email().required('Email must be valid'),
    password: yup.string().min(7, 'Password must be more than 6 character').required(),
  })

  return (
    <RootStyle>
        <SectionStyle>
          {/* <Typography variant="h3" sx={{ px: 5, mt: 10, mb: 5 }}>
            Hi, Welcome Back
          </Typography> */}
          <img style={{width: "300px"}} src="https://wolfinsights.com/wp-content/themes/wolfinsights-theme/assets/img/banner_img.png" alt="login" />
        </SectionStyle>
      <Container maxWidth="sm">
        <ContentStyle>
          <Stack sx={{ mb: 5 }}>
            <Typography variant="h4" gutterBottom>
              Sign in
            </Typography>
            <Typography sx={{ color: 'text.secondary' }}>Enter your details below.</Typography>
          </Stack>
          <Formik
            onSubmit={(values, { setSubmitting }) => {
              submitLogin(values, setSubmitting);
            }}
            validateOnBlur={false}
            validateOnChange={true}
            validationSchema={SchemaLoginForm}
            initialValues={{
              email: '',
              password: '',
            }}
          >
            {(formikProps: FormikProps<LoginFormValues>) => (
              <Form autoComplete="off" noValidate onSubmit={formikProps.handleSubmit}>
                <Stack spacing={3}>
                  <TextField
                    fullWidth
                    autoComplete="username"
                    type="email"
                    label="Email address"
                    {...formikProps.getFieldProps('email')}
                    error={Boolean(formikProps.touched.email && formikProps.errors.email)}
                    helperText={formikProps.touched.email && formikProps.errors.email}
                  />

                  <TextField
                    fullWidth
                    autoComplete="current-password"
                    type={showPassword ? 'text' : 'password'}
                    label="Password"
                    {...formikProps.getFieldProps('password')}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton onClick={handleShowPassword} edge="end">
                            <Icon icon={showPassword ? eyeFill : eyeOffFill} />
                          </IconButton>
                        </InputAdornment>
                      )
                    }}
                    error={Boolean(formikProps.touched.password && formikProps.errors.password)}
                    helperText={formikProps.touched.password && formikProps.errors.password}
                  />
                </Stack>

                <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
                  <FormControlLabel
                    control={<Checkbox />}
                    label="Remember me"
                  />

                  <Link component={RouterLink} variant="subtitle2" to="#">
                    Forgot password?
                  </Link>
                </Stack>

                <LoadingButton
                  fullWidth
                  size="large"
                  type="submit"
                  variant="contained"
                  loading={formikProps.isSubmitting}
                >
                  Login
                </LoadingButton>
              </Form>
            )}
          </Formik>
        </ContentStyle>
      </Container>
    </RootStyle>
  );
}
