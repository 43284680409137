import {  Grid } from '@mui/material';
import { useDispatch } from 'react-redux';
import { useSelector } from 'src/reducers';
import { useEffect, useState } from 'react';
import { DashboardProjectTrafficDataRequest} from 'src/actions/dashboardActions';
import RenderGraph from './RenderGraph';

export default function DashboardProjectTrafficSection() {

  const [ filter, setFilter] = useState<any>({type:1});
  const dispatch = useDispatch();
  const dashboardData = useSelector(state => state.dashboard);
  const { dashboardProjectTrafficSuccess } = dashboardData;

  var dates:any =[];
  var traffic:any =[];
  var security:any =[];
  var complete:any =[];
  var approved:any =[];
  var desktop:any =[];
  var tablet:any =[];
  var mobile:any =[];

  dashboardProjectTrafficSuccess.map((traff:any) => 
   dates.push(traff.date) && traffic.push(traff.traffic) && complete.push(traff.complete) && approved.push(traff.approved) &&  desktop.push(traff.desktop)
   &&  tablet.push(traff.tablet) && mobile.push(traff.mobile) 
  );
 
  useEffect(() => {
      dispatch(DashboardProjectTrafficDataRequest(filter))
  }, [filter])


  const CHART_DATA = [
    {
      name: 'Traffic',
      data: traffic
    },
    {
      name: 'Complete',
      data: complete
    },
    {
      name: 'Approved',
      data: approved
    },
    {
      name: 'Failed',
      data: security
    },
    {
      name: 'Desktop',
      data: desktop
    },
    {
      name: 'Tablet',
      data: tablet
    },
    {
      name: 'Mobile',
      data: mobile
    },
  ];

  var options:any = {
    chart: {
      type: 'bar',
      height: 350
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '55%',
        endingShape: 'rounded'
      },
    },
    dataLabels: {
      enabled: false
    },
    stroke: {
      show: true,
      width: 2,
      colors: ['transparent']
    },
    xaxis: {
      categories: dates
    },
    fill: {
      opacity: 1
    },
    tooltip: {
      shared: true,
      intersect: false,
      y: {
        formatter: (y:number) => {
          if (typeof y !== 'undefined') {
            return `${y}`;
          }
          return y;
        }
      }
    },
  };

  return (
    <Grid item xs={12} md={6} lg={6}>
    <RenderGraph filterType={['client','supplier']} filter={filter} setFilter={setFilter} title="Project Traffic" subheader="This Year"  chartdata={CHART_DATA} options={options} type="bar"/>
    </Grid>
  );
}
