import { countryActionTypes } from "src/types/actionTypes";
import { Country, CountryList } from "src/types/countryTypes";
import { ErrorAction, SuccessAction } from "src/types/responseTypes";

export type countryAction = SuccessAction<CountryList> | SuccessAction<Country> | SuccessAction<any> | ErrorAction
export const defaultCountryState: CountryList = {loading:true, regions: [], cities: [], countries: [], currencies: [], languages: [] };

const reducer = (state: CountryList = defaultCountryState, action: countryAction): CountryList => {
    switch (action.type) {
        case countryActionTypes.COUNTRY_REQUEST:
            state = { ...state, countries: [],loading:true }
            return state
        case countryActionTypes.FETCH_COUNTRY_SUCCESS:
            action = <SuccessAction<CountryList>>action
            state = { ...state, countries: action.payload,loading:false }
            return state
        case countryActionTypes.FETCH_REGION_SUCCESS:
            action = <SuccessAction<CountryList>>action
            state = { ...state, regions: action.payload }
            return state
        case countryActionTypes.FETCH_CITY_SUCCESS:
            action = <SuccessAction<CountryList>>action
            state = { ...state, cities: action.payload }
            return state
        case countryActionTypes.FETCH_CURRENCY_SUCCESS:
            action = <SuccessAction<CountryList>>action
            state = { ...state, currencies: action.payload }
            return state
        case countryActionTypes.FETCH_LANGUAGE_SUCCESS:
            action = <SuccessAction<CountryList>>action
            state = { ...state, languages: action.payload }
            return state
        case countryActionTypes.COUNTRY_FAILURE:
            action = <ErrorAction>action
            return { ...state }
        default:
            return state
    }
}
export default reducer