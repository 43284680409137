import { useEffect, useState } from 'react';
import { Container, Tab } from '@mui/material';
import { useDispatch } from 'react-redux';
import { useSelector } from 'src/reducers';
import {  DataGridRender } from 'src/components/table';
import TabLayout from 'src/components/tab/TabLayout';
import {  useNavigate } from 'react-router-dom';
import { ShortCodeRequest } from 'src/actions/serviceActions';
import Label from 'src/components/Label';
import TopBar from 'src/components/shared/TopBar';

export default function ShortCode() {

  const [pageNo, setPageNo] = useState(1);
  const [limit, setLimit] = useState(10);
  const [filters, setFilters] = useState<any>([]);
  const [search, setSearch] = useState<string>('');

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { loading , ShortCodes } = useSelector(state => state.shortCode);


  useEffect(() => {
    dispatch(ShortCodeRequest(filters));
  }, [filters]);

  const columns = [
    { field: 'shortCodeName', type: "string", headerName: 'Name', filterable: false, headerAlign: "center", align: 'center', width: 300, renderCell: (params: any) => {
        return <Label
          variant="ghost"
          color='primary'
        >
          {params?.value}
        </Label>
      }
    },
    { field: 'shortCodeDescription', type: "string", headerName: 'Description', filterable: false, headerAlign: "left", align: 'left', flex: 1 },
  ];


  return (
    <Container maxWidth={false}>
       <TopBar title="Support" link="" buttonText="" />
      <TabLayout handleChange=""  tabValue={2}>
        <Tab label="Identifiers" onClick={() => navigate("/admin/support")} value={1} />
        <Tab label="ShortCodes" onClick={() => navigate("/admin/support/shortcode")} value={2} />
        <Tab label="System Logs" onClick={() => navigate("/admin/support/log")} value={3} />
        <Tab label="Scheduled Jobs" onClick={() => navigate("/admin/support/job")} value={4} />
      </TabLayout>
      <DataGridRender
         loading={loading}
        filterTypes={['export','search']}
        limit={limit}
        search={search}
        filters={filters}
        pageNo={pageNo}
        columns={columns}
        data={ShortCodes}
        setPageNo={setPageNo}
        setSearch={setSearch}
        setLimit={setLimit}
        setFilters={setFilters}
        exportFileName="ShortCodes"
        hideFooterPagination={true}
      />
    </Container>
  );
}
