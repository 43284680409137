import { useContext } from 'react';
import { useEffect, useState } from 'react';
import { Container, Typography, Switch, Box, Tooltip, Button, MenuItem } from '@mui/material';
import { useDispatch } from 'react-redux';
import { useSelector } from 'src/reducers';
import { MoreMenu, DataGridRender } from 'src/components/table';
import MessageContext from 'src/context/MessageContext';
import ReactCountryFlag from "react-country-flag";
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import TopBar from 'src/components/shared/TopBar';
import { SupplierAllRequest, SupplierDeleteRequest, SupplierStatusUpdateRequest } from 'src/actions/supplierActions';
import { Icon } from '@iconify/react';
import ResponsiveDialog from 'src/components/dialog/ResponsiveDialog';
import EmailForm from 'src/components/admin/_dashboard/email/EmailForm';
import { SendBulkSupplierEmailRequest } from 'src/actions/emailActions';

export default function SupplierList() {

  const { setMessageHandler } = useContext<any>(MessageContext);
  const [pageNo, setPageNo] = useState(1);
  const [limit, setLimit] = useState(10);
  const [status] = useState(false);
  const [filters, setFilters] = useState<any>({});
  const [search, setSearch] = useState<string>('');
  const [dialogEmail ,setDialogEmail] = useState(false);
  

  const dispatch = useDispatch();
  const { loading, metadata, data } = useSelector(state => state.supplier);

  const navigate = useNavigate();

  useEffect(() => {
    dispatch(SupplierAllRequest(filters, search, pageNo, limit, status));
  }, [pageNo, search, limit, status, filters]);

  const deleteAction = (id: string) => {
    dispatch(SupplierDeleteRequest(id, { isDeleted: true }, setMessageHandler));
  }
  const editAction = (id: string) => {
    navigate(`/admin/supplier/${id}/edit`);
  }

  const sendEmailAction = (values: any, setSubmitting: any) => {
    dispatch(SendBulkSupplierEmailRequest(values,setDialogEmail, setMessageHandler));
  }

  const dialogActionsEmail = () => {
    var initialValues =""; 
    return  <ResponsiveDialog openDialog={dialogEmail} subTitle="Email Action" setOpenDialog={setDialogEmail} title="Send Bulk Email" >
        <EmailForm initialValues={initialValues} submitForm={sendEmailAction} />
      </ResponsiveDialog>
  }

  const columns = [
    {
      field: 'supplierName', type: "string", headerName: 'Supplier Name', filterable: false, headerAlign: "left", align: 'left', width: 200, renderCell: ({ id, value }: any) => {
        return <Tooltip title={value}>
          <RouterLink to={`/admin/supplier/${id}/edit`}> <Typography onClick={() => editAction(id)} color="primary" >{value}</Typography></RouterLink>
        </Tooltip>
      }
    },
    {
      field: 'countryName', type: "string", headerName: 'Region', filterable: false, flex: 1, headerAlign: "left", align: 'left',
      renderCell: (params: any) => {
        return <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <ReactCountryFlag className="emojiFlag" countryCode={`${params.row.countryCode}`} /><Tooltip title={params.row.countryName}>
            <Typography sx={{ ml: 1 }} variant="subtitle2">{params.row.countryName}</Typography>
          </Tooltip>
        </Box>
      },
    },
    { field: 'totalProjects', type: "string", headerName: 'T.Projects', filterable: false, flex: 1, headerAlign: "center", align: 'center' },
    { field: 'activeProjects', type: "string", headerName: 'A.Projects', filterable: false, flex: 1, headerAlign: "center", align: 'center' },
    { field: 'responded', type: "string", headerName: 'Traffic', filterable: false, flex: 1, headerAlign: "center", align: 'center' },
    { field: 'completed', type: "string", headerName: 'Complete', filterable: false, flex: 1, headerAlign: "center", align: 'center' },
    { field: 'approved', type: "string", headerName: 'Approved', filterable: false, flex: 1, headerAlign: "center", align: 'center' },
    {
      field: 'actualExpense', type: "string", headerName: 'A.Expense', filterable: false, flex: 1, headerAlign: "center", align: 'center',
      renderCell: (params: any) => {
        return "$ " + params.row.actualExpense
      },
    },
    {
      field: 'expectedExpense', type: "string", headerName: 'E.Expense', filterable: false, flex: 1, headerAlign: "center", align: 'center',
      renderCell: (params: any) => {
        return "$ " + params.row.expectedExpense
      },
    },
    // { field: 'rejectionRate', type: "string", headerName: 'R Rate', filterable: false, flex: 1, headerAlign: "center", align: 'center' },
    {
      field: 'isActive', headerName: 'Status', type: "boolean", width: 80, valueFormatter: ({ value }: any) => value ? "Active" : "InActive",
      renderCell: (params: any) => {
        return <Switch checked={params.value} onChange={() => dispatch(SupplierStatusUpdateRequest(params.id, { isActive: !params.value }, setMessageHandler))} />
      }
    },
    { field: 'actions', headerName: '', width: 40, type: 'actions', getActions: ({ id, row }: any) => [<MoreMenu id={id} name={row['supplierCode'] + row['supplierName']} cloneAction="" addAction='' editAction={editAction} deleteAction={deleteAction} exportAction="" />] },
  ];


  return (
    <Container maxWidth={false}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Box>
          <Typography sx={{ ml: 1 }} variant="h5">
            Suppliers
          </Typography>
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          {dialogActionsEmail()}
        <TopBar title="" link="/admin/supplier/add" buttonText="New Supplier" />
          <Box sx={{ height: 30, ml: 1, mt: 1 }}>
            <Button
              variant="contained"
              size="medium"
              startIcon={<Icon icon={'carbon:email'} />}
              onClick={() => setDialogEmail(true)}
            >
              Send Bulk Email
            </Button>
          </Box>
        </Box>
      </Box>
      <DataGridRender
        filterTypes={['countries', 'status', 'export', 'search']}
        exportFileName={"Suppliers"}
        loading={loading}
        filters={filters}
        data={data}
        metadata={metadata}
        limit={limit}
        search={search}
        pageNo={pageNo}
        columns={columns}
        setPageNo={setPageNo}
        setSearch={setSearch}
        setLimit={setLimit}
        setFilters={setFilters}
      />
    </Container>
  );
}
