import React from "react";
import { Snackbar } from "@mui/material";
import MuiAlert, { AlertProps,AlertColor } from '@mui/material/Alert';
import { useEffect } from "react";

interface SnackMessageProps {
  message: string;
  hasMessage : boolean;
  type:AlertColor;
}

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref,
) {
  return <MuiAlert  ref={ref} variant="filled" {...props} />;
});


const SnackMessage = ({ message ,hasMessage,type }: SnackMessageProps) => {
  const [state ] = React.useState<any>({
    vertical: 'top',
    horizontal: 'right',
  });
  const { vertical, horizontal  } = state;
  const [show, setShow] = React.useState<boolean>(false);

  useEffect(() => {
    if (hasMessage) {
      setShow(true);
    }
  }, [hasMessage]);

  return (
    <React.Fragment>
      <Snackbar anchorOrigin={{ vertical, horizontal }} onClose={() => setShow(false)} open={show} autoHideDuration={4000}>
        <Alert severity={type} sx={{ width: '100%' }}>
          {message}
        </Alert>
      </Snackbar>
    </React.Fragment>
  );
}

export default SnackMessage;
