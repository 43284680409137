import ReactApexChart from 'react-apexcharts';
import { Card, CardHeader, Box } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import NativeSelect from '@mui/material/NativeSelect';
import { useSelector } from 'src/reducers';
import { Client } from 'src/types/clientTypes';
import { Supplier } from 'src/types/supplierTypes';

export interface ChartProps {
  title: string,
  subheader: string,
  chartdata: any,
  options: any,
  type: any,
  filter?: any,
  setFilter?: any,
  filterType:any
}

var clientRender = (setFilterItem: any, clients: Client[]) =>
  <FormControl variant="outlined" sx={{ mr: 2, width: 150 }}  >
    <NativeSelect
      variant="outlined"
      onChange={(e) => setFilterItem("clientId", e.target.value)}
    >
      <option value="all">Clients</option>
      {clients.map((client: Client) =>
        <option value={client._id}>{client.label}</option>
      )}
    </NativeSelect>
  </FormControl>

var supplierRender = (setFilterItem: any, suppliers: Supplier[]) =>
  <FormControl variant="outlined" sx={{ mr: 2, width: 150 }}>
    <NativeSelect
      variant="outlined"
      onChange={(e) => setFilterItem("supplierId", e.target.value)}
    >
      <option value="all">Suppliers</option>
      {suppliers.map((supplier: Supplier) =>
        <option value={supplier._id}>{supplier.supplierName}</option>
      )}
    </NativeSelect>
  </FormControl>

export default function RenderGraph({filterType, filter, title, subheader, chartdata, options, type, setFilter }: ChartProps) {

  const { clients } = useSelector(state => state.client);
  const { suppliers } = useSelector(state => state.supplier);

  const setFilterItem = (type: string, value: any) => {
    if (!value) {
      delete filter[type];
      setFilter(filter);
    }
    else {
      var newobj = { [type]: value };
      setFilter({ ...filter, ...newobj });
    }
  }
  return (
    <Card>
      <CardHeader title={title} subheader={subheader}
        action={
          <Box>
            {filter && <FormControl variant="outlined" sx={{ mr: 2 }} >
              <NativeSelect
                variant="outlined"
                onChange={(e) => setFilterItem("type", e.target.value)}
                defaultValue={1}
              >
                <option value={1}>Daily</option>
                <option value={2}>Monthly</option>
                <option value={3}>Yearly</option>
              </NativeSelect>
            </FormControl>}
            {filterType.includes('client') && clientRender(setFilterItem, clients)}
            {filterType.includes('supplier') && supplierRender(setFilterItem, suppliers)}
          </Box>
        } />
      <Box sx={{ p: 3, pb: 1 }} dir="ltr">
        <ReactApexChart options={options} series={chartdata} type={type} height={350} />
      </Box>
    </Card>
  );
}
