import { ThunkAction, ThunkDispatch } from "redux-thunk";
import Prefix from 'src/helpers/ApiPrefix';
import AXIOS from 'src/helpers/Axios';
import Headers from 'src/helpers/Headers';
import { messageAction } from "src/reducers/messageReducer";
import { messageActionTypes } from "src/types/actionTypes";
import { Message, MessageList } from "src/types/messageTypes";
import { ErrorAction, SuccessAction } from "src/types/responseTypes";

export const MessageFailure = (message: string): ErrorAction => {
    return {
        type: messageActionTypes.MESSAGE_FAILURE,
        payload: { message: message }
    }
}
export const MessageSuccess = (payload: MessageList): SuccessAction<MessageList> => {
    return {
        type: messageActionTypes.FETCH_MESSAGE_SUCCESS,
        payload: payload,
    }
}
export const MessageAllSuccess = (payload: MessageList): SuccessAction<MessageList> => {
    return {
        type: messageActionTypes.FETCH_MESSAGE_ALL_SUCCESS,
        payload: payload,
    }
}

export const MessageAddSuccess = (payload: Message): SuccessAction<Message> => {
    return {
        type: messageActionTypes.ADD_MESSAGE_SUCCESS,
        payload: payload,
    }
}
export const MessageSingleSuccess = (payload: Message): SuccessAction<Message> => {
    return {
        type: messageActionTypes.FETCH_SINGLE_MESSAGE_SUCCESS,
        payload: payload,
    }
}

export const MessageUpdateSuccess = (payload: any): SuccessAction<any> => {
    return {
        type: messageActionTypes.UPDATE_MESSAGE_SUCCESS,
        payload: payload,
    }
}


export const MessageDeleteSuccess = (payload: any): SuccessAction<any> => {
    return {
        type: messageActionTypes.DELETE_MESSAGE_SUCCESS,
        payload: payload,
    }
}


export const MessageAllRequest = (filter: any, search: string, pageNo: number, limit: number, status: boolean):
    ThunkAction<Promise<void>, {}, {}, messageAction> => {
    return (dispatch: ThunkDispatch<{}, {}, messageAction>) => {
        return AXIOS.post(`${Prefix.api}/admin/message`, { filter, search, pageNo, limit, status }, { headers: Headers() }).then(res => {
            if (res) {
                const { data } = res;
                const categories: MessageList = data.result
                dispatch(MessageSuccess(categories))

            }
        }).catch((e) => {
            if (e.response) {
                dispatch(MessageFailure(e.response.data.message));
            }
            else {
                dispatch(MessageFailure(e.message));
            }
        })
    }
}

export const MessageAllRequestData = ():
    ThunkAction<Promise<void>, {}, {}, messageAction> => {
    return (dispatch: ThunkDispatch<{}, {}, messageAction>) => {
        return AXIOS.get(`${Prefix.api}/admin/message/all`, { headers: Headers() }).then(res => {
            if (res) {
                const { data } = res;
                const categories: MessageList = data.result
                dispatch(MessageAllSuccess(categories))
            }
        }).catch((e) => {
            if (e.response) {
                dispatch(MessageFailure(e.response.data.message));
            }
            else {
                dispatch(MessageFailure(e.message));
            }
        })
    }
}




export const MessageAddRequest = (message: Message, setMessageHandler: any, setSubmitting: any):
    ThunkAction<Promise<void>, {}, {}, messageAction> => {
    setMessageHandler({
        hasMessage: false,
        type: '',
        message: '',
    });
    return (dispatch: ThunkDispatch<{}, {}, messageAction>) => {
        return AXIOS.post(`${Prefix.api}/admin/message/add`, message, { headers: Headers() }).then(res => {
            if (res) {
                const { data } = res;
                const message: Message = data.result;
                dispatch(MessageAddSuccess(message))
                setSubmitting(false);
                setMessageHandler({
                    hasMessage: true,
                    type: 'success',
                    message: data.message,
                });
            }
        }).catch((e) => {
            if (e.response) {
                setSubmitting(false);
                dispatch(MessageFailure(e.response.data.message));
                setMessageHandler({
                    hasMessage: true,
                    type: 'error',
                    message: e.response.data.message,
                });
            }
            else {
                setSubmitting(false);
                dispatch(MessageFailure(e.message));
                setMessageHandler({
                    hasMessage: true,
                    type: 'error',
                    message: e.message,
                });
            }
        })
    }
}


export const MessageUpdateRequest = (catid: string, message: Message, setMessageHandler: any, setSubmitting: any):
    ThunkAction<Promise<void>, {}, {}, messageAction> => {
    setMessageHandler({
        hasMessage: false,
        type: '',
        message: '',
    });
    return (dispatch: ThunkDispatch<{}, {}, messageAction>) => {
        return AXIOS.put(`${Prefix.api}/admin/message/update/${catid}`, message, { headers: Headers() }).then(res => {
            if (res) {
                const { data } = res;
                const message: Message = data.result;
                dispatch(MessageUpdateSuccess(message))
                setSubmitting(false);
                setMessageHandler({
                    hasMessage: true,
                    type: 'success',
                    message: data.message,
                });
            }
        }).catch((e) => {
            if (e.response) {
                setSubmitting(false);
                setMessageHandler({
                    hasMessage: true,
                    type: 'error',
                    message: e.response.data.message,
                });
                dispatch(MessageFailure(e.response.data.message));
            }
            else {
                setSubmitting(false);
                setMessageHandler({
                    hasMessage: true,
                    type: 'error',
                    message: e.message,
                });
                dispatch(MessageFailure(e.message));
            }
        })
    }
}

export const MessageStatusUpdateRequest = (catid: string, message: Message, setMessageHandler: any):
    ThunkAction<Promise<void>, {}, {}, messageAction> => {
    setMessageHandler({
        hasMessage: false,
        type: '',
        message: '',
    });
    return (dispatch: ThunkDispatch<{}, {}, messageAction>) => {
        return AXIOS.put(`${Prefix.api}/admin/message/update/${catid}`, message, { headers: Headers() }).then(res => {
            if (res) {
                const { data } = res;
                const message: Message = data.result;
                dispatch(MessageUpdateSuccess(message))
                setMessageHandler({
                    hasMessage: true,
                    type: 'success',
                    message: data.message,
                });
            }
        }).catch((e) => {
            if (e.response) {
                setMessageHandler({
                    hasMessage: true,
                    type: 'error',
                    message: e.response.data.message,
                });
                dispatch(MessageFailure(e.response.data.message));
            }
            else {
                setMessageHandler({
                    hasMessage: true,
                    type: 'error',
                    message: e.message,
                });
                dispatch(MessageFailure(e.message));
            }
        })
    }
}


export const MessageSingleRequest = (catid: string):
    ThunkAction<Promise<void>, {}, {}, messageAction> => {

    return (dispatch: ThunkDispatch<{}, {}, messageAction>) => {
        return AXIOS.get(`${Prefix.api}/admin/message/${catid}`, { headers: Headers() }).then(res => {
            if (res) {
                const { data } = res;
                const message: Message = data.result;
                dispatch(MessageSingleSuccess(message));
            }
        }).catch((e) => {
            if (e.response) {
                dispatch(MessageFailure(e.response.data.message));
            }
            else {
                dispatch(MessageFailure(e.message));
            }
        })
    }
}


export const MessageDeleteRequest = (id: string, body: Message, setMessageHandler: any):
    ThunkAction<Promise<void>, {}, {}, messageAction> => {
    setMessageHandler({
        hasMessage: false,
        type: '',
        message: '',
    });
    return (dispatch: ThunkDispatch<{}, {}, messageAction>) => {
        return AXIOS.put(`${Prefix.api}/admin/message/update/${id}`, body, { headers: Headers() }).then(res => {
            if (res) {
                const { data } = res;
                const message: Message = data.result;
                dispatch(MessageDeleteSuccess(message))
                setMessageHandler({
                    hasMessage: true,
                    type: 'success',
                    message: data.message,
                });
            }
        }).catch((e) => {
            if (e.response) {
                dispatch(MessageFailure(e.response.data.message));
                setMessageHandler({
                    hasMessage: true,
                    type: 'error',
                    message: e.response.data.message,
                });
            }
            else {
                dispatch(MessageFailure(e.message));
                setMessageHandler({
                    hasMessage: true,
                    type: 'error',
                    message: e.message,
                });
            }
        })
    }
}





