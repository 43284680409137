import * as React from 'react';
import { Box ,Tabs ,Tab } from '@mui/material';

interface TabLayoutProps {
  handleChange: any,
  tabValue: number,
  children: React.ReactNode
}

export default function TabLayout({ handleChange, tabValue, children }: TabLayoutProps) {

  return (
    <Box sx={{ bgcolor: 'rgb(244, 246, 248)', borderTopLeftRadius: 10, borderTopRightRadius: 10 }}>
      <Tabs
        value={tabValue}
        onChange={handleChange}
        indicatorColor="primary"
        textColor="inherit"
        aria-label="full width tabs example"
      >
        {children}
      </Tabs>
    </Box>
  );
}