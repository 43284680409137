import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DesktopDatePicker from "@mui/lab/DesktopDatePicker";
import { TextField, Grid, Box, IconButton, Autocomplete, Typography } from '@mui/material';
import { FieldArray, Form, Formik, FormikProps } from 'formik';
import { LoadingButton } from '@mui/lab';
import { useEffect, useState } from 'react';
import { useSelector } from 'src/reducers';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Invoice, InvoiceAddress, InvoiceProject } from "src/types/invoiceTypes";
import { Delete as DeleteIcon } from "@mui/icons-material";
import { ProjectAllInvoiceRequestData } from "src/actions/projectActions";

export interface FormValues {
    initialValues: Invoice,
    submitForm: any,
    client: any,
    invoiceAddress:InvoiceAddress
    invoiceCode: any
}

export default function GenerateInvoiceForm({ initialValues, submitForm, client, invoiceCode,invoiceAddress }: FormValues) {

    const [searchpro, setSearchPro] = useState<string>('');

    useEffect(() => {
        dispatch(ProjectAllInvoiceRequestData({ search: searchpro, clientId: client._id }))
    }, [searchpro, client._id])

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { redirectTo, loading, projects } = useSelector(state => state.project);

    useEffect(() => {
        if (redirectTo) navigate('/admin/invoice', { replace: true })
    }, [redirectTo])

    return (
        <Formik
            onSubmit={(values, { setSubmitting }) => {
                values.clientId = client._id;
                submitForm(values, setSubmitting);
            }}
            validateOnBlur={false}
            validateOnChange={true}
            enableReinitialize={false}
            initialValues={{
                clientId: initialValues?.clientId,
                netTerms: initialValues?.netTerms || client.netDays || 0,
                taxLabel: initialValues?.taxLabel || '',
                taxAmount: initialValues?.taxAmount || 0,
                grandTotal: initialValues?.grandTotal || 0,
                taxBy: initialValues?.taxBy || 1,
                invoiceStatus: initialValues?.invoiceStatus || 1,
                projects: initialValues?.projects || [],
                currentDate: initialValues?.currentDate || new Date(),
                dueDate: initialValues?.dueDate || new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() + client.netDays),
            }}
        >
            {(formikProps: FormikProps<Invoice>) => (
                <Form autoComplete="off" noValidate onSubmit={formikProps.handleSubmit}>
                    <Box sx={{ bgcolor: 'rgb(244, 246, 248)', padding: 3, display: 'flex', flexDirection: 'row' }}>
                        <Autocomplete
                            fullWidth
                            freeSolo
                            id="free-solo-2-demo"
                            disableClearable
                            onChange={(event: any, newValue: any | null) => {
                                formikProps.setFieldValue(`projects`, [...formikProps.values.projects, { projectCode: newValue.projectCode, projectName: newValue.projectName, cpi: newValue.cpi, poNumber: newValue.poNumber, complete: newValue.approved, total: newValue.cpi * newValue.approved }]);
                            }}
                            getOptionLabel={(option) => option.projectCode + "_" + option.projectName}
                            options={projects}
                            renderInput={(params: any) => (
                                <TextField
                                    {...params}
                                    variant="outlined"
                                    fullWidth
                                    value=""
                                    size="medium"
                                    onChange={(e) => setSearchPro(e.target.value)}
                                    label={!projects ? "Please wait.." : "Search Project.."}
                                    InputProps={{
                                        ...params.InputProps,
                                        type: 'search',
                                    }}
                                />
                            )}
                        />
                        <LocalizationProvider sx={{ ml: 1 }} dateAdapter={AdapterDateFns}>
                            <DesktopDatePicker
                                minDate={formikProps.values.currentDate}
                                inputFormat="dd/MM/yyyy"
                                label="Current Date"
                                value={formikProps.values.currentDate}
                                onChange={(date: Date | null) => formikProps.setFieldValue('currentDate', date)}
                                renderInput={(params) => <TextField
                                    variant="outlined"
                                    sx={{ ml: 2 }}
                                    error={Boolean(formikProps.touched.currentDate && formikProps.errors.currentDate)}
                                    helperText={formikProps.touched.currentDate && formikProps.errors.currentDate}
                                    fullWidth {...params} />}
                            />
                        </LocalizationProvider>
                        <LocalizationProvider sx={{ ml: 1 }} dateAdapter={AdapterDateFns}>
                            <DesktopDatePicker
                                minDate={formikProps.values.dueDate}
                                inputFormat="dd/MM/yyyy"
                                label="Due Date"
                                value={formikProps.values.dueDate}
                                onChange={(date: Date | null) => formikProps.setFieldValue('dueDate', date)}
                                renderInput={(params) => <TextField
                                    variant="outlined"
                                    sx={{ ml: 2 }}
                                    error={Boolean(formikProps.touched.dueDate && formikProps.errors.dueDate)}
                                    helperText={formikProps.touched.dueDate && formikProps.errors.dueDate}
                                    fullWidth {...params} />}
                            />
                        </LocalizationProvider>
                        <TextField
                            sx={{ ml: 2 }}
                            fullWidth
                            label="Net Terms"
                            {...formikProps.getFieldProps('netTerms')}
                            name={`netTerms`}
                            onBlur={formikProps.handleBlur}
                            onChange={formikProps.handleChange}
                            variant="outlined"
                        />
                    </Box>
                    <Box>
                        <FieldArray name="projects" >
                            {({ remove }) => (
                                <>
                                    {formikProps.values.projects.length > 0 &&
                                        formikProps.values.projects.map((field: InvoiceProject, index: number) => (
                                            <>
                                                <Box sx={{ display: 'flex', padding: 3, paddingBottom: 0 }}>
                                                    <TextField
                                                        size="small"
                                                        fullWidth
                                                        sx={{ mr: 2 }}
                                                        label="Project Code"
                                                        margin="normal"
                                                        value={field.projectCode}
                                                        name={`projects.${index}.projectCode`}
                                                        onBlur={formikProps.handleBlur}
                                                        onChange={formikProps.handleChange}
                                                        type="text"
                                                        disabled
                                                        variant="outlined"
                                                    />
                                                    <TextField
                                                        size="small"
                                                        fullWidth
                                                        sx={{ mr: 2 }}
                                                        label="Project Name"
                                                        margin="normal"
                                                        value={field.projectName}
                                                        name={`projects.${index}.projectName`}
                                                        onBlur={formikProps.handleBlur}
                                                        onChange={formikProps.handleChange}
                                                        type="text"
                                                        variant="outlined"
                                                    />
                                                    <TextField
                                                        size="small"
                                                        fullWidth
                                                        sx={{ mr: 2 }}
                                                        label="PO Number"
                                                        margin="normal"
                                                        value={field.poNumber}
                                                        name={`projects.${index}.poNumber`}
                                                        onBlur={formikProps.handleBlur}
                                                        onChange={formikProps.handleChange}
                                                        type="text"
                                                        variant="outlined"
                                                    />
                                                    <TextField
                                                        size="small"
                                                        fullWidth
                                                        sx={{ maxWidth: 100, mr: 2 }}
                                                        label="CPI"
                                                        margin="normal"
                                                        value={field.cpi}
                                                        name={`projects.${index}.cpi`}
                                                        onBlur={formikProps.handleBlur}
                                                        onChange={(newValue: any | null) => {
                                                            formikProps.setFieldValue(`projects.${index}.cpi`, newValue.target.value);
                                                            formikProps.setFieldValue(`projects.${index}.total`, (formikProps.values.projects[index].complete || 0) * newValue.target.value);
                                                        }}
                                                        type="text"
                                                        variant="outlined"
                                                    />
                                                    <TextField
                                                        size="small"
                                                        fullWidth
                                                        label="Complete"
                                                        sx={{ maxWidth: 100, mr: 2 }}
                                                        margin="normal"
                                                        value={field.complete}
                                                        name={`projects.${index}.complete`}
                                                        onBlur={formikProps.handleBlur}
                                                        onChange={(newValue: any | null) => {
                                                            formikProps.setFieldValue(`projects.${index}.complete`, newValue.target.value);
                                                            formikProps.setFieldValue(`projects.${index}.total`, (formikProps.values.projects[index].cpi || 0) * newValue.target.value);
                                                        }}
                                                        type="text"
                                                        variant="outlined"
                                                    />
                                                    <TextField
                                                        size="small"
                                                        fullWidth
                                                        label="Total"
                                                        sx={{ maxWidth: 120 }}
                                                        margin="normal"
                                                        value={field.total}
                                                        onChange={formikProps.handleChange}
                                                        name={`projects.${index}.total`}
                                                        onBlur={formikProps.handleBlur}
                                                        type="text"
                                                        variant="outlined"
                                                    />
                                                    <Grid display="flex" justifyContent="center" alignItems="center" item xs={1} sm={1} md={1}>
                                                        <IconButton onClick={() => remove(index)} autoFocus color="error">
                                                            <DeleteIcon />
                                                        </IconButton>
                                                    </Grid>
                                                </Box>
                                            </>
                                        ))}
                                </>
                            )}
                        </FieldArray>
                    </Box>
                    {formikProps.values.projects.length > 0 && <Box sx={{ display: 'flex', justifyContent: 'right', alignItems: 'center', mr: 8 }}>
                        <Typography variant="body2" >Tax</Typography>
                        <TextField
                            size="small"
                            fullWidth
                            label="Type"
                            sx={{ maxWidth: 200, ml: 3 }}
                            margin="normal"
                            value={formikProps.values.taxLabel}
                            name="taxLabel"
                            onChange={formikProps.handleChange}
                            type="text"
                            variant="outlined"
                        />

                        <TextField
                            select
                            size="small"
                            fullWidth
                            label="By"
                            sx={{ maxWidth: 100, ml: 3 }}
                            margin="normal"
                            value={formikProps.values.taxBy}
                            name="taxBy"
                            onChange={formikProps.handleChange}
                            type="text"
                            variant="outlined"
                            SelectProps={{
                                native: true,
                            }}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        >
                            <option value={1}>Value</option>
                            <option value={2}>%</option>
                        </TextField>
                        <TextField
                            size="small"
                            fullWidth
                            label="Amount"
                            sx={{ maxWidth: 120, ml: 3 }}
                            margin="normal"
                            value={formikProps.values.taxAmount}
                            name="taxAmount"
                            onChange={formikProps.handleChange}
                            type="text"
                            variant="outlined"
                        />
                    </Box>}
                    {formikProps.values.projects.length > 0 && <Box sx={{ display: 'flex', justifyContent: 'right', alignItems: 'center', mr: 8 }}>
                        <Typography variant="body2" >Grand Total</Typography>
                        <TextField
                            size="small"
                            fullWidth
                            label="Total"
                            sx={{ maxWidth: 120, ml: 3 }}
                            margin="normal"
                            disabled={true}
                            value={formikProps.values.taxBy == 1 ? Number(formikProps.values.taxAmount) + formikProps.values.projects.reduce((a: any, b: any) => Number(Number(a) + Number(b.total || 0)), 0) : formikProps.values.projects.reduce((a: any, b: any) => Number(Number(a) + Number(b.total || 0)), 0) + (formikProps.values.projects.reduce((a: any, b: any) => Number(Number(a) + Number(b.total || 0)), 0) * Number(formikProps.values.taxAmount) / 100)}
                            name="grandTotal"
                            onBlur={formikProps.handleBlur}
                            type="text"
                            variant="outlined"
                        />
                    </Box>}
                    <Box sx={{ m: 3 }}>
                        <LoadingButton
                            disabled={client && (formikProps.values.projects.length > 0) ? false : true}
                            size="medium"
                            type="submit"
                            variant="contained"
                            loading={formikProps.isSubmitting}
                        >
                            {initialValues ? 'Save Invoice' : 'Create Invoice'}
                        </LoadingButton>
                    </Box>
                </Form>
            )}
        </Formik>
    );
}
