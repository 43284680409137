import { Icon } from '@iconify/react';
import { Box, Grid, Paper, Typography } from '@mui/material';
import { FC } from 'react';
import { convertSTM } from 'src/utils/common';

interface SiteItemProps {
  [site: string]: any
}

const SiteItem: FC<SiteItemProps> = ({ site }) => {
  var { icon, value, name ,key} = site;
  return (
    <Grid item xs={4}>
      <Paper variant="outlined" sx={{ py: 2.5, textAlign: 'center' }}>
        <Box sx={{ mb: 0.5 }}><Icon icon={icon} color="#EDAB2A" width={32} height={32} /></Box>
        <Typography variant="h6">{ key == "loi" ? convertSTM(value) : value}</Typography>
        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
          {name}
        </Typography>
      </Paper>
    </Grid>
  );
}

export default function ProjectStats({ stats }: any) {
  return (
    <Grid container spacing={2}>
      {stats.map((site: any) => (
        <SiteItem key={site.name} site={site} />
      ))}
    </Grid>
  );
}
