import SecureStorage from "./SecureStorage";

interface HeadersData {
  [key:string]: string;
}

export default function Headers() {
    const items: HeadersData = { 'Content-Type': 'application/json' };
    const token = SecureStorage.getItem('accessToken');
    if (token) {
      items.Authorization = `${token}`;
    }
    return items;
  }
  