import { useContext, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Container, Typography, Box, Tooltip, Tab, IconButton } from '@mui/material';
import swal from 'sweetalert';
import { useDispatch } from 'react-redux';
import { useSelector } from 'src/reducers';
import { MoreMenu, DataGridRender } from 'src/components/table';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { ProjectGroupExportRequest, ProjectGroupSupplierMapExportRequest, ProjectSupplierAllRequest } from 'src/actions/projectActions';
import TopBar from 'src/components/shared/TopBar';
import TabLayout from 'src/components/tab/TabLayout';
import { SupplierFinancesRequestData } from 'src/actions/supplierActions';
import { InvoiceSupplierAddRequest,InvoiceSupplierDownloadRequest } from 'src/actions/invoiceActions';
import { MessageContext } from 'src/context';

export default function InvoiceSupplierList() {
  const { setMessageHandler } = useContext<any>(MessageContext);
  const [limit, setLimit] = useState(20);
  const [pageNo, setPageNo] = useState(1);
  const [status] = useState(false);
  const [filters, setFilters] = useState<any>({});

  const dispatch = useDispatch();
  const { loading, suppliers } = useSelector(state => state.supplier);


  const navigate = useNavigate();

  useEffect(() => {
    dispatch(SupplierFinancesRequestData(filters, pageNo, limit, status))
  }, [pageNo, limit, filters, status]);

  const editAction = (id: string) => {
    navigate(`/admin/invoice/${id}/edit`, { replace: true });
  }
  const downloadInvoiceAction = (id: string, name: string) => {
    dispatch(InvoiceSupplierDownloadRequest( id));
  }
  const generateInvoiceAction = (id: string, name: string) => {
    swal({
      title: `This action will generate invoice for ${name}`,
      icon: "warning",
      dangerMode: false,
      buttons: ["No!", true],
    })
      .then((willDelete) => {
        if (willDelete) {
          dispatch(InvoiceSupplierAddRequest({ supplierId: id },setMessageHandler));
        } else {

        }
      });
  
  }
  const exportAction = (id: string, name: string) => {
    dispatch(ProjectGroupExportRequest({ projectId: id }, name));
  }
  const exportSupplierMap = (id: string, name: string) => {
    dispatch(ProjectGroupSupplierMapExportRequest({ projectId: id }, name));
  }
  const reloadAction = () => {
    dispatch(SupplierFinancesRequestData(filters, pageNo, limit, status));
  }



  const columns = [
    {
      field: 'supplierName', type: "string", headerName: 'Supplier Name', filterable: false, headerAlign: "left", align: 'left', flex: 1, pinnable: true, renderCell: ({ id, value }: any) => {
        return <Typography color="primary" >{value}</Typography>
      }
    },

    {
      field: 'project', type: "string", headerName: 'Projects', filterable: false, headerAlign: "left", align: 'left', flex: 1, pinnable: true, renderCell: ({ id, value }: any) => {
        return <IconButton onClick={() => navigate(`/admin/invoice/supplier/${id}/list`)}>
          <VisibilityIcon />
        </IconButton>
      }
    },
    {
      field: 'totalAccepted', type: "string", headerName: 'Total Accepted', filterable: false, width: 200, headerAlign: "center", align: 'center', valueGetter: ({ row }: any) => {
        return `${row['totalAccepted']}`;
      },
    },
    {
      field: 'totalCost', type: "string", headerName: 'Total Cost', filterable: false, width: 200, headerAlign: "center", align: 'center', valueGetter: ({ row }: any) => {
        return `$ ${row['totalCost']}`;
      },
    },
    { field: 'actions', headerName: '', width: 80, type: 'actions', getActions: ({ id, row }: any) => [<MoreMenu id={id} name={row['supplierName']} projectStatus={row['projectStatus']} exportFileName="Project" reconsileAction="" cloneAction='' addAction='' editAction={editAction} deleteAction="" exportSupplierMap={exportSupplierMap} generateInvoiceAction={generateInvoiceAction} />] },
  ];

  return (
    <Container maxWidth={false}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Box>
          <Typography sx={{ ml: 1 }} variant="h5">
            Accounts
          </Typography>
        </Box>
        <TopBar title="" link="/admin/invoice/generate" buttonText="Generate Invoice" />
      </Box>
      <TabLayout handleChange="" tabValue={4}>
        <Tab label="Ready To Invoice" href="/admin/invoice" value={1} />
        <Tab label="Archieved" href="/admin/invoice/archieved" value={3} />
        <Tab label="Invoices" href="/admin/invoice/list" value={2} />
        <Tab label="Finances" href="/admin/invoice/supplier" value={4} />
        <Tab label="Purchase Order" href="/admin/invoice/supplier/list" value={5} />
      </TabLayout>
      <DataGridRender
        reloadAction={reloadAction}
        getRowClassName={(params: any) => params.row['complete'] && params.row['complete'] >= params.row['sampleSize'] && params.row['sampleSize'] ? `data-grid-warning` : 'super-app-theme'}
        loading={loading}
        filterTypes={['export', 'search']}
        data={suppliers}
        exportFileName="Projects"
        metadata=""
        limit={limit}
        filters={filters}
        pageNo={pageNo}
        columns={columns}
        setPageNo={setPageNo}
        setLimit={setLimit}
        setFilters={setFilters}
        hideFooterPagination={true}
      />
    </Container>
  );
}
