import { shortCodeActionTypes } from "src/types/actionTypes";
import { ErrorAction, SuccessAction } from "src/types/responseTypes";
import { ShortCode, ShortCodeList } from "src/types/shortCodeTypes";

export type countryAction = SuccessAction<ShortCodeList> | SuccessAction<ShortCode> | SuccessAction<any> | ErrorAction
export const defaultCountryState: ShortCodeList = { ShortCodes: [],loading:true };

const reducer = (state: ShortCodeList = defaultCountryState, action: countryAction): ShortCodeList => {
    switch (action.type) {
        case shortCodeActionTypes.SHORTCODE_REQUEST:
            state = { ...state, loading:true }
            return state
        case shortCodeActionTypes.FETCH_SHORTCODE_SUCCESS:
            action = <SuccessAction<ShortCodeList>>action
            state = { ...state, ShortCodes: action.payload,loading:false }
            return state
        case shortCodeActionTypes.SHORTCODE_FAILURE:
            action = <ErrorAction>action
            return { ...state }
        default:
            return state
    }
}

export default reducer