import { dashboardActionTypes } from "src/types/actionTypes";
import { DashboardPayload } from "src/types/dashboardTypes";
import { ErrorAction, SuccessAction } from "src/types/responseTypes";

export type dashboardAction = SuccessAction<DashboardPayload> | SuccessAction<any> | ErrorAction
export const defaultDashboardState: DashboardPayload = { loading: true, dashboardBoxSectionData: {}, dashboardProjectListData: [], dashboardProjectManagerSectionData: [], dashboardProjectStatusSectionData: [], dashboardProjectExpenseSectionData: [], dashboardProjectRevenueSectionData: [], dashboardProjectTrafficSuccess: [], dashboardProjectSecuritySuccess: [], dashboardProjectExpenseRevenueSectionData: [], dashboardProjectInvoiceSectionData: [] };

const reducer = (state: DashboardPayload = defaultDashboardState, action: dashboardAction): DashboardPayload => {
    switch (action.type) {
        case dashboardActionTypes.DASHBOARD_REQUEST:
            state = { ...state }
            return state
        case dashboardActionTypes.FETCH_DASHBOARD_BOX_SUCCESS:
            action = <SuccessAction<DashboardPayload>>action
            state = { ...state, dashboardBoxSectionData: action.payload }
            return state
        case dashboardActionTypes.FETCH_DASHBOARD_PROJECT_LIST_SUCCESS:
            action = <SuccessAction<DashboardPayload>>action
            state = { ...state, dashboardProjectListData: action.payload }
            return state
        case dashboardActionTypes.FETCH_DASHBOARD_PROJECT_LIST_SECURITY_SUCCESS:
            action = <SuccessAction<DashboardPayload>>action
            state = { ...state, dashboardProjectListSecurityData: action.payload }
            return state
        case dashboardActionTypes.FETCH_DASHBOARD_PROJECT_MANAGER_SUCCESS:
            action = <SuccessAction<DashboardPayload>>action
            state = { ...state, dashboardProjectManagerSectionData: action.payload,loading: false }
            return state
        case dashboardActionTypes.FETCH_DASHBOARD_PROJECT_STATUS_SUCCESS:
            action = <SuccessAction<DashboardPayload>>action
            state = { ...state, dashboardProjectStatusSectionData: action.payload }
            return state
        case dashboardActionTypes.FETCH_DASHBOARD_PROJECT_EXPENSE_SUCCESS:
            action = <SuccessAction<DashboardPayload>>action
            state = { ...state, dashboardProjectExpenseSectionData: action.payload }
            return state
        case dashboardActionTypes.FETCH_DASHBOARD_PROJECT_REVENUE_SUCCESS:
            action = <SuccessAction<DashboardPayload>>action
            state = { ...state, dashboardProjectRevenueSectionData: action.payload }
            return state
        case dashboardActionTypes.FETCH_DASHBOARD_PROJECT_TRAFFIC_SUCCESS:
            action = <SuccessAction<DashboardPayload>>action
            state = { ...state, dashboardProjectTrafficSuccess: action.payload }
            return state
        case dashboardActionTypes.FETCH_DASHBOARD_PROJECT_SECURITY_SUCCESS:
            action = <SuccessAction<DashboardPayload>>action
            state = { ...state, dashboardProjectSecuritySuccess: action.payload }
            return state
        case dashboardActionTypes.FETCH_DASHBOARD_PROJECT_EXPENSE_REVENUE_SUCCESS:
            action = <SuccessAction<DashboardPayload>>action
            state = { ...state, dashboardProjectExpenseRevenueSectionData: action.payload }
            return state
        case dashboardActionTypes.FETCH_DASHBOARD_PROJECT_INVOICE_SUCCESS:
            action = <SuccessAction<DashboardPayload>>action
            state = { ...state, dashboardProjectInvoiceSectionData: action.payload }
            return state
        case dashboardActionTypes.DASHBOARD_FAILURE:
            action = <ErrorAction>action
            return { ...state }
        default:
            return state
    }
}
export default reducer