import { useContext, useEffect } from 'react';
import { Container, Typography, Box ,TextField} from '@mui/material';
import { useDispatch } from 'react-redux';
import { ProjectSingleRequest, ProjectUpdateRequest } from 'src/actions';
import { useNavigate, useParams } from 'react-router-dom';
import { Project } from 'src/types/projectTypes';
import MessageContext from 'src/context/MessageContext';
import TopBarForm from 'src/components/shared/TopBarForm';
import ChildProjectUpdateForm from 'src/components/admin/_dashboard/project/ChildProjectUpdateForm';
import { useSelector } from 'src/reducers';
import { ProjectChildsByRequest } from 'src/actions/projectActions';
import { Bars } from 'react-loader-spinner';

export default function EditChildProject() {

    const { setMessageHandler } = useContext<any>(MessageContext);
    const navigate =useNavigate();
    const dispatch = useDispatch();
    const { projectid }: any = useParams<any>();

    const { project, childProjects } = useSelector(state => state.project);
   
    const submitForm = async (values: Project, setSubmitting: any) => {
        setTimeout(() => dispatch(ProjectUpdateRequest(projectid, values, setMessageHandler, setSubmitting)), 200);
    }

    useEffect(() => {
        dispatch(ProjectSingleRequest(projectid));
    }, [projectid]);


    useEffect(() => {
        if ( project.parentProjectId) dispatch(ProjectChildsByRequest(project?.parentProjectId || ''));
    }, [project]);

    return (
        <Container maxWidth={false}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <TopBarForm title="Edit Child Project" link={`/admin/project/${project?.parentProjectId}/childs`} />
                {childProjects && <TextField
                    select
                    value={project?._id}
                    label="Project"
                    SelectProps={{
                        native: true,
                    }}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    onChange={(e)=> navigate(`/admin/project/child/${e.target.value}/edit`,{replace:true})}
                    variant="standard"
                >
                    {childProjects.map((cproject) => (
                        <option key={cproject._id} value={cproject._id}>
                            {cproject.projectCode}
                        </option>
                    ))}
                </TextField>}
                <Typography sx={{ mt: 2 }} variant="h5">{project?.projectCode + ' : ' + project?.projectName}</Typography>
            </Box>
         {project && project._id ? <ChildProjectUpdateForm submitForm={submitForm} initialValues={project} />: 
         <Bars height="50" width="50" color="#EDAB2A" ariaLabel="loading-indicator" /> 
         }
        </Container>
    );
}
