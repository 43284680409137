import { useContext, useEffect, useState } from 'react';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { Link, useParams } from 'react-router-dom';
import { Container, Typography, IconButton, Box, Tooltip, Badge, Switch } from '@mui/material';
import { useDispatch } from 'react-redux';
import { useSelector } from 'src/reducers';
import { sentenceCase } from 'change-case';
import { MoreMenu, DataGridRender } from 'src/components/table';
import Label from 'src/components/Label';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { CountryAllRequest, LanguageAllRequest } from 'src/actions/countryActions';
import { ProjectChildsRequest, ProjectExportRequest, ProjectPrescreenReportsRequest, ProjectSingleRequest, ProjectUpdateRequest } from 'src/actions/projectActions';
import { ProjectStatus } from 'src/constants';
import ResponsiveDialog from 'src/components/dialog/ResponsiveDialog';
import { fShortenNumber } from 'src/utils/formatNumber';
import TopBar from 'src/components/shared/TopBar';
import { SupplierMapAllRequest, SupplierMapStatusUpdateRequest } from 'src/actions/supplierMapActions';
import { CopyAllOutlined } from '@mui/icons-material';
import CloneProjectForm from 'src/components/admin/_dashboard/project/CloneProjectForm';
import ReactCountryFlag from 'react-country-flag';
import { MessageContext } from 'src/context';
import { ProjectStats } from 'src/components/admin/_dashboard/app/Dashboard';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';


export default function ChildProjectList() {
  const { setMessageHandler } = useContext<any>(MessageContext);
  const { projectid }: any = useParams<any>();
  const [pageNo, setPageNo] = useState(1);
  const [limit, setLimit] = useState(10);
  const [filters, setFilters] = useState<any>({});
  const [searchSupplier, setSearchSupplier] = useState<string>('');
  const [dialog, setDialog] = useState<string>('');
  const [dialogSupplier, setDialogSupplier] = useState<string>('');
  const [dialogClone, setDialogClone] = useState<string>('');
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [menuid, setMenuId] = useState<string>('');
  const open = Boolean(anchorEl);

  const handleClickMenu = (event: React.MouseEvent<HTMLElement>, id: string) => {
    setAnchorEl(event.currentTarget);
    setMenuId(id)
  };
  const handleClose = () => {
    setAnchorEl(null);
  };


  const dispatch = useDispatch();
  const { loading, project, metadata, childProjects } = useSelector(state => state.project);
  const supp = useSelector(state => state.supplierMap);
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(CountryAllRequest())
    dispatch(LanguageAllRequest())
    dispatch(ProjectChildsRequest(projectid, filters));
  }, [projectid, filters]);


  useEffect(() => {
    dispatch(ProjectSingleRequest(projectid));
  }, [projectid]);


  const editAction = (id: string) => {
    navigate(`/admin/project/child/${id}/edit`);
  }
  const exportAction = (id: string, name: string) => {
    dispatch(ProjectExportRequest({ projectId: id }, name));
  }

  const exportPrescreenReport = (id: string, name: string) => {
    dispatch(ProjectPrescreenReportsRequest({ projectId: id }, name));
  }


  const reloadAction = () => {
    dispatch(ProjectChildsRequest(projectid, filters));
  }

  useEffect(() => {
    if (dialogSupplier)
      dispatch(SupplierMapAllRequest(dialogSupplier, searchSupplier));
  }, [dialogSupplier, searchSupplier]);

  const setSubmitting =() =>{};

  const suppliercolumns = [
    {
      field: 'supplierName', type: "string", headerName: 'Supplier Name', filterable: false, headerAlign: "center", align: 'center', flex: 1, renderCell: ({ value }: any) => {
        return <Tooltip title={value}><Typography color="primary" >{value}</Typography></Tooltip>
      }
    },
    {
      field: 'complete', type: "string", headerName: 'Complete', filterable: false, width: 100, headerAlign: "center", align: 'center', valueGetter: ({ row }: any) => {
        return `${fShortenNumber(row['complete'])}`;
      },
    },
    { field: 'terminate', type: "number", headerName: 'ST', filterable: false, width: 60, headerAlign: "center", align: 'center' },
    { field: 'overQuota', type: "number", headerName: 'OQ', filterable: false, width: 60, headerAlign: "center", align: 'center' },
    { field: 'qualityTerm', type: "number", headerName: 'QT', filterable: false, width: 60, headerAlign: "center", align: 'center' },
    { field: 'drop', type: "number", headerName: 'DO', filterable: false, width: 60, headerAlign: "center", align: 'center' },
    { field: 'preScreenTerminate', type: "number", headerName: 'PT', filterable: false, width: 60, headerAlign: "center", align: 'center' },
    { field: 'preScreenDrop', type: "number", headerName: 'PD', filterable: false, width: 60, headerAlign: "center", align: 'center' },
    { field: 'totalResponded', type: "number", headerName: 'Traffic', filterable: false, width: 80, headerAlign: "center", align: 'center' },
    { field: 'supplierCPI', type: "number", headerName: 'CPI', filterable: false, width: 80, headerAlign: "center", align: 'center' },
    {
      field: 'allowTraffic', headerName: 'Traffic', type: "number", width: 80, valueFormatter: ({ value }: any) => value ? "Active" : "InActive",
      renderCell: (params: any) => {
        return <Switch checked={params.value} onChange={() => dispatch(SupplierMapStatusUpdateRequest(params.id, { allowTraffic: !params.value }, setMessageHandler))} />
      }
    },
    {
      field: 'isTestLink', headerName: 'Test Link', type: "number", width: 90, valueFormatter: ({ value }: any) => value ? "Active" : "InActive",
      renderCell: (params: any) => {
        return <Switch checked={params.value} onChange={() => dispatch(SupplierMapStatusUpdateRequest(params.id, { isTestLink: !params.value }, setMessageHandler))} />
      }
    },
    {
      field: 'isPreScreen', headerName: 'PreScreen', type: "number", width: 100, valueFormatter: ({ value }: any) => value ? "Active" : "InActive",
      renderCell: (params: any) => {
        return <Switch checked={params.value} onChange={() => dispatch(SupplierMapStatusUpdateRequest(params.id, { isPreScreen: !params.value }, setMessageHandler))} />
      }
    },
    {
      field: 'isBoarded', headerName: 'Boarded', type: "number", width: 90, valueFormatter: ({ value }: any) => value ? "Active" : "InActive",
      renderCell: (params: any) => {
        return <Switch checked={params.value} onChange={() => dispatch(SupplierMapStatusUpdateRequest(params.id, { isBoarded: !params.value }, setMessageHandler))} />
      }
    },
  ];

  const dialogActionsSupplier = (filename: string, id: string, title: any,subTitle:string ) => {
    return <Box>
      <IconButton onClick={() => setDialogSupplier(id)}>
        <VisibilityIcon />
      </IconButton>
      <ResponsiveDialog openDialog={dialogSupplier == id} subTitle={subTitle} setOpenDialog={setDialogSupplier} title={title} >
        <DataGridRender
          height={supp.supplierMaps.length == 0 ? 40+230 : supp.supplierMaps.length*40+230}
          width={1200}
          getRowClassName={(params: any) => params.row['complete'] >= params.row['sampleSize'] ? `data-grid-warning` : 'super-app-theme'}
          filterTypes={['export']}
          data={supp.supplierMaps}
          metadata=""
          filters={filters}
          loading={supp.loading}
          limit={limit}
          pageNo={pageNo}
          search={searchSupplier}
          exportFileName={filename}
          setSearch={setSearchSupplier}
          columns={suppliercolumns}
          hideFooterPagination={true}
          setPageNo={setPageNo}
          setLimit={setLimit}
          setFilters={setFilters}
        />
      </ResponsiveDialog>
    </Box>
  }

  const dialogActionsSecurity = (stats: any, id: string, title: any,subTitle:string, count: number) => {
    return <Box>
      <IconButton onClick={() => setDialog(id + title)}>
        <Badge showZero={true} badgeContent={count} max={100000} color="primary">
        </Badge>
      </IconButton>
      <ResponsiveDialog openDialog={dialog == id + title} subTitle={subTitle} setOpenDialog={setDialog} title={title} >
        <ProjectStats stats={stats} />
      </ResponsiveDialog>
    </Box>
  }


  const dialogActions = (stats: any, id: string, title: any,subTitle:string) => {
    return <Box>
      <IconButton onClick={() => setDialog(id + title)}>
        <VisibilityIcon />
      </IconButton>
      <ResponsiveDialog openDialog={dialog == id + title} setOpenDialog={setDialog} title={title}  subTitle={subTitle} >
        <ProjectStats stats={stats} />
      </ResponsiveDialog>
    </Box>
  }

  const dialogCloneActions = (id: string, title: string,subTitle:string) => {
    return <Box>
      <IconButton onClick={() => setDialogClone(id)}>
        <CopyAllOutlined />
      </IconButton>
      <ResponsiveDialog openDialog={dialogClone == id} setOpenDialog={setDialogClone} title={title} subTitle={subTitle}>
        <CloneProjectForm projectId={id} setDialogClone={setDialogClone} />
      </ResponsiveDialog>
    </Box>
  }

  const columns = [
    {
      field: 'projectCode', type: "string", headerName: 'Project #', filterable: false, headerAlign: "center", align: 'left', width: 100, pinnable: true, renderCell: ({ row, value }: any) => {
        return <RouterLink to={`/admin/project/child/${row['_id']}/edit`}>
          <Typography color="primary" >{value}</Typography>
        </RouterLink>
      }
    },
    {
      field: 'projectName', type: "string", headerName: 'Project Title', filterable: false, headerAlign: "left", align: 'left', flex: 1, renderCell: ({ value }: any) => {
        return <Tooltip title={value}>
          <Typography variant="subtitle2">{value}</Typography>
        </Tooltip>
      }
    },
    {
      field: 'countryName', type: "string", headerName: 'Region', filterable: false, width: 100, headerAlign: "left", align: 'left',
      renderCell: (params: any) => {
        return <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <ReactCountryFlag className="emojiFlag" countryCode={`${params.row.countryCode}`} /><Tooltip title={params.row.countryName}>
            <Typography sx={{ ml: 1 }} variant="subtitle2">{params.row.countryName}</Typography>
          </Tooltip>
        </Box>
      },
    },
    {
      field: 'complete', type: "string", headerName: 'Complete', filterable: false, width: 100, headerAlign: "center", align: 'center', valueGetter: ({ row }: any) => {
        return `${row['complete']}/${row['sampleSize']}`;
      },
    },
    { field: 'terminate', type: "number", headerName: 'ST', filterable: false, width: 60, headerAlign: "center", align: 'center' },
    { field: 'overQuota', type: "number", headerName: 'OQ', filterable: false, width: 60, headerAlign: "center", align: 'center' },

    { field: 'qualityTerm', type: "number", headerName: 'QT', filterable: false, width: 60, headerAlign: "center", align: 'center' },
    { field: 'drop', type: "number", headerName: 'DO', filterable: false, width: 60, headerAlign: "center", align: 'center' },
    {
      field: 'totalDisqualified', type: "string", headerName: 'Security', filterable: false, width: 90, headerAlign: "center", align: 'center', renderCell: ({ row }: any) => {
        return dialogActionsSecurity(row.disqualified, row._id,
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography variant="h6">Security</Typography>
          </Box>, row.projectCode  ,row.totalDisqualified);
      },
    },
    { field: 'clicked', type: "number", headerName: 'Traffic', filterable: false, width: 80, headerAlign: "center", align: 'center' },
    { field: 'cpi', type: "string", headerName: 'CPI', filterable: false, width: 60, headerAlign: "center", align: 'center' },
    {
      field: 'responsded', type: "string", headerName: 'Field Stats', disableExport: true, filterable: false, width: 100, headerAlign: "center", align: 'center', renderCell: ({ row }: any) => {
        return dialogActions(row.fieldStats, row._id, row.projectCode,'Field Stats');
      },
    },
    {
      field: 'sstats', type: "string", headerName: 'Supplier Stats', disableExport: true, filterable: false, width: 130, headerAlign: "center", align: 'center', renderCell: ({ row }: any) => {
        return dialogActionsSupplier(row['projectCode'] + "_" + row['projectName'], row._id,row.projectCode, 'Supplier Mapped');
      },
    },

    {
      field: 'clone', type: "string", headerName: 'Clone', filterable: false, width: 70, disableExport: true, headerAlign: "center", align: 'center', renderCell: ({ row }: any) => {
        return dialogCloneActions(row._id, row.projectCode,'Clone Criteria');
      },
    },
    {
      field: 'projectStatus', headerName: 'Status', type: "number", width: 80,
      valueFormatter: ({ value }: any) => ProjectStatus.find(status => status.value == value)?.label,
      renderCell: (params: any) => {
        var status: any = ProjectStatus.find(status => status.value == params.value);
        return <>
          <IconButton onClick={(e) => handleClickMenu(e, params.row._id)}>
            <Label
              variant="ghost"
              color={status?.color}
            >
              {sentenceCase(status.label)}
            </Label>
          </IconButton>
          <Menu
            id="lock-menu"
            anchorEl={anchorEl}
            open={open && params.row._id == menuid}
            onClose={handleClose}
            MenuListProps={{
              'aria-labelledby': 'lock-button',
              role: 'listbox',
            }}
          >
            <MenuItem onClick={() => dispatch(ProjectUpdateRequest(params.row._id, { projectStatus: 1 }, setMessageHandler, setSubmitting)) && handleClose()}>Live</MenuItem>
            <MenuItem onClick={() => dispatch(ProjectUpdateRequest(params.row._id, { projectStatus: 3 }, setMessageHandler, setSubmitting)) && handleClose()}>Paused</MenuItem>
          </Menu>
        </>
      }
    },
    { field: 'actions', headerName: '', width: 20, type: 'actions', getActions: ({ id, row }: any) => [<MoreMenu id={id} name={row['projectCode'] + "_" + row['projectName']} exportFileName="Project" cloneAction='' addAction='' exportPrescreenReport={exportPrescreenReport} editAction={editAction} deleteAction="" exportAction={exportAction} />] },
  ];


  return (
    <Container maxWidth={false}>
      <Box sx={{ mb: 1 }}>
        <Link style={{ display: 'flex', alignContent: 'center' }} to="/admin/project">
          <KeyboardBackspaceIcon fontSize='medium' color="primary" />
          <Typography sx={{ ml: 1 }} color="primary" variant="h5">Projects</Typography>
        </Link>
      </Box>
      <TopBar title={project?.projectCode + "_" + project?.projectName} link={`/admin/project/child/${projectid}/add`} buttonText="New Child Project" />
      <DataGridRender
        reloadAction={reloadAction}
        getRowClassName={(params: any) => params.row['complete'] && params.row['complete'] >= params.row['sampleSize'] && params.row['sampleSize'] ? `data-grid-warning` : 'super-app-theme'}
        loading={loading}
        hideFooterPagination={true}
        filters={filters}
        filterTypes={['projectStatus', 'export', 'search']}
        exportFileName={project?.projectCode + "_" + project?.projectName}
        data={childProjects}
        metadata={metadata}
        limit={limit}
        pageNo={pageNo}
        columns={columns}
        setPageNo={setPageNo}
        setLimit={setLimit}
        setFilters={setFilters}
      />
    </Container>
  );
}
