import { supplierActionTypes } from "src/types/actionTypes";
import { Supplier, SupplierList } from "src/types/supplierTypes";
import { ErrorAction, SuccessAction } from "src/types/responseTypes";

export type supplierAction = SuccessAction<SupplierList> | SuccessAction<Supplier> | SuccessAction<any> | ErrorAction
export const defaultSupplierState: SupplierList = { loading:true, redirectTo: '',suppliers:[], supplier: {}, data: [], metadata: {} };

const reducer = (state: SupplierList = defaultSupplierState, action: supplierAction): SupplierList => {

    switch (action.type) {
        case supplierActionTypes.SUPPLIER_REQUEST:
            state = { ...state,loading:true }
            return state
        case supplierActionTypes.FETCH_SUPPLIER_SUCCESS:
            action = <SuccessAction<SupplierList>>action
            state = { ...state, redirectTo: '', data: action.payload.data, metadata: action.payload.metadata ,loading:false }
            return state
        case supplierActionTypes.FETCH_SUPPLIER_ALL_SUCCESS:
            action = <SuccessAction<SupplierList>>action
            state = { ...state, suppliers: action.payload,loading:false }
            return state
        case supplierActionTypes.UPDATE_SUPPLIER_SUCCESS:
            action = <SuccessAction<any>>action
            state = { ...state, redirectTo: '/admin/supplier', data: state.data?.map(dat => [ dat._id ==action.payload._id && Object.assign(dat ,action.payload)].find(o => o._id == dat._id) || dat) }
            return state
        case supplierActionTypes.FETCH_SINGLE_SUPPLIER_SUCCESS:
            action = <SuccessAction<Supplier>>action
            state = { ...state,loading:false, supplier: action.payload }
            return state
        case supplierActionTypes.DELETE_SUPPLIER_SUCCESS:
            action = <SuccessAction<any>>action
            state = { ...state, data: state.data?.filter(dat => dat._id !== action.payload._id), metadata: { ...state.metadata, limit: Number(state.metadata?.limit) - 1, total: Number(state.metadata?.total) - 1 } }
            return state
        case supplierActionTypes.ADD_SUPPLIER_SUCCESS:
            action = <SuccessAction<Supplier>>action
            state = { ...state, redirectTo: '/admin/supplier', data: state.data?.concat(action.payload) }
            return state
        case supplierActionTypes.SUPPLIER_FAILURE:
            action = <ErrorAction>action
            return { ...state }
        default:
            return state
    }
}
export default reducer