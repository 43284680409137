import { useContext, useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Container, Typography, Box, Tooltip, Tab, IconButton } from '@mui/material';
import { useDispatch } from 'react-redux';
import { useSelector } from 'src/reducers';
import moment from 'moment';
import swal from 'sweetalert';
import { MoreMenu, DataGridRender } from 'src/components/table';
import VisibilityIcon from '@mui/icons-material/Visibility';
import Label from 'src/components/Label';
import { ProjectApiLogitRequest, ProjectLogitAddFromFetchRequest, ProjectApiLogitQuotaAllRequest } from 'src/actions/projectActions';
import TopBar from 'src/components/shared/TopBar';
import { MessageContext } from 'src/context';
import TabLayout from 'src/components/tab/TabLayout';
import ResponsiveDialog from 'src/components/dialog/ResponsiveDialog';

export default function LogitProjectList() {

  const [searchParams] = useSearchParams();
  const { setMessageHandler } = useContext<any>(MessageContext);
  var pageNo: any = searchParams.get("p") || 1
  const [limit, setLimit] = useState(20);
  const [filters, setFilters] = useState<any>({ countryId: "62151c9cca53f1138fab528e" });
  const [dialog, setDialog] = useState<string>('');
  const [dialogQualification, setDialogQualification] = useState<string>('');

  const dispatch = useDispatch();
  const { loading, fetchProjects, metadata, quotas } = useSelector(state => state.project);

  const navigate = useNavigate();

  useEffect(() => {
    dispatch(ProjectApiLogitRequest(filters));
  }, [filters]);


  useEffect(() => {
    if (dialog || dialogQualification) {
      dispatch(ProjectApiLogitQuotaAllRequest(filters, dialog || dialogQualification));
    }
  }, [dialog, dialogQualification, filters]);


  const addToProject = (project: any) => {
    swal({
      title: "This action will create a new project",
      icon: "warning",
      dangerMode: false,
      buttons: ["No!", true],
    })
      .then((willDelete) => {
        if (willDelete) {
          project.clientId = filters.client;
          dispatch(ProjectLogitAddFromFetchRequest(project, setMessageHandler));
        } else {
        }
      });
  }


  const setPageNo = (page: any) => {
    navigate(`/admin/project?p=${page}`);
  }


  const reloadAction = () => {
    dispatch(ProjectApiLogitRequest(filters));
  }


  const columnsLogit = [
    {
      field: 'completes', type: "string", headerName: 'RC', filterable: false, headerAlign: "left", align: 'left', width: 50, renderCell: ({ id, value }: any) => {
        return <Tooltip title={value}>
          <Typography variant="subtitle2">{value}</Typography>
        </Tooltip>
      }
    },
    {
      field: 'qualification', type: "string", headerName: 'Quotas', filterable: false, headerAlign: "left", align: 'left', flex: 1, renderCell: ({ value }: any) => {
        return value.map((val: any) =>
          <Box>
            <Typography>{`QuestionName : ${val.queryText} , Lucid Question Id : ${val.Lucid_Question_ID} , Answers : ${val.answerIds}`}</Typography>
          </Box>
        );
      }
    },
  ];


  const columnsQualificationLogit = [
    {
      field: 'queryText', type: "string", headerName: 'Question Name', filterable: false, headerAlign: "left", align: 'left', flex: 1, renderCell: ({ id, value }: any) => {
        return <Tooltip title={value}>
          <Typography variant="subtitle2">{value}</Typography>
        </Tooltip>
      }
    },
    {
      field: 'queryType', type: "string", headerName: 'Question Type', filterable: false, headerAlign: "left", align: 'left', width: 250, renderCell: ({ id, value }: any) => {
        return <Tooltip title={value}>
          <Typography variant="subtitle2">{value}</Typography>
        </Tooltip>
      }
    },
    {
      field: 'Lucid_Question_ID', type: "string", headerName: 'Lucid Question', filterable: false, headerAlign: "left", align: 'left', width: 200, renderCell: ({ id, value }: any) => {
        return <Tooltip title={value}>
          <Typography variant="subtitle2">{value}</Typography>
        </Tooltip>
      }
    },
    {
      field: 'answerIds', type: "string", headerName: 'Answer Ids', filterable: false, headerAlign: "left", align: 'left', flex: 1, renderCell: ({ value }: any) => {
        return value.map((val: any) =>
          <Box>
            {val.Lucid_Option_ID ? <Typography>{`${val.Lucid_Option_ID} ,`}</Typography> : val}
          </Box>
        );
      }
    },
  ];


  const dialogActionsQuotas = ( id: string, name: string, title: string) => {
    return <Box>
      <IconButton onClick={() => setDialog(id)}>
        <VisibilityIcon />
      </IconButton>
      <ResponsiveDialog openDialog={dialog == id} setOpenDialog={setDialog} title={title} subTitle={name}  >
        {quotas && quotas.quotas  &&
          <DataGridRender
            height={400}
            width={1160}
            filterTypes={['export']}
            data={quotas.quotas}
            filters="{}"
            exportFileName="Quotas"
            limit={limit}
            pageNo={pageNo}
            loading={loading}
            columns={columnsLogit}
            setPageNo={setPageNo}
            setLimit={setLimit}
            setFilters={setFilters}
            hideFooterPagination={true}
          />}
      </ResponsiveDialog>
    </Box>
  }


  const dialogActionsQualification = ( id: string, name: string, title: string) => {
    return <Box>
      <IconButton onClick={() => setDialogQualification(id)}>
        <VisibilityIcon />
      </IconButton>
      <ResponsiveDialog openDialog={dialogQualification == id} setOpenDialog={setDialogQualification} title={title} subTitle={name}  >
        {quotas && quotas.qualificationData  &&
          <DataGridRender
            height={400}
            width={1160}
            filterTypes={['export']}
            data={quotas.qualificationData}
            filters="{}"
            loading={loading}
            exportFileName="Quotas"
            limit={limit}
            pageNo={pageNo}
            columns={columnsQualificationLogit}
            setPageNo={setPageNo}
            setLimit={setLimit}
            setFilters={setFilters}
            hideFooterPagination={true}
          />}
      </ResponsiveDialog>
    </Box>
  }

  const columns = [
    {
      field: 'prj_name', type: "string", headerName: 'Project Title', filterable: false, headerAlign: "left", align: 'left', flex: 1, renderCell: ({ row }: any) => {
        return <Tooltip title={row['projectName'] || row['prj_name']}>
          <Typography variant="subtitle2">{row['projectName'] || row['prj_name'] || row['SurveyName']}</Typography>
        </Tooltip>
      }
    },
    {
      field: 'prj_id', type: "string", headerName: 'Project ID', filterable: false, headerAlign: "left", align: 'left', width: 100, renderCell: ({ row }: any) => {
        return <Tooltip title={row['prj_id'] || row['projectID']}>
          <Typography variant="subtitle2">{row['prj_id'] || row['projectID'] || row['SurveyID']}</Typography>
        </Tooltip>
      }
    },
    {
      field: 'Country_Code', type: "string", headerName: 'Country', filterable: false, headerAlign: "center", align: 'center', width: 100, renderCell: ({ row }: any) => {
        return row['Country_Code'] || "US";
      }
    },
    {
      field: 'total_completes', type: "string", headerName: 'Requirement', filterable: false, headerAlign: "center", align: 'center', width: 120, renderCell: ({ row }: any) => {
        return <Tooltip title={row['requirement'] || row['total_completes']}>
          <Typography variant="subtitle2">{row['requirement'] || row['total_completes'] || row['CompletesRequired']}</Typography>
        </Tooltip>
      }
    },
    {
      field: 'ir', type: "string", headerName: 'IR', filterable: false, headerAlign: "left", align: 'left', width: 60, renderCell: ({ row }: any) => {
        return row['IR'] || row['ir'];
      }
    },
    {
      field: 'loi', type: "string", headerName: 'LOI', filterable: false, headerAlign: "left", align: 'left', width: 60, renderCell: ({ row }: any) => {
        return row['LOI'] || row['loi'];
      }
    },
    { field: 'loi', type: "string", headerName: 'LOI', filterable: false, width: 60, headerAlign: "center", align: 'center' },
    {
      field: 'reward', type: "string", headerName: 'Reward', filterable: false, width: 80, headerAlign: "center", align: 'center', renderCell: ({ row }: any) => {
        return row['reward'] || row['CPI'] || row['Price']['Amount'];
      }
    },
    {
      field: 'quotas', type: "string", headerName: 'Quotas', disableExport: true, filterable: false, width: 120, headerAlign: "center", align: 'center', renderCell: ({ row }: any) => {
        return dialogActionsQuotas( row.prj_id || row['projectID'] || row['SurveyID'], row.prj_name || row['projectID'] || row['SurveyID'], 'Quotas');
      },
    },
    {
      field: 'qualificationData', type: "string", headerName: 'Qualifications', disableExport: true, filterable: false, width: 130, headerAlign: "center", align: 'center', renderCell: ({ row }: any) => {
        return dialogActionsQualification( row.prj_id || row['projectID'] || row['SurveyID'], row.prj_name || row['projectID'] || row['SurveyID'], 'Qualification');
      },
    },
    {
      field: 'end_date', type: "string", headerName: 'End Date', filterable: false, headerAlign: "center", align: 'center', width: 120, renderCell: ({ value }: any) => {
        return <Label
          variant="ghost"
          color="primary"
        >
          {moment(value).format('DD/MM/YYYY')}
        </Label>
      }
    },
    { field: 'actions', headerName: '', width: 60, type: 'actions', getActions: ({ id, row }: any) => [<MoreMenu id={id} project={row} name={row['projectCode'] + "_" + row['projectName']} projectStatus={row['projectStatus']} exportFileName="Project" addToProject={addToProject} />] },
  ];

  return (
    <Container maxWidth={false}>
      <TopBar title="Projects" link="" buttonText="Add Project" />
      <TabLayout handleChange="" tabValue={2}>
        <Tab label="Precision" onClick={() => navigate("/admin/api")} value={1} />
        <Tab label="Logit" onClick={() => navigate("/admin/api/logit")} value={2} />
        <Tab label="Toulna" onClick={() => navigate("/admin/api/toluna")} value={3} />
        <Tab label="Circle" onClick={() => navigate("/admin/api/circle")} value={4} />
        <Tab label="UniMrkt" onClick={() => navigate("/admin/api/mrkt")} value={5} />
      </TabLayout>
      <DataGridRender
        reloadAction={reloadAction}
        getRowClassName={(params: any) => params.row['complete'] && params.row['complete'] >= params.row['sampleSize'] && params.row['sampleSize'] ? `data-grid-warning` : 'super-app-theme'}
        loading={loading}
        filterTypes={['export', 'countries']}
        data={fetchProjects}
        exportFileName="Projects"
        metadata={metadata}
        filters={filters}
        limit={limit}
        hideFooterPagination={true}
        pageNo={pageNo}
        columns={columns}
        setPageNo={setPageNo}
        setLimit={setLimit}
        setFilters={setFilters}
      />
    </Container>
  );
}
