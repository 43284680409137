import {  Container } from '@mui/material';
import { useDispatch } from 'react-redux';
import { SupplierForm } from 'src/components/admin/_dashboard/supplier';
import { Supplier } from 'src/types/supplierTypes';
import MessageContext from 'src/context/MessageContext';
import { useContext } from 'react';
import { SupplierAddRequest } from 'src/actions/supplierActions';
import TopBarForm from 'src/components/shared/TopBarForm';

export default function AddSupplier() {
    const { setMessageHandler } = useContext<any>(MessageContext);
    const dispatch = useDispatch();
    const initialValues: any = null;

    const submitForm = async (values: Supplier, setSubmitting: any) => {
        setTimeout(() => dispatch(SupplierAddRequest(values, setMessageHandler, setSubmitting)), 200);
    }

    return (
        <Container maxWidth={false}>
            <TopBarForm title="Add Supplier" link="/admin/supplier" />
            <SupplierForm initialValues={initialValues} submitForm={submitForm} />
        </Container>
    );
}
