import { useContext } from 'react';
import { useEffect, useState } from 'react';
import { Icon } from '@iconify/react';
import editFill from '@iconify/icons-eva/edit-fill';
import { Container, Typography, IconButton, Box, Tooltip } from '@mui/material';
import { useDispatch } from 'react-redux';
import { useSelector } from 'src/reducers';
import { sentenceCase } from 'change-case';
import {  DataGridRender } from 'src/components/table';
import MessageContext from 'src/context/MessageContext';
import Label from 'src/components/Label';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { ProjectSupplierInventoryRequest , ProjectSupplierInventoryUpdateRequest } from 'src/actions/projectActions';
import { ProjectStatus } from 'src/constants';
import ResponsiveDialog from 'src/components/dialog/ResponsiveDialog';
import TopBar from 'src/components/shared/TopBar';
import { SupplerInventoryForm } from 'src/components/supplier/_dashboard/inventory';
import { Project } from 'src/types/projectTypes';
import ReactCountryFlag from 'react-country-flag';

export default function SupplierProjectInventory() {

  const { setMessageHandler } = useContext<any>(MessageContext);
  const [pageNo, setPageNo] = useState(1);
  const [limit, setLimit] = useState(10);
  const [status] = useState(false);
  const [filters, setFilters] = useState<any>({});
  const [dialog, setDialog] = useState<string>('');

  const dispatch = useDispatch();
  const { loading, metadata, data } = useSelector(state => state.project);

  useEffect(() => {
    dispatch(ProjectSupplierInventoryRequest(filters, pageNo, limit, status));
  }, [pageNo , limit, status, filters]);
     
  const submitForm = async (values: Project, setSubmitting: any) => {
     setTimeout(() => dispatch(ProjectSupplierInventoryUpdateRequest(dialog, values, setMessageHandler, setSubmitting)), 200);
   }

  const dialogActions = (surveyUrls: any, id: string, title: string) => {
    return <Box>
      <IconButton onClick={() => setDialog(id)}>
        <Icon icon={editFill} width={24} height={24} />
      </IconButton>
      <ResponsiveDialog subTitle=""  openDialog={dialog == id} setOpenDialog={setDialog} title={title} >
        <SupplerInventoryForm initialValues={surveyUrls} submitForm={submitForm} />
      </ResponsiveDialog>
    </Box>
  }

  const dialogActionsSurvey = (projectQuestionaire: any, id: string, title: string) => {
    return <Box>
      <IconButton onClick={() => setDialog(id + title)}>
        <VisibilityIcon />
      </IconButton>
      <ResponsiveDialog subTitle=""  openDialog={dialog == id + title} setOpenDialog={setDialog} title={title} >
      <div dangerouslySetInnerHTML={{ __html: projectQuestionaire }}>
         </div>
      </ResponsiveDialog>
    </Box>
  }

  const columns = [
    { field: 'projectCode', type: "string", headerName: 'Project #', filterable: false, headerAlign: "center", align: 'center', width: 100, pinnable: true, renderCell: ({ id, value }: any) => {
        return <Typography color="primary" >{value}</Typography>
      }
    },
    { field: 'projectName', type: "string", headerName: 'Project Title', filterable: false, headerAlign: "center", align: 'center', width: 300, renderCell: ({ id, value }: any) => {
        return <Tooltip title={value}>
          <Typography variant="subtitle2">{value}</Typography>
        </Tooltip>
      }
    },
    {
      field: 'countryName', type: "string", headerName: 'Region', filterable: false, width: 100, headerAlign: "left", align: 'left',
      renderCell: (params: any) => {
        return <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <ReactCountryFlag className="emojiFlag" countryCode={`${params.row.countryCode}`} /><Tooltip title={params.row.countryName}>
            <Typography sx={{ ml: 1 }} variant="subtitle2">{params.row.countryName}</Typography>
          </Tooltip>
        </Box>
      },
    },
    { field: 'projectIR', type: "string", headerName: 'IR', filterable: false, width: 150, headerAlign: "center", align: 'center' },
    { field: 'projectLOI', type: "string", headerName: 'LOI', filterable: false, width: 150, headerAlign: "center", align: 'center' },
    { field: 'supplierCPI', type: "string", headerName: 'Supplier CPI', filterable: false, width: 150, headerAlign: "center", align: 'center' },
    { field: 'projectQuestionaire', type: "string", headerName: 'Questionaire', filterable: false, width: 150, headerAlign: "center", align: 'center', renderCell: ({ row }: any) => {
        return dialogActionsSurvey(row.projectQuestionaire, row._id, 'Questionaire');
      },
    },
    { field: 'projectStatus', headerName: 'Status', type: "boolean", flex: 1, renderCell: (params: any) => {
        var status: any = ProjectStatus.find(status => status.value == params.value);
        return <Label
          variant="ghost"
          color={status?.color}
        >
          {sentenceCase(status.label)}
        </Label>
      }
    },
    { field: 'actions', headerName: 'Actions', flex: 1, type: 'actions', renderCell: ({ row }: any) => dialogActions(row.surveyUrls, row._id, 'Update Project') },
  ];


  return (
    <Container maxWidth={false}>
      <TopBar title="Projects" link="" buttonText="New Project" />
      <DataGridRender
        filterTypes={[ 'export', 'search']}
        filters={filters}
        data={data}
        loading={loading}
        metadata={metadata}
        limit={limit}
        pageNo={pageNo}
        columns={columns}
        setPageNo={setPageNo}
        setLimit={setLimit}
        setFilters={setFilters}
        exportFileName="Supplier"
      />
    </Container>
  );
}
