import { invoiceActionTypes } from "src/types/actionTypes";
import { Invoice, InvoiceList } from "src/types/invoiceTypes";
import { ErrorAction, SuccessAction } from "src/types/responseTypes";

export type invoiceAction = SuccessAction<InvoiceList> | SuccessAction<Invoice> | SuccessAction<any> | ErrorAction
export const defaultInvoiceState: InvoiceList = { loading: false, redirectTo: "", invoices: [], invoice: { projects: [] }, datasupplier: [], data: [], metadata: {} };

const reducer = (state: InvoiceList = defaultInvoiceState, action: invoiceAction): InvoiceList => {
    switch (action.type) {
        case invoiceActionTypes.INVOICE_REQUEST:
            state = { ...state, loading: true }
            return state
        case invoiceActionTypes.ADD_INVOICE_SUCCESS:
            action = <SuccessAction<any>>action
            state = { ...state, loading: false, redirectTo: '/admin/invoice/list' }
            return state
        case invoiceActionTypes.FETCH_INVOICE_SUCCESS:
            action = <SuccessAction<InvoiceList>>action
            state = { ...state, loading: false, data: action.payload.data, metadata: action.payload.metadata, redirectTo: '' }
            return state
        case invoiceActionTypes.FETCH_INVOICE_SUPPLIER_SUCCESS:
            action = <SuccessAction<InvoiceList>>action
            state = { ...state, loading: false, datasupplier: action.payload.data, metadata: action.payload.metadata, redirectTo: '' }
            return state
        case invoiceActionTypes.FETCH_INVOICE_ALL_SUCCESS:
            action = <SuccessAction<InvoiceList>>action
            state = { ...state, loading: false, invoices: action.payload, redirectTo: '' }
            return state
        case invoiceActionTypes.UPDATE_INVOICE_SUCCESS:
            action = <SuccessAction<any>>action
            state = { ...state, loading: false, redirectTo: '/admin/invoice/list', invoices: state.invoices?.map(dat => [dat._id == action.payload._id && Object.assign(dat, action.payload)].find(o => o._id == dat._id) || dat) }
            return state
        case invoiceActionTypes.FETCH_SINGLE_INVOICE_SUCCESS:
            action = <SuccessAction<Invoice>>action
            state = { ...state, loading: false, invoice: action.payload }
            return state
        case invoiceActionTypes.DELETE_INVOICE_SUCCESS:
            action = <SuccessAction<any>>action
            state = { ...state, loading: false, invoices: state.invoices?.filter(dat => dat._id !== action.payload._id), metadata: { ...state.metadata, limit: Number(state.metadata?.limit) - 1, total: Number(state.metadata?.total) - 1 } }
            return state
        case invoiceActionTypes.INVOICE_FAILURE:
            action = <ErrorAction>action
            return { ...state, loading: false, }
        default:
            return state
    }
}
export default reducer