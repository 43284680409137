import { styled } from '@mui/material/styles';
import { Card, Typography, Box } from '@mui/material';
import ResponsiveDialog from 'src/components/dialog/ResponsiveDialog';
import { DataGridRender } from 'src/components/table';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { fShortenNumber } from 'src/utils/formatNumber';
import { useDispatch } from 'react-redux';
import {  DashboardProjectListDataRequest } from 'src/actions';
import { DashboardProjectListSecurityDataRequest } from 'src/actions/dashboardActions';

const RootStyle = styled(Card)(({ theme }) => ({
  boxShadow: 'none',
  textAlign: 'center',
  padding: theme.spacing(2, 0),
  color: theme.palette.info.dark,
  backgroundColor: theme.palette.info.light
}));



export default function AppNewUsers({ filters, setFilters, todayCompleted, dashboardProjectListData }: any) {

  const dispatch = useDispatch();
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [pageNo, setPageNo] = useState<number>(0);
  const [limit, setLimit] = useState<number>(10);

     useEffect(() => {
       if(openDialog)  dispatch(DashboardProjectListDataRequest(filters))
    }, [filters,openDialog])

  const columns = [
    {
      field: 'projectCode', type: "string", headerName: 'Project #', filterable: false, headerAlign: "center", align: 'center', width: 150, pinnable: true, renderCell: ({ id, value }: any) => {
        return <Link to={`/admin/project/${id}/childs`}>
          <Typography color="primary" >{value}</Typography>
        </Link>
      }
    },
    {
      field: 'projectName', type: "string", headerName: 'Project Name', filterable: false, headerAlign: "center", align: 'center', flex: 1, pinnable: true, renderCell: ({ value }: any) => {
        return <Typography color="primary" >{value}</Typography>
      }
    },

    {
      field: 'complete', type: "string", headerName: 'Complete', filterable: false, headerAlign: "center", width: 100, align: 'center', valueGetter: ({ row }: any) => {
        return `${fShortenNumber(row['complete'])}`;
      },
    },
    {
      field: 'cpi', type: "string", headerName: 'CPI', filterable: false, headerAlign: "center", align: 'center', width: 80, pinnable: true,
    },
    {
      field: 'eRevenue', type: "string", headerName: 'Expected Revenue', filterable: false, headerAlign: "center", align: 'center', width: 150, pinnable: true,
      renderCell: (params: any) => {
        return "$ " + params.row.eRevenue.toFixed(2)
      },
    },

  ];
  return (
    <RootStyle>
      <Box onClick={() => setOpenDialog(true)}>
        <Typography variant="h3">{todayCompleted}</Typography>
        <Typography variant="subtitle2" sx={{ opacity: 0.72 }}>
          Today Completed
        </Typography>
      </Box>
      <ResponsiveDialog title="Project Completed" subTitle="" openDialog={openDialog} setOpenDialog={setOpenDialog} >
        <DataGridRender
          width={700}
          filterTypes={['export', 'sortDate']}
          exportFileName={"Today Completed"}
          hideFooterPagination={true}
          data={dashboardProjectListData}
          metadata=""
          filters={filters}
          limit={limit}
          pageNo={pageNo}
          columns={columns}
          setPageNo={setPageNo}
          setLimit={setLimit}
          setFilters={setFilters}
        />
      </ResponsiveDialog>
    </RootStyle>
  );
}
