import { ThunkAction, ThunkDispatch } from "redux-thunk";
import Prefix from 'src/helpers/ApiPrefix';
import AXIOS from 'src/helpers/Axios';
import Headers from 'src/helpers/Headers';
import { quotaAction } from "src/reducers/quotaReducer";
import {  quotaActionTypes } from "src/types/actionTypes";
import { ErrorAction, SuccessAction } from "src/types/responseTypes";
import { QuotaList,Quota } from "src/types/quotaTypes";
import { Qualification } from "src/types/qualificationTypes";

export const QuotaFailure = (message: string): ErrorAction => {
    return {
        type: quotaActionTypes.QUOTA_FAILURE,
        payload: { message: message }
    }
}
export const QuotaSuccess = (payload: QuotaList): SuccessAction<QuotaList> => {
    return {
        type: quotaActionTypes.FETCH_QUOTA_SUCCESS,
        payload: payload,
    }
}
export const QuotaAllSuccess = (payload: QuotaList): SuccessAction<QuotaList> => {
    return {
        type: quotaActionTypes.FETCH_QUOTA_ALL_SUCCESS,
        payload: payload,
    }
}

export const QuotaAddSuccess = (payload: Quota): SuccessAction<Quota> => {
    return {
        type: quotaActionTypes.ADD_QUOTA_SUCCESS,
        payload: payload,
    }
}
export const QuotaSingleSuccess = (payload: Quota): SuccessAction<Quota> => {
    return {
        type: quotaActionTypes.FETCH_SINGLE_QUOTA_SUCCESS,
        payload: payload,
    }
}

export const QuotaUpdateSuccess = (payload: any): SuccessAction<any> => {
    return {
        type: quotaActionTypes.UPDATE_QUOTA_SUCCESS,
        payload: payload,
    }
}


export const QuotaDeleteSuccess = (payload: any): SuccessAction<any> => {
    return {
        type: quotaActionTypes.DELETE_QUOTA_SUCCESS,
        payload: payload,
    }
}


export const QuotaAllRequest = (filter: any, search: string, pageNo: number, limit: number, status: boolean):
    ThunkAction<Promise<void>, {}, {}, quotaAction> => {
    return (dispatch: ThunkDispatch<{}, {}, quotaAction>) => {
        return AXIOS.post(`${Prefix.api}/admin/quotas`, { filter, search, pageNo, limit, status }, { headers: Headers() }).then(res => {
            if (res) {
                const { data } = res;
                const quotas: QuotaList = data.result
                dispatch(QuotaSuccess(quotas))

            }
        }).catch((e) => {
            if (e.response) {
                dispatch(QuotaFailure(e.response.data.message));
            }
            else {
                dispatch(QuotaFailure(e.message));
            }
        })
    }
}

export const QuotaAllRequestData = (projectid:string):
    ThunkAction<Promise<void>, {}, {}, quotaAction> => {
    return (dispatch: ThunkDispatch<{}, {}, quotaAction>) => {
        return AXIOS.get(`${Prefix.api}/admin/quotas/all/${projectid}`, { headers: Headers() }).then(res => {
            if (res) {
                const { data } = res;
                const quotas: QuotaList = data.result
                dispatch(QuotaAllSuccess(quotas))
            }
        }).catch((e) => {
            if (e.response) {
                dispatch(QuotaFailure(e.response.data.message));
            }
            else {
                dispatch(QuotaFailure(e.message));
            }
        })
    }
}


export const QuotaAddRequest = (projectId:string,body: any, setMessageHandler: any ):
    ThunkAction<Promise<void>, {}, {}, quotaAction> => {
    setMessageHandler({
        hasMessage: false,
        type: '',
        message: '',
    });
    return (dispatch: ThunkDispatch<{}, {}, quotaAction>) => {
        return AXIOS.post(`${Prefix.api}/admin/quota/add/${projectId}`, body, { headers: Headers() }).then(res => {
            if (res) {
                const { data } = res;
                const quota: Quota = data.result;
                dispatch(QuotaAddSuccess(quota))
                setMessageHandler({
                    hasMessage: true,
                    type: 'success',
                    message: data.message,
                });
            }
        }).catch((e) => {
            if (e.response) {
                dispatch(QuotaFailure(e.response.data.message));
                setMessageHandler({
                    hasMessage: true,
                    type: 'error',
                    message: e.response.data.message,
                });
            }
            else {
                dispatch(QuotaFailure(e.message));
                setMessageHandler({
                    hasMessage: true,
                    type: 'error',
                    message: e.message,
                });
            }
        })
    }
}


export const QuotaUpdateRequest = (id: string,projectid:string, quota: Quota, setMessageHandler: any):
    ThunkAction<Promise<void>, {}, {}, quotaAction> => {
    setMessageHandler({
        hasMessage: false,
        type: '',
        message: '',
    });
    return (dispatch: ThunkDispatch<{}, {}, quotaAction>) => {
        return AXIOS.put(`${Prefix.api}/admin/quota/update/${id}/${projectid}`, quota, { headers: Headers() }).then(res => {
            if (res) {
                const { data } = res;
                const quota: Quota = data.result;
                dispatch(QuotaUpdateSuccess(quota))
                setMessageHandler({
                    hasMessage: true,
                    type: 'success',
                    message: data.message,
                });
            }
        }).catch((e) => {
            if (e.response) {
                setMessageHandler({
                    hasMessage: true,
                    type: 'error',
                    message: e.response.data.message,
                });
                dispatch(QuotaFailure(e.response.data.message));
            }
            else {
                setMessageHandler({
                    hasMessage: true,
                    type: 'error',
                    message: e.message,
                });
                dispatch(QuotaFailure(e.message));
            }
        })
    }
}

export const QuotaSampleUpdateRequest = (id: string,projectid:string, quota: Quota,setSubmitting:any, setMessageHandler: any):
    ThunkAction<Promise<void>, {}, {}, quotaAction> => {
    setMessageHandler({
        hasMessage: false,
        type: '',
        message: '',
    });
    return (dispatch: ThunkDispatch<{}, {}, quotaAction>) => {
        return AXIOS.put(`${Prefix.api}/admin/quota/sample/update/${id}/${projectid}`, quota, { headers: Headers() }).then(res => {
            if (res) {
                const { data } = res;
                const quota: Quota = data.result;
                dispatch(QuotaUpdateSuccess(quota))
                setSubmitting(false)
                setMessageHandler({
                    hasMessage: true,
                    type: 'success',
                    message: data.message,
                });
            }
        }).catch((e) => {
            if (e.response) {
                setMessageHandler({
                    hasMessage: true,
                    type: 'error',
                    message: e.response.data.message,
                });
                setSubmitting(false)
                dispatch(QuotaFailure(e.response.data.message));
            }
            else {
                setMessageHandler({
                    hasMessage: true,
                    type: 'error',
                    message: e.message,
                });
                setSubmitting(false)
                dispatch(QuotaFailure(e.message));
            }
        })
    }
}



export const QuotaStatusUpdateRequest = (quotaid: string, quota: Quota, setMessageHandler: any):
    ThunkAction<Promise<void>, {}, {}, quotaAction> => {
    setMessageHandler({
        hasMessage: false,
        type: '',
        message: '',
    });
    return (dispatch: ThunkDispatch<{}, {}, quotaAction>) => {
        return AXIOS.put(`${Prefix.api}/admin/quota/update/${quotaid}`, quota, { headers: Headers() }).then(res => {
            if (res) {
                const { data } = res;
                const quota: Quota = data.result;
                dispatch(QuotaUpdateSuccess(quota))
                setMessageHandler({
                    hasMessage: true,
                    type: 'success',
                    message: data.message,
                });
            }
        }).catch((e) => {
            if (e.response) {
                setMessageHandler({
                    hasMessage: true,
                    type: 'error',
                    message: e.response.data.message,
                });
                dispatch(QuotaFailure(e.response.data.message));
            }
            else {
                setMessageHandler({
                    hasMessage: true,
                    type: 'error',
                    message: e.message,
                });
                dispatch(QuotaFailure(e.message));
            }
        })
    }
}


export const QuotaSingleRequest = (projectid: string):
    ThunkAction<Promise<void>, {}, {}, quotaAction> => {
    return (dispatch: ThunkDispatch<{}, {}, quotaAction>) => {
        return AXIOS.get(`${Prefix.api}/admin/quota/${projectid}`, { headers: Headers() }).then(res => {
            if (res) {
                const { data } = res;
                const quota: Quota = data.result;
                dispatch(QuotaSingleSuccess(quota));
            }
        }).catch((e) => {
            if (e.response) {
                dispatch(QuotaFailure(e.response.data.message));
            }
            else {
                dispatch(QuotaFailure(e.message));
            }
        })
    }
}


export const QuotaDeleteRequest = (id: string,projectid:string, body: Quota, setMessageHandler: any):
    ThunkAction<Promise<void>, {}, {}, quotaAction> => {
    setMessageHandler({
        hasMessage: false,
        type: '',
        message: '',
    });
    return (dispatch: ThunkDispatch<{}, {}, quotaAction>) => {
        return AXIOS.put(`${Prefix.api}/admin/quota/update/${id}/${projectid}`, body, { headers: Headers() }).then(res => {
            if (res) {
                const { data } = res;
                const quota: Quota = data.result;
                dispatch(QuotaDeleteSuccess(quota))
                setMessageHandler({
                    hasMessage: true,
                    type: 'success',
                    message: data.message,
                });
            }
        }).catch((e) => {
            if (e.response) {
                dispatch(QuotaFailure(e.response.data.message));
                setMessageHandler({
                    hasMessage: true,
                    type: 'error',
                    message: e.response.data.message,
                });
            }
            else {
                dispatch(QuotaFailure(e.message));
                setMessageHandler({
                    hasMessage: true,
                    type: 'error',
                    message: e.message,
                });
            }
        })
    }
}

