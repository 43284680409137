import {  Grid , Box, Accordion, AccordionSummary, AccordionDetails, Button } from '@mui/material';
import { Form, Formik, FormikProps } from 'formik';
import { LoadingButton } from '@mui/lab';
import plusFill from '@iconify/icons-eva/plus-fill';
import { Icon } from '@iconify/react';
import { Project } from 'src/types/projectTypes';
import { useRef, useState } from 'react';
import Editor from 'react-simple-wysiwyg';

export interface FormValues {
    initialValues: Project,
    submitForm: any,
}

export default function ChildProjectQuestionaireForm({ initialValues, submitForm }: FormValues) {

    const editorRef: any = useRef(null);
    const [expanded, setExpanded] = useState<string | false>(false);

    const handleChange = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
        setExpanded(isExpanded ? panel : false);
    };

    return (
        <Formik
            onSubmit={(values, { setSubmitting, resetForm }) => {
                submitForm(values, setSubmitting, resetForm);
            }}
            validateOnBlur={false}
            validateOnChange={true}
            enableReinitialize={true}
            initialValues={{
                projectQuestionaire: initialValues.projectQuestionaire,
            }}
        >
            {(formikProps: FormikProps<Project>) => (
                <>
                    <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                        <AccordionSummary sx={{ width: 200 }} >
                            <Button
                                variant="text"
                                startIcon={<Icon icon={plusFill} />}
                            >
                                Add Questionaire
                            </Button>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Form autoComplete="off" noValidate onSubmit={formikProps.handleSubmit}>
                                <Grid
                                    container
                                    spacing={3}
                                >
                                    <Grid
                                        item
                                        md={12}
                                        xs={12}
                                    >
                                                                        <Editor
                                containerProps={{ style: { resize: 'vertical' } }}
                                value={formikProps.values.projectQuestionaire || initialValues.projectQuestionaire} onChange={(e) => formikProps.setFieldValue('projectQuestionaire', e.target.value)}/>
                                    </Grid>
                                </Grid>
                                <Grid
                                    container
                                    spacing={3}
                                >
                                    <Grid item md={2} >
                                        <Box sx={{ display: 'flex', justifyContent: 'left', alignContent: 'right', mt: 2 }}>
                                             <LoadingButton
                                                size="medium"
                                                type="submit"
                                                variant="contained"
                                                loading={formikProps.isSubmitting}
                                            >
                                                Update
                                            </LoadingButton> 
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Form>
                        </AccordionDetails>
                    </Accordion>
                </>
            )}
        </Formik>
    );
}
