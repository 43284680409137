import {  useNavigate } from 'react-router-dom';
import { Typography , Box, IconButton } from '@mui/material';
import Stack from '@mui/material/Stack';
import KeyboardBackspace from '@mui/icons-material/KeyboardBackspace';

interface TopBarProps {
    title: string,
    link: string,
}

export default function TopBarForm({ title,link }: TopBarProps) {
    const navigate = useNavigate();
    return (
        <Stack mb={3} sx={{ display: 'flex', flexDirection: 'row' }}>
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mr: 1.5 }}>
                <IconButton onClick={()=>navigate(link)} >
                    <KeyboardBackspace fontSize='medium' color="primary" />
                </IconButton>
            </Box>
            <Box sx={{pt:1}}>
                <Typography variant="h5" gutterBottom>
                    {title}
                </Typography>
            </Box>
        </Stack>
    );
}
