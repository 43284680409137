
import { LogoutRequestOut } from "src/actions";
import { store } from "src/store";
import AXIOS from "./Axios";

const AxiosConfig = () => {
    const { dispatch } = store; 
    AXIOS.interceptors.response.use(
        (response) => {
            return response;
        },
        function (error) {
            if (error.response.status === 401) {
                dispatch(LogoutRequestOut());
            }
            if (error.response.status === 404) {
              //  window.location.href = '/404'
            }
            return Promise.reject(error);
        }
    );
};

export default AxiosConfig;
