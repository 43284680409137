import { ThunkAction, ThunkDispatch } from "redux-thunk";
import Prefix from 'src/helpers/ApiPrefix';
import AXIOS from 'src/helpers/Axios';
import Headers from 'src/helpers/Headers';
import { countryAction } from "src/reducers/countryReducer";
import { countryActionTypes } from "src/types/actionTypes";
import { Country, CountryList } from "src/types/countryTypes";
import { ErrorAction, SuccessAction } from "src/types/responseTypes";

export const CountryFailure = (message: string): ErrorAction => {
    return {
        type: countryActionTypes.COUNTRY_FAILURE,
        payload: { message: message }
    }
}
export const CountrySuccess = (payload: CountryList): SuccessAction<CountryList> => {
    return {
        type: countryActionTypes.FETCH_COUNTRY_SUCCESS,
        payload: payload,
    }
}

export const RegionSuccess = (payload: CountryList): SuccessAction<CountryList> => {
    return {
        type: countryActionTypes.FETCH_REGION_SUCCESS,
        payload: payload,
    }
}
export const CitiesSuccess = (payload: CountryList): SuccessAction<CountryList> => {
    return {
        type: countryActionTypes.FETCH_CITY_SUCCESS,
        payload: payload,
    }
}
export const CurrencySuccess = (payload: CountryList): SuccessAction<CountryList> => {
    return {
        type: countryActionTypes.FETCH_CURRENCY_SUCCESS,
        payload: payload,
    }
}
export const LanguageSuccess = (payload: CountryList): SuccessAction<CountryList> => {
    return {
        type: countryActionTypes.FETCH_LANGUAGE_SUCCESS,
        payload: payload,
    }
}



export const CountryAddSuccess = (payload: Country): SuccessAction<Country> => {
    return {
        type: countryActionTypes.ADD_COUNTRY_SUCCESS,
        payload: payload,
    }
}
export const CountrySingleSuccess = (payload: Country): SuccessAction<Country> => {
    return {
        type: countryActionTypes.FETCH_SINGLE_COUNTRY_SUCCESS,
        payload: payload,
    }
}

export const CountryUpdateSuccess = (payload: any): SuccessAction<any> => {
    return {
        type: countryActionTypes.UPDATE_COUNTRY_SUCCESS,
        payload: payload,
    }
}


export const CountryDeleteSuccess = (payload: any): SuccessAction<any> => {
    return {
        type: countryActionTypes.DELETE_COUNTRY_SUCCESS,
        payload: payload,
    }
}


export const CountryAllRequest = ():
    ThunkAction<Promise<void>, {}, {}, countryAction> => {
    return (dispatch: ThunkDispatch<{}, {}, countryAction>) => {
        return AXIOS.get(`${Prefix.api}/admin/countries`, { headers: Headers() }).then(res => {
            if (res) {
                const { data } = res;
                const categories: CountryList = data.result
                dispatch(CountrySuccess(categories))

            }
        }).catch((e) => {
            if (e.response) {
                dispatch(CountryFailure(e.response.data.message));
            }
            else {
                dispatch(CountryFailure(e.message));
            }
        })
    }
}


export const RegionAllRequest = (body:any):
    ThunkAction<Promise<void>, {}, {}, countryAction> => {
    return (dispatch: ThunkDispatch<{}, {}, countryAction>) => {
        return AXIOS.post(`${Prefix.api}/admin/regions`,body, { headers: Headers() }).then(res => {
            if (res) {
                const { data } = res;
                const categories: CountryList = data.result
                dispatch(RegionSuccess(categories))

            }
        }).catch((e) => {
            if (e.response) {
                dispatch(CountryFailure(e.response.data.message));
            }
            else {
                dispatch(CountryFailure(e.message));
            }
        })
    }
}
export const CitiesAllRequest = (body:any):
    ThunkAction<Promise<void>, {}, {}, countryAction> => {
    return (dispatch: ThunkDispatch<{}, {}, countryAction>) => {
        return AXIOS.post(`${Prefix.api}/admin/cities`,body, { headers: Headers() }).then(res => {
            if (res) {
                const { data } = res;
                const categories: CountryList = data.result
                dispatch(CitiesSuccess(categories))

            }
        }).catch((e) => {
            if (e.response) {
                dispatch(CountryFailure(e.response.data.message));
            }
            else {
                dispatch(CountryFailure(e.message));
            }
        })
    }
}


export const LanguageAllRequest = ():
    ThunkAction<Promise<void>, {}, {}, countryAction> => {
    return (dispatch: ThunkDispatch<{}, {}, countryAction>) => {
        return AXIOS.get(`${Prefix.api}/admin/languages`, { headers: Headers() }).then(res => {
            if (res) {
                const { data } = res;
                const categories: CountryList = data.result
                dispatch(LanguageSuccess(categories))

            }
        }).catch((e) => {
            if (e.response) {
                dispatch(CountryFailure(e.response.data.message));
            }
            else {
                dispatch(CountryFailure(e.message));
            }
        })
    }
}


export const CurrenciesAllRequest = ():
    ThunkAction<Promise<void>, {}, {}, countryAction> => {
    return (dispatch: ThunkDispatch<{}, {}, countryAction>) => {
        return AXIOS.get(`${Prefix.api}/admin/currencies`, { headers: Headers() }).then(res => {
            if (res) {
                const { data } = res;
                const categories: CountryList = data.result
                dispatch(CurrencySuccess(categories))

            }
        }).catch((e) => {
            if (e.response) {
                dispatch(CountryFailure(e.response.data.message));
            }
            else {
                dispatch(CountryFailure(e.message));
            }
        })
    }
}


export const CountryAddRequest = (country: Country, setSubmitting: any):
    ThunkAction<Promise<void>, {}, {}, countryAction> => {
    return (dispatch: ThunkDispatch<{}, {}, countryAction>) => {
        return AXIOS.post(`${Prefix.api}/admin/country/add`, country, { headers: Headers() }).then(res => {
            if (res) {
                const { data } = res;
                const country: Country = data.result;
                dispatch(CountryAddSuccess(country))
                setSubmitting(false);
            }
        }).catch((e) => {
            if (e.response) {
                setSubmitting(false);
                dispatch(CountryFailure(e.response.data.message));
            }
            else {
                setSubmitting(false);
                dispatch(CountryFailure(e.message));
            }
        })
    }
}


export const CountryUpdateRequest = (catid: string, country: Country, setSubmitting: any):
    ThunkAction<Promise<void>, {}, {}, countryAction> => {
    return (dispatch: ThunkDispatch<{}, {}, countryAction>) => {
        return AXIOS.put(`${Prefix.api}/admin/country/update/${catid}`, country, { headers: Headers() }).then(res => {
            if (res) {
                const { data } = res;
                const country: Country = data.result;
                dispatch(CountryAddSuccess(country))
                setSubmitting(false);
            }
        }).catch((e) => {
            if (e.response) {
                setSubmitting(false);
                dispatch(CountryFailure(e.response.data.message));
            }
            else {
                setSubmitting(false);
                dispatch(CountryFailure(e.message));
            }
        })
    }
}
export const CountrySingleRequest = (catid: string):
    ThunkAction<Promise<void>, {}, {}, countryAction> => {
    return (dispatch: ThunkDispatch<{}, {}, countryAction>) => {
        return AXIOS.get(`${Prefix.api}/admin/country/${catid}`, { headers: Headers() }).then(res => {
            if (res) {
                const { data } = res;
                const country: Country = data.result;
                dispatch(CountrySingleSuccess(country));
            }
        }).catch((e) => {
            if (e.response) {
                dispatch(CountryFailure(e.response.data.message));
            }
            else {
                dispatch(CountryFailure(e.message));
            }
        })
    }
}


export const CountryDeleteRequest = (id: string, body: Country):
    ThunkAction<Promise<void>, {}, {}, countryAction> => {
    return (dispatch: ThunkDispatch<{}, {}, countryAction>) => {
        return AXIOS.put(`${Prefix.api}/admin/country/update/${id}`, body, { headers: Headers() }).then(res => {
            if (res) {
                const { data } = res;
                const country: Country = data.result;
                dispatch(CountryDeleteSuccess(country))
            }
        }).catch((e) => {
            if (e.response) {
                dispatch(CountryFailure(e.response.data.message));
            }
            else {
                dispatch(CountryFailure(e.message));
            }
        })
    }
}





