import { TextField,Tooltip, Grid, FormHelperText, InputAdornment, List, ListItem, Checkbox, ListItemText, Typography, Box, IconButton } from '@mui/material';
import { Form, Formik, FormikProps } from 'formik';
import { LoadingButton } from '@mui/lab';
import * as Yup from 'yup';
import CSVReader from 'react-csv-reader'
import { Project } from 'src/types/projectTypes';
import { Link } from 'react-router-dom';
import { Download ,} from '@mui/icons-material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import CloseIcon from '@mui/icons-material/Close';

export interface FormValues {
    initialValues: Project,
    submitForm: any,
}

export default function ChildProjectUrlForm({ initialValues, submitForm }: FormValues) {

    const SchemaCategoryForm = Yup.object().shape({
        singleLinkUrl: Yup.string().trim().matches(/((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+(:[0-9]+)?|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)/, "Invalid Url").matches(/\[.?uid\]/g, "Invalid Url"),
    });

    const papaparseOptions: any = {
        header: false,
        dynamicTyping: true,
        skipEmptyLines: true,
        transformHeader: (header: any) =>
            header
                .toLowerCase()
                .replace(/\W/g, '_')
    }

    return (
        <Formik
            onSubmit={(values, { setSubmitting }) => {
                submitForm(values, setSubmitting);
            }}
            validateOnBlur={false}
            validateOnChange={true}
            enableReinitialize={true}
            validationSchema={SchemaCategoryForm}
            initialValues={{
                isMultiLinkUrl: initialValues?.isMultiLinkUrl,
                singleLinkUrl: initialValues?.singleLinkUrl,
                singleLinkUrlTest: initialValues?.singleLinkUrlTest,
                multiLinkUrls: initialValues?.multiLinkUrls,
            }}
        >
            {(formikProps: FormikProps<Project>) => (
                <Form autoComplete="off" noValidate onSubmit={formikProps.handleSubmit}>
                    <Grid
                        container
                        spacing={3}
                    >
                        <Grid
                            item
                            md={12}
                            xs={12}
                        >
                            <List>
                                <ListItem sx={{ p: 0 }} dense>
                                    <Checkbox
                                        edge="start"
                                        tabIndex={-1}
                                        disableRipple
                                        checked={formikProps.values.isMultiLinkUrl}
                                        {...formikProps.getFieldProps('isMultiLinkUrl')}
                                    />
                                    <ListItemText primary={`Multi Link`} />
                                </ListItem>
                            </List>
                        </Grid>
                        {!formikProps.values.isMultiLinkUrl ?
                            <>
                                <Grid
                                    sx={{ pt: 0 }}
                                    item
                                    md={6}
                                    xs={12}
                                >
                                    <TextField
                                        error={Boolean(formikProps.touched.singleLinkUrl && formikProps.errors.singleLinkUrl)}
                                        fullWidth
                                        helperText={(formikProps.touched.singleLinkUrl && formikProps.errors.singleLinkUrl) || "Link eg. https://app.wolfinsights.com/?uid=[uid]"}
                                        label="Live URL"
                                        {...formikProps.getFieldProps('singleLinkUrl')}
                                        variant="standard"
                                    />
                                    {((formikProps.touched.countryId && formikProps.errors.countryId) || (formikProps.touched.countryId && formikProps.errors.countryId)) && <FormHelperText error>Please verify country and language </FormHelperText>}
                                </Grid>
                                <Grid
                                    item
                                    md={6}
                                    xs={12}
                                >
                                    <TextField
                                        error={Boolean(formikProps.touched.singleLinkUrlTest && formikProps.errors.singleLinkUrlTest)}
                                        fullWidth
                                        helperText={(formikProps.touched.singleLinkUrlTest && formikProps.errors.singleLinkUrlTest) || "Link eg. https://app.wolfinsights.com/?uid=[uid]"}
                                        label="Test URL"
                                        {...formikProps.getFieldProps('singleLinkUrlTest')}
                                        variant="standard"
                                    />
                                </Grid>
                            </> :
                            <Grid
                                item
                                md={3}
                                xs={12}
                                sx={{ position: 'relative' }}
                            >
                                <TextField
                                    fullWidth
                                    label=""
                                    value={formikProps.values.multiLinkUrls.length ? formikProps.values.multiLinkUrls.length + " links uploaded" : ''}
                                    variant="standard"
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <Tooltip title="Download Sample" placement="top">
                                                <IconButton
                                                    aria-label="download"
                                                >
                                                    <Link style={{ color: 'inherit' }} to="/assets/csv/sample_link.csv" target="_blank" download>
                                                        <Download />
                                                    </Link>
                                                </IconButton>
                                                </Tooltip>
                                            </InputAdornment>
                                        ),
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <Tooltip title="Upload File" placement="top">
                                                <IconButton
                                                    aria-label="upload"
                                                >
                                                    {formikProps.values.multiLinkUrls.length ? <CloseIcon onClick={() => formikProps.setFieldValue('multiLinkUrls', [])} /> : <><CSVReader inputStyle={{ position: "absolute", opacity: 0, top: "27%", right: '5px', height: "35px", width: "30px" }} parserOptions={papaparseOptions} onFileLoaded={(data: any) => { data.shift() && formikProps.setFieldValue('multiLinkUrls', data) }} /><CloudUploadIcon /></>}
                                                </IconButton>
                                                </Tooltip>
                                            </InputAdornment>
                                        )
                                    }}
                                />
                            </Grid>
                            //   <Grid
                            //     item
                            //     md={6}
                            //     xs={12}
                            // >
                            //     <Link  to="/assets/csv/sample_link.csv" target="_blank" download>Download Sample</Link>
                            //     <Box sx={{display:'flex',alignItems:'center'}}>
                            //     <Typography sx={{mt:1,mb:1}} variant="subtitle2">{formikProps.values.multiLinkUrls.length+" links uploaded"}</Typography>
                            //     <IconButton onClick={()=>formikProps.setFieldValue('multiLinkUrls', [])} >
                            //       <Delete/>
                            //     </IconButton>
                            //     </Box>
                            //     <CSVReader parserOptions={papaparseOptions} onFileLoaded={(data:any) =>{  data.shift() && formikProps.setFieldValue('multiLinkUrls', data)}} />
                            // </Grid>
                        }
                    </Grid>
                    <Grid marginTop={2} item xs={2}>
                        <LoadingButton
                            size="medium"
                            type="submit"
                            variant="contained"
                            loading={formikProps.isSubmitting}
                        >
                            {initialValues ? 'Save Project' : 'Submit'}
                        </LoadingButton>
                    </Grid>
                </Form>
            )}
        </Formik>
    );
}
