import { Delete as DeleteIcon } from '@mui/icons-material';
import { IconButton, List, FormGroup, FormControlLabel, Checkbox, Typography,  Autocomplete, TextField, FormControl, Box } from '@mui/material';
import { QualificationOption } from 'src/types/qualificationTypes';
import { Quota, QuotaOption } from 'src/types/quotaTypes';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

export interface FormValues {
    quota: Quota,
    updateQuota: any,
    removeQuota: any,
}
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;


export default function QuotaOptionRender({ quota, removeQuota, updateQuota }: FormValues) {

    const getDefaultOption = (options: QualificationOption[], quotaOption: any) => {
        return options.filter((option: QualificationOption) => quotaOption.some((quotaOp: QuotaOption) => quotaOp.quotaOptionId == option._id))
    }

    const checkBoxQuotaUpdate =(checked:boolean,quotaid:string | undefined, optionid:string | undefined ,options:QualificationOption[] )=>{
        var updateOptions =options;
        if(!checked) var updateOptions = options.filter(opt=>opt._id != optionid);
        updateQuota(quotaid, { quotaOptionId: updateOptions });
    }
    return (
        <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
            {quota.quotaId.isDefaultQualification ?
                <Box>
                    <Typography mb={1} variant="h6">{quota.quotaId?.qualificationName}</Typography>
                    <FormGroup row >
                        {quota.quotaId?.qualificationOptions.map((option: QualificationOption) =>
                            <FormControlLabel
                                sx={{ mr: 4 }}
                                key={option._id}
                                control={
                                    <Checkbox onChange={(e) => checkBoxQuotaUpdate(e.target.checked , quota?._id,  option?._id ,quota.quotaId?.qualificationOptions)} defaultChecked={quota.quotaValue?.some(qValue => qValue.quotaOptionId == option._id)} />
                                }
                                label={<Typography variant="body2">{option.text}</Typography>}
                            />

                        )}
                    </FormGroup>
                </Box> :
                <Box>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Typography mb={1} variant="h6">{quota.quotaId?.qualificationName}</Typography>
                        <IconButton onClick={() => removeQuota(quota._id)}><DeleteIcon /></IconButton>
                    </Box>
                    <FormGroup row >
                        <FormControl sx={{ width: "100%" }}>
                            <Autocomplete
                                multiple
                                id="tags-standard"
                                disableCloseOnSelect
                                value={getDefaultOption(quota.quotaId.qualificationOptions, quota.quotaValue)}
                                options={quota.quotaId.qualificationOptions}
                                onChange={(e, value) => updateQuota(quota._id, { quotaOptionId: value })}
                                getOptionLabel={(option: QualificationOption) => option?.text}
                                renderOption={(props, option, { selected }) => (
                                    <li {...props}>
                                      <Checkbox
                                        icon={icon}
                                        checkedIcon={checkedIcon}
                                        style={{ marginRight: 8 }}
                                        checked={selected}
                                      />
                                      {option?.text}
                                    </li>
                                  )}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        variant="standard"
                                        label=""
                                        placeholder="Select..."
                                    />
                                )}
                            />
                        </FormControl>
                    </FormGroup>
                </Box>}
        </List>
    );
}
