import { Navigate, useRoutes } from 'react-router-dom';
import DashboardLayout from 'src/layouts/dashboard/DashboardLayout';
import LogoOnlyLayout from './layouts/auth/LogoOnlyLayout';
import Login from 'src/pages/Auth/Login';
import NotFound from 'src/pages/404/Page404';
import AuthLayout from './layouts/auth/AuthLayout';
import { adminRoutes } from 'src/config/routes';

export default function Router() {

  return useRoutes([
    { path: '/',
      element: <DashboardLayout/>,
      children: adminRoutes
    },
    { path: '/',
      element: <AuthLayout />,
      children: [
        { element: <Navigate to="/login" replace /> },
        { path: 'login', element: <Login /> },
      ]
    },
    {  path: '/survey',
      element: <LogoOnlyLayout />,
      children: [
        { path: '*', element: <NotFound /> },
      ]
    },
    { path: '/404', element: <NotFound /> },
    { path: '*', element: <Navigate to="/404" replace /> }
  ]);
}
