import { useState } from 'react';
import Router from './routes';
import ThemeConfig from 'src/theme';
import { Provider } from 'react-redux';
import GlobalStyles from 'src/theme/globalStyles';
import ScrollToTop from 'src/components/ScrollToTop';
import { PersistGate } from 'redux-persist/integration/react';
import { persistor, store } from 'src/store';
import { BaseOptionChartStyle } from 'src/components/charts/BaseOptionChart';
import { MessageContext } from "src/context";
import { SnackbarProvider } from 'notistack';
import SnackMessage from './utils/SnackMessage';
import AxiosConfig from './helpers/AxiosConfig';
AxiosConfig();

export default function App() {

  const [ messageHandler, setMessageHandler] = useState({ hasMessage: false, type: "", message: "" });

  return (
    <SnackbarProvider maxSnack={1}>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <MessageContext.Provider value={{ messageHandler, setMessageHandler }}>
            <ThemeConfig>
              <ScrollToTop />
              <GlobalStyles />
              <BaseOptionChartStyle />
              <SnackMessage {...messageHandler} />
              <Router />
            </ThemeConfig>
          </MessageContext.Provider>
        </PersistGate>
      </Provider>
    </SnackbarProvider>
  );
}
