import { Delete as DeleteIcon, Edit as EditIcon } from '@mui/icons-material';
import { ListItem ,ListItemAvatar , ListItemText ,Avatar ,IconButton,Box } from '@mui/material';
import { Draggable } from 'react-beautiful-dnd';
import { Question } from 'src/types/surveyTypes';
import swal from 'sweetalert';

export interface QuestionProps {
    question: Question,
    index:number,
    key:string,
    languageCode:string,
    deleteQuestion:any,
    setEditQuestion:any,
}

const QuestionListItem = ({ question,index ,languageCode,setEditQuestion,deleteQuestion }: QuestionProps) => {


    const deleteActionAlert = (id: string) => {
        swal({
            title: "Are you sure?",
            icon: "warning",
            dangerMode: true,
            buttons: ["No!", true],
        })
            .then((willDelete: any) => {
                if (willDelete) {
                 deleteQuestion(id);
                } else {

                }
            });

    }
    return (
        <Draggable draggableId={question._id || ''} index={index}>
              {(provided:any, snapshot:any) => (
                  <ListItem
                  ref={provided.innerRef}
                  {...provided.draggableProps}
                  {...provided.dragHandleProps}
                  className={snapshot.isDragging ? "classes.draggingListItem" : ''}
                  secondaryAction={
                    <Box>
                    <IconButton edge="start" aria-label="edit" onClick={()=>setEditQuestion(question)}>
                    <EditIcon />
                  </IconButton>
                    <IconButton edge="end" aria-label="delete" onClick={()=>deleteActionAlert(question._id || '')}>
                      <DeleteIcon />
                    </IconButton>
                    </Box>
                  }
                >
                  <ListItemAvatar>
                    <Avatar>
                      {index+1}
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText primary={question.questionTitle?.[languageCode]} secondary={question.questionType} />
                </ListItem>
              )}
        </Draggable>
    );
};

export default QuestionListItem;
