import { Container, Grid } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { MessageContext } from 'src/context';
import { useSelector } from 'src/reducers';
import { Project } from 'src/types/projectTypes';
import { QualificationFormRender, QualificationListRender } from '../qualification';
import { Qualification } from 'src/types/qualificationTypes';
import { QualificationAddRequest,QualificationAllRequestData,QualificationUpdateRequest } from 'src/actions/qualificationActions';

export interface FormValues {
    initialValues: Project,
    submitForm: any,
}

export default function QualificationForm({ initialValues, submitForm }: FormValues) {

    const [editQualification, setEditQualification] = useState<any>('');
    const { setMessageHandler } = useContext<any>(MessageContext);
    const dispatch = useDispatch();
    const {  qualifications } = useSelector(state => state.qualification);

    const updateForm = (values: Qualification, setSubmitting: any) => {
        setTimeout(() => dispatch(QualificationUpdateRequest(editQualification._id, values, setMessageHandler)), 200);
        setEditQualification('');
    }

    const submitFormQualification = (values: Qualification, setSubmitting: any, resetForm: any) => {
        setTimeout(() => dispatch(QualificationAddRequest(values, setMessageHandler, resetForm, setSubmitting)), 200);
    }

    const deleteQualification = (id: string) => {
      //  setTimeout(() => dispatch(SurveyQualificationDeleteRequest(id, { isDeleted: true }, setMessageHandler)), 200);
    }
    useEffect(() => {
        dispatch(QualificationAllRequestData());
    }, []);
    
    var initialValue: any = '';


   
    return (
        <Container maxWidth={false}>
            <Grid
                container
                spacing={3}
                mt={2}
            >
                <Grid item md={12} xs={12} spacing={3}>
                    <QualificationListRender  editQualification={editQualification} setEditQualification={setEditQualification} updateForm={updateForm} qualifications={qualifications} deleteQualification={deleteQualification} />
                    <QualificationFormRender  setEditQualification="" initialValues={initialValue} submitForm={submitFormQualification} />
                </Grid>
            </Grid> 
        </Container>
    );
}
