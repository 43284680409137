import { styled } from '@mui/material/styles';
import { Card, Stack, TextField, Grid, } from '@mui/material';
import { Form, Formik, FormikProps } from 'formik';
import * as Yup from 'yup';
import { useEffect } from 'react';
import { useSelector } from 'src/reducers';
import { useNavigate } from 'react-router-dom';
import { LoadingButton } from '@mui/lab';
import { SupplierRule } from 'src/types/supplierRuleTypes';

const CardStyle = styled(Card)(({ }) => ({
    width: '100%',
    padding: 24
}));

export interface FormValues {
    initialValues: SupplierRule,
    submitForm: any,
}

export default function SupplierRuleForm({ initialValues, submitForm }: FormValues) {

    const SchemaCategoryForm = Yup.object().shape({
        minSupplierCpi: Yup.number().required('Minimun CPI  is required'),
        maxSupplierCpi: Yup.number().required('Maximum CPI  is required'),
    });
    const navigate = useNavigate();
    const { redirectTo } = useSelector(state => state.supplierRule);

    useEffect(() => {
        if (redirectTo) navigate(redirectTo, { replace: true })
    }, [redirectTo])


    return (
        <CardStyle>
            <Formik
                onSubmit={(values, { setSubmitting }) => {
                    submitForm(values, setSubmitting);
                }}
                validateOnBlur={false}
                validateOnChange={true}
                enableReinitialize={true}
                validationSchema={SchemaCategoryForm}
                initialValues={{
                    minSupplierCpi: initialValues?.minSupplierCpi,
                    maxSupplierCpi: initialValues?.maxSupplierCpi ,
                }}
            >
                {(formikProps: FormikProps<SupplierRule>) => (
                    <Form autoComplete="off" noValidate onSubmit={formikProps.handleSubmit}>
                        <Stack spacing={3}>
                            <Grid
                                container
                                spacing={3}
                            >
                                <Grid
                                    item
                                    md={5}
                                    xs={12}
                                >
                                    <TextField
                                        error={Boolean(formikProps.touched.minSupplierCpi && formikProps.errors.minSupplierCpi)}
                                        fullWidth
                                        helperText={formikProps.touched.minSupplierCpi && formikProps.errors.minSupplierCpi}
                                        label="Min Supplier CPI"
                                        {...formikProps.getFieldProps('minSupplierCpi')}
                                        variant="standard"
                                    />
                                </Grid>
                                <Grid
                                    item
                                    md={7}
                                    xs={12}
                                >
                                    <TextField
                                        error={Boolean(formikProps.touched.maxSupplierCpi && formikProps.errors.maxSupplierCpi)}
                                        fullWidth
                                        helperText={formikProps.touched.maxSupplierCpi && formikProps.errors.maxSupplierCpi}
                                        label="Max Supplier CPI"
                                        {...formikProps.getFieldProps('maxSupplierCpi')}
                                        variant="standard"
                                    />
                                </Grid>
                            </Grid>
                            <Grid item xs={2}>
                                    <LoadingButton
                                        size="medium"
                                        type="submit"
                                        variant="contained"
                                        loading={formikProps.isSubmitting}
                                    >
                                        {initialValues ? 'Save Rule' : 'Add Rule'}
                                    </LoadingButton>
                                </Grid>
                        </Stack>
                    </Form>
                )}
            </Formik>
        </CardStyle>
    );
}
