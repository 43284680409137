export const ProjectStatus = [
  { label: 'Live', value: 1,color:"success" },
  { label: 'Awarded', value: 2,color:"default" },
  { label: 'Paused', value: 3,color:"warning"  },
  { label: 'Ready To Invoice', value: 4 ,color:"primary" },
  { label: 'Invoiced', value: 5,color:"info"  },
  { label: 'Closed', value: 6,color:"secondary"  },
  { label: 'Archieved', value: 7,color:"secondary"  },
  { label: 'Waiting', value: 8,color:"info"  },
  { label: 'Testing', value: 9,color:"info"  }
]

export const ProjectStatusMain = [
{ label: 'Live', value: 1,color:"success" },
{ label: 'Awarded', value: 2,color:"default" },
{ label: 'Paused', value: 3,color:"warning"  },
{ label: 'Closed', value: 6,color:"secondary"  },
{ label: 'Archieved', value: 7,color:"secondary"  },
{ label: 'Waiting', value: 8,color:"info"  },
{ label: 'Testing', value: 9,color:"info"  }
]

export const InvoiceStatus = [
  { label: 'Pending', value: 1,color:"success" },
  { label: 'Paid', value: 2,color:"warning"  },
]

export const userRole= {
    MANAGER: "MANAGER",
    ADMIN: "ADMIN",
    SUPERADMIN: "SUPERADMIN",
    ACCOUNTANT :"ACCOUNTANT",
    SMANAGER:"SMANAGER",
    APIMANAGER:"APIMANAGER"
  };


  export const deviceType= {
    DESKTOP:1,
    TABLET:2,
    MOBILE: 3,
  };