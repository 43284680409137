import { ThunkAction, ThunkDispatch } from "redux-thunk";
import Prefix from 'src/helpers/ApiPrefix';
import AXIOS from 'src/helpers/Axios';
import Headers from 'src/helpers/Headers';
import { projectAction } from "src/reducers/projectReducer";
import { projectActionTypes } from "src/types/actionTypes";
import { Exclude, Project, ProjectList } from "src/types/projectTypes";
import { ErrorAction, SuccessAction } from "src/types/responseTypes";
import fileDownload from 'js-file-download'


export const ProjectFailure = (message: string): ErrorAction => {
    return {
        type: projectActionTypes.PROJECT_FAILURE,
        payload: { message: message }
    }
}
export const ProjectSuccess = (payload: ProjectList): SuccessAction<ProjectList> => {
    
    return {
        type: projectActionTypes.FETCH_PROJECT_SUCCESS,
        payload: payload,
    }
}

export const ProjectApiSuccess = (payload: ProjectList): SuccessAction<ProjectList> => {
    return {
        type: projectActionTypes.FETCH_PROJECT_API_SUCCESS,
        payload: payload,
    }
}

export const ProjectApiQuotaSuccess = (payload: ProjectList): SuccessAction<ProjectList> => {
    return {
        type: projectActionTypes.FETCH_PROJECT_QUOTA_API_SUCCESS,
        payload: payload,
    }
}

export const ProjectApiQuestionSuccess = (payload: ProjectList): SuccessAction<ProjectList> => {
    return {
        type: projectActionTypes.FETCH_PROJECT_QUESTION_API_SUCCESS,
        payload: payload,
    }
}


export const ProjectChildSearchSuccess = (payload: ProjectList): SuccessAction<ProjectList> => {
    
    return {
        type: projectActionTypes.FETCH_CHILD_SEARCH_SUCCESS,
        payload: payload,
    }
}



export const ProjectReportsSuccess = (payload: ProjectList): SuccessAction<ProjectList> => {
    
    return {
        type: projectActionTypes.FETCH_REPORT_PROJECT_SUCCESS,
        payload: payload,
    }
}

export const ProjectChildSuccess = (payload: ProjectList): SuccessAction<ProjectList> => {
    
    return {
        type: projectActionTypes.FETCH_CHILD_PROJECT_SUCCESS,
        payload: payload,
    }
}
export const ProjectAllSuccess = (payload: ProjectList): SuccessAction<ProjectList> => {
    return {
        type: projectActionTypes.FETCH_PROJECT_ALL_SUCCESS,
        payload: payload,
    }
}

export const ProjectAddSuccess = (payload: Project): SuccessAction<Project> => {
    return {
        type: projectActionTypes.ADD_PROJECT_SUCCESS,
        payload: payload,
    }
}


export const ProjectChildAddSuccess = (payload: Project): SuccessAction<Project> => {
    return {
        type: projectActionTypes.ADD_CHILD_PROJECT_SUCCESS,
        payload: payload,
    }
}



export const ProjectSingleSuccess = (payload: Project): SuccessAction<Project> => {
    return {
        type: projectActionTypes.FETCH_SINGLE_PROJECT_SUCCESS,
        payload: payload,
    }
}

export const ProjectUpdateSuccess = (payload: any): SuccessAction<any> => {
    return {
        type: projectActionTypes.UPDATE_PROJECT_SUCCESS,
        payload: payload,
    }
}

export const ProjectChildUpdateSuccess = (payload: any): SuccessAction<any> => {
    return {
        type: projectActionTypes.UPDATE_CHILD_PROJECT_SUCCESS,
        payload: payload,
    }
}


export const ProjectDeleteSuccess = (payload: any): SuccessAction<any> => {
    return {
        type: projectActionTypes.DELETE_PROJECT_SUCCESS,
        payload: payload,
    }
}


export const ProjectPreScreenSuccess = (payload: any): SuccessAction<any> => {
    return {
        type: projectActionTypes.PROJECT_PRESCREEN_SUCCESS,
        payload: payload,
    }
}

export const ProjectExcludeSuccess = (payload: ProjectList): SuccessAction<ProjectList> => {
    return {
        type: projectActionTypes.FETCH_PROJECT_EXCLUDE_SUCCESS,
        payload: payload,
    }
}

export const ProjectExcludeSingleSuccess = (payload: Project): SuccessAction<Project> => {
    return {
        type: projectActionTypes.FETCH_SINGLE_EXCLUDE_PROJECT_SUCCESS,
        payload: payload,
    }
}

export const ProjectExcludeAddSuccess = (payload: Project): SuccessAction<Project> => {
    return {
        type: projectActionTypes.ADD_PROJECT_EXCLUDE_SUCCESS,
        payload: payload,
    }
}

export const ProjectExcludeUpdateSuccess = (payload: Project): SuccessAction<Project> => {
    return {
        type: projectActionTypes.UPDATE_PROJECT_EXCLUDE_SUCCESS,
        payload: payload,
    }
}

export const ProjectExcludeDeleteSuccess = (payload: any): SuccessAction<any> => {
    return {
        type: projectActionTypes.DELETE_PROJECT_EXCLUDE_SUCCESS,
        payload: payload,
    }
}

export const ProjectResetSuccess = (payload: any): SuccessAction<any> => {
    return {
        type: projectActionTypes.PROJECT_RESET,
        payload: payload,
    }
}


export const ProjectAllRequest = (filter: any,  pageNo: number, limit: number, status: boolean):
    ThunkAction<Promise<void>, {}, {}, projectAction> => {
    return (dispatch: ThunkDispatch<{}, {}, projectAction>) => {
       dispatch({ type:projectActionTypes.PROJECT_REQUEST,payload: { loading:true} });
        return AXIOS.post(`${Prefix.api}/admin/projects`, { filter, pageNo, limit, status }, { headers: Headers() }).then(res => {
            if (res) {
                const { data } = res;
                const projects: ProjectList = data.result
                dispatch(ProjectSuccess(projects))
            }
        }).catch((e) => {
            if (e.response) {
                dispatch(ProjectFailure(e.response.data.message));
            }
            else {
                dispatch(ProjectFailure(e.message));
            }
        })
    }
}



export const ProjectApiPrecisionRequest = ( filters:any):
    ThunkAction<Promise<void>, {}, {}, projectAction> => {
    return (dispatch: ThunkDispatch<{}, {}, projectAction>) => {
       dispatch({ type:projectActionTypes.PROJECT_REQUEST, payload: { loading:true ,projects:[]} });
        return AXIOS.post(`${Prefix.api}/admin/project/api/precision/request`, { filters }, { headers: Headers() }).then(res => {
            if (res) {
                const { data } = res;
                const projects: ProjectList = data.result
                dispatch(ProjectApiSuccess(projects))
            }
        }).catch((e) => {
            if (e.response) {
                dispatch(ProjectFailure(e.response.data.message));
            }
            else {
                dispatch(ProjectFailure(e.message));
            }
        })
    }
}


export const ProjectApiCircleRequest = ( filters:any):
    ThunkAction<Promise<void>, {}, {}, projectAction> => {
    return (dispatch: ThunkDispatch<{}, {}, projectAction>) => {
       dispatch({ type:projectActionTypes.PROJECT_REQUEST, payload: { loading:true ,projects:[]} });
        return AXIOS.post(`${Prefix.api}/admin/project/api/circle/request`, { filters }, { headers: Headers() }).then(res => {
            if (res) {
                const { data } = res;
                const projects: ProjectList = data.result
                dispatch(ProjectApiSuccess(projects))
            }
        }).catch((e) => {
            if (e.response) {
                dispatch(ProjectFailure(e.response.data.message));
            }
            else {
                dispatch(ProjectFailure(e.message));
            }
        })
    }
}

export const ProjectApiLogitRequest = ( filters:any):
    ThunkAction<Promise<void>, {}, {}, projectAction> => {
    return (dispatch: ThunkDispatch<{}, {}, projectAction>) => {
       dispatch({ type:projectActionTypes.PROJECT_REQUEST,payload: { loading:true ,projects:[] } });
        return AXIOS.post(`${Prefix.api}/admin/project/api/logit/request`, { filters }, { headers: Headers() }).then(res => {
            if (res) {
                const { data } = res;
                const projects: ProjectList = data.result
                dispatch(ProjectApiSuccess(projects))
            }
        }).catch((e) => {
            if (e.response) {
                dispatch(ProjectFailure(e.response.data.message));
            }
            else {
                dispatch(ProjectFailure(e.message));
            }
        })
    }
}

export const ProjectApiTolunaRequest = ( filters:any):
    ThunkAction<Promise<void>, {}, {}, projectAction> => {
    return (dispatch: ThunkDispatch<{}, {}, projectAction>) => {
       dispatch({ type:projectActionTypes.PROJECT_REQUEST,payload: { loading:true ,projects:[]} });
        return AXIOS.post(`${Prefix.api}/admin/project/api/toluna/request`, { filters }, { headers: Headers() }).then(res => {
            if (res) {
                const { data } = res;
                const projects: ProjectList = data.result
                dispatch(ProjectApiSuccess(projects))
            }
        }).catch((e) => {
            if (e.response) {
                dispatch(ProjectFailure(e.response.data.message));
            }
            else {
                dispatch(ProjectFailure(e.message));
            }
        })
    }
}


export const ProjectApiMrktRequest = ( filters:any):
    ThunkAction<Promise<void>, {}, {}, projectAction> => {
    return (dispatch: ThunkDispatch<{}, {}, projectAction>) => {
       dispatch({ type:projectActionTypes.PROJECT_REQUEST,payload: { loading:true ,projects:[]} });
        return AXIOS.post(`${Prefix.api}/admin/project/api/mrkt/request`, { filters }, { headers: Headers() }).then(res => {
            if (res) {
                const { data } = res;
                const projects: ProjectList = data.result
                dispatch(ProjectApiSuccess(projects))
            }
        }).catch((e) => {
            if (e.response) {
                dispatch(ProjectFailure(e.response.data.message));
            }
            else {
                dispatch(ProjectFailure(e.message));
            }
        })
    }
}


export const ProjectApiLogitQuotaAllRequest = ( filters:any,projectId:string):
    ThunkAction<Promise<void>, {}, {}, projectAction> => {
    return (dispatch: ThunkDispatch<{}, {}, projectAction>) => {
       dispatch({ type:projectActionTypes.PROJECT_REQUEST,payload: { loading:true ,quotas:""} });
        return AXIOS.post(`${Prefix.api}/admin/project/api/logit/quota/all`, { filters, projectId}, { headers: Headers() }).then(res => {
            if (res) {
                const { data } = res;
                const projects: ProjectList = data.result
                dispatch(ProjectApiQuotaSuccess(projects))
            }
        }).catch((e) => {
            if (e.response) {
                dispatch(ProjectFailure(e.response.data.message));
            }
            else {
                dispatch(ProjectFailure(e.message));
            }
        })
    }
}


export const ProjectApiTolunaQuotaAllRequest = ( filters:any,quotaId:string):
    ThunkAction<Promise<void>, {}, {}, projectAction> => {
    return (dispatch: ThunkDispatch<{}, {}, projectAction>) => {
       dispatch({ type:projectActionTypes.PROJECT_REQUEST,payload: { loading:true ,quotas:""} });
        return AXIOS.post(`${Prefix.api}/admin/project/api/toluna/quota/all`, { filters, quotaId}, { headers: Headers() }).then(res => {
            if (res) {
                const { data } = res;
                const projects: ProjectList = data.result
                dispatch(ProjectApiQuotaSuccess(projects))
            }
        }).catch((e) => {
            if (e.response) {
                dispatch(ProjectFailure(e.response.data.message));
            }
            else {
                dispatch(ProjectFailure(e.message));
            }
        })
    }
}

export const ProjectApiMrtQuotaAllRequest = ( filters:any,surveyId:string):
    ThunkAction<Promise<void>, {}, {}, projectAction> => {
    return (dispatch: ThunkDispatch<{}, {}, projectAction>) => {
       dispatch({ type:projectActionTypes.PROJECT_REQUEST,payload: { loading:true ,quotas:""} });
        return AXIOS.post(`${Prefix.api}/admin/project/api/mrkt/quota/all`, { filters, surveyId}, { headers: Headers() }).then(res => {
            if (res) {
                const { data } = res;
                const projects: ProjectList = data.result
                dispatch(ProjectApiQuotaSuccess(projects))
            }
        }).catch((e) => {
            if (e.response) {
                dispatch(ProjectFailure(e.response.data.message));
            }
            else {
                dispatch(ProjectFailure(e.message));
            }
        })
    }
}

export const ProjectApiTolunaQuestionAllRequest = ( filters:any,questionId:string):
    ThunkAction<Promise<void>, {}, {}, projectAction> => {
    return (dispatch: ThunkDispatch<{}, {}, projectAction>) => {
       dispatch({ type:projectActionTypes.PROJECT_REQUEST,payload: { loading:true ,questions:"" } });
        return AXIOS.post(`${Prefix.api}/admin/project/api/toluna/questions/all`, { filters, questionId}, { headers: Headers() }).then(res => {
            if (res) {
                const { data } = res;
                const projects: ProjectList = data.result
                dispatch(ProjectApiQuestionSuccess(projects))
            }
        }).catch((e) => {
            if (e.response) {
                dispatch(ProjectFailure(e.response.data.message));
            }
            else {
                dispatch(ProjectFailure(e.message));
            }
        })
    }
}




export const ProjectInvoiceAllRequest = (filter: any,  pageNo: number, limit: number, status: boolean):
    ThunkAction<Promise<void>, {}, {}, projectAction> => {
    return (dispatch: ThunkDispatch<{}, {}, projectAction>) => {
        dispatch({ type:projectActionTypes.PROJECT_REQUEST,payload: { loading:true} });
        return AXIOS.post(`${Prefix.api}/admin/invoice/projects`, { filter, pageNo, limit, status }, { headers: Headers() }).then(res => {
            if (res) {
                const { data } = res;
                const projects: ProjectList = data.result
                dispatch(ProjectSuccess(projects))
            }
        }).catch((e) => {
            if (e.response) {
                dispatch(ProjectFailure(e.response.data.message));
            }
            else {
                dispatch(ProjectFailure(e.message));
            }
        })
    }
}

export const ProjectAchievedAllRequest = (filter: any,  pageNo: number, limit: number, status: boolean):
    ThunkAction<Promise<void>, {}, {}, projectAction> => {
    return (dispatch: ThunkDispatch<{}, {}, projectAction>) => {
        dispatch({ type:projectActionTypes.PROJECT_REQUEST,payload: { loading:true} });
        return AXIOS.post(`${Prefix.api}/admin/archieved/projects`, { filter, pageNo, limit, status }, { headers: Headers() }).then(res => {
            if (res) {
                const { data } = res;
                const projects: ProjectList = data.result
                dispatch(ProjectSuccess(projects))
            }
        }).catch((e) => {
            if (e.response) {
                dispatch(ProjectFailure(e.response.data.message));
            }
            else {
                dispatch(ProjectFailure(e.message));
            }
        })
    }
}



export const ProjectSupplierInventoryRequest = (filter: any,  pageNo: number, limit: number, status: boolean):
    ThunkAction<Promise<void>, {}, {}, projectAction> => {
    return (dispatch: ThunkDispatch<{}, {}, projectAction>) => {
        dispatch({ type:projectActionTypes.PROJECT_REQUEST,payload: { loading:true} });
        return AXIOS.post(`${Prefix.api}/supplier/project/inventory`, { filter, pageNo, limit, status }, { headers: Headers() }).then(res => {
            if (res) {
                const { data } = res;
                const projects: ProjectList = data.result
                dispatch(ProjectSuccess(projects))

            }
        }).catch((e) => {
            if (e.response) {
                dispatch(ProjectFailure(e.response.data.message));
            }
            else {
                dispatch(ProjectFailure(e.message));
            }
        })
    }
}

export const ProjectSupplierInventoryUpdateRequest = (projectid: string , update:Project, setMessageHandler: any, setSubmitting: any):
    ThunkAction<Promise<void>, {}, {}, projectAction> => {
    return (dispatch: ThunkDispatch<{}, {}, projectAction>) => {
        return AXIOS.put(`${Prefix.api}/supplier/project/inventory/${projectid}/update`, update, { headers: Headers() }).then(res => {
            if (res) {
                const { data } = res;
                const project: Project = data.result
                dispatch(ProjectUpdateSuccess(project))
                setSubmitting(false)
                setMessageHandler({
                    hasMessage: true,
                    type: 'success',
                    message: data.message,
                });
            }
        }).catch((e) => {
            if (e.response) {
                dispatch(ProjectFailure(e.response.data.message));
                setSubmitting(false)
                setMessageHandler({
                    hasMessage: true,
                    type: 'error',
                    message: e.response.data.message,
                });
            }
            else {
                dispatch(ProjectFailure(e.message));
                setSubmitting(false)
                setMessageHandler({
                    hasMessage: true,
                    type: 'error',
                    message: e.message,
                });
            }
        })
    }
}




export const ProjectSupplierMappedRequest = (filter: any,  pageNo: number, limit: number, status: boolean):
    ThunkAction<Promise<void>, {}, {}, projectAction> => {
    return (dispatch: ThunkDispatch<{}, {}, projectAction>) => {
        dispatch({ type:projectActionTypes.PROJECT_REQUEST,payload: { loading:true} });
        return AXIOS.post(`${Prefix.api}/supplier/project/mapped`, { filter,  pageNo, limit, status }, { headers: Headers() }).then(res => {
            if (res) {
                const { data } = res;
                const projects: ProjectList = data.result
                dispatch(ProjectSuccess(projects))

            }
        }).catch((e) => {
            if (e.response) {
                dispatch(ProjectFailure(e.response.data.message));
            }
            else {
                dispatch(ProjectFailure(e.message));
            }
        })
    }
}


export const ProjectSupplierExportRequest = ( body: any ,fileName:string ):
    ThunkAction<Promise<void>, {}, {}, projectAction> => {
    return (dispatch: ThunkDispatch<{}, {}, projectAction>) => {
        return AXIOS.post(`${Prefix.api}/supplier/project/supplier/report`, body, { responseType: 'blob', headers: Headers() }).then(res => {
            if (res) {
                const { data } = res;
                fileDownload(data, `${fileName}.csv`);
            }
        }).catch((e) => {
            if (e.response) {
                dispatch(ProjectFailure(e.response.data.message));
            }
            else {
                dispatch(ProjectFailure(e.message));
            }
        })
    }
}

export const SupplierDataExportRequest = ( body: any ,fileName:string ):
    ThunkAction<Promise<void>, {}, {}, projectAction> => {
    return (dispatch: ThunkDispatch<{}, {}, projectAction>) => {
        return AXIOS.post(`${Prefix.api}/admin/supplier/report/data`, body, { responseType: 'blob', headers: Headers() }).then(res => {
            if (res) {
                const { data } = res;
                fileDownload(data, `${fileName}.csv`);
            }
        }).catch((e) => {
            if (e.response) {
                dispatch(ProjectFailure(e.response.data.message));
            }
            else {
                dispatch(ProjectFailure(e.message));
            }
        })
    }
}


export const ProjectByIdentifierRequest = (filter: any,  pageNo: number, limit: number, status: boolean):
    ThunkAction<Promise<void>, {}, {}, projectAction> => {
    return (dispatch: ThunkDispatch<{}, {}, projectAction>) => {
        return AXIOS.post(`${Prefix.api}/admin/projects/identifier`, { filter,  pageNo, limit, status }, { headers: Headers() }).then(res => {
            if (res) {
                const { data } = res;
                const projects: ProjectList = data.result
                dispatch(ProjectSuccess(projects))
            }
        }).catch((e) => {
            if (e.response) {
                dispatch(ProjectFailure(e.response.data.message));
            }
            else {
                dispatch(ProjectFailure(e.message));
            }
        })
    }
}

export const ProjectChildsRequest = (projectid:string ,filter:any):
    ThunkAction<Promise<void>, {}, {}, projectAction> => {
    return (dispatch: ThunkDispatch<{}, {}, projectAction>) => {
        dispatch({ type: projectActionTypes.PROJECT_REQUEST, payload:{ loading:true }})
        return AXIOS.post(`${Prefix.api}/admin/project/childs/${projectid}`,{ filter } ,{ headers: Headers() }).then(res => {
            if (res) {
                const { data } = res;
                const projects: ProjectList = data.result
                dispatch(ProjectChildSuccess(projects))
            }
        }).catch((e) => {
            if (e.response) {
                dispatch(ProjectFailure(e.response.data.message));
            }
            else {
                dispatch(ProjectFailure(e.message));
            }
        })
    }
}

export const ProjectChildsRequestDrawer = (projectid:string ,filter:any,toggleDrawer:any):
    ThunkAction<Promise<void>, {}, {}, projectAction> => {
    return (dispatch: ThunkDispatch<{}, {}, projectAction>) => {
        dispatch({ type: projectActionTypes.PROJECT_REQUEST, payload:{ loading:true }})
        return AXIOS.post(`${Prefix.api}/admin/project/childs/${projectid}`,{ filter } ,{ headers: Headers() }).then(res => {
            if (res) {
                const { data } = res;
                const projects: ProjectList = data.result
                dispatch(ProjectChildSuccess(projects))
                toggleDrawer('bottom',true)
            }
        }).catch((e) => {
            if (e.response) {
                dispatch(ProjectFailure(e.response.data.message));
            }
            else {
                dispatch(ProjectFailure(e.message));
            }
        })
    }
}

export const ProjectChildsByRequest = (projectid:string):
    ThunkAction<Promise<void>, {}, {}, projectAction> => {
    return (dispatch: ThunkDispatch<{}, {}, projectAction>) => {
        dispatch({ type: projectActionTypes.PROJECT_REQUEST, payload:{ loading:true }})
        return AXIOS.get(`${Prefix.api}/admin/project/child/all/${projectid}`,{ headers: Headers() }).then(res => {
            if (res) {
                const { data } = res;
                const projects: ProjectList = data.result
                dispatch(ProjectChildSuccess(projects))
        
            }
        }).catch((e) => {
            if (e.response) {
                dispatch(ProjectFailure(e.response.data.message));
            }
            else {
                dispatch(ProjectFailure(e.message));
            }
        })
    }
}



export const ProjectReportsRequest = (filter:any ,type:any):
    ThunkAction<Promise<void>, {}, {}, projectAction> => {
    return (dispatch: ThunkDispatch<{}, {}, projectAction>) => {
        return AXIOS.post(`${Prefix.api}/admin/project/report/data`,{ filter,type } ,{ headers: Headers() }).then(res => {
            if (res) {
                const { data } = res;
                const projects: ProjectList = data.result
                dispatch(ProjectReportsSuccess(projects))
            }
        }).catch((e) => {
            if (e.response) {
                dispatch(ProjectFailure(e.response.data.message));
            }
            else {
                dispatch(ProjectFailure(e.message));
            }
        })
    }
}
export const ProjectPrescreenReportsRequest = (body:any ,fileName:any):
    ThunkAction<Promise<void>, {}, {}, projectAction> => {
    return (dispatch: ThunkDispatch<{}, {}, projectAction>) => {
            return AXIOS.post(`${Prefix.api}/admin/project/prescreen/report`, body, { responseType: 'blob', headers: Headers() }).then(res => {
            if (res) {
                const { data } = res;
                fileDownload(data, `${fileName}.csv`);
            }
        }).catch((e) => {
            if (e.response) {
                dispatch(ProjectFailure(e.response.data.message));
            }
            else {
                dispatch(ProjectFailure(e.message));
            }
        })
    }
}




export const ProjectChildsAllRequest = (projectid:string):
    ThunkAction<Promise<void>, {}, {}, projectAction> => {
    return (dispatch: ThunkDispatch<{}, {}, projectAction>) => {
        return AXIOS.get(`${Prefix.api}/admin/projects/childs/all/${projectid}`,{ headers: Headers() }).then(res => {
            if (res) {
                const { data } = res;
                const projects: ProjectList = data.result
                dispatch(ProjectSuccess(projects))
            }
        }).catch((e) => {
            if (e.response) {
                dispatch(ProjectFailure(e.response.data.message));
            }
            else {
                dispatch(ProjectFailure(e.message));
            }
        })
    }
}


export const ProjectChildsSearchRequest = (body:any):
    ThunkAction<Promise<void>, {}, {}, projectAction> => {
    return (dispatch: ThunkDispatch<{}, {}, projectAction>) => {
        return AXIOS.post(`${Prefix.api}/admin/project/childs/search/all`,body,{ headers: Headers() }).then(res => {
            if (res) {
                const { data } = res;
                const projects: ProjectList = data.result
                dispatch(ProjectChildSearchSuccess(projects))
            }
        }).catch((e) => {
            if (e.response) {
                dispatch(ProjectFailure(e.response.data.message));
            }
            else {
                dispatch(ProjectFailure(e.message));
            }
        })
    }
}




export const ProjectExcludesAddRequest = (project: Project, setMessageHandler: any, setSubmitting: any):
    ThunkAction<Promise<void>, {}, {}, projectAction> => {
    setMessageHandler({
        hasMessage: false,
        type: '',
        message: '',
    });
    return (dispatch: ThunkDispatch<{}, {}, projectAction>) => {
        return AXIOS.post(`${Prefix.api}/admin/project/add`, project, { headers: Headers() }).then(res => {
            if (res) {
                const { data } = res;
                const project: Project = data.result;
                dispatch(ProjectAddSuccess(project))
                setSubmitting(false);
                setMessageHandler({
                    hasMessage: true,
                    type: 'success',
                    message: data.message,
                });
            }
        }).catch((e) => {
            if (e.response) {
                setSubmitting(false);
                dispatch(ProjectFailure(e.response.data.message));
                setMessageHandler({
                    hasMessage: true,
                    type: 'error',
                    message: e.response.data.message,
                });
            }
            else {
                setSubmitting(false);
                dispatch(ProjectFailure(e.message));
                setMessageHandler({
                    hasMessage: true,
                    type: 'error',
                    message: e.message,
                });
            }
        })
    }
}




export const ProjectExcludesAllRequest = (projectid:string ,search:string):
    ThunkAction<Promise<void>, {}, {}, projectAction> => {
    return (dispatch: ThunkDispatch<{}, {}, projectAction>) => {
        return AXIOS.post(`${Prefix.api}/admin/project/excluded/${projectid}`,{ search } , { headers: Headers() }).then(res => {
            if (res) {
                const { data } = res;
                const projects: ProjectList = data.result
                dispatch(ProjectExcludeSuccess(projects))
            }
        }).catch((e) => {
            if (e.response) {
                dispatch(ProjectFailure(e.response.data.message));
            }
            else {
                dispatch(ProjectFailure(e.message));
            }
        })
    }
}

export const ProjectAddExcludeRequest = (body:Exclude,setMessageHandler:any,setSubmitting:any,resetForm:any):
    ThunkAction<Promise<void>, {}, {}, projectAction> => {
    return (dispatch: ThunkDispatch<{}, {}, projectAction>) => {
        return AXIOS.post(`${Prefix.api}/admin/project/exclude/add`,body, { headers: Headers() }).then(res => {
            if (res) {
                const { data } = res;
                const project: Exclude = data.result
                dispatch(ProjectExcludeAddSuccess(project))
                setMessageHandler({
                    hasMessage: true,
                    type: 'success',
                    message: data.message,
                });
                setSubmitting(false)
                resetForm();
            }
        }).catch((e) => {
            if (e.response) {
                dispatch(ProjectFailure(e.response.data.message));
                setMessageHandler({
                    hasMessage: true,
                    type: 'error',
                    message: e.response.data.message,
                });
                setSubmitting(false)
            }
            else {
                dispatch(ProjectFailure(e.message));
                setMessageHandler({
                    hasMessage: true,
                    type: 'error',
                    message: e.response.data.message,
                });
                setSubmitting(false)
            }
        })
    }
}

export const ProjectUpdateExcludeRequest = (id:string,body:Exclude,setMessageHandler:any):
    ThunkAction<Promise<void>, {}, {}, projectAction> => {
    return (dispatch: ThunkDispatch<{}, {}, projectAction>) => {
        return AXIOS.put(`${Prefix.api}/admin/project/exclude/update/${id}`,body, { headers: Headers() }).then(res => {
            if (res) {
                const { data } = res;
                const project: Exclude = data.result
                dispatch(ProjectExcludeUpdateSuccess(project))
                setMessageHandler({
                    hasMessage: true,
                    type: 'success',
                    message: data.message,
                });
            }
        }).catch((e) => {
            if (e.response) {
                dispatch(ProjectFailure(e.response.data.message));
                setMessageHandler({
                    hasMessage: true,
                    type: 'error',
                    message: e.response.data.message,
                });
            }
            else {
                dispatch(ProjectFailure(e.message));
                setMessageHandler({
                    hasMessage: true,
                    type: 'error',
                    message: e.response.data.message,
                });
            }
        })
    }
}

export const ProjectDeleteExcludeRequest = (projectid:string, body:Exclude,setMessageHandler:any):
    ThunkAction<Promise<void>, {}, {}, projectAction> => {
    return (dispatch: ThunkDispatch<{}, {}, projectAction>) => {
        return AXIOS.put(`${Prefix.api}/admin/project/exclude/update/${projectid}`,body, { headers: Headers() }).then(res => {
            if (res) {
                const { data } = res;
                const project: Exclude = data.result
                dispatch(ProjectExcludeDeleteSuccess(project))
                setMessageHandler({
                    hasMessage: true,
                    type: 'success',
                    message: data.message,
                });
            }
        }).catch((e) => {
            if (e.response) {
                dispatch(ProjectFailure(e.response.data.message));
                setMessageHandler({
                    hasMessage: true,
                    type: 'error',
                    message: e.response.data.message,
                });
            }
            else {
                dispatch(ProjectFailure(e.message));
                setMessageHandler({
                    hasMessage: true,
                    type: 'error',
                    message: e.response.data.message,
                });
            }
        })
    }
}

export const ProjectSingleExcludeRequest = (id:string):
    ThunkAction<Promise<void>, {}, {}, projectAction> => {
    return (dispatch: ThunkDispatch<{}, {}, projectAction>) => {
        return AXIOS.get(`${Prefix.api}/admin/project/exclude/${id}`, { headers: Headers() }).then(res => {
            if (res) {
                const { data } = res;
                const project: Exclude = data.result
                dispatch(ProjectExcludeDeleteSuccess(project))
            }
        }).catch((e) => {
            if (e.response) {
                dispatch(ProjectFailure(e.response.data.message));
            }
            else {
                dispatch(ProjectFailure(e.message));
            }
        })
    }
}





export const ProjectAllRequestData = ():
    ThunkAction<Promise<void>, {}, {}, projectAction> => {
    return (dispatch: ThunkDispatch<{}, {}, projectAction>) => {
        return AXIOS.get(`${Prefix.api}/admin/projects/all`, { headers: Headers() }).then(res => {
            if (res) {
                const { data } = res;
                const projects: ProjectList = data.result
                dispatch(ProjectAllSuccess(projects))
            }
        }).catch((e) => {
            if (e.response) {
                dispatch(ProjectFailure(e.response.data.message));
            }
            else {
                dispatch(ProjectFailure(e.message));
            }
        })
    }
}



export const ProjectAllInvoiceRequestData = (body:any):
    ThunkAction<Promise<void>, {}, {}, projectAction> => {
    return (dispatch: ThunkDispatch<{}, {}, projectAction>) => {
        dispatch({ type: projectActionTypes.PROJECT_REQUEST, payload:{ loading:true,projects:[] }})
        return AXIOS.post(`${Prefix.api}/admin/invoice/projects/all` , body , { headers: Headers() }).then(res => {
            if (res) {
                const { data } = res;
                const projects: ProjectList = data.result
                dispatch(ProjectAllSuccess(projects))
            }
        }).catch((e) => {
            if (e.response) {
                dispatch(ProjectFailure(e.response.data.message));
            }
            else {
                dispatch(ProjectFailure(e.message));
            }
        })
    }
}


export const ProjectCloneRequest = ( id: string,body:any, setSubmitting:any, setMessageHandler: any ):
    ThunkAction<Promise<void>, {}, {}, projectAction> => {
    setMessageHandler({
        hasMessage: false,
        type: '',
        message: '',
    });
    return (dispatch: ThunkDispatch<{}, {}, projectAction>) => {
        return AXIOS.post(`${Prefix.api}/admin/project/clone/${id}`,body, { headers: Headers()}).then(res => {
            if (res) {
                const { data } = res;
                const project: Project = data.result;
                dispatch(ProjectChildsRequest(project.parentProjectId || '',{}))
                setMessageHandler({
                    hasMessage: true,
                    type: 'success',
                    message: data.message,
                });
                setSubmitting(false)
            }
        }).catch((e) => {
            if (e.response) {
                dispatch(ProjectFailure(e.response.data.message));
                setMessageHandler({
                    hasMessage: true,
                    type: 'error',
                    message: e.response.data.message,
                });
                setSubmitting(false)
            }
            else {
                dispatch(ProjectFailure(e.message));
                setMessageHandler({
                    hasMessage: true,
                    type: 'error',
                    message: e.message,
                });
                setSubmitting(false)
            }
        })
    }
}


export const ProjectRevokeRequest = ( id: string, setMessageHandler: any ):
    ThunkAction<Promise<void>, {}, {}, projectAction> => {
    setMessageHandler({
        hasMessage: false,
        type: '',
        message: '',
    });
    return (dispatch: ThunkDispatch<{}, {}, projectAction>) => {
        return AXIOS.post(`${Prefix.api}/admin/project/revoke/${id}`,{}, { headers: Headers()}).then(res => {
            if (res) {
                const { data } = res;
                const project: Project = data.result;
                setMessageHandler({
                    hasMessage: true,
                    type: 'success',
                    message: data.message,
                });
            }
        }).catch((e) => {
            if (e.response) {
                dispatch(ProjectFailure(e.response.data.message));
                setMessageHandler({
                    hasMessage: true,
                    type: 'error',
                    message: e.response.data.message,
                });
            }
            else {
                dispatch(ProjectFailure(e.message));
                setMessageHandler({
                    hasMessage: true,
                    type: 'error',
                    message: e.message,
                });
            }
        })
    }
}


export const ProjectAddRequest = (project: Project, setMessageHandler: any, setSubmitting: any):
    ThunkAction<Promise<void>, {}, {}, projectAction> => {
    setMessageHandler({
        hasMessage: false,
        type: '',
        message: '',
    });
    return (dispatch: ThunkDispatch<{}, {}, projectAction>) => {
        return AXIOS.post(`${Prefix.api}/admin/project/add`, project, { headers: Headers() }).then(res => {
            if (res) {
                const { data } = res;
                const project: Project = data.result;
                dispatch(ProjectAddSuccess(project))
                setSubmitting(false);
                setMessageHandler({
                    hasMessage: true,
                    type: 'success',
                    message: data.message,
                });
            }
        }).catch((e) => {
            if (e.response) {
                setSubmitting(false);
                dispatch(ProjectFailure(e.response.data.message));
                setMessageHandler({
                    hasMessage: true,
                    type: 'error',
                    message: e.response.data.message,
                });
            }
            else {
                setSubmitting(false);
                dispatch(ProjectFailure(e.message));
                setMessageHandler({
                    hasMessage: true,
                    type: 'error',
                    message: e.message,
                });
            }
        })
    }
}


export const ProjectPrecisionAddFromFetchRequest = (project: Project, setMessageHandler: any):
    ThunkAction<Promise<void>, {}, {}, projectAction> => {
    setMessageHandler({
        hasMessage: false,
        type: '',
        message: '',
    });
    return (dispatch: ThunkDispatch<{}, {}, projectAction>) => {
        return AXIOS.post(`${Prefix.api}/admin/project/precision/api/add`, project, { headers: Headers() }).then(res => {
            if (res) {
                const { data } = res;
                const project: Project = data.result;
                dispatch(ProjectAddSuccess(project))
                setMessageHandler({
                    hasMessage: true,
                    type: 'success',
                    message: data.message,
                });
            }
        }).catch((e) => {
            if (e.response) {
                dispatch(ProjectFailure(e.response.data.message));
                setMessageHandler({
                    hasMessage: true,
                    type: 'error',
                    message: e.response.data.message,
                });
            }
            else {
                dispatch(ProjectFailure(e.message));
                setMessageHandler({
                    hasMessage: true,
                    type: 'error',
                    message: e.message,
                });
            }
        })
    }
}

export const ProjectCircleAddFromFetchRequest = (project: Project, setMessageHandler: any):
    ThunkAction<Promise<void>, {}, {}, projectAction> => {
    setMessageHandler({
        hasMessage: false,
        type: '',
        message: '',
    });
    return (dispatch: ThunkDispatch<{}, {}, projectAction>) => {
        return AXIOS.post(`${Prefix.api}/admin/project/circle/api/add`, project, { headers: Headers() }).then(res => {
            if (res) {
                const { data } = res;
                const project: Project = data.result;
                dispatch(ProjectAddSuccess(project))
                setMessageHandler({
                    hasMessage: true,
                    type: 'success',
                    message: data.message,
                });
            }
        }).catch((e) => {
            if (e.response) {
                dispatch(ProjectFailure(e.response.data.message));
                setMessageHandler({
                    hasMessage: true,
                    type: 'error',
                    message: e.response.data.message,
                });
            }
            else {
                dispatch(ProjectFailure(e.message));
                setMessageHandler({
                    hasMessage: true,
                    type: 'error',
                    message: e.message,
                });
            }
        })
    }
}
export const ProjectLogitAddFromFetchRequest = (project: Project, setMessageHandler: any):
    ThunkAction<Promise<void>, {}, {}, projectAction> => {
    setMessageHandler({
        hasMessage: false,
        type: '',
        message: '',
    });
    return (dispatch: ThunkDispatch<{}, {}, projectAction>) => {
        return AXIOS.post(`${Prefix.api}/admin/project/logit/api/add`, project, { headers: Headers() }).then(res => {
            if (res) {
                const { data } = res;
                const project: Project = data.result;
                dispatch(ProjectAddSuccess(project))
                setMessageHandler({
                    hasMessage: true,
                    type: 'success',
                    message: data.message,
                });
            }
        }).catch((e) => {
            if (e.response) {
                dispatch(ProjectFailure(e.response.data.message));
                setMessageHandler({
                    hasMessage: true,
                    type: 'error',
                    message: e.response.data.message,
                });
            }
            else {
                dispatch(ProjectFailure(e.message));
                setMessageHandler({
                    hasMessage: true,
                    type: 'error',
                    message: e.message,
                });
            }
        })
    }
}


export const ProjectTolunaAddFromFetchRequest = (project: Project, setMessageHandler: any):
    ThunkAction<Promise<void>, {}, {}, projectAction> => {
    setMessageHandler({
        hasMessage: false,
        type: '',
        message: '',
    });
    return (dispatch: ThunkDispatch<{}, {}, projectAction>) => {
        return AXIOS.post(`${Prefix.api}/admin/project/toluna/api/add`, project, { headers: Headers() }).then(res => {
            if (res) {
                const { data } = res;
                const project: Project = data.result;
                dispatch(ProjectAddSuccess(project))
                setMessageHandler({
                    hasMessage: true,
                    type: 'success',
                    message: data.message,
                });
            }
        }).catch((e) => {
            if (e.response) {
                dispatch(ProjectFailure(e.response.data.message));
                setMessageHandler({
                    hasMessage: true,
                    type: 'error',
                    message: e.response.data.message,
                });
            }
            else {
                dispatch(ProjectFailure(e.message));
                setMessageHandler({
                    hasMessage: true,
                    type: 'error',
                    message: e.message,
                });
            }
        })
    }
}




export const ProjectMrktAddFromFetchRequest = (project: Project, setMessageHandler: any):
    ThunkAction<Promise<void>, {}, {}, projectAction> => {
    setMessageHandler({
        hasMessage: false,
        type: '',
        message: '',
    });
    return (dispatch: ThunkDispatch<{}, {}, projectAction>) => {
        return AXIOS.post(`${Prefix.api}/admin/project/mrkt/api/add`, project, { headers: Headers() }).then(res => {
            if (res) {
                const { data } = res;
                const project: Project = data.result;
                dispatch(ProjectAddSuccess(project))
                setMessageHandler({
                    hasMessage: true,
                    type: 'success',
                    message: data.message,
                });
            }
        }).catch((e) => {
            if (e.response) {
                dispatch(ProjectFailure(e.response.data.message));
                setMessageHandler({
                    hasMessage: true,
                    type: 'error',
                    message: e.response.data.message,
                });
            }
            else {
                dispatch(ProjectFailure(e.message));
                setMessageHandler({
                    hasMessage: true,
                    type: 'error',
                    message: e.message,
                });
            }
        })
    }
}


export const ProjectUpdateRequest = (catid: string, project: Project, setMessageHandler: any, setSubmitting: any):
    ThunkAction<Promise<void>, {}, {}, projectAction> => {
    setMessageHandler({
        hasMessage: false,
        type: '',
        message: '',
    });
    return (dispatch: ThunkDispatch<{}, {}, projectAction>) => {
        return AXIOS.put(`${Prefix.api}/admin/project/update/${catid}`, project, { headers: Headers() }).then(res => {
            if (res) {
                const { data } = res;
                const project: Project = data.result;
                project.projectType == 2 ? dispatch(ProjectChildUpdateSuccess(project)) : dispatch(ProjectUpdateSuccess(project))
                setSubmitting(false);
                setMessageHandler({
                    hasMessage: true,
                    type: 'success',
                    message: data.message,
                });
            }
        }).catch((e) => {
            if (e.response) {
                setSubmitting(false);
                setMessageHandler({
                    hasMessage: true,
                    type: 'error',
                    message: e.response.data.message,
                });
                dispatch(ProjectFailure(e.response.data.message));
            }
            else {
                setSubmitting(false);
                setMessageHandler({
                    hasMessage: true,
                    type: 'error',
                    message: e.message,
                });
                dispatch(ProjectFailure(e.message));
            }
        })
    }
}


export const ProjectUpdateByCodeRequest = (catid: string, project: Project, setMessageHandler: any, setSubmitting: any,setDialogProjectClose:any):
    ThunkAction<Promise<void>, {}, {}, projectAction> => {
    setMessageHandler({
        hasMessage: false,
        type: '',
        message: '',
    });
    return (dispatch: ThunkDispatch<{}, {}, projectAction>) => {
        return AXIOS.put(`${Prefix.api}/admin/project/code/update/${catid}`, project, { headers: Headers() }).then(res => {
            if (res) {
                const { data } = res;
                const project: Project = data.result;
                project.projectType == 2 ? dispatch(ProjectChildUpdateSuccess(project)) : dispatch(ProjectUpdateSuccess(project))
                setSubmitting(false);
                setMessageHandler({
                    hasMessage: true,
                    type: 'success',
                    message: data.message,
                });
                setDialogProjectClose('');
            }
        }).catch((e) => {
            if (e.response) {
                setSubmitting(false);
                setMessageHandler({
                    hasMessage: true,
                    type: 'error',
                    message: e.response.data.message,
                });
                dispatch(ProjectFailure(e.response.data.message));
            }
            else {
                setSubmitting(false);
                setMessageHandler({
                    hasMessage: true,
                    type: 'error',
                    message: e.message,
                });
                dispatch(ProjectFailure(e.message));
            }
        })
    }
}
export const ProjectMoveToInvoice = (catid: string, project: any, setMessageHandler: any):
    ThunkAction<Promise<void>, {}, {}, projectAction> => {
    setMessageHandler({
        hasMessage: false,
        type: '',
        message: '',
    });
    return (dispatch: ThunkDispatch<{}, {}, projectAction>) => {
        return AXIOS.put(`${Prefix.api}/admin/project/reconsile/update/${catid}`, project, { headers: Headers() }).then(res => {
            if (res) {
                const { data } = res;
                const project: Project = data.result;
                 dispatch(ProjectUpdateSuccess(project))
                 setMessageHandler({
                    hasMessage: true,
                    type: 'success',
                    message: data.message,
                 });
            }
        }).catch((e) => {
            if (e.response) {
                setMessageHandler({
                    hasMessage: true,
                    type: 'error',
                    message: e.response.data.message,
                });
                dispatch(ProjectFailure(e.response.data.message));
            }
            else {
                setMessageHandler({
                    hasMessage: true,
                    type: 'error',
                    message: e.message,
                });
                dispatch(ProjectFailure(e.message));
            }
        })
    }
}



export const ProjectStatusUpdateRequest = (projectid: string, project: Project, setMessageHandler: any):
    ThunkAction<Promise<void>, {}, {}, projectAction> => {
    setMessageHandler({
        hasMessage: false,
        type: '',
        message: '',
    });
    return (dispatch: ThunkDispatch<{}, {}, projectAction>) => {
        return AXIOS.put(`${Prefix.api}/admin/project/update/${projectid}`, project, { headers: Headers() }).then(res => {
            if (res) {
                const { data } = res;
                const project: Project = data.result;
                dispatch(ProjectUpdateSuccess(project))
                setMessageHandler({
                    hasMessage: true,
                    type: 'success',
                    message: data.message,
                });
            }
        }).catch((e) => {
            if (e.response) {
                setMessageHandler({
                    hasMessage: true,
                    type: 'error',
                    message: e.response.data.message,
                });
                dispatch(ProjectFailure(e.response.data.message));
            }
            else {
                setMessageHandler({
                    hasMessage: true,
                    type: 'error',
                    message: e.message,
                });
                dispatch(ProjectFailure(e.message));
            }
        })
    }
}


export const ProjectReconsileData = (projectid: string, body: any, setMessageHandler: any):
    ThunkAction<Promise<void>, {}, {}, projectAction> => {
    setMessageHandler({
        hasMessage: false,
        type: '',
        message: '',
    });
    return (dispatch: ThunkDispatch<{}, {}, projectAction>) => {
        return AXIOS.post(`${Prefix.api}/admin/project/reconsile/${projectid}`, body, { headers: Headers() }).then(res => {
            if (res) {
                const { data } = res;
                const project: Project = data.result;
                dispatch(ProjectUpdateSuccess(project))
                setMessageHandler({
                    hasMessage: true,
                    type: 'success',
                    message: data.message,
                });
            }
        }).catch((e) => {
            if (e.response) {
                setMessageHandler({
                    hasMessage: true,
                    type: 'error',
                    message: e.response.data.message,
                });
                dispatch(ProjectFailure(e.response.data.message));
            }
            else {
                setMessageHandler({
                    hasMessage: true,
                    type: 'error',
                    message: e.message,
                });
                dispatch(ProjectFailure(e.message));
            }
        })
    }
}


export const ProjectReconsileUndoData = (projectid: string, setMessageHandler: any):
    ThunkAction<Promise<void>, {}, {}, projectAction> => {
    setMessageHandler({
        hasMessage: false,
        type: '',
        message: '',
    });
    return (dispatch: ThunkDispatch<{}, {}, projectAction>) => {
        return AXIOS.post(`${Prefix.api}/admin/project/reconsile/undo/${projectid}`, {}, { headers: Headers() }).then(res => {
            if (res) {
                const { data } = res;
                const project: Project = data.result;
                dispatch(ProjectUpdateSuccess(project))
                setMessageHandler({
                    hasMessage: true,
                    type: 'success',
                    message: data.message,
                });
            }
        }).catch((e) => {
            if (e.response) {
                setMessageHandler({
                    hasMessage: true,
                    type: 'error',
                    message: e.response.data.message,
                });
                dispatch(ProjectFailure(e.response.data.message));
            }
            else {
                setMessageHandler({
                    hasMessage: true,
                    type: 'error',
                    message: e.message,
                });
                dispatch(ProjectFailure(e.message));
            }
        })
    }
}




export const ProjectSingleRequest = (catid: string):
    ThunkAction<Promise<void>, {}, {}, projectAction> => {
    return (dispatch: ThunkDispatch<{}, {}, projectAction>) => {
        return AXIOS.get(`${Prefix.api}/admin/project/${catid}`, { headers: Headers() }).then(res => {
            if (res) {
                const { data } = res;
                const project: Project = data.result;
                dispatch(ProjectSingleSuccess(project));
            }
        }).catch((e) => {
            if (e.response) {
                dispatch(ProjectFailure(e.response.data.message));
            }
            else {
                dispatch(ProjectFailure(e.message));
            }
        })
    }
}

export const ProjectPreScreenRequest = (body: any):
    ThunkAction<Promise<void>, {}, {}, projectAction> => {
    return (dispatch: ThunkDispatch<{}, {}, projectAction>) => {
        return AXIOS.post(`${Prefix.api}/admin/project/screen`,body).then(res => {
            if (res) {
                const { data } = res;
                const project: Project = data.result;
                dispatch(ProjectSingleSuccess(project));
            }
        }).catch((e) => {
            if (e.response) {
                dispatch(ProjectFailure(e.response.data.message));
            }
            else {
                dispatch(ProjectFailure(e.message));
            }
        })
    }
}


export const ProjectDeleteRequest = (id: string, body: Project, setMessageHandler: any):
    ThunkAction<Promise<void>, {}, {}, projectAction> => {
    setMessageHandler({
        hasMessage: false,
        type: '',
        message: '',
    });
    return (dispatch: ThunkDispatch<{}, {}, projectAction>) => {
        return AXIOS.put(`${Prefix.api}/admin/project/update/${id}`, body, { headers: Headers() }).then(res => {
            if (res) {
                const { data } = res;
                const project: Project = data.result;
                dispatch(ProjectDeleteSuccess(project))
                setMessageHandler({
                    hasMessage: true,
                    type: 'success',
                    message: data.message,
                });
            }
        }).catch((e) => {
            if (e.response) {
                dispatch(ProjectFailure(e.response.data.message));
                setMessageHandler({
                    hasMessage: true,
                    type: 'error',
                    message: e.response.data.message,
                });
            }
            else {
                dispatch(ProjectFailure(e.message));
                setMessageHandler({
                    hasMessage: true,
                    type: 'error',
                    message: e.message,
                });
            }
        })
    }
}

export const ProjectValidatePreScreen = ( body: any,setMessageHandler:any):
    ThunkAction<Promise<void>, {}, {}, projectAction> => {
        setMessageHandler({
            hasMessage: false,
            type: '',
            message: '',
        });
    return (dispatch: ThunkDispatch<{}, {}, projectAction>) => {
        return AXIOS.post(`${Prefix.api}/admin/project/validateprescreen`, body, { headers: Headers() }).then(res => {
            if (res) {
                const { data } = res;
                const project: Project = data.result;
                dispatch(ProjectPreScreenSuccess(project))
            }
        }).catch((e) => {
            if (e.response) {
                dispatch(ProjectFailure(e.response.data.message));
                setMessageHandler({
                    hasMessage: true,
                    type: 'error',
                    message: e.response.data.message,
                });
            }
            else {
                dispatch(ProjectFailure(e.message));
                setMessageHandler({
                    hasMessage: true,
                    type: 'error',
                    message: e.message,
                });
            }
        })
    }
}

export const ProjectValidateResponse = ( body: any):
    ThunkAction<Promise<void>, {}, {}, projectAction> => {
    return (dispatch: ThunkDispatch<{}, {}, projectAction>) => {
        return AXIOS.post(`${Prefix.api}/admin/project/validate/response`, body, { headers: Headers() }).then(res => {
            if (res) {
                const { data } = res;
                const project: Project = data.result;
                dispatch(ProjectPreScreenSuccess(project))
            }
        }).catch((e) => {
            if (e.response) {
                dispatch(ProjectFailure(e.response.data.message));
            }
            else {
                dispatch(ProjectFailure(e.message));
            }
        })
    }
}

export const ProjectExportRequest = ( body: any ,fileName:string ):
    ThunkAction<Promise<void>, {}, {}, projectAction> => {
    return (dispatch: ThunkDispatch<{}, {}, projectAction>) => {
        return AXIOS.post(`${Prefix.api}/admin/project/child/report`, body, { responseType: 'blob', headers: Headers() }).then(res => {
            if (res) {
                const { data } = res;
                fileDownload(data, `${fileName}.csv`);
            }
        }).catch((e) => {
            if (e.response) {
                dispatch(ProjectFailure(e.response.data.message));
            }
            else {
                dispatch(ProjectFailure(e.message));
            }
        })
    }
}



export const SupplierProjectExportRequest = ( body: any ,fileName:string ):
    ThunkAction<Promise<void>, {}, {}, projectAction> => {
    return (dispatch: ThunkDispatch<{}, {}, projectAction>) => {
        return AXIOS.post(`${Prefix.api}/admin/project/supplier/report`, body, { responseType: 'blob', headers: Headers() }).then(res => {
            if (res) {
                const { data } = res;
                fileDownload(data, `${fileName}.csv`);
            }
        }).catch((e) => {
            if (e.response) {
                dispatch(ProjectFailure(e.response.data.message));
            }
            else {
                dispatch(ProjectFailure(e.message));
            }
        })
    }
}

export const ProjectGroupExportRequest = ( body: any ,fileName:string ):
    ThunkAction<Promise<void>, {}, {}, projectAction> => {
    return (dispatch: ThunkDispatch<{}, {}, projectAction>) => {
        return AXIOS.post(`${Prefix.api}/admin/project/group/report`, body, { responseType: 'blob', headers: Headers() }).then(res => {
            if (res) {
                const { data } = res;
                fileDownload(data, `${fileName}.csv`);
            }
        }).catch((e) => {
            if (e.response) {
                dispatch(ProjectFailure(e.response.data.message));
            }
            else {
                dispatch(ProjectFailure(e.message));
            }
        })
    }
}

export const ProjectPrescreenExportRequest = ( body: any ,fileName:string ):
    ThunkAction<Promise<void>, {}, {}, projectAction> => {
    return (dispatch: ThunkDispatch<{}, {}, projectAction>) => {
        return AXIOS.post(`${Prefix.api}/admin/project/prescreen/report`, body, { responseType: 'blob', headers: Headers() }).then(res => {
            if (res) {
                const { data } = res;
                fileDownload(data, `${fileName}.csv`);
            }
        }).catch((e) => {
            if (e.response) {
                dispatch(ProjectFailure(e.response.data.message));
            }
            else {
                dispatch(ProjectFailure(e.message));
            }
        })
    }
}

export const ProjectGroupPrescreenExportRequest = ( body: any ,fileName:string,  setDialog:any ):
    ThunkAction<Promise<void>, {}, {}, projectAction> => {
    return (dispatch: ThunkDispatch<{}, {}, projectAction>) => {
        return AXIOS.post(`${Prefix.api}/admin/project/group/prescreen/report`, body, { responseType: 'blob', headers: Headers() }).then(res => {
            if (res) {
                const { data } = res;
                fileDownload(data, `${fileName}.csv`);
                setDialog('')
            }
        }).catch((e) => {
            if (e.response) {
                dispatch(ProjectFailure(e.response.data.message));
            }
            else {
                dispatch(ProjectFailure(e.message));
            }
        })
    }
}

export const ProjectGroupSupplierMapExportRequest = ( body: any ,fileName:string ):
    ThunkAction<Promise<void>, {}, {}, projectAction> => {
    return (dispatch: ThunkDispatch<{}, {}, projectAction>) => {
        return AXIOS.post(`${Prefix.api}/admin/project/suppliermap/report`, body, { responseType: 'blob', headers: Headers() }).then(res => {
            if (res) {
                const { data } = res;
                fileDownload(data, `${fileName}.csv`);
            }
        }).catch((e) => {
            if (e.response) {
                dispatch(ProjectFailure(e.response.data.message));
            }
            else {
                dispatch(ProjectFailure(e.message));
            }
        })
    }
}




export const ProjectDisqualifyExportRequest = ( body: any ):
    ThunkAction<Promise<void>, {}, {}, projectAction> => {
    return (dispatch: ThunkDispatch<{}, {}, projectAction>) => {
        return AXIOS.post(`${Prefix.api}/admin/project/disqualify/report`, body, { responseType: 'blob', headers: Headers() }).then(res => {
            if (res) {
                const { data } = res;
                fileDownload(data, `project.csv`);
            }
        }).catch((e) => {
            if (e.response) {
                dispatch(ProjectFailure(e.response.data.message));
            }
            else {
                dispatch(ProjectFailure(e.message));
            }
        })
    }
}

export const ProjectRequestByClient = ( body: any ):
    ThunkAction<Promise<void>, {}, {}, projectAction> => {
    return (dispatch: ThunkDispatch<{}, {}, projectAction>) => {
        return AXIOS.post(`${Prefix.api}/admin/project/disqualify/report`, body, { responseType: 'blob', headers: Headers() }).then(res => {
            if (res) {
                const { data } = res;
                fileDownload(data, `project.csv`);
            }
        }).catch((e) => {
            if (e.response) {
                dispatch(ProjectFailure(e.response.data.message));
            }
            else {
                dispatch(ProjectFailure(e.message));
            }
        })
    }
}




export const ProjectSupplierAllRequest = (suppid:any ,body:any):
    ThunkAction<Promise<void>, {}, {}, projectAction> => {
    return (dispatch: ThunkDispatch<{}, {}, projectAction>) => {
        dispatch({ type:projectActionTypes.PROJECT_REQUEST,payload: { data:[], loading:true} });
        return AXIOS.post(`${Prefix.api}/admin/project/reconsile/supplier/${suppid}`, body, { headers: Headers() }).then(res => {
            if (res) {
                const { data } = res;
                const projects: ProjectList = data.result
                dispatch(ProjectSuccess(projects))
            }
        }).catch((e) => {
            if (e.response) {
                dispatch(ProjectFailure(e.response.data.message));
            }
            else {
                dispatch(ProjectFailure(e.message));
            }
        })
    }
}


export const IdentifierExportRequest = ( body: any ,setLoading:any ):
    ThunkAction<Promise<void>, {}, {}, projectAction> => {
        setLoading(true);
    return (dispatch: ThunkDispatch<{}, {}, projectAction>) => {
        return AXIOS.post(`${Prefix.api}/admin/identifiers/report`, body, { responseType: 'blob', headers: Headers() }).then(res => {
            if (res) {
                setLoading(false);
                const { data } = res;
                fileDownload(data, `matched IDs.csv`);
            }
        }).catch((e) => {
            if (e.response) {
                setLoading(false);
                dispatch(ProjectFailure(e.response.data.message));
            }
            else {
                setLoading(false);
                dispatch(ProjectFailure(e.message));
            }
        })
    }
}

export const ProjectIdentifierExportRequest = ( body: any ,setLoading:any ):
    ThunkAction<Promise<void>, {}, {}, projectAction> => {
        setLoading(true);
    return (dispatch: ThunkDispatch<{}, {}, projectAction>) => {
        return AXIOS.post(`${Prefix.api}/admin/project/identifiers/report`, body, { responseType: 'blob', headers: Headers() }).then(res => {
            if (res) {
                setLoading(false);
                const { data } = res;
                fileDownload(data, `data.csv`);
            }
        }).catch((e) => {
            if (e.response) {
                setLoading(false);
                dispatch(ProjectFailure(e.response.data.message));
            }
            else {
                setLoading(false);
                dispatch(ProjectFailure(e.message));
            }
        })
    }
}

export const ProjectInvoiceExportRequest = ( body: any ,setLoading:any ):
    ThunkAction<Promise<void>, {}, {}, projectAction> => {
        setLoading(true);
    return (dispatch: ThunkDispatch<{}, {}, projectAction>) => {
        return AXIOS.post(`${Prefix.api}/admin/project/invoice/report`, body, { responseType: 'blob', headers: Headers() }).then(res => {
            if (res) {
                setLoading(false);
                const { data } = res;
                fileDownload(data, `data.csv`);
            }
        }).catch((e) => {
            if (e.response) {
                setLoading(false);
                dispatch(ProjectFailure(e.response.data.message));
            }
            else {
                setLoading(false);
                dispatch(ProjectFailure(e.message));
            }
        })
    }
}


export const ProjectClientCustomExportRequest = ( body: any ,setLoading:any ):
    ThunkAction<Promise<void>, {}, {}, projectAction> => {
        setLoading(true);
    return (dispatch: ThunkDispatch<{}, {}, projectAction>) => {
        return AXIOS.post(`${Prefix.api}/admin/project/client/custom/report`, body, { responseType: 'blob', headers: Headers() }).then(res => {
            if (res) {
                setLoading(false);
                const { data } = res;
                fileDownload(data, `data.csv`);
            }
        }).catch((e) => {
            if (e.response) {
                setLoading(false);
                dispatch(ProjectFailure(e.response.data.message));
            }
            else {
                setLoading(false);
                dispatch(ProjectFailure(e.message));
            }
        })
    }
}

export const ProjectBidCustomExportRequest = ( body: any ,setLoading:any ):
    ThunkAction<Promise<void>, {}, {}, projectAction> => {
        setLoading(true);
    return (dispatch: ThunkDispatch<{}, {}, projectAction>) => {
        return AXIOS.post(`${Prefix.api}/admin/project/bid/custom/report`, body, { responseType: 'blob', headers: Headers() }).then(res => {
            if (res) {
                setLoading(false);
                const { data } = res;
                fileDownload(data, `data.csv`);
            }
        }).catch((e) => {
            if (e.response) {
                setLoading(false);
                dispatch(ProjectFailure(e.response.data.message));
            }
            else {
                setLoading(false);
                dispatch(ProjectFailure(e.message));
            }
        })
    }
}








