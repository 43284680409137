import { bidActionTypes } from "src/types/actionTypes";
import { Bid, BidList } from "src/types/bidTypes";
import { ErrorAction, SuccessAction } from "src/types/responseTypes";

export type bidAction = SuccessAction<BidList> | SuccessAction<Bid> | SuccessAction<any> | ErrorAction
export const defaultBidState: BidList = { loading:true,contacts:[], redirectTo: '',bids:[], bid: {}, data: [], metadata: {} };

const reducer = (state: BidList = defaultBidState, action: bidAction): BidList => {
    switch (action.type) {
        case bidActionTypes.BID_REQUEST:
            state = { ...state,loading: true }
            return state
        case bidActionTypes.FETCH_BID_SUCCESS:
            action = <SuccessAction<BidList>>action
            state = { ...state,loading: false, redirectTo: '', data: action.payload.data, metadata: action.payload.metadata }
            return state
        case bidActionTypes.FETCH_BID_ALL_SUCCESS:
            action = <SuccessAction<BidList>>action
            state = { ...state,loading: false, bids: action.payload }
            return state
        case bidActionTypes.FETCH_BID_CONTACT_ALL_SUCCESS:
            action = <SuccessAction<BidList>>action
            state = { ...state,loading: false, contacts: action.payload.contacts }
            return state
        case bidActionTypes.UPDATE_BID_SUCCESS:
            action = <SuccessAction<any>>action
            state = { ...state,loading: false, redirectTo: '/admin/bid', data: state.data?.map(dat => [ dat._id ==action.payload._id && Object.assign(dat ,action.payload)].find(o => o._id == dat._id) || dat) }
            return state
        case bidActionTypes.FETCH_SINGLE_BID_SUCCESS:
            action = <SuccessAction<Bid>>action
            state = { ...state,loading: false, bid: action.payload }
            return state
        case bidActionTypes.DELETE_BID_SUCCESS:
            action = <SuccessAction<any>>action
            state = { ...state,loading: false, data: state.data?.filter(dat => dat._id !== action.payload._id), metadata: { ...state.metadata, limit: Number(state.metadata?.limit) - 1, total: Number(state.metadata?.total) - 1 } }
            return state
        case bidActionTypes.ADD_BID_SUCCESS:
            action = <SuccessAction<Bid>>action
            state = { ...state,loading: false, redirectTo: '/admin/bid', data: state.data?.concat(action.payload) }
            return state
        case bidActionTypes.BID_FAILURE:
            action = <ErrorAction>action
            return { ...state ,loading: false, }
        default:
            return state
    }
}
export default reducer