import {  Container } from '@mui/material';
import { useDispatch } from 'react-redux';
import MessageContext from 'src/context/MessageContext';
import { useContext } from 'react';
import TopBarForm from 'src/components/shared/TopBarForm';
import { SupplierRuleForm } from 'src/components/admin/_dashboard/service/SupplierRule';
import { SupplierRuleAddRequest } from 'src/actions/supplierRuleActions';
import { SupplierRule } from 'src/types/supplierRuleTypes';

export default function AddSupplierRule() {
    const { setMessageHandler } = useContext<any>(MessageContext);
    const dispatch = useDispatch();
    const initialValues: any = null;

    const submitForm = async (values: SupplierRule, setSubmitting: any) => {
        setTimeout(() => dispatch(SupplierRuleAddRequest(values, setMessageHandler, setSubmitting)), 200);
    }

    return (
        <Container maxWidth={false}>
            <TopBarForm title="Add Supplier Rule"  link="/admin/library/rule"/>
            <SupplierRuleForm initialValues={initialValues} submitForm={submitForm} />
        </Container>
    );
}
