import { FieldArray } from 'formik'
import { styled } from '@mui/material/styles';
import { Grid, IconButton, TextField, Checkbox, FormControl, FormLabel, FormGroup, FormControlLabel } from '@mui/material';
import { Remove as RemoveIcon, Add as AddIcon } from "@mui/icons-material";
import { QuestionOption } from 'src/types/surveyTypes';

const TextFieldStyle = styled(TextField)(({ }) => ({
    marginTop: 0
}));

const QuestionOptionRender = ({ formikProps, icon }: any) => {
    return (
        <FieldArray name="questionOption" >
            {({ push, remove }) => (
                <div>
                    {formikProps.values.questionOption.length > 0 &&
                        formikProps.values.questionOption.map((field: QuestionOption, index: number) => (
                            <Grid key={index} container rowSpacing={0} spacing={2}>
                                <Grid item xs={9} sm={9} md={9} display="flex" justifyContent="center" alignItems="center">
                                    {icon}
                                    <TextFieldStyle
                                        fullWidth
                                        label="Add Option"
                                        margin="normal"
                                        value={field.title}
                                        error={Boolean(formikProps.touched.questionOption && formikProps.touched.questionOption[index] && formikProps.touched.questionOption[index].title && formikProps.errors.questionOption && formikProps.errors.questionOption[index] && formikProps.errors.questionOption[index].title)}
                                        helperText={formikProps.touched.questionOption && formikProps.touched.questionOption[index] && formikProps.touched.questionOption[index].title && formikProps.errors.questionOption && formikProps.errors.questionOption[index] && formikProps.errors.questionOption[index].title}
                                        name={`questionOption.${index}.title`}
                                        onBlur={formikProps.handleBlur}
                                        onChange={formikProps.handleChange}
                                        type="text"
                                        variant="standard"
                                    />
                                </Grid>
                                <Grid item xs={1} sm={1} md={0.5} display="flex" justifyContent="center" alignItems="center">
                                    <FormControl component="fieldset">
                                        <FormLabel component="legend" sx={{ fontSize: 13, textAlign: 'center' }}>Enable</FormLabel>
                                        <FormGroup aria-label="position" row>
                                            <FormControlLabel
                                                sx={{ fontSize: 1 }}
                                                value="top"
                                                control={<Checkbox name={`questionOption.${index}.enable`} checked={field.enable} onChange={formikProps.handleChange} value={field.enable} />}
                                                label=""
                                                labelPlacement="top"
                                            />
                                        </FormGroup>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={1} sm={1} md={0.5} display="flex" justifyContent="center" alignItems="center">
                                    <FormControl component="fieldset">
                                        <FormLabel component="legend" sx={{ fontSize: 13, textAlign: 'center' }}>Validate</FormLabel>
                                        <FormGroup aria-label="position" row>
                                            <FormControlLabel
                                                sx={{ fontSize: 1 }}
                                                value="top"
                                                control={<Checkbox name={`questionOption.${index}.formvalidate`} checked={field.formvalidate} onChange={formikProps.handleChange} value={field.formvalidate} />}
                                                label=""
                                                labelPlacement="top"
                                            />
                                        </FormGroup>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={1} sm={1} md={0.5}>
                                    <FormControl component="fieldset">
                                        <FormLabel component="legend" sx={{ fontSize: 13, textAlign: 'center' }}>DIY</FormLabel>
                                        <FormGroup aria-label="position" row>
                                            <FormControlLabel
                                                sx={{ fontSize: 1 }}
                                                value="top"
                                                control={<Checkbox name={`questionOption.${index}.diy`} checked={field.diy} onChange={formikProps.handleChange} value={field.diy} />}
                                                label=""
                                                labelPlacement="top"
                                            />
                                        </FormGroup>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={1} sm={1} md={1} display="flex" justifyContent="center" alignItems="center">
                                    <FormControl component="fieldset">
                                        <FormLabel component="legend" sx={{ fontSize: 13, textAlign: 'center' }}>Local</FormLabel>
                                        <FormGroup aria-label="position" row>
                                            <FormControlLabel
                                                sx={{ fontSize: 1 }}
                                                value="top"
                                                control={<Checkbox name={`questionOption.${index}.local`} checked={field.local} onChange={formikProps.handleChange} value={field.local} />}
                                                label=""
                                                labelPlacement="top"
                                            />
                                        </FormGroup>
                                    </FormControl>
                                </Grid>
                                <Grid display="flex" justifyContent="center" alignItems="center" item xs={1} sm={1} md={0.5}>
                                    <IconButton onClick={() => push({ title: '',diy:true,local:true, enable: true, formvalidate: true, quota: 0 })} autoFocus color="primary">
                                        <AddIcon />
                                    </IconButton>
                                    <IconButton disabled={formikProps.values.questionOption.length < 3} onClick={() => remove(index)} autoFocus color="primary">
                                        <RemoveIcon />
                                    </IconButton>
                                </Grid>
                            </Grid>
                        ))}
                </div>
            )}
        </FieldArray>
    );
};

export default QuestionOptionRender;
