import {  Container  } from '@mui/material';
import { useDispatch } from 'react-redux';
import MessageContext from 'src/context/MessageContext';
import { useContext, useEffect } from 'react';
import { ProjectAddRequest, ProjectSingleRequest } from 'src/actions/projectActions';
import ChildProjectForm from 'src/components/admin/_dashboard/project/ChildProjectForm';
import {  useParams } from 'react-router-dom';
import { Project } from 'src/types/projectTypes';
import TopBarForm from 'src/components/shared/TopBarForm';
import { useSelector } from 'src/reducers';

export default function AddChildProject() {

    const { projectid }: any = useParams<any>();
    const { setMessageHandler } = useContext<any>(MessageContext);
    const dispatch = useDispatch();

    const { project } = useSelector(state => state.project);

    const initialValues: Project = { parentProjectId: project?._id ,clientId: project?.clientId };

    const submitForm = async (values: Project, setSubmitting: any) => {
        setTimeout(() => dispatch(ProjectAddRequest(values, setMessageHandler, setSubmitting)), 200);
    }
    
    useEffect(()=>{
        if(projectid) dispatch(ProjectSingleRequest(projectid));
    },[projectid]);

    return (
        <Container maxWidth={false}>
             <TopBarForm title="Add Child Project"  link={`/admin/project/${projectid}/childs`} />
             {initialValues.parentProjectId && <ChildProjectForm initialValues={initialValues} submitForm={submitForm} />}
        </Container>
    );
}
