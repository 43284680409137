import { useContext } from 'react';
import { Container } from '@mui/material';
import { useDispatch } from 'react-redux';
import { ProjectForm } from 'src/components/admin/_dashboard/project';
import {  useParams } from 'react-router-dom';
import { useEffect } from 'react';
import { useSelector } from 'src/reducers';
import MessageContext from 'src/context/MessageContext';
import TopBarForm from 'src/components/shared/TopBarForm';
import { InvoiceSingleRequest, InvoiceUpdateRequest } from 'src/actions/invoiceActions';
import { Invoice } from 'src/types/invoiceTypes';
import InvoiceStatusForm from 'src/components/admin/_dashboard/invoice/InvoiceStatusForm';

export default function EditInvoiceStatus() {

    const { setMessageHandler } = useContext<any>(MessageContext);
    const dispatch = useDispatch();
    const { invoiceid }: any = useParams<any>();
    const submitForm = async (values: Invoice, setSubmitting: any) => {
        setTimeout(() => dispatch(InvoiceUpdateRequest(invoiceid, values, setMessageHandler, setSubmitting)), 200);
    }
    const { invoice } = useSelector(state => state.invoice);
    const initialValues: any = invoice || null;

    useEffect(() => {
        dispatch(InvoiceSingleRequest(invoiceid));
    }, [invoiceid])

    return (
        <Container maxWidth={false}>
            <TopBarForm title="Edit Invoice" link="/admin/invoice/list" />
            <InvoiceStatusForm submitForm={submitForm} initialValues={initialValues} />
        </Container>
    );
}
