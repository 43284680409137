import { ThunkAction, ThunkDispatch } from "redux-thunk";
import Prefix from 'src/helpers/ApiPrefix';
import AXIOS from 'src/helpers/Axios';
import Headers from 'src/helpers/Headers';
import { userAction } from "src/reducers/userReducer";
import { userActionTypes } from "src/types/actionTypes";
import { adminInfoProps } from "src/types/adminTypes";
import { ErrorAction, SuccessAction } from "src/types/responseTypes";
import {  UserList } from "src/types/userTypes";

export const UserFailure = (message: string): ErrorAction => {
    return {
        type: userActionTypes.USER_REQUEST,
        payload: { message: message }
    }
}
export const UserSuccess = (payload: UserList): SuccessAction<UserList> => {
    return {
        type: userActionTypes.FETCH_USER_SUCCESS,
        payload: payload,
    }
}

export const UserSuccessAll = (payload: UserList): SuccessAction<UserList> => {
    return {
        type: userActionTypes.FETCH_USER_SUCCESS_ALL,
        payload: payload,
    }
}

export const UserAddSuccess = (payload: adminInfoProps): SuccessAction<adminInfoProps> => {
    return {
        type: userActionTypes.ADD_USER_SUCCESS,
        payload: payload,
    }
}
export const UserSingleSuccess = (payload: adminInfoProps): SuccessAction<adminInfoProps> => {
    return {
        type: userActionTypes.FETCH_SINGLE_USER_SUCCESS,
        payload: payload,
    }
}

export const UserUpdateSuccess = (payload: any): SuccessAction<any> => {
    return {
        type: userActionTypes.UPDATE_USER_SUCCESS,
        payload: payload,
    }
}


export const UserDeleteSuccess = (payload: any): SuccessAction<any> => {
    return {
        type: userActionTypes.DELETE_USER_SUCCESS,
        payload: payload,
    }
}


export const UserAllRequest = (filter: any, search: string, pageNo: number, limit: number, status: boolean):
    ThunkAction<Promise<void>, {}, {}, userAction> => {
    return (dispatch: ThunkDispatch<{}, {}, userAction>) => {
        dispatch({ type:userActionTypes.USER_REQUEST,payload: { loading:true} });
        return AXIOS.post(`${Prefix.api}/admin/users`, { filter, search, pageNo, limit, status }, { headers: Headers() }).then(res => {
            if (res) {
                const { data } = res;
                const users: UserList = data.result
                dispatch(UserSuccess(users))

            }
        }).catch((e) => {
            if (e.response) {
                dispatch(UserFailure(e.response.data.message));
            }
            else {
                dispatch(UserFailure(e.message));
            }
        })
    }
}

export const UserAllSupplierRequest = (filter: any, search: string, pageNo: number, limit: number, status: boolean):
    ThunkAction<Promise<void>, {}, {}, userAction> => {
    return (dispatch: ThunkDispatch<{}, {}, userAction>) => {
        dispatch({ type:userActionTypes.USER_REQUEST,payload: { loading:true} });
        return AXIOS.post(`${Prefix.api}/admin/users/supplier`, { filter, search, pageNo, limit, status }, { headers: Headers() }).then(res => {
            if (res) {
                const { data } = res;
                const users: UserList = data.result
                dispatch(UserSuccess(users))

            }
        }).catch((e) => {
            if (e.response) {
                dispatch(UserFailure(e.response.data.message));
            }
            else {
                dispatch(UserFailure(e.message));
            }
        })
    }
}


export const UserAllRequestDataAdmin = ():
    ThunkAction<Promise<void>, {}, {}, userAction> => {
    return (dispatch: ThunkDispatch<{}, {}, userAction>) => {
        return AXIOS.get(`${Prefix.api}/admin/users/all`, { headers: Headers() }).then(res => {
            if (res) {
                const { data } = res;
                const users: UserList = data.result
                dispatch(UserSuccessAll(users))

            }
        }).catch((e) => {
            if (e.response) {
                dispatch(UserFailure(e.response.data.message));
            }
            else {
                dispatch(UserFailure(e.message));
            }
        })
    }
}
export const UserAllRequestData = ():
    ThunkAction<Promise<void>, {}, {}, userAction> => {
    return (dispatch: ThunkDispatch<{}, {}, userAction>) => {
        return AXIOS.get(`${Prefix.api}/admin/usersall`, { headers: Headers() }).then(res => {
            if (res) {
                const { data } = res;
                const users: UserList = data.result
                dispatch(UserSuccessAll(users))

            }
        }).catch((e) => {
            if (e.response) {
                dispatch(UserFailure(e.response.data.message));
            }
            else {
                dispatch(UserFailure(e.message));
            }
        })
    }
}


export const UserAllRequestBdeData = ():
    ThunkAction<Promise<void>, {}, {}, userAction> => {
    return (dispatch: ThunkDispatch<{}, {}, userAction>) => {
        return AXIOS.get(`${Prefix.api}/admin/users/role/bde`, { headers: Headers() }).then(res => {
            if (res) {
                const { data } = res;
                const users: UserList = data.result
                dispatch(UserSuccessAll(users))
            }
        }).catch((e) => {
            if (e.response) {
                dispatch(UserFailure(e.response.data.message));
            }
            else {
                dispatch(UserFailure(e.message));
            }
        })
    }
}




export const UserAddRequest = (user: adminInfoProps, setMessageHandler: any, setSubmitting: any):
    ThunkAction<Promise<void>, {}, {}, userAction> => {
    setMessageHandler({
        hasMessage: false,
        type: '',
        message: '',
    });
    return (dispatch: ThunkDispatch<{}, {}, userAction>) => {
        return AXIOS.post(`${Prefix.api}/admin/user/add`, user, { headers: Headers() }).then(res => {
            if (res) {
                const { data } = res;
                const user: adminInfoProps = data.result;
                dispatch(UserAddSuccess(user))
                setSubmitting(false);
                setMessageHandler({
                    hasMessage: true,
                    type: 'success',
                    message: data.message,
                });
            }
        }).catch((e) => {
            if (e.response) {
                setSubmitting(false);
                dispatch(UserFailure(e.response.data.message));
                setMessageHandler({
                    hasMessage: true,
                    type: 'success',
                    message: e.response.data.message,
                });
            }
            else {
                setSubmitting(false);
                dispatch(UserFailure(e.message));
                setMessageHandler({
                    hasMessage: true,
                    type: 'success',
                    message: e.message,
                });
            }
        })
    }
}


export const UserUpdateRequest = (catid: string, user: adminInfoProps, setMessageHandler:any, setSubmitting: any):
    ThunkAction<Promise<void>, {}, {}, userAction> => {
    setMessageHandler({
        hasMessage: false,
        type: '',
        message: '',
    });
    return (dispatch: ThunkDispatch<{}, {}, userAction>) => {
        return AXIOS.put(`${Prefix.api}/admin/user/update/${catid}`, user, { headers: Headers() }).then(res => {
            if (res) {
                const { data } = res;
                const user: adminInfoProps = data.result;
                dispatch(UserUpdateSuccess(user))
                setSubmitting(false);
                setMessageHandler({
                    hasMessage: true,
                    type: 'success',
                    message: data.message,
                });
            }
        }).catch((e) => {
            if (e.response) {
                setSubmitting(false);
                dispatch(UserFailure(e.response.data.message));
                setMessageHandler({
                    hasMessage: true,
                    type: 'success',
                    message: e.response.data.message,
                });
            }
            else {
                setSubmitting(false);
                dispatch(UserFailure(e.message));
                setMessageHandler({
                    hasMessage: true,
                    type: 'success',
                    message: e.message,
                });
            }
        })
    }
}
export const UserStatusUpdateRequest = (catid: string, user: adminInfoProps,setMessageHandler:any):
    ThunkAction<Promise<void>, {}, {}, userAction> => {
        setMessageHandler({
            hasMessage: false,
            type: '',
            message: '',
        });
    return (dispatch: ThunkDispatch<{}, {}, userAction>) => {
        return AXIOS.put(`${Prefix.api}/admin/user/update/${catid}`, user, { headers: Headers() }).then(res => {
            if (res) {
                const { data } = res;
                const user: adminInfoProps = data.result;
                dispatch(UserUpdateSuccess(user))
                setMessageHandler({
                    hasMessage: true,
                    type: 'success',
                    message: data.message,
                });
            }
        }).catch((e) => {
            if (e.response) {
                dispatch(UserFailure(e.response.data.message));
                setMessageHandler({
                    hasMessage: true,
                    type: 'success',
                    message: e.response.data.message,
                });
            }
            else {
                dispatch(UserFailure(e.message));
                setMessageHandler({
                    hasMessage: true,
                    type: 'success',
                    message: e.message,
                });
            }
        })
    }
}


export const UserSingleRequest = (catid: string):
    ThunkAction<Promise<void>, {}, {}, userAction> => {
        
    return (dispatch: ThunkDispatch<{}, {}, userAction>) => {
        return AXIOS.get(`${Prefix.api}/admin/user/${catid}`, { headers: Headers() }).then(res => {
            if (res) {
                const { data } = res;
                const user: adminInfoProps = data.result;
                dispatch(UserSingleSuccess(user));
            }
        }).catch((e) => {
            if (e.response) {
                dispatch(UserFailure(e.response.data.message));
            }
            else {
                dispatch(UserFailure(e.message));
            }
        })
    }
}


export const UserDeleteRequest = (id: string, body: adminInfoProps,setMessageHandler:any):
    ThunkAction<Promise<void>, {}, {}, userAction> => {
        setMessageHandler({
            hasMessage: false,
            type: '',
            message: '',
        });
    return (dispatch: ThunkDispatch<{}, {}, userAction>) => {
        return AXIOS.put(`${Prefix.api}/admin/user/update/${id}`, body, { headers: Headers() }).then(res => {
            if (res) {
                const { data } = res;
                const user: adminInfoProps = data.result;
                dispatch(UserDeleteSuccess(user))
                setMessageHandler({
                    hasMessage: true,
                    type: 'success',
                    message: data.message,
                });
            }
        }).catch((e) => {
            if (e.response) {
                dispatch(UserFailure(e.response.data.message));
                setMessageHandler({
                    hasMessage: true,
                    type: 'success',
                    message: e.response.data.message,
                });
            }
            else {
                dispatch(UserFailure(e.message));
                setMessageHandler({
                    hasMessage: true,
                    type: 'success',
                    message: e.response.data.message,
                });
            }
        })
    }
}





