import { styled } from '@mui/material/styles';
import { Card, Stack, TextField, Grid } from '@mui/material';
import { Form, Formik, FormikProps } from 'formik';
import * as Yup from 'yup';
import { useEffect } from 'react';
import { useSelector } from 'src/reducers';
import { useNavigate } from 'react-router-dom';
import { LoadingButton } from '@mui/lab';
import { ScheduleJob } from 'src/types/scheduleJobTypes';

const CardStyle = styled(Card)(({ }) => ({
    width: '100%',
    padding: 24
}));

export interface FormValues {
    initialValues: ScheduleJob,
    submitForm: any,
}

export default function ScheduleJobForm({ initialValues, submitForm }: FormValues) {

    const SchemaCategoryForm = Yup.object().shape({
        projectCode: Yup.string().trim().required('Project Code  is required'),
        interval: Yup.number().min(2).required('Interval  is required'),
    });
    const navigate = useNavigate();
    const { redirectTo } = useSelector(state => state.scheduleJob);

    useEffect(() => {
        if (redirectTo) navigate(redirectTo, { replace: true })
    }, [redirectTo])


    return (
        <CardStyle>
            <Formik
                onSubmit={(values, { setSubmitting }) => {
                    submitForm(values, setSubmitting);
                }}
                validateOnBlur={false}
                validateOnChange={true}
                enableReinitialize={true}
                validationSchema={SchemaCategoryForm}
                initialValues={{
                    projectCode: initialValues?.projectCode || '',
                    comment:initialValues?.comment || '',
                    interval: initialValues?.interval ,
                }}
            >
                {(formikProps: FormikProps<ScheduleJob>) => (
                    <Form autoComplete="off" noValidate onSubmit={formikProps.handleSubmit}>
                        <Stack spacing={3}>
                            <Grid
                                container
                                spacing={3}
                            >
                                <Grid
                                    item
                                    md={4}
                                    xs={12}
                                >
                                    <TextField
                                        error={Boolean(formikProps.touched.projectCode && formikProps.errors.projectCode)}
                                        fullWidth
                                        helperText={formikProps.touched.projectCode && formikProps.errors.projectCode}
                                        label="Project Code"
                                        {...formikProps.getFieldProps('projectCode')}
                                        variant="standard"
                                    />
                                </Grid>
                                <Grid
                                    item
                                    md={2}
                                    xs={12}
                                >
                                    <TextField
                                        error={Boolean(formikProps.touched.interval && formikProps.errors.interval)}
                                        fullWidth
                                        helperText={formikProps.touched.interval && formikProps.errors.interval}
                                        label="Interval"
                                        {...formikProps.getFieldProps('interval')}
                                        variant="standard"
                                    />
                                </Grid>
                                <Grid
                                    item
                                    md={6}
                                    xs={12}
                                >
                                    <TextField
                                        error={Boolean(formikProps.touched.comment && formikProps.errors.comment)}
                                        fullWidth
                                        helperText={formikProps.touched.comment && formikProps.errors.comment}
                                        label="Comment"
                                        {...formikProps.getFieldProps('comment')}
                                        variant="standard"
                                    />
                                </Grid>
                            </Grid>
                            <Grid item xs={2}>
                                    <LoadingButton
                                        size="medium"
                                        type="submit"
                                        variant="contained"
                                        loading={formikProps.isSubmitting}
                                    >
                                        {initialValues ? 'Save Schedule Job' : 'Add Schedule Job'}
                                    </LoadingButton>
                                </Grid>
                        </Stack>
                    </Form>
                )}
            </Formik>
        </CardStyle>
    );
}
