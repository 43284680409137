import { ThunkAction, ThunkDispatch } from "redux-thunk";
import Prefix from 'src/helpers/ApiPrefix';
import AXIOS from 'src/helpers/Axios';
import Headers from 'src/helpers/Headers';
import { supplierRuleAction } from "src/reducers/supplierRuleReducer";
import { supplierRuleActionTypes } from "src/types/actionTypes";
import { SupplierRule, SupplierRuleList } from "src/types/supplierRuleTypes";
import { ErrorAction, SuccessAction } from "src/types/responseTypes";

export const SupplierRuleFailure = (message: string): ErrorAction => {
    return {
        type: supplierRuleActionTypes.SUPPLIER_RULE_FAILURE,
        payload: { message: message }
    }
}
export const SupplierRuleSuccess = (payload: SupplierRuleList): SuccessAction<SupplierRuleList> => {
    return {
        type: supplierRuleActionTypes.FETCH_SUPPLIER_RULE_SUCCESS,
        payload: payload,
    }
}
export const SupplierRuleAllSuccess = (payload: SupplierRuleList): SuccessAction<SupplierRuleList> => {
    return {
        type: supplierRuleActionTypes.FETCH_SUPPLIER_RULE_ALL_SUCCESS,
        payload: payload,
    }
}

export const SupplierRuleAddSuccess = (payload: SupplierRule): SuccessAction<SupplierRule> => {
    return {
        type: supplierRuleActionTypes.ADD_SUPPLIER_RULE_SUCCESS,
        payload: payload,
    }
}
export const SupplierRuleSingleSuccess = (payload: SupplierRule): SuccessAction<SupplierRule> => {
    return {
        type: supplierRuleActionTypes.FETCH_SINGLE_SUPPLIER_RULE_SUCCESS,
        payload: payload,
    }
}

export const SupplierRuleUpdateSuccess = (payload: any): SuccessAction<any> => {
    return {
        type: supplierRuleActionTypes.UPDATE_SUPPLIER_RULE_SUCCESS,
        payload: payload,
    }
}


export const SupplierRuleDeleteSuccess = (payload: any): SuccessAction<any> => {
    return {
        type: supplierRuleActionTypes.DELETE_SUPPLIER_RULE_SUCCESS,
        payload: payload,
    }
}


export const SupplierRuleAllRequest = (filter: any, search: string, pageNo: number, limit: number, status: boolean):
    ThunkAction<Promise<void>, {}, {}, supplierRuleAction> => {
    return (dispatch: ThunkDispatch<{}, {}, supplierRuleAction>) => {
        return AXIOS.post(`${Prefix.api}/admin/supplier/rules/data`, { filter, search, pageNo, limit, status }, { headers: Headers() }).then(res => {
            if (res) {
                const { data } = res;
                const categories: SupplierRuleList = data.result
                dispatch(SupplierRuleSuccess(categories))
            }
        }).catch((e) => {
            if (e.response) {
                dispatch(SupplierRuleFailure(e.response.data.message));
            }
            else {
                dispatch(SupplierRuleFailure(e.message));
            }
        })
    }
}

export const SupplierRuleAllRequestData = ():
    ThunkAction<Promise<void>, {}, {}, supplierRuleAction> => {
    return (dispatch: ThunkDispatch<{}, {}, supplierRuleAction>) => {
        return AXIOS.get(`${Prefix.api}/admin/categories/all`, { headers: Headers() }).then(res => {
            if (res) {
                const { data } = res;
                const categories: SupplierRuleList = data.result
                dispatch(SupplierRuleAllSuccess(categories))
            }
        }).catch((e) => {
            if (e.response) {
                dispatch(SupplierRuleFailure(e.response.data.message));
            }
            else {
                dispatch(SupplierRuleFailure(e.message));
            }
        })
    }
}




export const SupplierRuleAddRequest = (supplierRule: SupplierRule, setMessageHandler: any, setSubmitting: any):
    ThunkAction<Promise<void>, {}, {}, supplierRuleAction> => {
    setMessageHandler({
        hasMessage: false,
        type: '',
        message: '',
    });
    return (dispatch: ThunkDispatch<{}, {}, supplierRuleAction>) => {
        return AXIOS.post(`${Prefix.api}/admin/supplier/rule/add`, supplierRule, { headers: Headers() }).then(res => {
            if (res) {
                const { data } = res;
                const supplierRule: SupplierRule = data.result;
                dispatch(SupplierRuleAddSuccess(supplierRule))
                setSubmitting(false);
                setMessageHandler({
                    hasMessage: true,
                    type: 'success',
                    message: data.message,
                });
            }
        }).catch((e) => {
            if (e.response) {
                setSubmitting(false);
                dispatch(SupplierRuleFailure(e.response.data.message));
                setMessageHandler({
                    hasMessage: true,
                    type: 'error',
                    message: e.response.data.message,
                });
            }
            else {
                setSubmitting(false);
                dispatch(SupplierRuleFailure(e.message));
                setMessageHandler({
                    hasMessage: true,
                    type: 'error',
                    message: e.message,
                });
            }
        })
    }
}


export const SupplierRuleUpdateRequest = (catid: string, supplierRule: SupplierRule, setMessageHandler: any, setSubmitting: any):
    ThunkAction<Promise<void>, {}, {}, supplierRuleAction> => {
    setMessageHandler({
        hasMessage: false,
        type: '',
        message: '',
    });
    return (dispatch: ThunkDispatch<{}, {}, supplierRuleAction>) => {
        return AXIOS.put(`${Prefix.api}/admin/supplier/rule/update/${catid}`, supplierRule, { headers: Headers() }).then(res => {
            if (res) {
                const { data } = res;
                const supplierRule: SupplierRule = data.result;
                dispatch(SupplierRuleUpdateSuccess(supplierRule))
                setSubmitting(false);
                setMessageHandler({
                    hasMessage: true,
                    type: 'success',
                    message: data.message,
                });
            }
        }).catch((e) => {
            if (e.response) {
                setSubmitting(false);
                setMessageHandler({
                    hasMessage: true,
                    type: 'error',
                    message: e.response.data.message,
                });
                dispatch(SupplierRuleFailure(e.response.data.message));
            }
            else {
                setSubmitting(false);
                setMessageHandler({
                    hasMessage: true,
                    type: 'error',
                    message: e.message,
                });
                dispatch(SupplierRuleFailure(e.message));
            }
        })
    }
}

export const SupplierRuleStatusUpdateRequest = (catid: string, supplierRule: SupplierRule, setMessageHandler: any):
    ThunkAction<Promise<void>, {}, {}, supplierRuleAction> => {
    setMessageHandler({
        hasMessage: false,
        type: '',
        message: '',
    });
    return (dispatch: ThunkDispatch<{}, {}, supplierRuleAction>) => {
        return AXIOS.put(`${Prefix.api}/admin/supplierRule/update/${catid}`, supplierRule, { headers: Headers() }).then(res => {
            if (res) {
                const { data } = res;
                const supplierRule: SupplierRule = data.result;
                dispatch(SupplierRuleUpdateSuccess(supplierRule))
                setMessageHandler({
                    hasMessage: true,
                    type: 'success',
                    message: data.message,
                });
            }
        }).catch((e) => {
            if (e.response) {
                setMessageHandler({
                    hasMessage: true,
                    type: 'error',
                    message: e.response.data.message,
                });
                dispatch(SupplierRuleFailure(e.response.data.message));
            }
            else {
                setMessageHandler({
                    hasMessage: true,
                    type: 'error',
                    message: e.message,
                });
                dispatch(SupplierRuleFailure(e.message));
            }
        })
    }
}


export const SupplierRuleSingleRequest = (catid: string):
    ThunkAction<Promise<void>, {}, {}, supplierRuleAction> => {

    return (dispatch: ThunkDispatch<{}, {}, supplierRuleAction>) => {
        return AXIOS.get(`${Prefix.api}/admin/supplier/rule/${catid}`, { headers: Headers() }).then(res => {
            if (res) {
                const { data } = res;
                const supplierRule: SupplierRule = data.result;
                dispatch(SupplierRuleSingleSuccess(supplierRule));
            }
        }).catch((e) => {
            if (e.response) {
                dispatch(SupplierRuleFailure(e.response.data.message));
            }
            else {
                dispatch(SupplierRuleFailure(e.message));
            }
        })
    }
}


export const SupplierRuleDeleteRequest = (id: string, body: SupplierRule, setMessageHandler: any):
    ThunkAction<Promise<void>, {}, {}, supplierRuleAction> => {
    setMessageHandler({
        hasMessage: false,
        type: '',
        message: '',
    });
    return (dispatch: ThunkDispatch<{}, {}, supplierRuleAction>) => {
        return AXIOS.put(`${Prefix.api}/admin/supplier/rule/update/${id}`, body, { headers: Headers() }).then(res => {
            if (res) {
                const { data } = res;
                const supplierRule: SupplierRule = data.result;
                dispatch(SupplierRuleDeleteSuccess(supplierRule))
                setMessageHandler({
                    hasMessage: true,
                    type: 'success',
                    message: data.message,
                });
            }
        }).catch((e) => {
            if (e.response) {
                dispatch(SupplierRuleFailure(e.response.data.message));
                setMessageHandler({
                    hasMessage: true,
                    type: 'error',
                    message: e.response.data.message,
                });
            }
            else {
                dispatch(SupplierRuleFailure(e.message));
                setMessageHandler({
                    hasMessage: true,
                    type: 'error',
                    message: e.message,
                });
            }
        })
    }
}





