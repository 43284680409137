import { useContext } from 'react';
import { Container } from '@mui/material';
import { useDispatch } from 'react-redux';
import { CategoryUpdateRequest, CategorySingleRequest } from 'src/actions';
import { useParams } from 'react-router-dom';
import { useEffect } from 'react';
import { useSelector } from 'src/reducers';
import MessageContext from 'src/context/MessageContext';
import { Category } from 'src/types/categoryTypes';
import { CategoryForm } from 'src/components/admin/_dashboard/service/category';
import TopBarForm from 'src/components/shared/TopBarForm';

export default function EditCategory() {

    const { setMessageHandler } = useContext<any>(MessageContext);
    const dispatch = useDispatch();
    const { catid }: any = useParams<any>();
    const submitForm = async (values: Category, setSubmitting: any) => {
        setTimeout(() => dispatch(CategoryUpdateRequest(catid, values, setMessageHandler, setSubmitting)), 200);
    }
    const { category } = useSelector(state => state.category);
    const initialValues: any = category

    useEffect(() => {
        dispatch(CategorySingleRequest(catid));
    }, [catid])

    return (
        <Container maxWidth={false}>
            <TopBarForm title="Edit Category" link="/admin/library"/>
            <CategoryForm initialValues={initialValues} submitForm={submitForm} />
        </Container>
    );
}
