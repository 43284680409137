import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import { Qualification } from 'src/types/qualificationTypes';
import { Icon } from '@iconify/react';

export interface FormValues {
  qualification: Qualification,
  selected:boolean,
  defaultQuota?:boolean,
  addQuotaForm:any
}

export default function QuotaListRender({ selected,qualification,defaultQuota,addQuotaForm }: FormValues) {
  const labelId = `checkbox-list-label-${qualification?._id}`;
  return (
    <ListItem
      key={qualification?._id}
      secondaryAction={
        <IconButton edge="end" aria-label="comments">
          <Icon icon={qualification.qualificationIcon} fontSize={24} />
        </IconButton>
      }
      disablePadding
    >
      <ListItemButton disabled={selected}  onClick={()=>  addQuotaForm(qualification?._id)} dense>
        <ListItemIcon>
          <Checkbox
            edge="start"
            tabIndex={-1}
            disableRipple
            checked={selected}
            inputProps={{ 'aria-labelledby': labelId }}
          />
        </ListItemIcon>
        <ListItemText id={labelId} primary={`${qualification.qualificationName}`} />
      </ListItemButton>
    </ListItem>
  );

}
