import { DragDropContext,  Droppable, OnDragEndResponder} from 'react-beautiful-dnd';
import { Question } from 'src/types/surveyTypes';
import QuestionFormRender from './QuestionFormRender';
import QuestionListItem from './QuestionListItem';

export interface QuestionProps {
    questions: Question[],
    updateForm: any,
    deleteQuestion: any,
    editQuestion: Question,
    setEditQuestion: any,
    languageCode: string,
    onDragEnd: OnDragEndResponder;
}

const QuestionListRender = ({ editQuestion, setEditQuestion, questions, updateForm, deleteQuestion, languageCode, onDragEnd }: QuestionProps) => {

    return (
        <>
        {editQuestion  && <QuestionFormRender languageCode={languageCode} setEditQuestion={setEditQuestion} initialValues={editQuestion} submitForm={updateForm} />}
        {!editQuestion && <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId={"droppable-list"}>
                {provided => (
                    <div ref={provided.innerRef} {...provided.droppableProps}>
                        {questions.map((question, index) =>
                        <>
                            <QuestionListItem question={question} index={index} languageCode={languageCode} setEditQuestion={setEditQuestion} deleteQuestion={deleteQuestion} key={question._id || ''} />

                        </>
                        )}
                        {provided.placeholder}
                    </div>
                )}
            </Droppable>
        </DragDropContext>}
        </>
    );
};

export default QuestionListRender;
