import { Card, Container, IconButton, styled, Box } from '@mui/material';
import { Document, Page, StyleSheet } from '@react-pdf/renderer';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import TopBarForm from 'src/components/shared/TopBarForm';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'src/reducers';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import EditIcon from '@mui/icons-material/Edit';
import { InvoiceDownloadRequest, InvoiceSingleRequest } from 'src/actions/invoiceActions';
import './pdfstyle.css'

const styles = StyleSheet.create({
	page: {
		flexDirection: 'row',
	},
	section: {
		flexGrow: 1,
	},
});

const CardStyle = styled(Card)(({ }) => ({
	width: '100%',
	padding: 30
}));

const ViewInvoice = () => {

	const { invoiceid }: any = useParams<any>();
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { invoice } = useSelector(state => state.invoice);

	useEffect(() => {
		dispatch(InvoiceSingleRequest(invoiceid));
	}, [invoiceid]);

	return <Container maxWidth={false}>
		<Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
			<Box>
				<TopBarForm title="View Invoice" link="/admin/invoice/list" />
			</Box>
			<Box>
				<IconButton onClick={() => navigate(`/admin/invoice/generate/${invoiceid}/edit`)}>
					<EditIcon />
				</IconButton>
				<IconButton onClick={() => dispatch(InvoiceDownloadRequest(invoiceid))}>
					<FileDownloadIcon />
				</IconButton>
			</Box>
		</Box>
		<CardStyle>
			<Document>
				<Page size="A4" style={styles.page}>
					<div className="header">
						<h1 style={{ textAlign: "center", width: "100%" }}>Wolf Insights</h1>
					</div>
					<div className="bg-img">
					</div>
					<div className="info">
						<table style={{ width: "100%" }}>
							<tbody>
								<tr>
									<td width="70%">
										{invoice?.clientId?.wolfAccount == 'windia' ?
											<div className="company-info">
												<h3 style={{ margin: 0, padding: 0 }}>WOLF INSIGHTS PRIVATE LIMITED</h3>
												<p>Ground Floor, Plot No. 57 </p>
												<p>Industrial Area, Phase 1,</p>
												<p>Chandigarh, CH, 160001, IN</p>
												<p>+91 (896) 818-2409 </p>
												<p>deepika@wolfinsights.com</p>
												<p>PAN No. AACCW7215J</p>
												<p>CIN: U74999CH2020PTC043168</p>
												<p>GSTIN: 04AACCW7215J1ZC</p>
											</div> :
											<div className="company-info">
												<h3 style={{ margin: 0, padding: 0 }}>WOLF INSIGHTS INC.</h3>
												<p>23 Dwyer Dr</p>
                                                <p>Brampton,L6S 6L2</p>
												<p>Canada</p>
												<p>+91 (896) 818-2409</p>
												<p>deepika@wolfinsights.com</p>
											</div>}


									</td>
									<td>
										<div className="invoice-info">
											<h3 style={{ margin: 0, padding: 0 }}>
												{"INVOICE " + invoice.invoiceCode}
											</h3>
											<p>
												<strong>DATE:</strong>
												{invoice.currentDate}
											</p>
											<p>
												<strong>DUE DATE:</strong>
												{invoice.dueDate}
											</p>
											<p>
												<strong>TERMS Net:</strong>
												{invoice.netTerms} Days
											</p>
											<h4>BILL TO:</h4>
											<p>
												<strong>
													{invoice?.clientId?.company}
												</strong>
											</p>
											<p>
												{invoice?.clientId?.address1}
												{invoice?.clientId?.address2 ? ',' + invoice?.clientId?.address2 : ""}
											</p>
											<p>
												{invoice?.clientId?.city + ','}
												{invoice?.clientId?.state + ','}
												{invoice?.clientId?.zipCode + ','}
												{invoice?.clientId?.countryId?.name}
											</p>
										</div>
									</td>
								</tr>
							</tbody>
						</table>
					</div>
					<div className="invoice-body">
						<table width="100%" className="invoice-body">
							<thead>
								<tr style={{ borderBottom: "2px solid #000" }}>
									<th align="left">
										<strong>S No.</strong>
									</th>
									<th align="left">
										<strong>Project Name</strong>
									</th>
									<th align="left">
										<strong>Amount Due</strong>
									</th>
								</tr>
							</thead>
							<tbody>
								{invoice.projects.map((project, index) =>
									<tr>
										<td height={30}>
											{index + 1}.
										</td>
										<td height={30}>
											{project.poNumber + ' - ' + project.projectName}
										</td>
										<td height={30}>
											{"USD $" + project.total}
										</td>
									</tr>)}
							</tbody>
							<tfoot>
								<tr>
									<th colSpan={2} align="right" style={{ paddingRight: 50 }}>
										<strong>GRAND TOTAL</strong>
									</th>
									<th align="left">
										<strong>
											{"USD $" + invoice.projects.reduce(function (acc, obj) { return acc + (obj.total || 0) }, 0)}
										</strong>
									</th>
								</tr>
							</tfoot>
						</table>
					</div>
					{invoice?.clientId?.wolfAccount == 'windia' ? <div className="footer" style={{ marginTop: 20 }}>
						<p>Description- Online Market Research Services</p>
						<div className="bank-detail">
							<p><strong>BENEFICIARY BANK DETAILS</strong></p>
							<p>Beneficiary Name: WOLF INSIGHTS PRIVATE LIMITED</p>
							<p>Beneficiary Account No.: 920020061272384</p>
							<p>Beneficiary Bank: Axis Bank Limited</p>
							<p>Beneficiary Bank SWIFT Code: AXISINBB041</p>
						</div>
						<div className="bank-address">
							<p><strong>BENEFICIARY BANK ADDRESS</strong></p>
							<p>SCO - 61, Sector 32 C and D, Chandigarh</p>
							<p>160030, IN</p>
						</div>
					</div>
						:
						<div className="footer" style={{ marginTop: 20 }}>
							<p>Description- Online Market Research Services</p>
							<div className="bank-detail">
								<p><strong>BENEFICIARY BANK DETAILS</strong></p>
								<p>Beneficiary Name: WOLF INSIGHTS INC.</p>
								<p>Beneficiary Transit No.: 09998</p>
								<p>Beneficiary Bank No.: 003</p>
								<p>Beneficiary Account No.: 4002010</p>
								<p>Beneficiary Bank: RBC Royal Bank</p>
								<p>Beneficiary Bank SWIFT Code: ROYCCAT2</p>
								<p>Canadian Clearing Code: 000309998</p>
							</div>
							<div className="bank-address">
								<p><strong>BENEFICIARY BANK ADDRESS</strong></p>
								<p>1450 Major Mackenzie Dr East,Richmond Hill</p>
								<p>ON L4S0 0A1,Canada</p>
							</div>
						</div>}
				</Page>
			</Document>
		</CardStyle>
	</Container>
}

export default ViewInvoice;
