import { useContext } from 'react';
import { Container } from '@mui/material';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useEffect } from 'react';
import { useSelector } from 'src/reducers';
import MessageContext from 'src/context/MessageContext';
import TopBarForm from 'src/components/shared/TopBarForm';
import { SupplierRule } from 'src/types/supplierRuleTypes';
import {  MessageSingleRequest, MessageUpdateRequest } from 'src/actions/messageActions';
import { MessageForm } from 'src/components/admin/_dashboard/service/Message';
import { Message } from 'src/types/messageTypes';

export default function EditMessage() {

    const { setMessageHandler } = useContext<any>(MessageContext);
    const dispatch = useDispatch();
    const { messageid }: any = useParams<any>();
    const submitForm = async (values: Message, setSubmitting: any) => {
        setTimeout(() => dispatch(MessageUpdateRequest(messageid, values, setMessageHandler, setSubmitting)), 200);
    }
    const { message } = useSelector(state => state.message);
    const initialValues: any = message

    useEffect(() => {
        dispatch(MessageSingleRequest(messageid));
    }, [messageid])

    return (
        <Container maxWidth={false}>
            <TopBarForm title="Edit Supplier Rule" link="/admin/library/message"/>
            <MessageForm initialValues={initialValues} submitForm={submitForm} />
        </Container>
    );
}
