import { quotaActionTypes } from "src/types/actionTypes";
import { Quota, QuotaList } from "src/types/quotaTypes";
import { ErrorAction, SuccessAction } from "src/types/responseTypes";

export type quotaAction = SuccessAction<QuotaList> | SuccessAction<Quota> | SuccessAction<any> | ErrorAction
export const defaultQuestionState: QuotaList = { loading:true, redirectTo: "",  quotas: [], quota: {}, data: [], metadata: {} };

const reducer = (state: QuotaList = defaultQuestionState, action: quotaAction): QuotaList => {
    switch (action.type) {
        case quotaActionTypes.QUOTA_REQUEST:
            state = { loading:true,redirectTo: "", quotas: [], quota: {}, data: [], metadata: {} }
            return state
        case quotaActionTypes.FETCH_QUOTA_SUCCESS:
            action = <SuccessAction<QuotaList>>action
            state = { ...state,loading:false, data: action.payload.quotas, metadata: action.payload.metadata }
            return state
        case quotaActionTypes.FETCH_QUOTA_ALL_SUCCESS:
            action = <SuccessAction<QuotaList>>action
            state = { ...state,loading:false, quotas: action.payload }
            return state
        case quotaActionTypes.UPDATE_QUOTA_SUCCESS:
            action = <SuccessAction<any>>action
            state = { ...state,loading:false, quotas: state.quotas?.map(dat => [dat._id == action.payload._id && Object.assign(dat, action.payload)].find(o => o._id == dat._id) || dat) }
            return state
        case quotaActionTypes.FETCH_SINGLE_QUOTA_SUCCESS:
            action = <SuccessAction<Quota>>action
            state = { ...state,loading:false, quota: action.payload }
            return state
        case quotaActionTypes.DELETE_QUOTA_SUCCESS:
            action = <SuccessAction<any>>action
            state = { ...state,loading:false, quotas: state.quotas?.filter(dat => dat._id !== action.payload._id), metadata: { ...state.metadata, limit: Number(state.metadata?.limit) - 1, total: Number(state.metadata?.total) - 1 } }
            return state
        case quotaActionTypes.ADD_QUOTA_SUCCESS:
            action = <SuccessAction<Quota>>action
            state = { ...state,loading:false, quotas: [...state.quotas, action.payload] }
            return state
        case quotaActionTypes.QUOTA_FAILURE:
            action = <ErrorAction>action
            return { ...state,loading:false, }
        default:
            return state
    }
}
export default reducer