import { ThunkAction, ThunkDispatch } from "redux-thunk";
import Prefix from 'src/helpers/ApiPrefix';
import AXIOS from 'src/helpers/Axios';
import Headers from 'src/helpers/Headers';
import { clientAction } from "src/reducers/clientReducer";
import {  emailActionTypes } from "src/types/actionTypes";
import {  ClientList } from "src/types/clientTypes";
import { ErrorAction, SuccessAction } from "src/types/responseTypes";


export const EmailFailure = (message: string): ErrorAction => {
    return {
        type: emailActionTypes.EMAIL_FAILURE,
        payload: { message: message }
    }
}
export const EmailSuccess = (payload: any): SuccessAction<any> => {
    return {
        type: emailActionTypes.EMAIL_SUCCESS,
        payload: payload,
    }
}

export const SendEmailRequest = (body:any,setDialogEmail:any,setMessageHandler:any):
    ThunkAction<Promise<void>, {}, {}, clientAction> => {
    return (dispatch: ThunkDispatch<{}, {}, clientAction>) => {
        return AXIOS.post(`${Prefix.api}/admin/email/send`, body , { headers: Headers() }).then(res => {
            if (res) {
                const { data } = res;
                setMessageHandler({
                    hasMessage: true,
                    type: 'success',
                    message: data.message,
                });
                setDialogEmail('');
            }
        }).catch((e) => {
            if (e.response) {
                dispatch(EmailFailure(e.response.data.message));
            }
            else {
                dispatch(EmailFailure(e.message));
            }
        })
    }
}

export const SendBulkSupplierEmailRequest = (body:any,setDialogEmail:any,setMessageHandler:any):
    ThunkAction<Promise<void>, {}, {}, clientAction> => {
    return (dispatch: ThunkDispatch<{}, {}, clientAction>) => {
        return AXIOS.post(`${Prefix.api}/admin/email/supplier/bulk/send`, body , { headers: Headers() }).then(res => {
            if (res) {
                const { data } = res;
                setMessageHandler({
                    hasMessage: true,
                    type: 'success',
                    message: data.message,
                });
                setDialogEmail('');
            }
        }).catch((e) => {
            if (e.response) {
                dispatch(EmailFailure(e.response.data.message));
            }
            else {
                dispatch(EmailFailure(e.message));
            }
        })
    }
}

export const SendEmailQuotaRequest = (body:any,setMessageHandler:any):
    ThunkAction<Promise<void>, {}, {}, clientAction> => {
    return (dispatch: ThunkDispatch<{}, {}, clientAction>) => {
        return AXIOS.post(`${Prefix.api}/admin/email/send/quota`, body , { headers: Headers() }).then(res => {
            if (res) {
                const { data } = res;
                setMessageHandler({
                    hasMessage: true,
                    type: 'success',
                    message: data.message,
                });
            }
        }).catch((e) => {
            if (e.response) {
                dispatch(EmailFailure(e.response.data.message));
            }
            else {
                dispatch(EmailFailure(e.message));
            }
        })
    }
}


export const SendEmailMapRequest = (body:any,setMessageHandler:any):
    ThunkAction<Promise<void>, {}, {}, clientAction> => {
    return (dispatch: ThunkDispatch<{}, {}, clientAction>) => {
        return AXIOS.post(`${Prefix.api}/admin/email/send/map`, body , { headers: Headers() }).then(res => {
            if (res) {
                const { data } = res;
                setMessageHandler({
                    hasMessage: true,
                    type: 'success',
                    message: data.message,
                });
            }
        }).catch((e) => {
            if (e.response) {
                dispatch(EmailFailure(e.response.data.message));
            }
            else {
                dispatch(EmailFailure(e.message));
            }
        })
    }
}

export const SendEmailReportRequest = (body:any,setMessageHandler:any):
    ThunkAction<Promise<void>, {}, {}, clientAction> => {
    return (dispatch: ThunkDispatch<{}, {}, clientAction>) => {
        return AXIOS.post(`${Prefix.api}/admin/email/send/report`, body , { headers: Headers() }).then(res => {
            if (res) {
                const { data } = res;
                setMessageHandler({
                    hasMessage: true,
                    type: 'success',
                    message: data.message,
                });
            }
        }).catch((e) => {
            if (e.response) {
                dispatch(EmailFailure(e.response.data.message));
            }
            else {
                dispatch(EmailFailure(e.message));
            }
        })
    }
}
export const sendEmailSupplierSummaryAction = (body:any,setMessageHandler:any):
    ThunkAction<Promise<void>, {}, {}, clientAction> => {
    return (dispatch: ThunkDispatch<{}, {}, clientAction>) => {
        return AXIOS.post(`${Prefix.api}/admin/suppliers/send/summary`, body , { headers: Headers() }).then(res => {
            if (res) {
                const { data } = res;
                setMessageHandler({
                    hasMessage: true,
                    type: 'success',
                    message: data.message,
                });
            }
        }).catch((e) => {
            if (e.response) {
                dispatch(EmailFailure(e.response.data.message));
            }
            else {
                dispatch(EmailFailure(e.message));
            }
        })
    }
}










