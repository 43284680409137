import { userActionTypes } from "src/types/actionTypes";
import { adminInfoProps } from "src/types/adminTypes";
import { ErrorAction, SuccessAction } from "src/types/responseTypes";
import {  UserList } from "src/types/userTypes";

export type userAction = SuccessAction<adminInfoProps> | SuccessAction<adminInfoProps> | SuccessAction<any> | ErrorAction
export const defaultUserState: UserList = { loading:true, redirectTo:'',users:[], user :{}, data: [], metadata: {} };

const reducer = (state: UserList = defaultUserState, action: userAction): UserList => {
    switch (action.type) {
        case userActionTypes.USER_REQUEST:
            state = { ...state, loading:true }
            return state
        case userActionTypes.FETCH_USER_SUCCESS:
            action = <SuccessAction<UserList>>action
            state = { ...state,redirectTo:'', data: action.payload.data, metadata: action.payload.metadata ,loading:false }
            return state
            case userActionTypes.FETCH_USER_SUCCESS_ALL:
            action = <SuccessAction<UserList>>action
            state = { ...state, users: action.payload, loading:false }
            return state
        case userActionTypes.UPDATE_USER_SUCCESS:
            action = <SuccessAction<any>>action
            state = { ...state,redirectTo:'/admin/user' ,data: state.data?.map(dat => [action.payload].find(o => o._id == dat._id) || dat) }
            return state
        case userActionTypes.FETCH_SINGLE_USER_SUCCESS:
            action = <SuccessAction<adminInfoProps>>action
            state = { ...state, user:  action.payload }
            return state
        case userActionTypes.DELETE_USER_SUCCESS:
            action = <SuccessAction<any>>action
            state = { ...state, data: state.data?.filter(dat => dat._id !== action.payload._id), metadata: { ...state.metadata, limit: Number(state.metadata?.limit) - 1, total: Number(state.metadata?.total) - 1 } }
            return state
        case userActionTypes.ADD_USER_SUCCESS:
            action = <SuccessAction<adminInfoProps>>action
            state = { ...state,redirectTo:'/admin/user', data: state.data?.concat(action.payload) }
            return state
        case userActionTypes.USER_FAILURE:
            action = <ErrorAction>action
            return { ...state }
        default:
            return state
    }
}
export default reducer