import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Container, Typography, Box, Tooltip, Tab } from '@mui/material';
import { useDispatch } from 'react-redux';
import { useSelector } from 'src/reducers';
import { sentenceCase } from 'change-case';
import { DataGridRender } from 'src/components/table';
import Label from 'src/components/Label';
import { ProjectAchievedAllRequest } from 'src/actions/projectActions';
import { ProjectStatus } from 'src/constants';
import TopBar from 'src/components/shared/TopBar';
import { fShortenNumber } from 'src/utils/formatNumber';
import TabLayout from 'src/components/tab/TabLayout';

export default function ArchievedProjectList() {

    const [limit, setLimit] = useState(20);
    const [pageNo, setPageNo] = useState(1);
    const [status] = useState(false);
    const [filters, setFilters] = useState<any>({});

    const dispatch = useDispatch();
    const { loading, metadata, data } = useSelector(state => state.project);

    const navigate = useNavigate();

    useEffect(() => {
        dispatch(ProjectAchievedAllRequest(filters, pageNo, limit, status))
    }, [pageNo, limit, filters, status]);

    const reloadAction = () => {
        dispatch(ProjectAchievedAllRequest(filters, pageNo, limit, status));
    }

    const columns = [
        {
            field: 'projectCode', type: "string", headerName: 'Project #', filterable: false, headerAlign: "center", align: 'center', width: 100, pinnable: true, renderCell: ({ id, value }: any) => {
                return <Link to={`/admin/project/${id}/childs`}>
                    <Typography color="primary" >{value}</Typography>
                </Link>
            }
        },
        {
            field: 'projectName', type: "string", headerName: 'Project Title', filterable: false, headerAlign: "left", align: 'left', flex: 1, renderCell: ({ id, value }: any) => {
                return <Tooltip title={value}>
                    <Typography variant="subtitle2">{value}</Typography>
                </Tooltip>
            }
        },
        {
            field: 'clienName', type: "string", headerName: 'Client Name', filterable: false, headerAlign: "center", align: 'center', width: 150, renderCell: ({ value }: any) => {
                return <Tooltip title={value}>
                    <Typography variant="subtitle2">{value}</Typography>
                </Tooltip>
            }
        },
        {
            field: 'complete', type: "string", headerName: 'Complete', filterable: false, width: 100, headerAlign: "center", align: 'center', valueGetter: ({ row }: any) => {
                return `${fShortenNumber(row['approved'])}`;
            },
        },
        {
            field: 'cpi', type: "string", headerName: 'CPI', filterable: false, width: 80, headerAlign: "center", align: 'center', valueGetter: ({ row }: any) => {
                return `${fShortenNumber(row['cpi'])}`;
            },
        },
        {
            field: 'poNumber', type: "string", headerName: 'PO Number', filterable: false, headerAlign: "center", align: 'center', width: 120, renderCell: ({ value }: any) => {
                return <Tooltip title={value}>
                    <Typography variant="subtitle2">{value}</Typography>
                </Tooltip>
            }
        },
        {
            field: 'invoiceDueDate', type: "string", headerName: 'Due Date', filterable: false, headerAlign: "center", align: 'center', width: 100
        },
        {
            field: 'invoiceRecievedDate', type: "string", headerName: 'Recieved Date', filterable: false, headerAlign: "center", align: 'center', width: 120
        },
        {
            field: 'invoiceReferenceNo', type: "string", headerName: 'Reference', filterable: false, headerAlign: "center", align: 'center', width: 120
        },
        {
            field: 'Amount', type: "string", headerName: 'Total', filterable: false, width: 150, headerAlign: "center", align: 'center', valueGetter: ({ row }: any) => {
                return `$ ${(row['approved'] * row['cpi']).toFixed(2)}`;
            },
        },
        {
            field: 'projectStatus', headerName: 'Status', type: "number", width: 120, headerAlign: "center", align: 'center', valueFormatter: ({ value }: any) => ProjectStatus.find(status => status.value == value)?.label,
            renderCell: (params: any) => {
                var status: any = ProjectStatus.find(status => status.value == params.value);
                return <Label
                    variant="ghost"
                    color={status?.color}
                >
                    {sentenceCase(status.label)}
                </Label>
            }
        },
    ];

    return (
        <Container maxWidth={false}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Box>
                    <Typography sx={{ ml: 1 }} variant="h5">
                        Accounts
                    </Typography>
                </Box>
                <TopBar title="" link="/admin/invoice/generate" buttonText="Generate Invoice" />
            </Box>
            <TabLayout handleChange="" tabValue={3}>
            <Tab label="Ready To Invoice" href="/admin/invoice"  value={1} />
        <Tab label="Archieved" href="/admin/invoice/archieved" value={3} />
        <Tab label="Invoices" href="/admin/invoice/list" value={2} />
        <Tab label="Finances" href="/admin/invoice/supplier" value={4} />
        <Tab label="Purchase Order" href="/admin/invoice/supplier/list" value={5} />
            </TabLayout>
            <DataGridRender
                reloadAction={reloadAction}
                getRowClassName={(params: any) => params.row['complete'] && params.row['complete'] >= params.row['sampleSize'] && params.row['sampleSize'] ? `data-grid-warning` : 'super-app-theme'}
                loading={loading}
                filterTypes={['client', 'user', 'export', 'search']}
                data={data}
                filters={filters}
                exportFileName="Projects"
                metadata={metadata}
                limit={limit}
                pageNo={pageNo}
                columns={columns}
                setPageNo={setPageNo}
                setLimit={setLimit}
                setFilters={setFilters}
            />
        </Container>
    );
}
