import { useContext } from 'react';
import { Container } from '@mui/material';
import { useDispatch } from 'react-redux';
import { CategorySingleRequest } from 'src/actions';
import { useParams } from 'react-router-dom';
import { useEffect } from 'react';
import { useSelector } from 'src/reducers';
import MessageContext from 'src/context/MessageContext';
import TopBarForm from 'src/components/shared/TopBarForm';
import { ScheduleJobForm } from 'src/components/admin/_dashboard/service/schedule';
import { ScheduleJobUpdateRequest } from 'src/actions/serviceActions';
import { ScheduleJob } from 'src/types/scheduleJobTypes';

export default function EditScheduleJob() {

    const { setMessageHandler } = useContext<any>(MessageContext);
    const dispatch = useDispatch();
    const { jobid }: any = useParams<any>();
    const submitForm = async (values: ScheduleJob, setSubmitting: any) => {
        setTimeout(() => dispatch(ScheduleJobUpdateRequest(jobid, values, setMessageHandler)), 200);
    }
    const { scheduleJob } = useSelector(state => state.scheduleJob);
    const initialValues: any = scheduleJob

    useEffect(() => {
        dispatch(CategorySingleRequest(jobid));
    }, [jobid])

    return (
        <Container maxWidth={false}>
           <TopBarForm title="Edit Schedule Job"  link="/admin/support/job"/>
            <ScheduleJobForm initialValues={initialValues} submitForm={submitForm} />
        </Container>
    );
}
