import { ThunkAction, ThunkDispatch } from "redux-thunk";
import Prefix from 'src/helpers/ApiPrefix';
import AXIOS from 'src/helpers/Axios';
import Headers from 'src/helpers/Headers';
import { bidAction } from "src/reducers/bidReducer";
import { bidActionTypes } from "src/types/actionTypes";
import { Bid, BidList } from "src/types/bidTypes";
import fileDownload from 'js-file-download'
import { ErrorAction, SuccessAction } from "src/types/responseTypes";

export const BidFailure = (message: string): ErrorAction => {
    return {
        type: bidActionTypes.BID_FAILURE,
        payload: { message: message }
    }
}
export const BidSuccess = (payload: BidList): SuccessAction<BidList> => {
    return {
        type: bidActionTypes.FETCH_BID_SUCCESS,
        payload: payload,
    }
}
export const BidAllSuccess = (payload: BidList): SuccessAction<BidList> => {
    return {
        type: bidActionTypes.FETCH_BID_ALL_SUCCESS,
        payload: payload,
    }
}

export const BidContactAllSuccess = (payload: BidList): SuccessAction<BidList> => {
    return {
        type: bidActionTypes.FETCH_BID_CONTACT_ALL_SUCCESS,
        payload: payload,
    }
}

export const BidAddSuccess = (payload: Bid): SuccessAction<Bid> => {
    return {
        type: bidActionTypes.ADD_BID_SUCCESS,
        payload: payload,
    }
}
export const BidSingleSuccess = (payload: Bid): SuccessAction<Bid> => {
    return {
        type: bidActionTypes.FETCH_SINGLE_BID_SUCCESS,
        payload: payload,
    }
}

export const BidUpdateSuccess = (payload: any): SuccessAction<any> => {
    return {
        type: bidActionTypes.UPDATE_BID_SUCCESS,
        payload: payload,
    }
}


export const BidDeleteSuccess = (payload: any): SuccessAction<any> => {
    return {
        type: bidActionTypes.DELETE_BID_SUCCESS,
        payload: payload,
    }
}


export const BidAllRequest = (filter: any, search: string, pageNo: number, limit: number, status: boolean):
    ThunkAction<Promise<void>, {}, {}, bidAction> => {
    return (dispatch: ThunkDispatch<{}, {}, bidAction>) => {
        dispatch({ type:bidActionTypes.BID_REQUEST,payload: "" });
        return AXIOS.post(`${Prefix.api}/admin/bids`, { filter, search, pageNo, limit, status }, { headers: Headers() }).then(res => {
            if (res) {
                const { data } = res;
                const bids: BidList = data.result
                dispatch(BidSuccess(bids))

            }
        }).catch((e) => {
            if (e.response) {
                dispatch(BidFailure(e.response.data.message));
            }
            else {
                dispatch(BidFailure(e.message));
            }
        })
    }
}

export const BidAllRequestData = (searchBid:string):
    ThunkAction<Promise<void>, {}, {}, bidAction> => {
    return (dispatch: ThunkDispatch<{}, {}, bidAction>) => {
        return AXIOS.get(`${Prefix.api}/admin/bids/all?search=${searchBid}`, { headers: Headers() }).then(res => {
            if (res) {
                const { data } = res;
                const bids: BidList = data.result
                dispatch(BidAllSuccess(bids))
            }
        }).catch((e) => {
            if (e.response) {
                dispatch(BidFailure(e.response.data.message));
            }
            else {
                dispatch(BidFailure(e.message));
            }
        })
    }
}

export const BidAllInvoiceRequestData = (searchBid:string):
    ThunkAction<Promise<void>, {}, {}, bidAction> => {
    return (dispatch: ThunkDispatch<{}, {}, bidAction>) => {
        return AXIOS.get(`${Prefix.api}/admin/bids/invoice/all?search=${searchBid}`, { headers: Headers() }).then(res => {
            if (res) {
                const { data } = res;
                const bids: BidList = data.result
                dispatch(BidAllSuccess(bids))
            }
        }).catch((e) => {
            if (e.response) {
                dispatch(BidFailure(e.response.data.message));
            }
            else {
                dispatch(BidFailure(e.message));
            }
        })
    }
}




export const BidAllRequestDataWithFilter = (body:any):
    ThunkAction<Promise<void>, {}, {}, bidAction> => {
    return (dispatch: ThunkDispatch<{}, {}, bidAction>) => {
        return AXIOS.post(`${Prefix.api}/admin/bids/filter/all`,body, { headers: Headers() }).then(res => {
            if (res) {
                const { data } = res;
                const bids: BidList = data.result
                dispatch(BidAllSuccess(bids))
            }
        }).catch((e) => {
            if (e.response) {
                dispatch(BidFailure(e.response.data.message));
            }
            else {
                dispatch(BidFailure(e.message));
            }
        })
    }
}


export const BidAllContactRequestData = (clientId:string):
    ThunkAction<Promise<void>, {}, {}, bidAction> => {
    return (dispatch: ThunkDispatch<{}, {}, bidAction>) => {
        return AXIOS.post(`${Prefix.api}/admin/bids/contact/all/${clientId}`,{}, { headers: Headers() }).then(res => {
            if (res) {
                const { data } = res;
                const bids: BidList = data.result
                dispatch(BidContactAllSuccess(bids))
            }
        }).catch((e) => {
            if (e.response) {
                dispatch(BidFailure(e.response.data.message));
            }
            else {
                dispatch(BidFailure(e.message));
            }
        })
    }
}






export const BidAddRequest = (bid: Bid, setMessageHandler: any, setSubmitting: any):
    ThunkAction<Promise<void>, {}, {}, bidAction> => {
    setMessageHandler({
        hasMessage: false,
        type: '',
        message: '',
    });
    return (dispatch: ThunkDispatch<{}, {}, bidAction>) => {
        return AXIOS.post(`${Prefix.api}/admin/bid/add`, bid, { headers: Headers() }).then(res => {
            if (res) {
                const { data } = res;
                const bid: Bid = data.result;
                dispatch(BidAddSuccess(bid))
                setSubmitting(false);
                setMessageHandler({
                    hasMessage: true,
                    type: 'success',
                    message: data.message,
                });
            }
        }).catch((e) => {
            if (e.response) {
                setSubmitting(false);
                dispatch(BidFailure(e.response.data.message));
                setMessageHandler({
                    hasMessage: true,
                    type: 'error',
                    message: e.response.data.message,
                });
            }
            else {
                setSubmitting(false);
                dispatch(BidFailure(e.message));
                setMessageHandler({
                    hasMessage: true,
                    type: 'error',
                    message: e.message,
                });
            }
        })
    }
}


export const BidUpdateRequest = (catid: string, bid: Bid, setMessageHandler: any, setSubmitting: any):
    ThunkAction<Promise<void>, {}, {}, bidAction> => {
    setMessageHandler({
        hasMessage: false,
        type: '',
        message: '',
    });
    return (dispatch: ThunkDispatch<{}, {}, bidAction>) => {
        return AXIOS.put(`${Prefix.api}/admin/bid/update/${catid}`, bid, { headers: Headers() }).then(res => {
            if (res) {
                const { data } = res;
                const bid: Bid = data.result;
                dispatch(BidUpdateSuccess(bid))
                setSubmitting(false);
                setMessageHandler({
                    hasMessage: true,
                    type: 'success',
                    message: data.message,
                });
            }
        }).catch((e) => {
            if (e.response) {
                setSubmitting(false);
                setMessageHandler({
                    hasMessage: true,
                    type: 'error',
                    message: e.response.data.message,
                });
                dispatch(BidFailure(e.response.data.message));
            }
            else {
                setSubmitting(false);
                setMessageHandler({
                    hasMessage: true,
                    type: 'error',
                    message: e.message,
                });
                dispatch(BidFailure(e.message));
            }
        })
    }
}

export const BidStatusUpdateRequest = (bidid: string, bid: Bid, setMessageHandler: any):
    ThunkAction<Promise<void>, {}, {}, bidAction> => {
    setMessageHandler({
        hasMessage: false,
        type: '',
        message: '',
    });
    return (dispatch: ThunkDispatch<{}, {}, bidAction>) => {
        return AXIOS.put(`${Prefix.api}/admin/bid/update/${bidid}`, bid, { headers: Headers() }).then(res => {
            if (res) {
                const { data } = res;
                const bid: Bid = data.result;
                dispatch(BidUpdateSuccess(bid))
                setMessageHandler({
                    hasMessage: true,
                    type: 'success',
                    message: data.message,
                });
            }
        }).catch((e) => {
            if (e.response) {
                setMessageHandler({
                    hasMessage: true,
                    type: 'error',
                    message: e.response.data.message,
                });
                dispatch(BidFailure(e.response.data.message));
            }
            else {
                setMessageHandler({
                    hasMessage: true,
                    type: 'error',
                    message: e.message,
                });
                dispatch(BidFailure(e.message));
            }
        })
    }
}


export const BidSingleRequest = (catid: string):
    ThunkAction<Promise<void>, {}, {}, bidAction> => {
    return (dispatch: ThunkDispatch<{}, {}, bidAction>) => {
        return AXIOS.get(`${Prefix.api}/admin/bid/${catid}`, { headers: Headers() }).then(res => {
            if (res) {
                const { data } = res;
                const bid: Bid = data.result;
                dispatch(BidSingleSuccess(bid));
            }
        }).catch((e) => {
            if (e.response) {
                dispatch(BidFailure(e.response.data.message));
            }
            else {
                dispatch(BidFailure(e.message));
            }
        })
    }
}


export const BidDeleteRequest = (id: string, body: Bid, setMessageHandler: any):
    ThunkAction<Promise<void>, {}, {}, bidAction> => {
    setMessageHandler({
        hasMessage: false,
        type: '',
        message: '',
    });
    return (dispatch: ThunkDispatch<{}, {}, bidAction>) => {
        return AXIOS.put(`${Prefix.api}/admin/bid/update/${id}`, body, { headers: Headers() }).then(res => {
            if (res) {
                const { data } = res;
                const bid: Bid = data.result;
                dispatch(BidDeleteSuccess(bid))
                setMessageHandler({
                    hasMessage: true,
                    type: 'success',
                    message: data.message,
                });
            }
        }).catch((e) => {
            if (e.response) {
                dispatch(BidFailure(e.response.data.message));
                setMessageHandler({
                    hasMessage: true,
                    type: 'error',
                    message: e.response.data.message,
                });
            }
            else {
                dispatch(BidFailure(e.message));
                setMessageHandler({
                    hasMessage: true,
                    type: 'error',
                    message: e.message,
                });
            }
        })
    }
}

export const BidDownloadRequest = ( body: any ,fileName:string ):
    ThunkAction<Promise<void>, {}, {}, bidAction> => {
    return (dispatch: ThunkDispatch<{}, {}, bidAction>) => {
        return AXIOS.post(`${Prefix.api}/admin/bid/report`, body, { responseType: 'blob', headers: Headers() }).then(res => {
            if (res) {
                const { data } = res;
                fileDownload(data, `${fileName}.csv`);
            }
        }).catch((e) => {
            if (e.response) {
                dispatch(BidFailure(e.response.data.message));
            }
            else {
                dispatch(BidFailure(e.message));

            }
        })
    }
}





