import { supplierRuleActionTypes } from "src/types/actionTypes";
import { SupplierRule, SupplierRuleList } from "src/types/supplierRuleTypes";
import { ErrorAction, SuccessAction } from "src/types/responseTypes";

export type supplierRuleAction = SuccessAction<SupplierRuleList> | SuccessAction<SupplierRule> | SuccessAction<any> | ErrorAction
export const defaultSupplierRuleState: SupplierRuleList = { loading:true , redirectTo: '',supplierRules:[], supplierRule: {}, data: [], metadata: {} };

const reducer = (state: SupplierRuleList = defaultSupplierRuleState, action: supplierRuleAction): SupplierRuleList => {
    switch (action.type) {
        case supplierRuleActionTypes.SUPPLIER_RULE_REQUEST:
            state = { ...state, loading:true }
            return state
        case supplierRuleActionTypes.FETCH_SUPPLIER_RULE_SUCCESS:
            action = <SuccessAction<SupplierRuleList>>action
            state = { ...state, redirectTo: '', data: action.payload.data, metadata: action.payload.metadata,loading:false }
            return state
        case supplierRuleActionTypes.FETCH_SUPPLIER_RULE_ALL_SUCCESS:
            action = <SuccessAction<SupplierRuleList>>action
            state = { ...state, supplierRules: action.payload,loading:false }
            return state
        case supplierRuleActionTypes.UPDATE_SUPPLIER_RULE_SUCCESS:
            action = <SuccessAction<any>>action
            state = { ...state, redirectTo: '/admin/library/rule', data: state.data?.map(dat => [ dat._id ==action.payload._id && Object.assign(dat ,action.payload)].find(o => o._id == dat._id) || dat) }
            return state
        case supplierRuleActionTypes.FETCH_SINGLE_SUPPLIER_RULE_SUCCESS:
            action = <SuccessAction<SupplierRule>>action
            state = { ...state, supplierRule: action.payload }
            return state
        case supplierRuleActionTypes.DELETE_SUPPLIER_RULE_SUCCESS:
            action = <SuccessAction<any>>action
            state = { ...state, data: state.data?.filter(dat => dat._id !== action.payload._id), metadata: { ...state.metadata, limit: Number(state.metadata?.limit) - 1, total: Number(state.metadata?.total) - 1 } }
            return state
        case supplierRuleActionTypes.ADD_SUPPLIER_RULE_SUCCESS:
            action = <SuccessAction<SupplierRule>>action
            state = { ...state, redirectTo: '/admin/library/rule', data: state.data?.concat(action.payload) }
            return state
        case supplierRuleActionTypes.SUPPLIER_RULE_FAILURE:
            action = <ErrorAction>action
            return { ...state }
        default:
            return state
    }
}
export default reducer