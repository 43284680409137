import { useEffect } from "react";
import { Outlet, useNavigate } from 'react-router-dom';
import { useSelector } from "src/reducers";


export default function AuthLayout() {

  const admin = useSelector(state => state.admin);
  const navigate = useNavigate();
  const adminInfo = admin?.admin?.adminInfo;
  const logined = !!admin?.admin?.adminInfo;
  const role =adminInfo?.role;

  useEffect(() => {
    if (logined && (role == 'SUPPLIER'))  navigate('/supplier/project',{replace:true})
    else if(logined)  navigate('/admin',{replace:true})
  }, [logined,role]);

  return (
      <Outlet />
  );
}
