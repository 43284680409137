import { LoadingButton } from '@mui/lab';
import { Grid, List, Checkbox, ListItemText, ListItem, TextField, FormControlLabel } from '@mui/material';
import { Form, Formik, FormikProps } from 'formik';
import { useContext } from 'react';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';
import { ProjectCloneRequest } from 'src/actions/projectActions';
import { MessageContext } from 'src/context';
import { useSelector } from 'src/reducers';
export interface FormValues {
    projectId: string,
    setDialogClone: any
}

export default function CloneProjectForm({ projectId, setDialogClone }: FormValues) {

    const SchemaCategoryForm = Yup.object().shape({
        countryId: Yup.string().required('Country   is required'),
        projectCPI: Yup.number().min(1).required('Project CPI  is required'),
        languageId: Yup.string().required('Language  is required'),
    });

    const dispatch = useDispatch();
    const { setMessageHandler } = useContext<any>(MessageContext);

    const cloneProject = (values: any, setSubmitting: any) => {
        dispatch(ProjectCloneRequest(projectId, values, setSubmitting, setMessageHandler));
        setDialogClone(false);
    }
    const { countries, languages } = useSelector(state => state.country);
    return (
        <Formik
            onSubmit={(values, { setSubmitting }) => {
                cloneProject(values, setSubmitting);
            }}
            validateOnBlur={false}
            validateOnChange={true}
            validationSchema={SchemaCategoryForm}
            enableReinitialize={true}
            initialValues={{
                isPreScreen: false,
                isPostScreen: false,
                isSupplier: false,
                isLiveUrl: false,
                isQuota: false,
                projectCPI:0,
                countryId: "",
                languageId: ""
            }}
        >
            {(formikProps: FormikProps<any>) => (
                <Form autoComplete="off" noValidate onSubmit={formikProps.handleSubmit}>
                    <Grid
                        container
                        spacing={3}
                        width={300}
                    >
                        <Grid
                            item
                            md={6}
                            xs={12}
                        >
                            <List>
                                <ListItem dense>
                                    <Checkbox
                                        edge="start"
                                        tabIndex={-1}
                                        disableRipple
                                        {...formikProps.getFieldProps('isPreScreen')}
                                    />
                                    <ListItemText primary={`Prescreen`} />
                                </ListItem>
                                <ListItem dense>
                                    <Checkbox
                                        edge="start"
                                        tabIndex={-1}
                                        disableRipple
                                        {...formikProps.getFieldProps('isPostScreen')}
                                    />
                                    <ListItemText primary={`Postscreen`} />
                                </ListItem>
                            </List>
                        </Grid>
                        <Grid
                            item
                            md={6}
                            xs={12}
                        >
                            <List>
                                <ListItem dense>
                                    <Checkbox
                                        edge="start"
                                        tabIndex={-1}
                                        disableRipple
                                        {...formikProps.getFieldProps('isSupplier')}
                                    />
                                    <ListItemText primary={`Supplier`} />
                                </ListItem>
                                <ListItem dense>
                                    <Checkbox
                                        edge="start"
                                        tabIndex={-1}
                                        disableRipple
                                        {...formikProps.getFieldProps('isQuota')}
                                    />
                                    <ListItemText primary={`Quota`} />
                                </ListItem>
                            </List>
                        </Grid>
                                <ListItem sx={{ml:3,mt:-1}} dense>
                                    <Checkbox
                                        edge="start"
                                        tabIndex={-1}
                                        disableRipple
                                        {...formikProps.getFieldProps('isLiveUrl')}
                                    />
                                    <ListItemText primary={`Live Url`} />
                                </ListItem>
                        <Grid
                            item
                            md={12}
                            xs={12}
                        >
                            <TextField
                                select
                                label="Country Name"
                                error={Boolean(formikProps.touched.countryId && formikProps.errors.countryId)}
                                fullWidth
                                helperText={formikProps.touched.countryId && formikProps.errors.countryId}
                                {...formikProps.getFieldProps('countryId')}
                                SelectProps={{
                                    native: true,
                                }}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                variant="standard"
                            >
                                <option key="" value="">
                                    {"Select Country"}
                                </option>
                                {countries.map((country) => (
                                    <option key={country._id} value={country._id}>
                                        {country.label}
                                    </option>
                                ))}

                            </TextField>
                        </Grid>
                        <Grid
                            item
                            md={12}
                            xs={12}
                        >
                            <TextField
                                select
                                label="Language"
                                error={Boolean(formikProps.touched.languageId && formikProps.errors.languageId)}
                                fullWidth
                                helperText={formikProps.touched.languageId && formikProps.errors.languageId}
                                {...formikProps.getFieldProps('languageId')}
                                SelectProps={{
                                    native: true,
                                }}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                variant="standard"
                            >
                                <option key="" value="">
                                    {"Select Language"}
                                </option>
                                {languages.map((language) => (
                                    <option key={language._id} value={language._id}>
                                        {language.label}
                                    </option>
                                ))}

                            </TextField>
                        </Grid>
                        <Grid
                            item
                            md={12}
                            xs={12}
                        >
                        <TextField
                                error={Boolean(formikProps.touched.projectCPI && formikProps.errors.projectCPI)}
                                fullWidth
                                helperText={formikProps.touched.projectCPI && formikProps.errors.projectCPI}
                                label="Project CPI"
                                {...formikProps.getFieldProps('projectCPI')}
                                variant="standard"
                            />
                     </Grid>
                        <Grid
                            item
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            md={12}
                            xs={12}
                        >
                            <LoadingButton
                                size="medium"
                                type="submit"
                                loading={formikProps.isSubmitting}
                                variant="contained"
                            >
                                Clone Project
                            </LoadingButton>
                        </Grid>
                    </Grid>
                </Form>
            )}
        </Formik>
    );
}
