import { systemLogActionTypes } from "src/types/actionTypes";
import { ErrorAction, SuccessAction } from "src/types/responseTypes";
import { SystemLog, SystemLogList } from "src/types/systemLogTypes";

export type countryAction = SuccessAction<SystemLogList> | SuccessAction<SystemLog> | SuccessAction<any> | ErrorAction
export const defaultCountryState: SystemLogList = { loading:false, data: [],metadata:{} };

const reducer = (state: SystemLogList = defaultCountryState, action: countryAction): SystemLogList => {
    switch (action.type) {
        case systemLogActionTypes.SYSTEM_LOG_REQUEST:
            state = { ...state ,loading:true,}
            return state
        case systemLogActionTypes.FETCH_SYSTEM_LOG_SUCCESS:
            action = <SuccessAction<SystemLogList>>action
            state = { ...state,loading:false, data: action.payload.data,metadata:  action.payload.metadata}
            return state
        case systemLogActionTypes.SYSTEM_LOG_FAILURE:
            action = <ErrorAction>action
            return { ...state }
        default:
            return state
    }
}

export default reducer