import { ThunkAction, ThunkDispatch } from "redux-thunk";
import Prefix from 'src/helpers/ApiPrefix';
import AXIOS from 'src/helpers/Axios';
import Headers from 'src/helpers/Headers';
import { clientAction } from "src/reducers/clientReducer";
import { clientActionTypes } from "src/types/actionTypes";
import { Client, ClientList } from "src/types/clientTypes";
import { ErrorAction, SuccessAction } from "src/types/responseTypes";

export const ClientFailure = (message: string): ErrorAction => {
    return {
        type: clientActionTypes.CLIENT_FAILURE,
        payload: { message: message }
    }
}
export const ClientSuccess = (payload: ClientList): SuccessAction<ClientList> => {
    return {
        type: clientActionTypes.FETCH_CLIENT_SUCCESS,
        payload: payload,
    }
}
export const ClientAllSuccess = (payload: ClientList): SuccessAction<ClientList> => {
    return {
        type: clientActionTypes.FETCH_CLIENT_ALL_SUCCESS,
        payload: payload,
    }
}

export const ClientAddSuccess = (payload: Client): SuccessAction<Client> => {
    return {
        type: clientActionTypes.ADD_CLIENT_SUCCESS,
        payload: payload,
    }
}
export const ClientSingleSuccess = (payload: Client): SuccessAction<Client> => {
    return {
        type: clientActionTypes.FETCH_SINGLE_CLIENT_SUCCESS,
        payload: payload,
    }
}

export const ClientUpdateSuccess = (payload: any): SuccessAction<any> => {
    return {
        type: clientActionTypes.UPDATE_CLIENT_SUCCESS,
        payload: payload,
    }
}


export const ClientDeleteSuccess = (payload: any): SuccessAction<any> => {
    return {
        type: clientActionTypes.DELETE_CLIENT_SUCCESS,
        payload: payload,
    }
}


export const ClientAllRequest = (filter: any, search: string, pageNo: number, limit: number, status: boolean):
    ThunkAction<Promise<void>, {}, {}, clientAction> => {
    return (dispatch: ThunkDispatch<{}, {}, clientAction>) => {
        dispatch({ type:clientActionTypes.CLIENT_REQUEST,payload: "" });
        return AXIOS.post(`${Prefix.api}/admin/clients`, { filter, search, pageNo, limit, status }, { headers: Headers() }).then(res => {
            if (res) {
                const { data } = res;
                const clients: ClientList = data.result
                dispatch(ClientSuccess(clients))

            }
        }).catch((e) => {
            if (e.response) {
                dispatch(ClientFailure(e.response.data.message));
            }
            else {
                dispatch(ClientFailure(e.message));
            }
        })
    }
}

export const ClientAllRequestData = (searchClient:string):
    ThunkAction<Promise<void>, {}, {}, clientAction> => {
    return (dispatch: ThunkDispatch<{}, {}, clientAction>) => {
        return AXIOS.get(`${Prefix.api}/admin/clients/all?search=${searchClient}`, { headers: Headers() }).then(res => {
            if (res) {
                const { data } = res;
                const clients: ClientList = data.result
                dispatch(ClientAllSuccess(clients))
            }
        }).catch((e) => {
            if (e.response) {
                dispatch(ClientFailure(e.response.data.message));
            }
            else {
                dispatch(ClientFailure(e.message));
            }
        })
    }
}

export const ClientAllInvoiceRequestData = (searchClient:string):
    ThunkAction<Promise<void>, {}, {}, clientAction> => {
    return (dispatch: ThunkDispatch<{}, {}, clientAction>) => {
        return AXIOS.get(`${Prefix.api}/admin/clients/invoice/all?search=${searchClient}`, { headers: Headers() }).then(res => {
            if (res) {
                const { data } = res;
                const clients: ClientList = data.result
                dispatch(ClientAllSuccess(clients))
            }
        }).catch((e) => {
            if (e.response) {
                dispatch(ClientFailure(e.response.data.message));
            }
            else {
                dispatch(ClientFailure(e.message));
            }
        })
    }
}




export const ClientAllRequestDataWithFilter = (body:any):
    ThunkAction<Promise<void>, {}, {}, clientAction> => {
    return (dispatch: ThunkDispatch<{}, {}, clientAction>) => {
        return AXIOS.post(`${Prefix.api}/admin/clients/filter/all`,body, { headers: Headers() }).then(res => {
            if (res) {
                const { data } = res;
                const clients: ClientList = data.result
                dispatch(ClientAllSuccess(clients))
            }
        }).catch((e) => {
            if (e.response) {
                dispatch(ClientFailure(e.response.data.message));
            }
            else {
                dispatch(ClientFailure(e.message));
            }
        })
    }
}




export const ClientAddRequest = (client: Client, setMessageHandler: any, setSubmitting: any):
    ThunkAction<Promise<void>, {}, {}, clientAction> => {
    setMessageHandler({
        hasMessage: false,
        type: '',
        message: '',
    });
    return (dispatch: ThunkDispatch<{}, {}, clientAction>) => {
        return AXIOS.post(`${Prefix.api}/admin/client/add`, client, { headers: Headers() }).then(res => {
            if (res) {
                const { data } = res;
                const client: Client = data.result;
                dispatch(ClientAddSuccess(client))
                setSubmitting(false);
                setMessageHandler({
                    hasMessage: true,
                    type: 'success',
                    message: data.message,
                });
            }
        }).catch((e) => {
            if (e.response) {
                setSubmitting(false);
                dispatch(ClientFailure(e.response.data.message));
                setMessageHandler({
                    hasMessage: true,
                    type: 'error',
                    message: e.response.data.message,
                });
            }
            else {
                setSubmitting(false);
                dispatch(ClientFailure(e.message));
                setMessageHandler({
                    hasMessage: true,
                    type: 'error',
                    message: e.message,
                });
            }
        })
    }
}


export const ClientUpdateRequest = (catid: string, client: Client, setMessageHandler: any, setSubmitting: any):
    ThunkAction<Promise<void>, {}, {}, clientAction> => {
    setMessageHandler({
        hasMessage: false,
        type: '',
        message: '',
    });
    return (dispatch: ThunkDispatch<{}, {}, clientAction>) => {
        return AXIOS.put(`${Prefix.api}/admin/client/update/${catid}`, client, { headers: Headers() }).then(res => {
            if (res) {
                const { data } = res;
                const client: Client = data.result;
                dispatch(ClientUpdateSuccess(client))
                setSubmitting(false);
                setMessageHandler({
                    hasMessage: true,
                    type: 'success',
                    message: data.message,
                });
            }
        }).catch((e) => {
            if (e.response) {
                setSubmitting(false);
                setMessageHandler({
                    hasMessage: true,
                    type: 'error',
                    message: e.response.data.message,
                });
                dispatch(ClientFailure(e.response.data.message));
            }
            else {
                setSubmitting(false);
                setMessageHandler({
                    hasMessage: true,
                    type: 'error',
                    message: e.message,
                });
                dispatch(ClientFailure(e.message));
            }
        })
    }
}

export const ClientStatusUpdateRequest = (clientid: string, client: Client, setMessageHandler: any):
    ThunkAction<Promise<void>, {}, {}, clientAction> => {
    setMessageHandler({
        hasMessage: false,
        type: '',
        message: '',
    });
    return (dispatch: ThunkDispatch<{}, {}, clientAction>) => {
        return AXIOS.put(`${Prefix.api}/admin/client/update/${clientid}`, client, { headers: Headers() }).then(res => {
            if (res) {
                const { data } = res;
                const client: Client = data.result;
                dispatch(ClientUpdateSuccess(client))
                setMessageHandler({
                    hasMessage: true,
                    type: 'success',
                    message: data.message,
                });
            }
        }).catch((e) => {
            if (e.response) {
                setMessageHandler({
                    hasMessage: true,
                    type: 'error',
                    message: e.response.data.message,
                });
                dispatch(ClientFailure(e.response.data.message));
            }
            else {
                setMessageHandler({
                    hasMessage: true,
                    type: 'error',
                    message: e.message,
                });
                dispatch(ClientFailure(e.message));
            }
        })
    }
}


export const ClientSingleRequest = (catid: string):
    ThunkAction<Promise<void>, {}, {}, clientAction> => {
    return (dispatch: ThunkDispatch<{}, {}, clientAction>) => {
        return AXIOS.get(`${Prefix.api}/admin/client/${catid}`, { headers: Headers() }).then(res => {
            if (res) {
                const { data } = res;
                const client: Client = data.result;
                dispatch(ClientSingleSuccess(client));
            }
        }).catch((e) => {
            if (e.response) {
                dispatch(ClientFailure(e.response.data.message));
            }
            else {
                dispatch(ClientFailure(e.message));
            }
        })
    }
}


export const ClientDeleteRequest = (id: string, body: Client, setMessageHandler: any):
    ThunkAction<Promise<void>, {}, {}, clientAction> => {
    setMessageHandler({
        hasMessage: false,
        type: '',
        message: '',
    });
    return (dispatch: ThunkDispatch<{}, {}, clientAction>) => {
        return AXIOS.put(`${Prefix.api}/admin/client/update/${id}`, body, { headers: Headers() }).then(res => {
            if (res) {
                const { data } = res;
                const client: Client = data.result;
                dispatch(ClientDeleteSuccess(client))
                setMessageHandler({
                    hasMessage: true,
                    type: 'success',
                    message: data.message,
                });
            }
        }).catch((e) => {
            if (e.response) {
                dispatch(ClientFailure(e.response.data.message));
                setMessageHandler({
                    hasMessage: true,
                    type: 'error',
                    message: e.response.data.message,
                });
            }
            else {
                dispatch(ClientFailure(e.message));
                setMessageHandler({
                    hasMessage: true,
                    type: 'error',
                    message: e.message,
                });
            }
        })
    }
}





