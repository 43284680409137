import {  Grid } from '@mui/material';
import { useDispatch } from 'react-redux';
import { useSelector } from 'src/reducers';
import { useEffect, useState } from 'react';
import { DashboardProjectSecurityDataRequest } from 'src/actions/dashboardActions';
import RenderGraph from './RenderGraph';

export default function DashboardProjectSecuritySection() {

  const [ filter, setFilter] = useState<any>({type:1});
  const dispatch = useDispatch();
  const dashboardData = useSelector(state => state.dashboard);
  const { dashboardProjectSecuritySuccess } = dashboardData;

  var dates:any =[];
  var ipAddress:any =[];
  var vpn:any =[];
  var geoLocation:any =[];
  var deviceStudy:any =[];
  var preScreenDropout:any =[];
  var qualityTerm:any =[];
  var traffic:any =[];
  var completeAllocated:any=[];
  var rd:any=[];

  dashboardProjectSecuritySuccess.map((traff:any) => 
   dates.push(traff.date) && ipAddress.push(traff.ipAddress)  && traffic.push(traff.traffic) && vpn.push(traff.vpn) && deviceStudy.push(traff.deviceStudy) &&  geoLocation.push(traff.geoLocation)
   &&  preScreenDropout.push(traff.preScreenDropout) && qualityTerm.push(traff.qualityTerm) && completeAllocated.push(traff.completeAllocated) && rd.push(traff.rd)
  );
 
  useEffect(() => {
      dispatch(DashboardProjectSecurityDataRequest(filter))
  }, [filter])


  const CHART_DATA = [
    {
      name: 'Traffic',
      data: traffic
    },
    {
      name: 'IP Address',
      data: ipAddress
    },
    {
      name: 'Geo Location',
      data: geoLocation
    },
    {
      name: 'Device Study',
      data: deviceStudy
    },
    {
      name: 'VPN',
      data: vpn
    },
    {
      name: 'Prescreen Dropped',
      data: preScreenDropout
    },
    {
      name: 'Quality Terminate',
      data: qualityTerm
    },
    {
      name: 'Complete Allocated',
      data: completeAllocated
    },
    {
      name: 'RD',
      data: rd
    }
  ];

  var options:any = {
    chart: {
      type: 'bar',
      height: 350
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '55%',
        endingShape: 'rounded'
      },
    },
    dataLabels: {
      enabled: false
    },
    stroke: {
      show: true,
      width: 2,
      colors: ['transparent']
    },
    xaxis: {
      categories: dates
    },
    fill: {
      opacity: 1
    },
    tooltip: {
      shared: true,
      intersect: false,
      y: {
        formatter: (y:number) => {
          if (typeof y !== 'undefined') {
            return `${y}`;
          }
          return y;
        }
      }
    },
  };

  return (
    <Grid item xs={12} md={6} lg={6}>
    <RenderGraph filterType={['client','supplier']} filter={filter} setFilter={setFilter} title="Project Security" subheader="This Year"  chartdata={CHART_DATA} options={options} type="bar"/>
    </Grid>
  );
}
