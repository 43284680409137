import {  Container } from '@mui/material';
import { useDispatch } from 'react-redux';
import { ProjectForm } from 'src/components/admin/_dashboard/project';
import { Project } from 'src/types/projectTypes';
import MessageContext from 'src/context/MessageContext';
import { useContext } from 'react';
import { ProjectAddRequest } from 'src/actions/projectActions';
import TopBarForm from 'src/components/shared/TopBarForm';

export default function AddProject() {
    const { setMessageHandler } = useContext<any>(MessageContext);
    const dispatch = useDispatch();
    const initialValues: any = null;

    const submitForm = async (values: Project, setSubmitting: any) => {
        setTimeout(() => dispatch(ProjectAddRequest(values, setMessageHandler, setSubmitting)), 200);
    }

    return (
        <Container maxWidth={false}>
            <TopBarForm title="Add Project" link="/admin/project"/>
            <ProjectForm initialValues={initialValues} submitForm={submitForm} />
        </Container>
    );
}
