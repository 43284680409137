import {  Grid } from '@mui/material';
import { useDispatch } from 'react-redux';
import { useSelector } from 'src/reducers';
import { useEffect, useState } from 'react';
import {  DashboardProjectExpenseDataRequest } from 'src/actions/dashboardActions';
import RenderGraph from './RenderGraph';

export default function DashboardProjectReveneSection() {

  const [ filter, setFilter] = useState<any>({type:1});
  const dispatch = useDispatch();
  const dashboardData = useSelector(state => state.dashboard);
  const { dashboardProjectExpenseSectionData } = dashboardData;

  var dates: any = [];
  var expected: any = [];
  var actual: any = [];

  dashboardProjectExpenseSectionData.map((expense: any) =>
    dates.push(expense.date) && expected.push(expense.expected) && actual.push(expense.actual)
  );

  useEffect(() => {
    dispatch(DashboardProjectExpenseDataRequest(filter))
  }, [filter])

  const CHART_DATA = [
    {
      name: 'Expected',
      data: expected
    },
    {
      name: 'Actual',
      data: actual
    },
  ];


  var options: any = {
    chart: {
      type: 'bar',
      height: 350
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '55%',
        endingShape: 'rounded'
      },
    },
    dataLabels: {
      enabled: false
    },
    stroke: {
      show: true,
      width: 2,
      colors: ['transparent']
    },
    xaxis: {
      categories: dates
    },
    fill: {
      opacity: 1
    },
    tooltip: {
      y: {
        formatter: function (val: number) {
          return "$ " + val
        }
      }
    }
  };

  return (
    <Grid item xs={12} md={6} lg={6}>
      <RenderGraph filterType={['supplier']} filter={filter} setFilter={setFilter} title="Project Expense" subheader="This Year" chartdata={CHART_DATA} options={options} type="bar" />
    </Grid>
  );
}
