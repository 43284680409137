import { Icon } from '@iconify/react';
import { useRef, useState } from 'react';
import homeFill from '@iconify/icons-eva/home-fill';
import personFill from '@iconify/icons-eva/person-fill';
import settings2Fill from '@iconify/icons-eva/settings-2-fill';
import { Link as RouterLink } from 'react-router-dom';
import { alpha } from '@mui/material/styles';
import { Button, Box, Divider, MenuItem, Typography, Avatar, IconButton } from '@mui/material';
import MenuPopover from 'src/components/MenuPopover';
import { LogoutRequest } from 'src/actions/adminActions';
import { useDispatch } from 'react-redux';
import { useSelector } from 'src/reducers';
import { useContext } from 'react';
import MessageContext from 'src/context/MessageContext';


const MENU_OPTIONS = [
  {
    label: 'Home',
    icon: homeFill,
    linkTo: '/'
  },
  {
    label: 'Profile',
    icon: personFill,
    linkTo: '#'
  },
  {
    label: 'Settings',
    icon: settings2Fill,
    linkTo: '#'
  }
];


export default function AccountPopover() {
  const { setMessageHandler } = useContext<any>(MessageContext);
  const anchorRef = useRef(null);
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const logout = async () => {
    setTimeout(() => dispatch(LogoutRequest(setMessageHandler)), 100)
  }
  function stringAvatar(name: string) {
    return {
      sx: {
        bgcolor: 'primary',
      },
      children: name ? `${name.split(' ')[0][0]}${name.split(' ')[1][0]}` : "",
    };
  }

  const { admin } = useSelector(state => state.admin);

  return (
    <>
      <IconButton
        ref={anchorRef}
        onClick={handleOpen}
        sx={{
          padding: 0,
          width: 44,
          height: 44,
          ...(open && {
            '&:before': {
              zIndex: 1,
              content: "''",
              width: '100%',
              height: '100%',
              borderRadius: '50%',
              position: 'absolute',
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.72)
            }
          })
        }}
      >
        <Avatar {...stringAvatar(admin.adminInfo ? admin.adminInfo.firstName + " " + admin.adminInfo.lastName : '')} />
      </IconButton>

      <MenuPopover
        open={open}
        onClose={handleClose}
        anchorEl={anchorRef.current}
        sx={{ width: 220 }}
      >
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant="subtitle1" noWrap>
            {admin.adminInfo && admin.adminInfo.firstName + " " + admin.adminInfo.lastName}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
            {admin.adminInfo && admin.adminInfo.email}
          </Typography>
        </Box>
        <Divider sx={{ my: 1 }} />
        {MENU_OPTIONS.map((option) => (
          <MenuItem
            key={option.label}
            to={option.linkTo}
            component={RouterLink}
            onClick={handleClose}
            sx={{ typography: 'body2', py: 1, px: 2.5 }}
          >
            <Box
              component={Icon}
              icon={option.icon}
              sx={{
                mr: 2,
                width: 24,
                height: 24
              }}
            />

            {option.label}
          </MenuItem>
        ))}

        <Box sx={{ p: 2, pt: 1.5 }}>
          <Button fullWidth color="inherit" variant="outlined" onClick={() => logout()} >
            Logout
          </Button>
        </Box>
      </MenuPopover>
    </>
  );
}
