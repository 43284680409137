import { ThunkAction, ThunkDispatch } from "redux-thunk";
import Prefix from 'src/helpers/ApiPrefix';
import AXIOS from 'src/helpers/Axios';
import Headers from 'src/helpers/Headers';
import { qualificationAction } from "src/reducers/qualificationReducer";
import {  qualificationActionTypes } from "src/types/actionTypes";
import { ErrorAction, SuccessAction } from "src/types/responseTypes";
import { QualificationList,Qualification } from "src/types/qualificationTypes";

export const QualificationFailure = (message: string): ErrorAction => {
    return {
        type: qualificationActionTypes.QUALIFICATION_FAILURE,
        payload: { message: message }
    }
}
export const QualificationSuccess = (payload: QualificationList): SuccessAction<QualificationList> => {
    return {
        type: qualificationActionTypes.FETCH_QUALIFICATION_SUCCESS,
        payload: payload,
    }
}
export const QualificationAllSuccess = (payload: QualificationList): SuccessAction<QualificationList> => {
    return {
        type: qualificationActionTypes.FETCH_QUALIFICATION_ALL_SUCCESS,
        payload: payload,
    }
}

export const QualificationAddSuccess = (payload: Qualification): SuccessAction<Qualification> => {
    return {
        type: qualificationActionTypes.ADD_QUALIFICATION_SUCCESS,
        payload: payload,
    }
}
export const QualificationSingleSuccess = (payload: Qualification): SuccessAction<Qualification> => {
    return {
        type: qualificationActionTypes.FETCH_SINGLE_QUALIFICATION_SUCCESS,
        payload: payload,
    }
}

export const QualificationUpdateSuccess = (payload: any): SuccessAction<any> => {
    return {
        type: qualificationActionTypes.UPDATE_QUALIFICATION_SUCCESS,
        payload: payload,
    }
}


export const QualificationDeleteSuccess = (payload: any): SuccessAction<any> => {
    return {
        type: qualificationActionTypes.DELETE_QUALIFICATION_SUCCESS,
        payload: payload,
    }
}


export const QualificationAllRequest = (filter: any, search: string, pageNo: number, limit: number, status: boolean):
    ThunkAction<Promise<void>, {}, {}, qualificationAction> => {
    return (dispatch: ThunkDispatch<{}, {}, qualificationAction>) => {
        return AXIOS.post(`${Prefix.api}/admin/qualifications`, { filter, search, pageNo, limit, status }, { headers: Headers() }).then(res => {
            if (res) {
                const { data } = res;
                const qualifications: QualificationList = data.result
                dispatch(QualificationSuccess(qualifications))

            }
        }).catch((e) => {
            if (e.response) {
                dispatch(QualificationFailure(e.response.data.message));
            }
            else {
                dispatch(QualificationFailure(e.message));
            }
        })
    }
}

export const QualificationAllRequestData = ():
    ThunkAction<Promise<void>, {}, {}, qualificationAction> => {
    return (dispatch: ThunkDispatch<{}, {}, qualificationAction>) => {
        return AXIOS.get(`${Prefix.api}/admin/qualifications/all`, { headers: Headers() }).then(res => {
            if (res) {
                const { data } = res;
                const qualifications: QualificationList = data.result
                dispatch(QualificationAllSuccess(qualifications))
            }
        }).catch((e) => {
            if (e.response) {
                dispatch(QualificationFailure(e.response.data.message));
            }
            else {
                dispatch(QualificationFailure(e.message));
            }
        })
    }
}




export const QualificationAddRequest = (qualification: Qualification, setMessageHandler: any,resetForm:any, setSubmitting: any):
    ThunkAction<Promise<void>, {}, {}, qualificationAction> => {
    setMessageHandler({
        hasMessage: false,
        type: '',
        message: '',
    });
    return (dispatch: ThunkDispatch<{}, {}, qualificationAction>) => {
        return AXIOS.post(`${Prefix.api}/admin/qualification/add`, qualification, { headers: Headers() }).then(res => {
            if (res) {
                const { data } = res;
                const qualification: Qualification = data.result;
                dispatch(QualificationAddSuccess(qualification))
                setSubmitting(false);
                resetForm();
                setMessageHandler({
                    hasMessage: true,
                    type: 'success',
                    message: data.message,
                });
            }
        }).catch((e) => {
            if (e.response) {
                setSubmitting(false);
                dispatch(QualificationFailure(e.response.data.message));
                setMessageHandler({
                    hasMessage: true,
                    type: 'error',
                    message: e.response.data.message,
                });
            }
            else {
                setSubmitting(false);
                dispatch(QualificationFailure(e.message));
                setMessageHandler({
                    hasMessage: true,
                    type: 'error',
                    message: e.message,
                });
            }
        })
    }
}


export const QualificationUpdateRequest = (catid: string, qualification: Qualification, setMessageHandler: any):
    ThunkAction<Promise<void>, {}, {}, qualificationAction> => {
    setMessageHandler({
        hasMessage: false,
        type: '',
        message: '',
    });
    return (dispatch: ThunkDispatch<{}, {}, qualificationAction>) => {
        return AXIOS.put(`${Prefix.api}/admin/qualification/update/${catid}`, qualification, { headers: Headers() }).then(res => {
            if (res) {
                const { data } = res;
                const qualification: Qualification = data.result;
                dispatch(QualificationUpdateSuccess(qualification))
                setMessageHandler({
                    hasMessage: true,
                    type: 'success',
                    message: data.message,
                });
            }
        }).catch((e) => {
            if (e.response) {
                setMessageHandler({
                    hasMessage: true,
                    type: 'error',
                    message: e.response.data.message,
                });
                dispatch(QualificationFailure(e.response.data.message));
            }
            else {
                setMessageHandler({
                    hasMessage: true,
                    type: 'error',
                    message: e.message,
                });
                dispatch(QualificationFailure(e.message));
            }
        })
    }
}

export const QualificationStatusUpdateRequest = (qualificationid: string, qualification: Qualification, setMessageHandler: any):
    ThunkAction<Promise<void>, {}, {}, qualificationAction> => {
    setMessageHandler({
        hasMessage: false,
        type: '',
        message: '',
    });
    return (dispatch: ThunkDispatch<{}, {}, qualificationAction>) => {
        return AXIOS.put(`${Prefix.api}/admin/qualification/update/${qualificationid}`, qualification, { headers: Headers() }).then(res => {
            if (res) {
                const { data } = res;
                const qualification: Qualification = data.result;
                dispatch(QualificationUpdateSuccess(qualification))
                setMessageHandler({
                    hasMessage: true,
                    type: 'success',
                    message: data.message,
                });
            }
        }).catch((e) => {
            if (e.response) {
                setMessageHandler({
                    hasMessage: true,
                    type: 'error',
                    message: e.response.data.message,
                });
                dispatch(QualificationFailure(e.response.data.message));
            }
            else {
                setMessageHandler({
                    hasMessage: true,
                    type: 'error',
                    message: e.message,
                });
                dispatch(QualificationFailure(e.message));
            }
        })
    }
}


export const QualificationSingleRequest = (catid: string):
    ThunkAction<Promise<void>, {}, {}, qualificationAction> => {

    return (dispatch: ThunkDispatch<{}, {}, qualificationAction>) => {
        return AXIOS.get(`${Prefix.api}/admin/qualification/${catid}`, { headers: Headers() }).then(res => {
            if (res) {
                const { data } = res;
                const qualification: Qualification = data.result;
                dispatch(QualificationSingleSuccess(qualification));
            }
        }).catch((e) => {
            if (e.response) {
                dispatch(QualificationFailure(e.response.data.message));
            }
            else {
                dispatch(QualificationFailure(e.message));
            }
        })
    }
}


export const QualificationDeleteRequest = (id: string, body: Qualification, setMessageHandler: any):
    ThunkAction<Promise<void>, {}, {}, qualificationAction> => {
    setMessageHandler({
        hasMessage: false,
        type: '',
        message: '',
    });
    return (dispatch: ThunkDispatch<{}, {}, qualificationAction>) => {
        return AXIOS.put(`${Prefix.api}/admin/qualification/update/${id}`, body, { headers: Headers() }).then(res => {
            if (res) {
                const { data } = res;
                const qualification: Qualification = data.result;
                dispatch(QualificationDeleteSuccess(qualification))
                setMessageHandler({
                    hasMessage: true,
                    type: 'success',
                    message: data.message,
                });
            }
        }).catch((e) => {
            if (e.response) {
                dispatch(QualificationFailure(e.response.data.message));
                setMessageHandler({
                    hasMessage: true,
                    type: 'error',
                    message: e.response.data.message,
                });
            }
            else {
                dispatch(QualificationFailure(e.message));
                setMessageHandler({
                    hasMessage: true,
                    type: 'error',
                    message: e.message,
                });
            }
        })
    }
}

