import { surveyActionTypes } from "src/types/actionTypes";
import { Survey, SurveyList } from "src/types/surveyTypes";
import { ErrorAction, SuccessAction } from "src/types/responseTypes";

export type surveyAction = SuccessAction<SurveyList> | SuccessAction<Survey> | SuccessAction<any> | ErrorAction
export const defaultSurveyState: SurveyList = {loading:true , redirectTo: '', surveys: [], survey: {}, data: [], metadata: {} };

const reducer = (state: SurveyList = defaultSurveyState, action: surveyAction): SurveyList => {
    switch (action.type) {
        case surveyActionTypes.SURVEY_REQUEST:
            state = { ...state ,loading:true }
            return state
        case surveyActionTypes.FETCH_SURVEY_SUCCESS:
            action = <SuccessAction<SurveyList>>action
            state = { ...state, redirectTo: '', data: action.payload.data, metadata: action.payload.metadata ,loading:false }
            return state
        case surveyActionTypes.FETCH_SURVEY_ALL_SUCCESS:
            action = <SuccessAction<SurveyList>>action
            state = { ...state, surveys: action.payload }
            return state
        case surveyActionTypes.UPDATE_SURVEY_SUCCESS:
            action = <SuccessAction<any>>action
            state = { ...state, redirectTo: '/admin/survey', data: state.data?.map(dat => [dat._id == action.payload._id && Object.assign(dat, action.payload)].find(o => o._id == dat._id) || dat) }
            return state
        case surveyActionTypes.FETCH_SINGLE_SURVEY_SUCCESS:
            action = <SuccessAction<Survey>>action
            state = { ...state, survey: action.payload }
            return state
        case surveyActionTypes.DELETE_SURVEY_SUCCESS:
            action = <SuccessAction<any>>action
            state = { ...state, data: state.data?.filter(dat => dat._id !== action.payload._id), metadata: { ...state.metadata, limit: Number(state.metadata?.limit) - 1, total: Number(state.metadata?.total) - 1 } }
            return state
        case surveyActionTypes.ADD_SURVEY_SUCCESS:
            action = <SuccessAction<Survey>>action
            state = { ...state, redirectTo: '/admin/survey' }
            return state
        case surveyActionTypes.SURVEY_FAILURE:
            action = <ErrorAction>action
            return { ...state }
        default:
            return state
    }
}
export default reducer