import { Qualification } from 'src/types/qualificationTypes';
import QualificationFormRender from './QualificationFormRender';
import QualificationListItem from './QualificationListItem';

export interface QualificationProps {
    qualifications: Qualification[],
    updateForm: any,
    deleteQualification: any,
    editQualification: Qualification,
    setEditQualification: any,

}

const QualificationListRender = ({ editQualification, setEditQualification, qualifications, updateForm, deleteQualification }: QualificationProps) => {

    return (
        <>
            {editQualification && <QualificationFormRender setEditQualification={setEditQualification} initialValues={editQualification} submitForm={updateForm} />}
            {!editQualification &&
                qualifications.map((qualification, index) =>
                    <QualificationListItem qualification={qualification} index={index} setEditQualification={setEditQualification} deleteQualification={deleteQualification} key={qualification._id || ''} />
                )
            }
        </>
    );
};

export default QualificationListRender;
