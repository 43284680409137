import { ThunkAction, ThunkDispatch } from "redux-thunk";
import Prefix from 'src/helpers/ApiPrefix';
import AXIOS from 'src/helpers/Axios';
import Headers from 'src/helpers/Headers';
import { dashboardAction } from "src/reducers/dashboardReducer";
import { dashboardActionTypes } from "src/types/actionTypes";
import {  DashboardPayload } from "src/types/dashboardTypes";
import { ErrorAction, SuccessAction } from "src/types/responseTypes";

export const DashboardFailure = (message: string): ErrorAction => {
    return {
        type: dashboardActionTypes.DASHBOARD_FAILURE,
        payload: { message: message }
    }
}
export const DashboardBoxSuccess = (payload: DashboardPayload): SuccessAction<DashboardPayload> => {
    return {
        type: dashboardActionTypes.FETCH_DASHBOARD_BOX_SUCCESS,
        payload: payload,
    }
}

export const DashboardProjectListSuccess = (payload: DashboardPayload): SuccessAction<DashboardPayload> => {
    return {
        type: dashboardActionTypes.FETCH_DASHBOARD_PROJECT_LIST_SUCCESS,
        payload: payload,
    }
}
export const DashboardProjectListSecuritySuccess = (payload: DashboardPayload): SuccessAction<DashboardPayload> => {
    return {
        type: dashboardActionTypes.FETCH_DASHBOARD_PROJECT_LIST_SECURITY_SUCCESS,
        payload: payload,
    }
}

export const DashboardProjectManagerSuccess = (payload: DashboardPayload): SuccessAction<DashboardPayload> => {
    return {
        type: dashboardActionTypes.FETCH_DASHBOARD_PROJECT_MANAGER_SUCCESS,
        payload: payload,
    }
}


export const DashboardProjectInvoiceSuccess = (payload: DashboardPayload): SuccessAction<DashboardPayload> => {
    return {
        type: dashboardActionTypes.FETCH_DASHBOARD_PROJECT_INVOICE_SUCCESS,
        payload: payload,
    }
}

export const DashboardProjectStatusSuccess = (payload: DashboardPayload): SuccessAction<DashboardPayload> => {
    return {
        type: dashboardActionTypes.FETCH_DASHBOARD_PROJECT_STATUS_SUCCESS,
        payload: payload,
    }
}

export const DashboardProjectRevenueSuccess = (payload: DashboardPayload): SuccessAction<DashboardPayload> => {
    return {
        type: dashboardActionTypes.FETCH_DASHBOARD_PROJECT_REVENUE_SUCCESS,
        payload: payload,
    }
}

export const DashboardProjectExpenseSuccess = (payload: DashboardPayload): SuccessAction<DashboardPayload> => {
    return {
        type: dashboardActionTypes.FETCH_DASHBOARD_PROJECT_EXPENSE_SUCCESS,
        payload: payload,
    }
}

export const DashboardProjectTrafficSuccess = (payload: DashboardPayload): SuccessAction<DashboardPayload> => {
    return {
        type: dashboardActionTypes.FETCH_DASHBOARD_PROJECT_TRAFFIC_SUCCESS,
        payload: payload,
    }
}


export const DashboardProjectSecuritySuccess = (payload: DashboardPayload): SuccessAction<DashboardPayload> => {
    return {
        type: dashboardActionTypes.FETCH_DASHBOARD_PROJECT_SECURITY_SUCCESS,
        payload: payload,
    }
}



export const DashboardProjectExpenseRevenueSuccess = (payload: DashboardPayload): SuccessAction<DashboardPayload> => {
    return {
        type: dashboardActionTypes.FETCH_DASHBOARD_PROJECT_EXPENSE_REVENUE_SUCCESS,
        payload: payload,
    }
}





export const DashboardBoxDataRequest = ():
    ThunkAction<Promise<void>, {}, {}, dashboardAction> => {
    return (dispatch: ThunkDispatch<{}, {}, dashboardAction>) => {
        return AXIOS.post(`${Prefix.api}/admin/dashboard/box/data`,{}, { headers: Headers() }).then(res => {
            if (res) {
                const { data } = res;
                const categories: DashboardPayload = data.result
                dispatch(DashboardBoxSuccess(categories))
            }
        }).catch((e) => {
            if (e.response) {
                dispatch(DashboardFailure(e.response.data.message));
            }
            else {
                dispatch(DashboardFailure(e.message));
            }
        })
    }
}



export const DashboardProjectListDataRequest = (body:any):
    ThunkAction<Promise<void>, {}, {}, dashboardAction> => {
    return (dispatch: ThunkDispatch<{}, {}, dashboardAction>) => {
        return AXIOS.post(`${Prefix.api}/admin/dashboard/project/list/data`,body, { headers: Headers() }).then(res => {
            if (res) {
                const { data } = res;
                const categories: DashboardPayload = data.result
                dispatch(DashboardProjectListSuccess(categories))
            }
        }).catch((e) => {
            if (e.response) {
                dispatch(DashboardFailure(e.response.data.message));
            }
            else {
                dispatch(DashboardFailure(e.message));
            }
        })
    }
}


export const DashboardProjectListSecurityDataRequest = (body:any):
    ThunkAction<Promise<void>, {}, {}, dashboardAction> => {
    return (dispatch: ThunkDispatch<{}, {}, dashboardAction>) => {
        return AXIOS.post(`${Prefix.api}/admin/dashboard/project/list/security/data`,body, { headers: Headers() }).then(res => {
            if (res) {
                const { data } = res;
                const categories: DashboardPayload = data.result
                dispatch(DashboardProjectListSecuritySuccess(categories))
            }
        }).catch((e) => {
            if (e.response) {
                dispatch(DashboardFailure(e.response.data.message));
            }
            else {
                dispatch(DashboardFailure(e.message));
            }
        })
    }
}



export const DashboardProjectManagerDataRequest = (body:any):
    ThunkAction<Promise<void>, {}, {}, dashboardAction> => {
    return (dispatch: ThunkDispatch<{}, {}, dashboardAction>) => {
        dispatch({ type:dashboardActionTypes.DASHBOARD_REQUEST,payload: { loading:true} });
        return AXIOS.post(`${Prefix.api}/admin/dashboard/pm/data`,body,  { headers: Headers() }).then(res => {
            if (res) {
                const { data } = res;
                const categories: DashboardPayload = data.result
                dispatch(DashboardProjectManagerSuccess(categories))
            }
        }).catch((e) => {
            if (e.response) {
                dispatch(DashboardFailure(e.response.data.message));
            }
            else {
                dispatch(DashboardFailure(e.message));
            }
        })
    }
}

export const DashboardInvoiceDataRequest = (body:any):
    ThunkAction<Promise<void>, {}, {}, dashboardAction> => {
    return (dispatch: ThunkDispatch<{}, {}, dashboardAction>) => {
        return AXIOS.post(`${Prefix.api}/admin/dashboard/project/invoice/data`,body,  { headers: Headers() }).then(res => {
            if (res) {
                const { data } = res;
                const categories: DashboardPayload = data.result
                dispatch(DashboardProjectInvoiceSuccess(categories))
            }
        }).catch((e) => {
            if (e.response) {
                dispatch(DashboardFailure(e.response.data.message));
            }
            else {
                dispatch(DashboardFailure(e.message));
            }
        })
    }
}



export const DashboardProjectStatusDataRequest = (body:any):
    ThunkAction<Promise<void>, {}, {}, dashboardAction> => {
    return (dispatch: ThunkDispatch<{}, {}, dashboardAction>) => {
        return AXIOS.post(`${Prefix.api}/admin/dashboard/project/status/data`,body, { headers: Headers() }).then(res => {
            if (res) {
                const { data } = res;
                const categories: DashboardPayload = data.result
                dispatch(DashboardProjectStatusSuccess(categories))
            }
        }).catch((e) => {
            if (e.response) {
                dispatch(DashboardFailure(e.response.data.message));
            }
            else {
                dispatch(DashboardFailure(e.message));
            }
        })
    }
}

export const DashboardProjectRevenueDataRequest = (body:any):
    ThunkAction<Promise<void>, {}, {}, dashboardAction> => {
    return (dispatch: ThunkDispatch<{}, {}, dashboardAction>) => {
        return AXIOS.post(`${Prefix.api}/admin/dashboard/project/revenue/data`,body, { headers: Headers() }).then(res => {
            if (res) {
                const { data } = res;
                const categories: DashboardPayload = data.result
                dispatch(DashboardProjectRevenueSuccess(categories))
            }
        }).catch((e) => {
            if (e.response) {
                dispatch(DashboardFailure(e.response.data.message));
            }
            else {
                dispatch(DashboardFailure(e.message));
            }
        })
    }
}

export const DashboardProjectExpenseDataRequest = (body:any):
    ThunkAction<Promise<void>, {}, {}, dashboardAction> => {
    return (dispatch: ThunkDispatch<{}, {}, dashboardAction>) => {
        return AXIOS.post(`${Prefix.api}/admin/dashboard/project/expense/data`,body, { headers: Headers() }).then(res => {
            if (res) {
                const { data } = res;
                const categories: DashboardPayload = data.result
                dispatch(DashboardProjectExpenseSuccess(categories))
            }
        }).catch((e) => {
            if (e.response) {
                dispatch(DashboardFailure(e.response.data.message));
            }
            else {
                dispatch(DashboardFailure(e.message));
            }
        })
    }
}

export const DashboardProjectTrafficDataRequest = (body:any):
    ThunkAction<Promise<void>, {}, {}, dashboardAction> => {
    return (dispatch: ThunkDispatch<{}, {}, dashboardAction>) => {
        return AXIOS.post(`${Prefix.api}/admin/dashboard/project/traffic/data`,body,  { headers: Headers() }).then(res => {
            if (res) {
                const { data } = res;
                const categories: DashboardPayload = data.result
                dispatch(DashboardProjectTrafficSuccess(categories))
            }
        }).catch((e) => {
            if (e.response) {
                dispatch(DashboardFailure(e.response.data.message));
            }
            else {
                dispatch(DashboardFailure(e.message));
            }
        })
    }
}



export const DashboardProjectSecurityDataRequest = (body:any):
    ThunkAction<Promise<void>, {}, {}, dashboardAction> => {
    return (dispatch: ThunkDispatch<{}, {}, dashboardAction>) => {
        return AXIOS.post(`${Prefix.api}/admin/dashboard/project/security/data`,body,  { headers: Headers() }).then(res => {
            if (res) {
                const { data } = res;
                const categories: DashboardPayload = data.result
                dispatch(DashboardProjectSecuritySuccess(categories))
            }
        }).catch((e) => {
            if (e.response) {
                dispatch(DashboardFailure(e.response.data.message));
            }
            else {
                dispatch(DashboardFailure(e.message));
            }
        })
    }
}

export const DashboardProjectExpenseRevenueDataRequest = (body:any):
    ThunkAction<Promise<void>, {}, {}, dashboardAction> => {
    return (dispatch: ThunkDispatch<{}, {}, dashboardAction>) => {
        return AXIOS.post(`${Prefix.api}/admin/dashboard/project/expense/revenue/data`,body,  { headers: Headers() }).then(res => {
            if (res) {
                const { data } = res;
                const categories: DashboardPayload = data.result
                dispatch(DashboardProjectExpenseRevenueSuccess(categories))
            }
        }).catch((e) => {
            if (e.response) {
                dispatch(DashboardFailure(e.response.data.message));
            }
            else {
                dispatch(DashboardFailure(e.message));
            }
        })
    }
}









