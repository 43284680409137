import { useEffect, useState ,useContext} from 'react';
import { Container, Tab,Switch, Typography } from '@mui/material';
import { useDispatch } from 'react-redux';
import { useSelector } from 'src/reducers';
import {  DataGridRender, MoreMenu } from 'src/components/table';
import {  ScheduleJobDeleteRequest, ScheduleJobRequest, ScheduleJobUpdateRequest } from 'src/actions/serviceActions';
import TabLayout from 'src/components/tab/TabLayout';
import {  useNavigate } from 'react-router-dom';
import TopBar from 'src/components/shared/TopBar';
import { MessageContext } from 'src/context';
import Label from 'src/components/Label';
import { sentenceCase } from 'change-case';
import { GridRenderCellParams } from '@mui/x-data-grid';

export default function ScheduleJob() {

  const { setMessageHandler } = useContext<any>(MessageContext);
  const [pageNo, setPageNo] = useState(1);
  const [limit, setLimit] = useState(10);
  const [filters, setFilters] = useState<any>({});
  const [search, setSearch] = useState<string>('');

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { loading, data,metadata } = useSelector(state => state.scheduleJob);

  const deleteAction = (id: string) => {
    dispatch(ScheduleJobDeleteRequest(id, { isDeleted: true }, setMessageHandler));
  }

  useEffect(() => {
    dispatch(ScheduleJobRequest(filters));
  }, [filters]);

  const columns = [
    {
      field: 'projectCode', type: "string", headerName: 'Project #', filterable: false, headerAlign: "center", align: 'center', width: 150,  renderCell: ({ id, value }: any) => {
        return  <Typography color="primary" >{value}</Typography>
    },
  },
    { field: 'projectName', type: "string", headerName: 'Project Name', filterable: false, headerAlign: "left", align: 'left', flex: 1 },
    { field: 'interval', type: "string", headerName: 'Interval', filterable: false, headerAlign: "center", align: 'center', width:80 },
    { field: 'comment', type: "string", headerName: 'Comment', filterable: false, headerAlign: "center", align: 'center', width:350},
    { field: 'createdAt', type: "string", headerName: 'Created On', filterable: false, headerAlign: "center", align: 'center', width:160},
    { field: 'fullName', type: "string", headerName: 'Created By', filterable: false, width: 150,headerAlign: "center", align: 'center', renderCell: ({ value }: GridRenderCellParams) =>
        <Label
          variant="ghost"
          color='primary'
        >
          {sentenceCase(value)}
        </Label>
    },
    {
      field: 'isSoft', headerName: 'Soft', type: "number", width: 80, valueFormatter: ({ value }: any) => value ? "Active" : "InActive",
      renderCell: (params: any) => {
        return <Switch checked={params.value} onChange={() => dispatch(ScheduleJobUpdateRequest(params.id, { isSoft: !params.value }, setMessageHandler))} />
      }
    },
    {
      field: 'isActive', headerName: 'Status', type: "number", width: 80, valueFormatter: ({ value }: any) => value ? "Active" : "InActive",
      renderCell: (params: any) => {
        return <Switch checked={params.value} onChange={() => dispatch(ScheduleJobUpdateRequest(params.id, { isActive: !params.value }, setMessageHandler))} />
      }
    },
    { field: 'actions', headerName: '', width: 40, type: 'actions', getActions: ({ id, row }: any) => [<MoreMenu id={id} name={row['clientCode'] + row['clientName']} cloneAction="" addAction='' editAction='' deleteAction={deleteAction} exportAction="" />] },
  ];


  return (
    <Container maxWidth={false}>
       <TopBar title="Support" link="/admin/support/job/add" buttonText="Add Schedule Job" />
        <TabLayout handleChange="" tabValue={4}>
        <Tab label="Identifiers" onClick={()=> navigate("/admin/support" )}  value={1} />
        <Tab label="ShortCodes" onClick={()=> navigate("/admin/support/shortcode" )}  value={2} />
        <Tab label="System Logs" onClick={()=> navigate("/admin/support/log" )}  value={3} />
        <Tab label="Scheduled Jobs" onClick={()=> navigate("/admin/support/job" )} value={4} />
      </TabLayout>
      <DataGridRender
        loading={loading}
        filterTypes={['export','search']}
        limit={limit}
        search={search}
        filters={filters}
        pageNo={pageNo}
        columns={columns}
        data={data}
        setPageNo={setPageNo}
        setSearch={setSearch}
        metadata={metadata}
        setLimit={setLimit}
        setFilters={setFilters}
        exportFileName="Systemlogs"
      />
    </Container>
  );
}
