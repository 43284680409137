import { categoryActionTypes } from "src/types/actionTypes";
import { Category, CategoryList } from "src/types/categoryTypes";
import { ErrorAction, SuccessAction } from "src/types/responseTypes";

export type categoryAction = SuccessAction<CategoryList> | SuccessAction<Category> | SuccessAction<any> | ErrorAction
export const defaultCategoryState: CategoryList = { loading:true , redirectTo: '',categories:[], category: {}, data: [], metadata: {} };

const reducer = (state: CategoryList = defaultCategoryState, action: categoryAction): CategoryList => {
    switch (action.type) {
        case categoryActionTypes.CATEGORY_REQUEST:
            state = { ...state, loading:true }
            return state
        case categoryActionTypes.FETCH_CATEGORY_SUCCESS:
            action = <SuccessAction<CategoryList>>action
            state = { ...state,loading:false, redirectTo: '', data: action.payload.data, metadata: action.payload.metadata }
            return state
        case categoryActionTypes.FETCH_CATEGORY_ALL_SUCCESS:
            action = <SuccessAction<CategoryList>>action
            state = { ...state, categories: action.payload,loading:false }
            return state
        case categoryActionTypes.UPDATE_CATEGORY_SUCCESS:
            action = <SuccessAction<any>>action
            state = { ...state, redirectTo: '/admin/library', data: state.data?.map(dat => [ dat._id ==action.payload._id && Object.assign(dat ,action.payload)].find(o => o._id == dat._id) || dat) }
            return state
        case categoryActionTypes.FETCH_SINGLE_CATEGORY_SUCCESS:
            action = <SuccessAction<Category>>action
            state = { ...state, category: action.payload }
            return state
        case categoryActionTypes.DELETE_CATEGORY_SUCCESS:
            action = <SuccessAction<any>>action
            state = { ...state, data: state.data?.filter(dat => dat._id !== action.payload._id), metadata: { ...state.metadata, limit: Number(state.metadata?.limit) - 1, total: Number(state.metadata?.total) - 1 } }
            return state
        case categoryActionTypes.ADD_CATEGORY_SUCCESS:
            action = <SuccessAction<Category>>action
            state = { ...state, redirectTo: '/admin/library', data: state.data?.concat(action.payload) }
            return state
        case categoryActionTypes.CATEGORY_FAILURE:
            action = <ErrorAction>action
            return { ...state }
        default:
            return state
    }
}
export default reducer