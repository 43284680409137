import { ThunkAction, ThunkDispatch } from "redux-thunk";
import Prefix from 'src/helpers/ApiPrefix';
import AXIOS from 'src/helpers/Axios';
import Headers from 'src/helpers/Headers';
import { surveyAction } from "src/reducers/surveyReducer";
import { questionActionTypes, surveyActionTypes } from "src/types/actionTypes";
import { Question, QuestionList,  Survey, SurveyList } from "src/types/surveyTypes";
import { ErrorAction, SuccessAction } from "src/types/responseTypes";

export const SurveyFailure = (message: string): ErrorAction => {
    return {
        type: surveyActionTypes.SURVEY_FAILURE,
        payload: { message: message }
    }
}
export const SurveySuccess = (payload: SurveyList): SuccessAction<SurveyList> => {
    return {
        type: surveyActionTypes.FETCH_SURVEY_SUCCESS,
        payload: payload,
    }
}
export const SurveyAllSuccess = (payload: SurveyList): SuccessAction<SurveyList> => {
    return {
        type: surveyActionTypes.FETCH_SURVEY_ALL_SUCCESS,
        payload: payload,
    }
}

export const SurveyAddSuccess = (payload: Survey): SuccessAction<Survey> => {
    return {
        type: surveyActionTypes.ADD_SURVEY_SUCCESS,
        payload: payload,
    }
}
export const SurveySingleSuccess = (payload: Survey): SuccessAction<Survey> => {
    return {
        type: surveyActionTypes.FETCH_SINGLE_SURVEY_SUCCESS,
        payload: payload,
    }
}

export const SurveyUpdateSuccess = (payload: any): SuccessAction<any> => {
    return {
        type: surveyActionTypes.UPDATE_SURVEY_SUCCESS,
        payload: payload,
    }
}


export const SurveyDeleteSuccess = (payload: any): SuccessAction<any> => {
    return {
        type: surveyActionTypes.DELETE_SURVEY_SUCCESS,
        payload: payload,
    }
}

export const SurveyQuestionAddSuccess = (payload: Question): SuccessAction<Question> => {
    return {
        type: questionActionTypes.ADD_QUESTION_SUCCESS,
        payload: payload,
    }
}

export const SurveyQuestionSingleSuccess = (payload: Question): SuccessAction<Question> => {
    return {
        type: questionActionTypes.FETCH_SINGLE_QUESTION_SUCCESS,
        payload: payload,
    }
}
export const SurveyQuestionDeleteSuccess = (payload: any): SuccessAction<any> => {
    return {
        type: questionActionTypes.DELETE_QUESTION_SUCCESS,
        payload: payload,
    }
}

export const SurveyQuestionUpdateSuccess = (payload: any): SuccessAction<any> => {
    return {
        type: questionActionTypes.UPDATE_QUESTION_SUCCESS,
        payload: payload,
    }
}



export const SurveyQuestionsSuccess = (payload: QuestionList): SuccessAction<QuestionList> => {
    return {
        type: questionActionTypes.FETCH_QUESTION_ALL_SUCCESS,
        payload: payload,
    }
}

export const SurveyPostQuestionsSuccess = (payload: QuestionList): SuccessAction<QuestionList> => {
    return {
        type: questionActionTypes.FETCH_POST_QUESTION_ALL_SUCCESS,
        payload: payload,
    }
}
export const SurveyCategoryQuestionsSuccess = (payload: QuestionList): SuccessAction<QuestionList> => {
    return {
        type: questionActionTypes.FETCH_CATEGORY_QUESTION_SUCCESS,
        payload: payload,
    }
}



export const SurveyAllRequest = (filter: any, search: string, pageNo: number, limit: number, status: boolean):
    ThunkAction<Promise<void>, {}, {}, surveyAction> => {
    return (dispatch: ThunkDispatch<{}, {}, surveyAction>) => {
        return AXIOS.post(`${Prefix.api}/admin/surveys`, { filter, search, pageNo, limit, status }, { headers: Headers() }).then(res => {
            if (res) {
                const { data } = res;
                const surveys: SurveyList = data.result
                dispatch(SurveySuccess(surveys))

            }
        }).catch((e) => {
            if (e.response) {
                dispatch(SurveyFailure(e.response.data.message));
            }
            else {
                dispatch(SurveyFailure(e.message));
            }
        })
    }
}

export const SurveyAllRequestData = ():
    ThunkAction<Promise<void>, {}, {}, surveyAction> => {
    return (dispatch: ThunkDispatch<{}, {}, surveyAction>) => {
        return AXIOS.get(`${Prefix.api}/admin/surveys/all`, { headers: Headers() }).then(res => {
            if (res) {
                const { data } = res;
                const surveys: SurveyList = data.result
                dispatch(SurveyAllSuccess(surveys))
            }
        }).catch((e) => {
            if (e.response) {
                dispatch(SurveyFailure(e.response.data.message));
            }
            else {
                dispatch(SurveyFailure(e.message));
            }
        })
    }
}




export const SurveyAddRequest = (survey: Survey, setMessageHandler: any, setSubmitting: any):
    ThunkAction<Promise<void>, {}, {}, surveyAction> => {
    setMessageHandler({
        hasMessage: false,
        type: '',
        message: '',
    });
    return (dispatch: ThunkDispatch<{}, {}, surveyAction>) => {
        return AXIOS.post(`${Prefix.api}/admin/survey/add`, survey, { headers: Headers() }).then(res => {
            if (res) {
                const { data } = res;
                const survey: Survey = data.result;
                dispatch(SurveyAddSuccess(survey))
                setSubmitting(false);
                setMessageHandler({
                    hasMessage: true,
                    type: 'success',
                    message: data.message,
                });
            }
        }).catch((e) => {
            if (e.response) {
                setSubmitting(false);
                dispatch(SurveyFailure(e.response.data.message));
                setMessageHandler({
                    hasMessage: true,
                    type: 'error',
                    message: e.response.data.message,
                });
            }
            else {
                setSubmitting(false);
                dispatch(SurveyFailure(e.message));
                setMessageHandler({
                    hasMessage: true,
                    type: 'error',
                    message: e.message,
                });
            }
        })
    }
}


export const SurveyUpdateRequest = (catid: string, survey: Survey, setMessageHandler: any, setSubmitting: any):
    ThunkAction<Promise<void>, {}, {}, surveyAction> => {
    setMessageHandler({
        hasMessage: false,
        type: '',
        message: '',
    });
    return (dispatch: ThunkDispatch<{}, {}, surveyAction>) => {
        return AXIOS.put(`${Prefix.api}/admin/survey/update/${catid}`, survey, { headers: Headers() }).then(res => {
            if (res) {
                const { data } = res;
                const survey: Survey = data.result;
                dispatch(SurveyUpdateSuccess(survey))
                setSubmitting(false);
                setMessageHandler({
                    hasMessage: true,
                    type: 'success',
                    message: data.message,
                });
            }
        }).catch((e) => {
            if (e.response) {
                setSubmitting(false);
                setMessageHandler({
                    hasMessage: true,
                    type: 'error',
                    message: e.response.data.message,
                });
                dispatch(SurveyFailure(e.response.data.message));
            }
            else {
                setSubmitting(false);
                setMessageHandler({
                    hasMessage: true,
                    type: 'error',
                    message: e.message,
                });
                dispatch(SurveyFailure(e.message));
            }
        })
    }
}

export const SurveyStatusUpdateRequest = (surveyid: string, survey: Survey, setMessageHandler: any):
    ThunkAction<Promise<void>, {}, {}, surveyAction> => {
    setMessageHandler({
        hasMessage: false,
        type: '',
        message: '',
    });
    return (dispatch: ThunkDispatch<{}, {}, surveyAction>) => {
        return AXIOS.put(`${Prefix.api}/admin/survey/update/${surveyid}`, survey, { headers: Headers() }).then(res => {
            if (res) {
                const { data } = res;
                const survey: Survey = data.result;
                dispatch(SurveyUpdateSuccess(survey))
                setMessageHandler({
                    hasMessage: true,
                    type: 'success',
                    message: data.message,
                });
            }
        }).catch((e) => {
            if (e.response) {
                setMessageHandler({
                    hasMessage: true,
                    type: 'error',
                    message: e.response.data.message,
                });
                dispatch(SurveyFailure(e.response.data.message));
            }
            else {
                setMessageHandler({
                    hasMessage: true,
                    type: 'error',
                    message: e.message,
                });
                dispatch(SurveyFailure(e.message));
            }
        })
    }
}


export const SurveySingleRequest = (catid: string):
    ThunkAction<Promise<void>, {}, {}, surveyAction> => {

    return (dispatch: ThunkDispatch<{}, {}, surveyAction>) => {
        return AXIOS.get(`${Prefix.api}/admin/survey/${catid}`, { headers: Headers() }).then(res => {
            if (res) {
                const { data } = res;
                const survey: Survey = data.result;
                dispatch(SurveySingleSuccess(survey));
            }
        }).catch((e) => {
            if (e.response) {
                dispatch(SurveyFailure(e.response.data.message));
            }
            else {
                dispatch(SurveyFailure(e.message));
            }
        })
    }
}


export const SurveyDeleteRequest = (id: string, body: Survey, setMessageHandler: any):
    ThunkAction<Promise<void>, {}, {}, surveyAction> => {
    setMessageHandler({
        hasMessage: false,
        type: '',
        message: '',
    });
    return (dispatch: ThunkDispatch<{}, {}, surveyAction>) => {
        return AXIOS.put(`${Prefix.api}/admin/survey/update/${id}`, body, { headers: Headers() }).then(res => {
            if (res) {
                const { data } = res;
                const survey: Survey = data.result;
                dispatch(SurveyDeleteSuccess(survey))
                setMessageHandler({
                    hasMessage: true,
                    type: 'success',
                    message: data.message,
                });
            }
        }).catch((e) => {
            if (e.response) {
                dispatch(SurveyFailure(e.response.data.message));
                setMessageHandler({
                    hasMessage: true,
                    type: 'error',
                    message: e.response.data.message,
                });
            }
            else {
                dispatch(SurveyFailure(e.message));
                setMessageHandler({
                    hasMessage: true,
                    type: 'error',
                    message: e.message,
                });
            }
        })
    }
}

export const SurveyQuestionAddRequest = (question: Question, setMessageHandler: any,resetForm:any, setSubmitting: any):
    ThunkAction<Promise<void>, {}, {}, surveyAction> => {
    setMessageHandler({
        hasMessage: false,
        type: '',
        message: '',
    });
    return (dispatch: ThunkDispatch<{}, {}, surveyAction>) => {
        return AXIOS.post(`${Prefix.api}/admin/survey/question/add`, question, { headers: Headers() }).then(res => {
            if (res) {
                const { data } = res;
                const question: Question = data.result;
                dispatch(SurveyQuestionAddSuccess(question))
                setSubmitting(false);
                setMessageHandler({
                    hasMessage: true,
                    type: 'success',
                    message: data.message,
                });
                resetForm();
            }
        }).catch((e) => {
            if (e.response) {
                setSubmitting(false);
                dispatch(SurveyFailure(e.response.data.message));
                setMessageHandler({
                    hasMessage: true,
                    type: 'error',
                    message: e.response.data.message,
                });
                resetForm();
            }
            else {
                setSubmitting(false);
                dispatch(SurveyFailure(e.message));
                setMessageHandler({
                    hasMessage: true,
                    type: 'error',
                    message: e.message,
                });
                resetForm();
            }
        })
    }
}

export const ProjectQuestionAddRequest = (question: Question, setMessageHandler: any,resetForm:any, setSubmitting: any):
    ThunkAction<Promise<void>, {}, {}, surveyAction> => {
    setMessageHandler({
        hasMessage: false,
        type: '',
        message: '',
    });
    return (dispatch: ThunkDispatch<{}, {}, surveyAction>) => {
        return AXIOS.post(`${Prefix.api}/admin/project/question/add`, question, { headers: Headers() }).then(res => {
            if (res) {
                const { data } = res;
                const question: Question = data.result;
                dispatch(SurveyQuestionAddSuccess(question))
                setSubmitting(false);
                setMessageHandler({
                    hasMessage: true,
                    type: 'success',
                    message: data.message,
                });
                resetForm();
            }
        }).catch((e) => {
            if (e.response) {
                setSubmitting(false);
                dispatch(SurveyFailure(e.response.data.message));
                setMessageHandler({
                    hasMessage: true,
                    type: 'error',
                    message: e.response.data.message,
                });
                resetForm();
            }
            else {
                setSubmitting(false);
                dispatch(SurveyFailure(e.message));
                setMessageHandler({
                    hasMessage: true,
                    type: 'error',
                    message: e.message,
                });
                resetForm();
            }
        })
    }
}

export const QuestionTempAddRequest = (question: Question, setMessageHandler: any,resetForm:any, setSubmitting: any):
    ThunkAction<Promise<void>, {}, {}, surveyAction> => {
    setMessageHandler({
        hasMessage: false,
        type: '',
        message: '',
    });
    return (dispatch: ThunkDispatch<{}, {}, surveyAction>) => {
        return AXIOS.post(`${Prefix.api}/admin/question/temp/add`, question, { headers: Headers() }).then(res => {
            if (res) {
                const { data } = res;
                const question: Question = data.result;
                dispatch(SurveyQuestionAddSuccess(question))
                setSubmitting(false);
                setMessageHandler({
                    hasMessage: true,
                    type: 'success',
                    message: data.message,
                });
                resetForm();
            }
        }).catch((e) => {
            if (e.response) {
                setSubmitting(false);
                dispatch(SurveyFailure(e.response.data.message));
                setMessageHandler({
                    hasMessage: true,
                    type: 'error',
                    message: e.response.data.message,
                });
                resetForm();
            }
            else {
                setSubmitting(false);
                dispatch(SurveyFailure(e.message));
                setMessageHandler({
                    hasMessage: true,
                    type: 'error',
                    message: e.message,
                });
                resetForm();
            }
        })
    }
}




export const SurveyQuestionDeleteRequest = (id: string, body: Question, setMessageHandler: any):
    ThunkAction<Promise<void>, {}, {}, surveyAction> => {
    setMessageHandler({
        hasMessage: false,
        type: '',
        message: '',
    });
    return (dispatch: ThunkDispatch<{}, {}, surveyAction>) => {
        return AXIOS.put(`${Prefix.api}/admin/survey/question/update/${id}`, body, { headers: Headers() }).then(res => {
            if (res) {
                const { data } = res;
                const survey: Question = data.result;
                dispatch(SurveyQuestionDeleteSuccess(survey))
                setMessageHandler({
                    hasMessage: true,
                    type: 'success',
                    message: data.message,
                });
            }
        }).catch((e) => {
            if (e.response) {
                dispatch(SurveyFailure(e.response.data.message));
                setMessageHandler({
                    hasMessage: true,
                    type: 'error',
                    message: e.response.data.message,
                });
            }
            else {
                dispatch(SurveyFailure(e.message));
                setMessageHandler({
                    hasMessage: true,
                    type: 'error',
                    message: e.message,
                });
            }
        })
    }
}

export const SurveyQuestionUpdateRequest = (id: string, body: Question, setMessageHandler: any):
    ThunkAction<Promise<void>, {}, {}, surveyAction> => {
    setMessageHandler({
        hasMessage: false,
        type: '',
        message: '',
    });
    return (dispatch: ThunkDispatch<{}, {}, surveyAction>) => {
        return AXIOS.put(`${Prefix.api}/admin/survey/question/update/${id}`, body, { headers: Headers() }).then(res => {
            if (res) {
                const { data } = res;
                const survey: Question = data.result;
                dispatch(SurveyQuestionUpdateSuccess(survey))
                setMessageHandler({
                    hasMessage: true,
                    type: 'success',
                    message: data.message,
                });
            }
        }).catch((e) => {
            if (e.response) {
                dispatch(SurveyFailure(e.response.data.message));
                setMessageHandler({
                    hasMessage: true,
                    type: 'error',
                    message: e.response.data.message,
                });
            }
            else {
                dispatch(SurveyFailure(e.message));
                setMessageHandler({
                    hasMessage: true,
                    type: 'error',
                    message: e.message,
                });
            }
        })
    }
}


export const SurveyQuestionTempUpdateRequest = (id: string, body: Question, setMessageHandler: any):
    ThunkAction<Promise<void>, {}, {}, surveyAction> => {
    setMessageHandler({
        hasMessage: false,
        type: '',
        message: '',
    });
    return (dispatch: ThunkDispatch<{}, {}, surveyAction>) => {
        return AXIOS.put(`${Prefix.api}/admin/survey/question/temp/update/${id}`, body, { headers: Headers() }).then(res => {
            if (res) {
                const { data } = res;
                const survey: Question = data.result;
                dispatch(SurveyQuestionUpdateSuccess(survey))
                setMessageHandler({
                    hasMessage: true,
                    type: 'success',
                    message: data.message,
                });
            }
        }).catch((e) => {
            if (e.response) {
                dispatch(SurveyFailure(e.response.data.message));
                setMessageHandler({
                    hasMessage: true,
                    type: 'error',
                    message: e.response.data.message,
                });
            }
            else {
                dispatch(SurveyFailure(e.message));
                setMessageHandler({
                    hasMessage: true,
                    type: 'error',
                    message: e.message,
                });
            }
        })
    }
}



export const SurveyQuestionUpdateRequestAll = (id: string, body: Question[], setMessageHandler: any):
    ThunkAction<Promise<void>, {}, {}, surveyAction> => {
    setMessageHandler({
        hasMessage: false,
        type: '',
        message: '',
    });
    return (dispatch: ThunkDispatch<{}, {}, surveyAction>) => {
        return AXIOS.put(`${Prefix.api}/admin/survey/question/update/all/${id}`, body, { headers: Headers() }).then(res => {
            if (res) {
                const { data } = res;
                const survey: Question = data.result;
                dispatch(SurveyQuestionUpdateSuccess(survey))
                setMessageHandler({
                    hasMessage: true,
                    type: 'success',
                    message: data.message,
                });
            }
        }).catch((e) => {
            if (e.response) {
                dispatch(SurveyFailure(e.response.data.message));
                setMessageHandler({
                    hasMessage: true,
                    type: 'error',
                    message: e.response.data.message,
                });
            }
            else {
                dispatch(SurveyFailure(e.message));
                setMessageHandler({
                    hasMessage: true,
                    type: 'error',
                    message: e.message,
                });
            }
        })
    }
}

export const SurveyQuestionSingleRequest = (catid: string):
    ThunkAction<Promise<void>, {}, {}, surveyAction> => {
    return (dispatch: ThunkDispatch<{}, {}, surveyAction>) => {
        return AXIOS.get(`${Prefix.api}/admin/survey/question/${catid}`, { headers: Headers() }).then(res => {
            if (res) {
                const { data } = res;
                const survey: Question = data.result;
                dispatch(SurveyQuestionSingleSuccess(survey));
            }
        }).catch((e) => {
            if (e.response) {
                dispatch(SurveyFailure(e.response.data.message));
            }
            else {
                dispatch(SurveyFailure(e.message));
            }
        })
    }
}


export const SurveyQuestionsRequest = (surveyid: Question ):
    ThunkAction<Promise<void>, {}, {}, surveyAction> => {
    return (dispatch: ThunkDispatch<{}, {}, surveyAction>) => {
        return AXIOS.get(`${Prefix.api}/admin/survey/questions/${surveyid}`, { headers: Headers() }).then(res => {
            if (res) {
                const { data } = res;
                const questions: QuestionList = data.result;
                dispatch(SurveyQuestionsSuccess(questions));
            }
        }).catch((e) => {
            if (e.response) {
                dispatch(SurveyFailure(e.response.data.message));
            }
            else {
                dispatch(SurveyFailure(e.message));
            }
        })
    }
}

export const ProjectQuestionsRequest = (projectid: Question ):
    ThunkAction<Promise<void>, {}, {}, surveyAction> => {
    return (dispatch: ThunkDispatch<{}, {}, surveyAction>) => {
        return AXIOS.get(`${Prefix.api}/admin/project/questions/${projectid}`, { headers: Headers() }).then(res => {
            if (res) {
                const { data } = res;
                const questions: QuestionList = data.result;
                dispatch(SurveyQuestionsSuccess(questions));
            }
        }).catch((e) => {
            if (e.response) {
                dispatch(SurveyFailure(e.response.data.message));
            }
            else {
                dispatch(SurveyFailure(e.message));
            }
        })
    }
}

export const ProjectQuestionsPostRequest = (projectid: Question ):
    ThunkAction<Promise<void>, {}, {}, surveyAction> => {
    return (dispatch: ThunkDispatch<{}, {}, surveyAction>) => {
        return AXIOS.get(`${Prefix.api}/admin/project/post/questions/${projectid}`, { headers: Headers() }).then(res => {
            if (res) {
                const { data } = res;
                const questions: QuestionList = data.result;
                dispatch(SurveyQuestionsSuccess(questions));
            }
        }).catch((e) => {
            if (e.response) {
                dispatch(SurveyFailure(e.response.data.message));
            }
            else {
                dispatch(SurveyFailure(e.message));
            }
        })
    }
}

export const ProjectQuestionsGroupRequest = (projectid: string ):
    ThunkAction<Promise<void>, {}, {}, surveyAction> => {
    return (dispatch: ThunkDispatch<{}, {}, surveyAction>) => {
        return AXIOS.get(`${Prefix.api}/admin/project/questions/group/${projectid}`, { headers: Headers() }).then(res => {
            if (res) {
                const { data } = res;
                const questions: QuestionList = data.result;
                dispatch(SurveyQuestionsSuccess(questions));
            }
        }).catch((e) => {
            if (e.response) {
                dispatch(SurveyFailure(e.response.data.message));
            }
            else {
                dispatch(SurveyFailure(e.message));
            }
        })
    }
}

export const QuestionsTempRequest = ():
    ThunkAction<Promise<void>, {}, {}, surveyAction> => {
    return (dispatch: ThunkDispatch<{}, {}, surveyAction>) => {
        return AXIOS.get(`${Prefix.api}/admin/questions/temp`, { headers: Headers() }).then(res => {
            if (res) {
                const { data } = res;
                const questions: QuestionList = data.result;
                dispatch(SurveyQuestionsSuccess(questions));
            }
        }).catch((e) => {
            if (e.response) {
                dispatch(SurveyFailure(e.response.data.message));
            }
            else {
                dispatch(SurveyFailure(e.message));
            }
        })
    }
}



export const SurveyQuestionsScreenRequest = (body: any ):
    ThunkAction<Promise<void>, {}, {}, surveyAction> => {
    return (dispatch: ThunkDispatch<{}, {}, surveyAction>) => {
        return AXIOS.post(`${Prefix.api}/admin/survey/questions/screen`, body , { headers: Headers() }).then(res => {
            if (res) {
                const { data } = res;
                const questions: QuestionList = data.result;
                dispatch(SurveyQuestionsSuccess(questions));
            }
        }).catch((e) => {
            if (e.response) {
                dispatch(SurveyFailure(e.response.data.message));
            }
            else {
                dispatch(SurveyFailure(e.message));
            }
        })
    }
}



export const SurveyQuestionsCategoryRequest = ():
    ThunkAction<Promise<void>, {}, {}, surveyAction> => {
    return (dispatch: ThunkDispatch<{}, {}, surveyAction>) => {
        return AXIOS.get(`${Prefix.api}/admin/survey/question/category`, { headers: Headers() }).then(res => {
            if (res) {
                const { data } = res;
                const questions: QuestionList = data.result;
                dispatch(SurveyCategoryQuestionsSuccess(questions));
            }
        }).catch((e) => {
            if (e.response) {
                dispatch(SurveyFailure(e.response.data.message));
            }
            else {
                dispatch(SurveyFailure(e.message));
            }
        })
    }
}

export const SurveyQuestionValidateRequest = (body:any):
    ThunkAction<Promise<void>, {}, {}, surveyAction> => {
    return (dispatch: ThunkDispatch<{}, {}, surveyAction>) => {
        return AXIOS.post(`${Prefix.api}/admin/survey/prescreen/response`,body , { headers: Headers() }).then(res => {
            if (res) {
                const { data } = res;
                const question: Question = data.result;
                dispatch(SurveyQuestionSingleSuccess(question));
            }
        }).catch((e) => {
            if (e.response) {
                dispatch(SurveyFailure(e.response.data.message));
            }
            else {
                dispatch(SurveyFailure(e.message));
            }
        })
    }
}


