import { TextField, Grid, Typography, Box, IconButton, Tooltip, Accordion, AccordionSummary, AccordionDetails, Button, Switch } from '@mui/material';
import { Form, Formik, FormikProps } from 'formik';
import { LoadingButton } from '@mui/lab';
import * as Yup from 'yup';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { DataGridRender, MoreMenu } from 'src/components/table';
import { useSelector } from 'src/reducers';
import { useDispatch } from 'react-redux';
import {  SupplierAllRequestData } from 'src/actions/supplierActions';
import { useContext, useEffect, useState } from 'react';
import { SupplierMapAddRequest, SupplierMapAllRequest, SupplierMapDeleteRequest, SupplierMapSingleRequest, SupplierMapUpdateRequest, SupplierMapSingleCancel, SupplierMapStatusUpdateRequest, SupplierMapAddAllRequest } from 'src/actions/supplierMapActions';
import { SupplierMap } from 'src/types/supplierMapTypes';
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';
import { useParams } from 'react-router-dom';
import MessageContext from 'src/context/MessageContext';
import plusFill from '@iconify/icons-eva/plus-fill';
import { Icon } from '@iconify/react';
import swal from 'sweetalert';
import { SupplierSingleRequest } from 'src/actions/serviceActions';
import { SupplierProjectExportRequest } from 'src/actions/projectActions';

export default function ChildProjectSupplierForm() {

    const { projectid }: any = useParams<any>();
    const [expanded, setExpanded] = useState<string | false>(false);
    const [pageNo, setPageNo] = useState(1);
    const [limit, setLimit] = useState(10);
    const [filters, setFilters] = useState<any>({});
    const [search, setSearch] = useState<string>('');
    const [copied, setCopied] = useState<string>('');
    const [suppid, setSupplierId] = useState<string>('');
    const { setMessageHandler } = useContext<any>(MessageContext);

    const SchemaCategoryForm = Yup.object().shape({
        supplierId: Yup.string().required('Supplier  is required'),
        supplierCPI: Yup.string().trim().required('Supplier CPI is required'),
        supplierQuota: Yup.string().trim().matches(/^[0-9\s]+$/, "Only digits are allowed for this field ").required('Supplier Quota is required'),
        supplierClickQuota: Yup.number().required('Supplier Click Quota is required'),
        redirectComplete: Yup.string().trim().matches(/((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+(:[0-9]+)?|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)/, "Invalid Url").matches(/=\[.?sid\]/g, "Invalid Url"),
        redirectQuotaFull: Yup.string().trim().matches(/((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+(:[0-9]+)?|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)/, "Invalid Url").matches(/=\[.?sid\]/g, "Invalid Url"),
        redirectTerminate: Yup.string().trim().matches(/((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+(:[0-9]+)?|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)/, "Invalid Url").matches(/=\[.?sid\]/g, "Invalid Url"),
        redirectQualityTerm: Yup.string().trim().matches(/((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+(:[0-9]+)?|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)/, "Invalid Url").matches(/=\[.?sid\]/g, "Invalid Url"),
        redirectSurveyClose: Yup.string().trim().matches(/((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+(:[0-9]+)?|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)/, "Invalid Url").matches(/=\[.?sid\]/g, "Invalid Url"),
    });

    const dispatch = useDispatch();
    const { supplier, suppliers } = useSelector(state => state.supplier);
    const { loading, supplierMaps, supplierMap } = useSelector(state => state.supplierMap);


    const changeSupplier = (value: string, formikProps: FormikProps<SupplierMap>) => {
        formikProps.setFieldValue('supplierId', value)
        setSupplierId(value);
    }

    useEffect(() => {
        dispatch(SupplierMapAllRequest(projectid, search))
    }, [projectid, search])


    const reloadAction = () => {
        dispatch(SupplierMapAllRequest(projectid, search))
    }

    useEffect(() => {
        dispatch(SupplierSingleRequest(suppid || supplierMap?.supplierId || '', { projectId: projectid }))
    }, [suppid, supplierMap?.supplierId])


    useEffect(() => {
        dispatch(SupplierAllRequestData())
    }, [])


    const setCopyAction = (value: string) => {
        setCopied(value);
        setTimeout(() => setCopied(''), 1000);
    }
    const deleteAction = (id: string) => {
        dispatch(SupplierMapDeleteRequest(id, { isDeleted: true }, setMessageHandler));
    }
    const editAction = (id: string) => {
        setExpanded('panel1');
        dispatch(SupplierMapSingleRequest(id));
    }
    const exportAction = (id: string, name: string) => {
        dispatch(SupplierProjectExportRequest({ supplierMapId: id, projectId: projectid }, name));
    }
    const submitSupplier = (values: SupplierMap, setSubmitting: any, resetForm: any) => {
        setTimeout(() => supplierMap._id ? dispatch(SupplierMapUpdateRequest(supplierMap._id, values, setMessageHandler, setSubmitting, resetForm)) : dispatch(SupplierMapAddRequest(values, setMessageHandler, setSubmitting, resetForm)), 200);
    }
    const handleChange = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
        setExpanded(isExpanded ? panel : false);
    };


    const addSupplierAll = (projectId: string) => {
        swal({
          title: "Are you sure?",
          icon: "warning",
          dangerMode: true,
          buttons: ["No!", true],
        })
          .then((willDelete) => {
            if (willDelete) {
              dispatch(SupplierMapAddAllRequest(projectId, setMessageHandler));
              reloadAction()
            } else {
            }
          });
    }
    

    const columns = [
        {
            field: 'supplierName', type: "string", headerName: 'Supplier', filterable: false, headerAlign: "left", align: 'left',  width: 120,renderCell: ({ value }: any) => {
                return <Tooltip title={value}><Typography color="primary" >{value}</Typography></Tooltip>
            }
        },

        {
            field: 'redirectUrl', type: "string", headerName: 'Redirect URL', filterable: false, flex: 1, headerAlign: "left", align: 'left', renderCell: ({ value }: any) => {
                return <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                    <CopyToClipboard text={value} onCopy={() => setCopyAction(value)}>
                        <Tooltip title={value}>
                            <Typography color="primary" >{value}</Typography>
                        </Tooltip>
                    </CopyToClipboard>
                </Box>
            }
        },
        { field: 'supplierCPI', type: "string", headerName: 'CPI', filterable: false, width: 60, headerAlign: "center", align: 'center' },
        {
            field: 'allowTraffic', headerName: 'Traffic', type: "number", width: 100, valueFormatter: ({ value }: any) => value ? "Active" : "InActive",
            renderCell: (params: any) => {
                return <Switch checked={params.value} onChange={() => dispatch(SupplierMapStatusUpdateRequest(params.id, { allowTraffic: !params.value }, setMessageHandler))} />
            }
        },
        {
            field: 'isTestLink', headerName: 'Test Link', type: "number", width: 90, valueFormatter: ({ value }: any) => value ? "Active" : "InActive",
            renderCell: (params: any) => {
                return <Switch checked={params.value} onChange={() => dispatch(SupplierMapStatusUpdateRequest(params.id, { isTestLink: !params.value }, setMessageHandler))} />
            }
        },
        {
            field: 'isPreScreen', headerName: 'PreScreen', type: "number", width: 110, valueFormatter: ({ value }: any) => value ? "Active" : "InActive",
            renderCell: (params: any) => {
                return <Switch checked={params.value} onChange={() => dispatch(SupplierMapStatusUpdateRequest(params.id, { isPreScreen: !params.value }, setMessageHandler))} />
            }
        },
        {
            field: 'isPostScreen', headerName: 'PostScreen', type: "number", width: 110, valueFormatter: ({ value }: any) => value ? "Active" : "InActive",
            renderCell: (params: any) => {
                return <Switch checked={params.value} onChange={() => dispatch(SupplierMapStatusUpdateRequest(params.id, { isPreScreen: !params.value }, setMessageHandler))} />
            }
        },
        {
            field: 'isBoarded', headerName: 'Boarded', type: "number", width: 90, valueFormatter: ({ value }: any) => value ? "Active" : "InActive",
            renderCell: (params: any) => {
                return <Switch checked={params.value} onChange={() => dispatch(SupplierMapStatusUpdateRequest(params.id, { isBoarded: !params.value }, setMessageHandler))} />
            }
        },
        { field: 'actions', headerName: '', width: 40, type: 'actions', getActions: ({ id, row }: any) => [<MoreMenu name={row['supplierName']} id={id} exportFileName="Supplier" cloneAction='' addAction='' editAction={editAction} deleteAction={deleteAction} exportAction={exportAction} />] },
    ];
    return (
        <Formik
            onSubmit={(values, { setSubmitting, resetForm }) => {
                submitSupplier(values, setSubmitting, resetForm);
            }}
            validateOnBlur={false}
            validateOnChange={true}
            enableReinitialize={true}
            validationSchema={SchemaCategoryForm}
            initialValues={{
                projectId: projectid,
                supplierId: supplierMap?.supplierId || supplier?._id,
                supplierCPI: supplierMap?.supplierCPI || supplier?.supplierCPI || 0,
                supplierQuota: supplierMap?.supplierQuota || 0,
                supplierClickQuota: supplierMap?.supplierClickQuota || 0,
                isPreScreen: supplierMap?.isPreScreen || supplier?.isPreScreen,
                isPostScreen: supplierMap?.isPostScreen || supplier?.isPostScreen,
                isTestLink: supplierMap?.isTestLink || false,
                allowTraffic: supplierMap?.allowTraffic || true,
                isBoarded: supplierMap?.isBoarded || true,
                redirectComplete: supplierMap?.redirectComplete || supplier?.redirectComplete || '',
                redirectQuotaFull: supplierMap?.redirectQuotaFull || supplier?.redirectQuotaFull || '',
                redirectTerminate: supplierMap?.redirectTerminate || supplier?.redirectTerminate || '',
                redirectQualityTerm: supplierMap?.redirectQualityTerm || supplier?.redirectQualityTerm || '',
                redirectSurveyClose: supplierMap?.redirectSurveyClose || supplier?.redirectSurveyClose || '',
            }}
        >
            {(formikProps: FormikProps<SupplierMap>) => (
                <>
                    <DataGridRender
                        loading={loading}
                        reloadAction={reloadAction}
                        height={400}
                        filterTypes={['export', 'search']}
                        data={supplierMaps}
                        metadata=""
                        exportFileName="Suppliers Mapped"
                        limit={limit}
                        search={search}
                        filters={filters}
                        pageNo={pageNo}
                        columns={columns}
                        hideFooterPagination={true}
                        setPageNo={setPageNo}
                        setSearch={setSearch}
                        setLimit={setLimit}
                        setFilters={setFilters}
                    />
                    <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                        <AccordionSummary sx={{ width: 200 }} >
                            <Button
                                variant="text"
                                startIcon={<Icon icon={plusFill} />}
                            >
                                {supplierMap?._id ? 'Update Supplier' : 'Add Supplier'}
                            </Button>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Form autoComplete="off" noValidate onSubmit={formikProps.handleSubmit}>
                                <Grid
                                    container
                                    spacing={3}
                                >
                                    <Grid
                                        item
                                        md={4}
                                        xs={12}
                                    >
                                        <TextField
                                            select
                                            label="Supplier"
                                            error={Boolean(formikProps.touched.supplierId && formikProps.errors.supplierId)}
                                            fullWidth
                                            helperText={formikProps.touched.supplierId && formikProps.errors.supplierId}
                                            name="supplierId"
                                            value={formikProps.values.supplierId}
                                            onChange={(e) => changeSupplier(e.target.value, formikProps)}
                                            SelectProps={{
                                                native: true,
                                            }}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            variant="standard"
                                        >
                                            <option key="" value="">
                                                {"Select Supplier"}
                                            </option>
                                            {suppliers.map((supplier) => (
                                                <option key={supplier._id} value={supplier._id}>
                                                    {supplier.supplierName}
                                                </option>
                                            ))}
                                        </TextField>
                                    </Grid>
                                    <Grid
                                        item
                                        md={2}
                                        xs={12}
                                    >
                                        <TextField
                                            error={Boolean(formikProps.touched.supplierCPI && formikProps.errors.supplierCPI)}
                                            fullWidth
                                            helperText={(formikProps.touched.supplierCPI && formikProps.errors.supplierCPI)}
                                            label="Supplier CPI"
                                            {...formikProps.getFieldProps('supplierCPI')}
                                            variant="standard"
                                        />
                                    </Grid>
                                    <Grid
                                        item
                                        md={2}
                                        xs={12}
                                    >
                                        <TextField
                                            error={Boolean(formikProps.touched.supplierQuota && formikProps.errors.supplierQuota)}
                                            fullWidth
                                            helperText={(formikProps.touched.supplierQuota && formikProps.errors.supplierQuota)}
                                            label="Supplier Quota"
                                            {...formikProps.getFieldProps('supplierQuota')}
                                            variant="standard"
                                        />
                                    </Grid>
                                    <Grid
                                        item
                                        md={2}
                                        xs={12}
                                    >
                                        <TextField
                                            error={Boolean(formikProps.touched.supplierClickQuota && formikProps.errors.supplierClickQuota)}
                                            fullWidth
                                            helperText={(formikProps.touched.supplierClickQuota && formikProps.errors.supplierClickQuota)}
                                            label="Click Quota"
                                            {...formikProps.getFieldProps('supplierClickQuota')}
                                            variant="standard"
                                        />
                                    </Grid>
                                    <Grid
                                        item
                                        md={4}
                                        xs={12}
                                    >
                                        <TextField
                                            error={Boolean(formikProps.touched.redirectComplete && formikProps.errors.redirectComplete)}
                                            fullWidth
                                            helperText={(formikProps.touched.redirectComplete && formikProps.errors.redirectComplete) || 'Link eg. thanks?status=c&sid=[sid]&pid=[pid]'}
                                            label="Complete"
                                            {...formikProps.getFieldProps('redirectComplete')}
                                            type="text"
                                            variant="standard"
                                        />
                                    </Grid>

                                    <Grid
                                        item
                                        md={4}
                                        xs={12}
                                    >
                                        <TextField
                                            error={Boolean(formikProps.touched.redirectTerminate && formikProps.errors.redirectTerminate)}
                                            fullWidth
                                            helperText={(formikProps.touched.redirectTerminate && formikProps.errors.redirectTerminate) || 'Link eg. thanks?status=q&sid=[sid]&pid=[pid]'}
                                            label="Terminate"
                                            {...formikProps.getFieldProps('redirectTerminate')}
                                            type="text"
                                            variant="standard"
                                        />
                                    </Grid>
                                    <Grid
                                        item
                                        md={4}
                                        xs={12}
                                    >
                                        <TextField
                                            error={Boolean(formikProps.touched.redirectQuotaFull && formikProps.errors.redirectQuotaFull)}
                                            fullWidth
                                            helperText={(formikProps.touched.redirectQuotaFull && formikProps.errors.redirectQuotaFull) || 'Link eg. thanks?status=q&sid=[sid]&pid=[pid]'}
                                            label="Over Quota"
                                            {...formikProps.getFieldProps('redirectQuotaFull')}
                                            type="text"
                                            variant="standard"
                                        />
                                    </Grid>
                                    <Grid
                                        item
                                        md={4}
                                        xs={12}
                                    >
                                        <TextField
                                            error={Boolean(formikProps.touched.redirectQualityTerm && formikProps.errors.redirectQualityTerm)}
                                            fullWidth
                                            helperText={(formikProps.touched.redirectQualityTerm && formikProps.errors.redirectQualityTerm) || 'Link eg. thanks?status=q&sid=[sid]&pid=[pid]'}
                                            label="Quality Term"
                                            {...formikProps.getFieldProps('redirectQualityTerm')}
                                            type="text"
                                            variant="standard"
                                        />
                                    </Grid>
                                    <Grid
                                        item
                                        md={4}
                                        xs={12}
                                    >
                                        <TextField
                                            error={Boolean(formikProps.touched.redirectSurveyClose && formikProps.errors.redirectSurveyClose)}
                                            fullWidth
                                            helperText={(formikProps.touched.redirectSurveyClose && formikProps.errors.redirectSurveyClose) || 'Link eg. thanks?status=q&sid=[sid]&pid=[pid]'}
                                            label="Survey Close"
                                            {...formikProps.getFieldProps('redirectSurveyClose')}
                                            type="text"
                                            variant="standard"
                                        />
                                    </Grid>
                                </Grid>
                                <Grid
                                    container
                                    spacing={3}
                                >
                                    <Grid item md={2} >
                                        <Box sx={{ display: 'flex', justifyContent: 'left', alignContent: 'right', mt: 2 }}>
                                            {!supplierMap?._id ? <LoadingButton
                                                size="medium"
                                                type="submit"
                                                variant="contained"
                                                loading={formikProps.isSubmitting}
                                                sx={{mr:1}}
                                            >
                                                Add
                                            </LoadingButton> :
                                                <IconButton
                                                sx={{mr:1}}
                                                    onClick={() => formikProps.submitForm()}
                                                >
                                                    <DoneIcon />
                                                </IconButton>
                                            }
                                            {supplierMap?._id && <IconButton
                                                onClick={() => dispatch(SupplierMapSingleCancel())}
                                            >
                                                <CloseIcon />
                                            </IconButton>}
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Form>
                        </AccordionDetails>
                    </Accordion>
                </>
            )}
        </Formik>
    );
}
