import { TextField, Grid, Typography, Divider, IconButton,  InputAdornment } from '@mui/material';
import { Form, Formik, FormikProps } from 'formik';
import { LoadingButton } from '@mui/lab';
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DesktopDatePicker from "@mui/lab/DesktopDatePicker";
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import CloseIcon from '@mui/icons-material/Close';
import * as Yup from 'yup';
import { useEffect } from 'react';
import { useSelector } from 'src/reducers';
import { ClientAllRequestData } from 'src/actions/clientActions';
import { CountryAllRequest, CurrenciesAllRequest, LanguageAllRequest } from 'src/actions/countryActions';
import { useDispatch } from 'react-redux';
import { Project } from 'src/types/projectTypes';
import { ProjectStatus } from 'src/constants';
import { City, Regions } from 'src/types/countryTypes';
import { CategoryAllRequestData } from 'src/actions';
import CSVReader from 'react-csv-reader';
import { Delete, Download } from '@mui/icons-material';
import { Link } from 'react-router-dom';
import { QualificationAllRequestData } from 'src/actions/qualificationActions';
import { Qualification, QualificationOption } from 'src/types/qualificationTypes';

export interface FormValues {
    initialValues: Project,
    submitForm: any,
    changeCountry: any,
    changeRegion: any,
    regionId: string,
    countryId: string,
    cityId: string,
    newProject: boolean
}

const ITEM_HEIGHT: number = 48;
const ITEM_PADDING_TOP: number = 8;
const MenuProps: any = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

export default function ChildProjectInfoForm({ initialValues, newProject, submitForm, changeCountry, changeRegion, countryId, regionId, cityId }: FormValues) {

    const papaparseOptions: any = {
        header: false,
        dynamicTyping: true,
        skipEmptyLines: true,
        transformHeader: (header: any) =>
            header
                .toLowerCase()
                .replace(/\W/g, '_')
    }


    const SchemaCategoryForm = Yup.object().shape({
        projectName: Yup.string().trim().min(4).required('Project name  is required'),
        countryId: Yup.string().required('Country  is required'),
        startDate: Yup.date().required('Start Date is required'),
        endDate: Yup.date().required('End Date is required'),
        parentProjectId: Yup.string().required('Group Project  is required'),
        clientId: Yup.string().required('Client  is required'),
        averageLOI: Yup.string().trim().matches(/^[0-9\s]+$/, "Only digits are allowed for this field ").required('LOI  is required'),
        ir: Yup.string().trim().matches(/^[0-9\s]+$/, "Only digits are allowed for this field ").required('IR  is required'),
        cpi: Yup.string().trim().required('CPI  is required'),
        currencyId: Yup.string().trim().required('Currency  is required'),
        languageId: Yup.string().trim().required('Language  is required'),
        sampleSize: Yup.number().required('SampleSize  is required'),
        respondentClickQuota: Yup.number().required('RrespondentClickQuota  is required'),
    });

    const dispatch = useDispatch();

    const changeRegionData = (value: string, formikProps: FormikProps<Project>) => {
        formikProps.setFieldValue('regionId', value);
        changeRegion(value, formikProps);
    };

    const addProjectQuota = (quotaid: string, valueid: any, formikProps: FormikProps<Project>) => {
        var quotaSize = Number(formikProps.values.sampleSize) / Number(valueid.length);
        var quotaOptions = valueid.map((val: string) => ({ quotaSize, valueId: val }));
        // var currentQuota = formikProps.values.projectQuota.map((quo:Qualification) => quo._id !== quotaid);
        var quota = [...formikProps.values.projectQuota, [{ nameId: quotaid, value: quotaOptions }]];
        formikProps.setFieldValue('projectQuota', quota);
        console.log(formikProps.values)
    }

    const { clients } = useSelector(state => state.client);
    const { countries, currencies, languages, regions, cities } = useSelector(state => state.country);
    const { categories } = useSelector(state => state.category);
    const { qualifications } = useSelector(state => state.qualification);

    useEffect(() => {
        dispatch(ClientAllRequestData(''))
    }, [])

    useEffect(() => {
        dispatch(CountryAllRequest())
        dispatch(CurrenciesAllRequest());
        dispatch(LanguageAllRequest());
        dispatch(CategoryAllRequestData());
        dispatch(QualificationAllRequestData());
    }, []);
    return (
        <Formik
            onSubmit={(values, { setSubmitting }) => {
                submitForm(values, setSubmitting);
            }}
            validateOnBlur={false}
            validateOnChange={true}
            enableReinitialize={!newProject}
            validationSchema={SchemaCategoryForm}
            initialValues={{
                projectName: initialValues?.projectName || '',
                projectDescription: initialValues?.projectDescription || '',
                clientId: initialValues?.clientId,
                parentProjectId: initialValues?.parentProjectId,
                projectType: 2,
                wid: initialValues?.wid,
                categoryId: initialValues?.categoryId,
                countryId:initialValues?.countryId || countryId ,
                regionId: initialValues?.regionId || regionId,
                cityId: initialValues?.cityId || cityId,
                zipCodeId: initialValues?.zipCodeId || [],
                languageId: initialValues?.languageId || "en",
                averageLOI: initialValues?.averageLOI || '',
                ir: initialValues?.ir || '',
                sampleSize: initialValues?.sampleSize || 0,
                respondentClickQuota: initialValues?.respondentClickQuota || 0,
                respondentTestClickQuota: initialValues?.respondentClickQuota || 5,
                currencyId: initialValues?.currencyId || "622b3eb0be73c54656d45ca0",
                cpi: initialValues?.cpi || '',
              //  projectQuota: initialValues?.projectQuota || [],
                startDate: initialValues?.startDate || new Date(),
                endDate: initialValues?.endDate || new Date(new Date().getFullYear(), new Date().getMonth() + 1, new Date().getDate()),
                projectStatus: initialValues?.projectStatus || 2,
            }}
        >
            {(formikProps: FormikProps<Project>) => (
                <Form autoComplete="off" noValidate onSubmit={formikProps.handleSubmit}>
                    <Grid
                        container
                        spacing={3}
                    >
                        <Grid
                            item
                            md={12}
                            xs={12}
                        >
                            <Typography variant="h6">Survey Targetting</Typography>
                        </Grid>
                        <Grid
                            item
                            md={3}
                            xs={12}
                        >
                            <TextField
                                error={Boolean(formikProps.touched.projectName && formikProps.errors.projectName)}
                                fullWidth
                                helperText={formikProps.touched.projectName && formikProps.errors.projectName}
                                label="Project name"
                                {...formikProps.getFieldProps('projectName')}
                                variant="standard"
                            />
                        </Grid>
                        <Grid
                            item
                            md={3}
                            xs={12}
                        >
                            <TextField
                                select
                                label="Client Name"
                                error={Boolean(formikProps.touched.clientId && formikProps.errors.clientId)}
                                fullWidth
                                helperText={formikProps.touched.clientId && formikProps.errors.clientId}
                                {...formikProps.getFieldProps('clientId')}
                                disabled
                                SelectProps={{
                                    native: true,
                                }}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                variant="standard"
                            >
                                <option key="" value="">
                                    {"Select Client"}
                                </option>
                                {clients.map((client) => (
                                    <option key={client.value} value={client.value}>
                                        {client.label}
                                    </option>
                                ))}
                            </TextField>
                        </Grid>
                        <Grid
                            item
                            md={3}
                            xs={12}
                        >
                            <TextField
                                select
                                label="Category Name"
                                error={Boolean(formikProps.touched.categoryId && formikProps.errors.categoryId)}
                                fullWidth
                                helperText={formikProps.touched.categoryId && formikProps.errors.categoryId}
                                {...formikProps.getFieldProps('categoryId')}
                                SelectProps={{
                                    native: true,
                                }}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                variant="standard"
                            >
                                <option key="" value="">
                                    {"Select Category"}
                                </option>
                                {categories.map((category) => (
                                    <option key={category._id} value={category._id}>
                                        {category.categoryTitle}
                                    </option>
                                ))}
                            </TextField>
                        </Grid>
                        <Grid
                            item
                            md={3}
                            xs={12}
                        >
                            <TextField
                                select
                                label="Project Status"
                                error={Boolean(formikProps.touched.projectStatus && formikProps.errors.projectStatus)}
                                fullWidth
                                helperText={formikProps.touched.projectStatus && formikProps.errors.projectStatus}
                                {...formikProps.getFieldProps('projectStatus')}
                                SelectProps={{
                                    native: true,
                                }}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                variant="standard"
                            >
                                {ProjectStatus.map((projectStat: any) => (
                                    <option key={projectStat.value} value={projectStat.value}>
                                        {projectStat.label}
                                    </option>
                                ))}
                            </TextField>
                        </Grid>
                        <Grid
                            item
                            md={3}
                            xs={12}
                        >
                            <TextField
                                select
                                label="Language"
                                error={Boolean(formikProps.touched.languageId && formikProps.errors.languageId)}
                                fullWidth
                                disabled={initialValues?.languageId ? true : false}
                                helperText={formikProps.touched.languageId && formikProps.errors.languageId}
                                {...formikProps.getFieldProps('languageId')}
                                SelectProps={{
                                    native: true,
                                }}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                variant="standard"
                            >
                                <option key="" value="">
                                    {"Select Language"}
                                </option>
                                {languages.map((language) => (
                                    <option key={language._id} value={language.code}>
                                        {language.label}
                                    </option>
                                ))}
                            </TextField>
                        </Grid>


                        <Grid
                            item
                            md={3}
                            xs={12}
                        >
                            <TextField
                                error={Boolean(formikProps.touched.averageLOI && formikProps.errors.averageLOI)}
                                fullWidth
                                helperText={formikProps.touched.averageLOI && formikProps.errors.averageLOI}
                                label="LOI (Minute)"
                                {...formikProps.getFieldProps('averageLOI')}
                                variant="standard"
                            />
                        </Grid>
                        <Grid
                            item
                            md={3}
                            xs={12}
                        >
                            <TextField
                                error={Boolean(formikProps.touched.ir && formikProps.errors.ir)}
                                fullWidth
                                helperText={formikProps.touched.ir && formikProps.errors.ir}
                                label="IR (%)"
                                {...formikProps.getFieldProps('ir')}
                                variant="standard"
                            />
                        </Grid>
                        <Grid
                            item
                            md={3}
                            xs={12}
                        >
                            <TextField
                                error={Boolean(formikProps.touched.sampleSize && formikProps.errors.sampleSize)}
                                fullWidth
                                helperText={formikProps.touched.sampleSize && formikProps.errors.sampleSize}
                                label="Sample Size"
                                minRows={1}
                                {...formikProps.getFieldProps('sampleSize')}
                                variant="standard"
                            />
                        </Grid>
                        <Grid
                            item
                            md={2}
                            xs={12}
                        >
                            <TextField
                                error={Boolean(formikProps.touched.cpi && formikProps.errors.cpi)}
                                fullWidth
                                helperText={formikProps.touched.cpi && formikProps.errors.cpi}
                                label="CPI"
                                {...formikProps.getFieldProps('cpi')}
                                variant="standard"
                            />
                        </Grid>
                        <Grid
                            item
                            md={2}
                            xs={12}
                        >
                            <TextField
                                error={Boolean(formikProps.touched.respondentClickQuota && formikProps.errors.respondentClickQuota)}
                                fullWidth
                                helperText={formikProps.touched.respondentClickQuota && formikProps.errors.respondentClickQuota}
                                label="Respondent Click Quota"
                                {...formikProps.getFieldProps('respondentClickQuota')}
                                variant="standard"
                            />
                        </Grid>
                        <Grid
                            item
                            md={2}
                            xs={12}
                        >
                            <TextField
                                error={Boolean(formikProps.touched.respondentTestClickQuota && formikProps.errors.respondentTestClickQuota)}
                                fullWidth
                                helperText={formikProps.touched.respondentTestClickQuota && formikProps.errors.respondentTestClickQuota}
                                label="Respondent Test Click Quota"
                                {...formikProps.getFieldProps('respondentTestClickQuota')}
                                variant="standard"
                            />
                        </Grid>
                        <Grid
                            item
                            md={2}
                            xs={12}
                        > <TextField
                            select
                            label="Currency Code"
                            error={Boolean(formikProps.touched.currencyId && formikProps.errors.currencyId)}
                            fullWidth
                            helperText={formikProps.touched.currencyId && formikProps.errors.currencyId}
                            {...formikProps.getFieldProps('currencyId')}
                            SelectProps={{
                                native: true,
                            }}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            variant="standard"
                        >
                                <option key="" value="">
                                    {"Select Currency"}
                                </option>
                                {currencies.map((currency) => (
                                    <option key={currency._id} value={currency._id}>
                                        {currency.code}
                                    </option>
                                ))}
                            </TextField>
                        </Grid>
                        <Grid
                            item
                            md={2}
                            xs={12}
                        >
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DesktopDatePicker
                                    minDate={formikProps.values.startDate}
                                    inputFormat="dd/MM/yyyy"
                                    label="Start Date"
                                    value={formikProps.values.startDate}
                                    onChange={(date: Date | null) => formikProps.setFieldValue('startDate', date)}
                                    renderInput={(params) => <TextField
                                        variant="standard"
                                        error={Boolean(formikProps.touched.startDate && formikProps.errors.startDate)}
                                        helperText={formikProps.touched.startDate && formikProps.errors.startDate}
                                        fullWidth {...params} />}
                                />
                            </LocalizationProvider>
                        </Grid>
                        <Grid
                            item
                            md={2}
                            xs={12}
                        >
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DesktopDatePicker
                                    minDate={formikProps.values.endDate}
                                    inputFormat="dd/MM/yyyy"
                                    label="End Date"
                                    value={formikProps.values.endDate}
                                    onChange={(date: Date | null) => formikProps.setFieldValue('endDate', date)}
                                    renderInput={(params) => <TextField
                                        variant="standard"
                                        error={Boolean(formikProps.touched.endDate && formikProps.errors.endDate)}
                                        fullWidth {...params} />}
                                />
                            </LocalizationProvider>
                        </Grid>
                        <Grid
                            item
                            md={12}
                            xs={12}
                        >
                            <Divider />
                        </Grid>
                        <Grid
                            item
                            md={12}
                            xs={12}
                        >
                            <Typography variant="h6">Geolocation Targetting</Typography>
                        </Grid>
                        <Grid
                            item
                            md={3}
                            xs={4}
                        >
                            <TextField
                                select
                                label="Country Name"
                                error={Boolean(formikProps.touched.countryId && formikProps.errors.countryId)}
                                fullWidth
                                helperText={formikProps.touched.countryId && formikProps.errors.countryId}
                                name="countryId"
                                onChange={(e) => changeCountry(e.target.value, formikProps)}
                                SelectProps={{
                                    native: true,
                                }}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                value={formikProps.values.countryId}
                                variant="standard"
                            >
                                <option key="" value="">
                                    {"Select Country"}
                                </option>
                                {countries.map((country) => (
                                    <option key={country._id} value={country._id}>
                                        {country.label}
                                    </option>
                                ))}

                            </TextField>
                        </Grid>
                        <Grid
                            item
                            md={3}
                            xs={4}
                        >
                            <FormControl sx={{ width: "100%" }}>
                                <InputLabel id="demo-multiple-checkbox-label">Region</InputLabel>
                                <Select
                                    labelId="demo-multiple-checkbox-label"
                                    id="demo-multiple-checkbox"
                                    multiple
                                    value={formikProps.values.regionId}
                                    onChange={(e) => changeRegionData(e.target.value, formikProps)}
                                    input={<Input placeholder="Region" />}
                                    renderValue={(selected) => {
                                        return regions.filter((x: Regions) => { if (selected.includes(x._id)) return x }).map((x: Regions) => x.label).join(' ,');
                                    }}
                                    MenuProps={MenuProps}
                                >
                                    {regions.map((region: any) => (
                                        <MenuItem key={region._id} value={region._id}>
                                            <>
                                                <Checkbox
                                                    checked={
                                                        formikProps.values.regionId.findIndex((rid: string) => rid === region._id) >= 0
                                                    }
                                                />
                                                <ListItemText primary={region.label} />
                                            </>
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid
                            item
                            md={3}
                            xs={4}
                        >
                            <FormControl sx={{ width: "100%" }}>
                                <InputLabel id="demo-multiple-checkbox-label">City</InputLabel>
                                <Select
                                    labelId="demo-multiple-checkbox-label"
                                    id="demo-multiple-checkbox"
                                    multiple
                                    value={formikProps.values.cityId}
                                    onChange={(e) => formikProps.setFieldValue('cityId', e.target.value)}
                                    input={<Input placeholder="City" />}
                                    renderValue={(selected) => {
                                        return cities.filter((x: City) => { if (selected.includes(x._id)) return x }).map((x: City) => x.label).join(' ,');
                                    }}
                                    MenuProps={MenuProps}
                                >
                                    {cities.map((city: any) => (
                                        <MenuItem key={city._id} value={city._id}>
                                            <>
                                                <Checkbox
                                                    checked={
                                                        formikProps.values.cityId.findIndex((rid: string) => rid === city._id) >= 0
                                                    }
                                                />
                                                <ListItemText primary={city.label} />
                                            </>
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid
                            item
                            md={3}
                            xs={12}
                            sx={{ position: 'relative' }}
                        >
                            <TextField
                                fullWidth
                                label="Zip Code"
                                value={formikProps.values.zipCodeId.length ? formikProps.values.zipCodeId.length + "  uploaded" : ''}
                                variant="standard"
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <IconButton
                                                aria-label="download"
                                            >
                                                <Link style={{ color: 'inherit' }} to="/assets/csv/sample_zip.csv" target="_blank" download>
                                                    <Download />
                                                </Link>
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="upload"
                                            >
                                                {formikProps.values.zipCodeId.length ? <CloseIcon onClick={() => formikProps.setFieldValue('zipCodeId', [])} /> : <><CSVReader inputStyle={{ position: "absolute", opacity: 0, top: "27%", right: '5px', height: "35px", width: "30px" }} parserOptions={papaparseOptions} onFileLoaded={(data: any) => { data.shift() && formikProps.setFieldValue('zipCodeId', data) }} /><CloudUploadIcon /></>}
                                            </IconButton>
                                        </InputAdornment>
                                    )
                                }}
                            />
                        </Grid>
                    </Grid>
                    <Grid item marginTop={2} xs={2}>
                        <LoadingButton
                            size="medium"
                            type="submit"
                            variant="contained"
                            loading={formikProps.isSubmitting}
                        >
                            {initialValues ? 'Save Project' : 'Create Project'}
                        </LoadingButton>
                    </Grid>
                </Form>
            )}
        </Formik>
    );
}
