import { Stack, TextField, Grid, IconButton, Box, FormControlLabel, Radio, Checkbox } from '@mui/material';
import { Form, Formik, FormikProps } from 'formik';
import { LoadingButton } from '@mui/lab';
import * as Yup from 'yup'
import { Question } from 'src/types/surveyTypes';
import QuestionOptionRender from './QuestionOptionRender';
import { Done as DoneIcon, ArrowDropDown as ArrowDropDownIcon, Download } from '@mui/icons-material';
import QuestionMapRender from './QuestionMapRender';

export interface SubmitFormValues {
    initialValues: Question,
    submitForm: any,
    setEditQuestion: any
    languageCode:string;
}

export default function QuestionFormRender({ initialValues, setEditQuestion,languageCode, submitForm }: SubmitFormValues) {
    const questionEditorMap: any = {
        'TEXT': () => {
            return '';
        },
        'DATE': () => {
            return '';
        },
        'RADIO': (formikProps: FormikProps<Question>) => {
            return <QuestionOptionRender languageCode={languageCode} formikProps={formikProps} icon={<FormControlLabel
                value="disabled"
                disabled
                sx={{ mt: 2 }}
                control={<Radio />}
                label=""
            />} />
        },
        'CHECKBOX': (formikProps: FormikProps<Question>) => {
            return <QuestionOptionRender languageCode={languageCode} formikProps={formikProps} icon={<Checkbox disabled />} />
        },
        'DROPDOWN': (formikProps: FormikProps<Question>) => {
            return <QuestionOptionRender languageCode={languageCode} formikProps={formikProps} icon={<ArrowDropDownIcon sx={{ mr: 2, mt: 2 }} />} />
        },
        'MAP': (formikProps: FormikProps<Question>) => {
            return <QuestionMapRender languageCode={languageCode} formikProps={formikProps} icon={<Download sx={{ mr: 2, mt: 2 }} />} />
        },
    };


    const SchemaQuestionForm = Yup.object().shape({
        questionTitle: Yup.string().trim().min(4).required('question name  is required'),
        questionType: Yup.string().required('question Type  is required'),
        questionOption: Yup.array().when('questionType', {
            is: (questionType: string) => questionType != 'TEXT',
            then: Yup.array().when('questionType', {
                is: (questionType: string) => questionType != 'DATE',
                then:  Yup.array().of(
                    Yup.object().shape({
                        title: Yup.string().trim().required("option is required"),
                    }),
            ),
            otherwise: Yup.array(),
        }),}),});

    return (
        <Formik
            onSubmit={(values, { setSubmitting, resetForm }) => {
                submitForm(values, setSubmitting, resetForm);
            }}
            validateOnBlur={false}
            validateOnChange={true}
           validationSchema={SchemaQuestionForm}
            initialValues={{
                appendVariable: initialValues.appendVariable || '',
                questionTitle: initialValues.questionTitle && initialValues.questionTitle?.[languageCode] || initialValues.questionTitle || '',
                questionType: initialValues.questionType || "TEXT",
                questionOption: initialValues.questionOption || [{ title: '', enable:true, formvalidate: true,diy:true,local:true, quota: 0 }, { title: '', enable:true, diy:true, local:true,formvalidate: true, quota: 0 }],
            }}
        >
            {(formikProps: FormikProps<Question>) => (
                <Form  autoComplete="off" noValidate onSubmit={formikProps.handleSubmit}>
                    <Stack spacing={3} marginTop={3} padding={2}>
                        <Grid
                            container
                            spacing={3}
                        >
                            <Grid
                                item
                                md={9}
                                xs={12}
                            >
                                <TextField
                                    error={Boolean(formikProps.touched.questionTitle && formikProps.errors.questionTitle)}
                                    fullWidth
                                    helperText={formikProps.touched.questionTitle && formikProps.errors.questionTitle}
                                    label="Question Title"
                                    variant="standard"
                                    defaultValue=""
                                    name="questionTitle"
                                    onChange={formikProps.handleChange}
                                    value={formikProps.values.questionTitle}
                                />
                            </Grid>
                            <Grid
                                item
                                md={3}
                                xs={12}
                            >
                                <TextField
                                    select
                                    label="Type"
                                    error={Boolean(formikProps.touched.questionType && formikProps.errors.questionType)}
                                    fullWidth
                                    {...formikProps.getFieldProps('questionType')}
                                    helperText={formikProps.touched.questionType && formikProps.errors.questionType}
                                    variant="standard"
                                    SelectProps={{
                                        native: true,
                                    }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                >
                                    <option value="TEXT">
                                        Text
                                    </option>
                                    <option value="DATE">
                                        Date
                                    </option>
                                    <option value="RADIO">
                                        Radio
                                    </option>
                                    <option value="DROPDOWN">
                                        Dropdown
                                    </option>
                                    <option value="CHECKBOX">
                                        Checkbox
                                    </option>
                                    <option value="MAP">
                                        Map
                                    </option>
                                </TextField>
                            </Grid>
                        </Grid>
                        <Grid
                            container
                            spacing={3}
                        >
                            <Grid
                                item
                                md={2}
                                xs={12}
                            >
                                <TextField
                                    error={Boolean(formikProps.touched.appendVariable && formikProps.errors.appendVariable)}
                                    fullWidth
                                    helperText={formikProps.touched.appendVariable && formikProps.errors.appendVariable}
                                    label="Append Variable"
                                    variant="standard"
                                    defaultValue=""
                                    name="appendVariable"
                                    onChange={formikProps.handleChange}
                                    value={formikProps.values.appendVariable}
                                />
                            </Grid>
                        </Grid>
                        {questionEditorMap[formikProps.values.questionType || 'TEXT'](formikProps)}
                        <Grid item xs={12} display="flex" justifyContent="center" >
                            <Grid
                                item
                                md={4}
                                xs={4}
                            >
                                {initialValues ?
                                    <Box>
                                        <IconButton onClick={() => formikProps.submitForm()}>
                                            <DoneIcon />
                                        </IconButton>
                                    </Box> :
                                    <Box sx={{ justifyContent: 'center', display: 'flex' }}>
                                        <LoadingButton
                                            sx={{ mr: 2 }}
                                            size="large"
                                            type="submit"
                                            fullWidth
                                            variant="contained"
                                            loading={formikProps.isSubmitting}
                                        >
                                            Add Question
                                        </LoadingButton>
                                    </Box>
                                }
                            </Grid>
                        </Grid>
                    </Stack>
                </Form>
            )}
        </Formik>
    );
}
