import { Stack, TextField, Grid, IconButton, Box, FormControlLabel, Radio, Checkbox } from '@mui/material';
import { Form, Formik, FormikProps } from 'formik';
import { LoadingButton } from '@mui/lab';
import * as Yup from 'yup'
import { Question } from 'src/types/surveyTypes';
import QualificationOptionRender from './QualificationOptionRender';
import { Done as DoneIcon, Download } from '@mui/icons-material';
import { Qualification } from 'src/types/qualificationTypes';
import QualificationMapRender from './QualificationMapRender';

export interface SubmitFormValues {
    initialValues: Qualification,
    submitForm: any,
    setEditQualification: any
}

export default function QualificationFormRender({ initialValues, setEditQualification, submitForm }: SubmitFormValues) {
    const questionEditorMap: any = {
        'TEXT': () => {
            return '';
        },
        'RADIO': (formikProps: FormikProps<Qualification>) => {
            return <QualificationOptionRender  formikProps={formikProps} icon={<FormControlLabel
                value="disabled"
                disabled
                sx={{ mt: 2 }}
                control={<Radio />}
                label=""
            />} />
        },
        'MAP': (formikProps: FormikProps<Question>) => {
            return <QualificationMapRender  formikProps={formikProps} icon={<Download sx={{ mr: 2, mt: 2 }} />} />
        },
    };


    const SchemaQuestionForm = Yup.object().shape({
        qualificationName: Yup.string().trim().required('question name  is required'),
        qualificationIcon: Yup.string().trim().required('question name  is required'),
        qualificationOptions: Yup.array().when('questionType', {
            is: (questionType: string) => questionType != 'TEXT',
            then: Yup.array().of(
                Yup.object().shape({
                    text: Yup.string().trim().required("option is required"),
                })
            ),
            otherwise: Yup.array()
        })
    });
    return (
        <Formik
            onSubmit={(values, { setSubmitting, resetForm }) => {
                submitForm(values, setSubmitting, resetForm);
            }}
            validateOnBlur={false}
            validateOnChange={true}
           validationSchema={SchemaQuestionForm}
            initialValues={{
                qualificationName: initialValues.qualificationName || '',
                qualificationKey: initialValues.qualificationKey || '',
                qualificationIcon: initialValues.qualificationIcon || '',
                qualificationOptions: initialValues.qualificationOptions || [{ text: "",subText:" " ,isActive:true },{ text:"",subText:"", isActive:true } ],
            }}
        >
            {(formikProps: FormikProps<Qualification>) => (
                <Form  autoComplete="off" noValidate onSubmit={formikProps.handleSubmit}>
                    <Stack spacing={3} marginTop={3} padding={2}>
                        <Grid
                            container
                            spacing={3}
                        >
                            <Grid
                                item
                                md={6}
                                xs={12}
                            >
                                <TextField
                                    error={Boolean(formikProps.touched.qualificationName && formikProps.errors.qualificationName)}
                                    fullWidth
                                    helperText={formikProps.touched.qualificationName && formikProps.errors.qualificationName}
                                    label="Qualification Label"
                                    variant="standard"
                                    defaultValue=""
                                    name="qualificationName"
                                    onChange={formikProps.handleChange}
                                    value={formikProps.values.qualificationName}
                                />
                            </Grid>
                            <Grid
                                item
                                md={3}
                                xs={12}
                            >
                                <TextField
                                    error={Boolean(formikProps.touched.qualificationIcon && formikProps.errors.qualificationIcon)}
                                    fullWidth
                                    helperText={formikProps.touched.qualificationIcon && formikProps.errors.qualificationIcon}
                                    label="Qualification Icon"
                                    variant="standard"
                                    defaultValue=""
                                    name="qualificationIcon"
                                    onChange={formikProps.handleChange}
                                    value={formikProps.values.qualificationIcon}
                                />
                            </Grid>
                            <Grid
                                item
                                md={3}
                                xs={12}
                            >
                                <TextField
                                    error={Boolean(formikProps.touched.qualificationKey && formikProps.errors.qualificationKey)}
                                    fullWidth
                                    helperText={formikProps.touched.qualificationKey && formikProps.errors.qualificationKey}
                                    label="Qualification Key"
                                    variant="standard"
                                    defaultValue=""
                                    name="qualificationKey"
                                    onChange={formikProps.handleChange}
                                    value={formikProps.values.qualificationKey}
                                />
                            </Grid>
                        </Grid>
                        {questionEditorMap['RADIO'](formikProps)}
                        <Grid item xs={12} display="flex" justifyContent="center" >
                            <Grid
                                item
                                md={4}
                                xs={4}
                            >
                                {initialValues ?
                                    <Box>
                                        <IconButton onClick={() => formikProps.submitForm()}>
                                            <DoneIcon />
                                        </IconButton>
                                    </Box> :
                                    <Box sx={{ justifyContent: 'center', display: 'flex' }}>
                                        <LoadingButton
                                            sx={{ mr: 2 }}
                                            size="large"
                                            type="submit"
                                            fullWidth
                                            variant="contained"
                                            loading={formikProps.isSubmitting}
                                        >
                                            Add Qualification
                                        </LoadingButton>
                                    </Box>
                                }
                            </Grid>
                        </Grid>
                    </Stack>
                </Form>
            )}
        </Formik>
    );
}
