import { Container, Grid } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { DropResult } from 'react-beautiful-dnd';
import { ProjectQuestionAddRequest, SurveyQuestionDeleteRequest,SurveyQuestionUpdateRequestAll, ProjectQuestionsRequest, SurveyQuestionUpdateRequest } from 'src/actions/surveyActions';
import { QuestionListRender, QuestionFormRender } from 'src/components/admin/_dashboard/survey/questions';
import { MessageContext } from 'src/context';
import { useSelector } from 'src/reducers';
import { Project } from 'src/types/projectTypes';
import { Question } from 'src/types/surveyTypes';
import { reorder } from 'src/utils/common';

export interface FormValues {
    initialValues: Project,
    submitForm: any,
}

export default function ChildProjectSurveyForm({ initialValues, submitForm }: FormValues) {

    const [editQuestion, setEditQuestion] = useState<any>('');
    const [items, setItems] = useState<any>([]);
    const { setMessageHandler } = useContext<any>(MessageContext);
    const { projectid }: any = useParams<any>();
    const dispatch = useDispatch();
    const {  questions } = useSelector(state => state.question);

    const updateForm = (values: Question, setSubmitting: any) => {
        values.projectId = projectid;
        setTimeout(() => dispatch(SurveyQuestionUpdateRequest(editQuestion._id, values, setMessageHandler)), 200);
        setEditQuestion('');
    }

    const submitFormQuestion = (values: Question, setSubmitting: any, resetForm: any) => {
        values.projectId = projectid;
        setTimeout(() => dispatch(ProjectQuestionAddRequest(values, setMessageHandler, resetForm, setSubmitting)), 200);
    }

    const deleteQuestion = (id: string) => {
        setTimeout(() => dispatch(SurveyQuestionDeleteRequest(id, { isDeleted: true }, setMessageHandler)), 200);
    }
    useEffect(() => {
        dispatch(ProjectQuestionsRequest(projectid));
    }, [projectid]);
    
    var languageCode:string =initialValues.languageId || '';
    var initialValue: any = '';

    const onDragEnd = ({ destination, source }: DropResult) => {
        if (!destination) return;
        const newItems:Question[] = reorder(questions, source.index, destination.index);
         setItems(newItems);
        dispatch(SurveyQuestionUpdateRequestAll(projectid ,newItems , setMessageHandler));
    }

    editQuestion && languageCode && editQuestion.questionOption.map((editques:any) => editques.title = editques?.title?.[languageCode]);
    return (
        <Container maxWidth={false}>
            <Grid
                container
                spacing={3}
                mt={2}
            >
                <Grid item md={12} xs={12} spacing={3}>
                    <QuestionListRender onDragEnd={onDragEnd} languageCode={languageCode} editQuestion={editQuestion} setEditQuestion={setEditQuestion} updateForm={updateForm} questions={items.length ?  items : questions} deleteQuestion={deleteQuestion} />
                    <QuestionFormRender languageCode={languageCode} setEditQuestion="" initialValues={initialValue} submitForm={submitFormQuestion} />
                </Grid>
            </Grid> 
        </Container>
    );
}
