import { Icon } from '@iconify/react';
import androidFilled from '@iconify/icons-ant-design/android-filled';
import { alpha, styled } from '@mui/material/styles';
import { Card, Typography } from '@mui/material';
import { DashboardPayload } from 'src/types/dashboardTypes';

const RootStyle = styled(Card)(({ theme }) => ({
  boxShadow: 'none',
  textAlign: 'center',
  padding: theme.spacing(2, 0),
  color: theme.palette.primary.dark,
  backgroundColor: theme.palette.primary.light
}));


export default function AppWeeklySales({ totalCompleted }:any) {
  return (
    <RootStyle>
      <Typography variant="h3">{totalCompleted}</Typography>
      <Typography variant="subtitle2" sx={{ opacity: 0.72 }}>
        Total Completed
      </Typography>
    </RootStyle>
  );
}
