import { ThunkAction, ThunkDispatch } from "redux-thunk";
import Prefix from 'src/helpers/ApiPrefix';
import AXIOS from 'src/helpers/Axios';
import Headers from 'src/helpers/Headers';
import { supplierMapAction } from "src/reducers/supplierMapReducer";
import {  supplierMapActionTypes } from "src/types/actionTypes";
import { SupplierMap, SupplierMapList } from "src/types/supplierMapTypes";
import { ErrorAction, SuccessAction } from "src/types/responseTypes";
import { SupplierAllSuccess } from "./supplierActions";

export const SupplierMapFailure = (message: string): ErrorAction => {
    return {
        type: supplierMapActionTypes.SUPPLIER_MAP_FAILURE,
        payload: { message: message }
    }
}
export const SupplierMapSuccess = (payload: SupplierMapList): SuccessAction<SupplierMapList> => {
    return {
        type: supplierMapActionTypes.FETCH_SUPPLIER_MAP_SUCCESS,
        payload: payload,
    }
}
export const SupplierMapAllSuccess = (payload: SupplierMapList): SuccessAction<SupplierMapList> => {
    return {
        type: supplierMapActionTypes.FETCH_SUPPLIER_MAP_ALL_SUCCESS,
        payload: payload,
    }
}

export const SupplierMapAddSuccess = (payload: SupplierMap): SuccessAction<SupplierMap> => {
    return {
        type: supplierMapActionTypes.ADD_SUPPLIER_MAP_SUCCESS,
        payload: payload,
    }
}
export const SupplierMapSingleSuccess = (payload: any): SuccessAction<SupplierMap> => {
    return {
        type: supplierMapActionTypes.FETCH_SINGLE_SUPPLIER_MAP_SUCCESS,
        payload: payload,
    }
}

export const SupplierMapUpdateSuccess = (payload: any): SuccessAction<any> => {
    return {
        type: supplierMapActionTypes.UPDATE_SUPPLIER_MAP_SUCCESS,
        payload: payload,
    }
}



export const SupplierMapDeleteSuccess = (payload: any): SuccessAction<any> => {
    return {
        type: supplierMapActionTypes.DELETE_SUPPLIER_MAP_SUCCESS,
        payload: payload,
    }
}


export const SupplierMapAllRequest = (projectId:string ,search: string):
    ThunkAction<Promise<void>, {}, {}, supplierMapAction> => {
    return (dispatch: ThunkDispatch<{}, {}, supplierMapAction>) => {
        dispatch({ type: supplierMapActionTypes.SUPPLIER_MAP_REQUEST, payload:{ loading:true }})
        return AXIOS.post(`${Prefix.api}/admin/supplierMaps`, {  search,projectId }, { headers: Headers() }).then(res => {
            if (res) {
                const { data } = res;
                const supplierMaps: SupplierMapList = data.result
                dispatch(SupplierMapSuccess(supplierMaps))
            }
        }).catch((e) => {
            if (e.response) {
                dispatch(SupplierMapFailure(e.response.data.message));
            }
            else {
                dispatch(SupplierMapFailure(e.message));
            }
        })
    }
}

export const SupplierProjectMapAllRequest = (projectId:string):
    ThunkAction<Promise<void>, {}, {}, supplierMapAction> => {
    return (dispatch: ThunkDispatch<{}, {}, supplierMapAction>) => {
        console.log(projectId)
        dispatch({ type: supplierMapActionTypes.SUPPLIER_MAP_REQUEST, payload:{ loading:true }})
        return AXIOS.post(`${Prefix.api}/admin/project/supplier/stat/${projectId}`,{}, { headers: Headers() }).then(res => {
            if (res) {
                const { data } = res;
                const supplierMaps: SupplierMapList = data.result
                dispatch(SupplierMapSuccess(supplierMaps))
            }
        }).catch((e) => {
            if (e.response) {
                dispatch(SupplierMapFailure(e.response.data.message));
            }
            else {
                dispatch(SupplierMapFailure(e.message));
            }
        })
    }
}

export const SupplierMapAllRequestData = ():
    ThunkAction<Promise<void>, {}, {}, supplierMapAction> => {
    return (dispatch: ThunkDispatch<{}, {}, supplierMapAction>) => {
        return AXIOS.get(`${Prefix.api}/admin/supplierMaps/all`, { headers: Headers() }).then(res => {
            if (res) {
                const { data } = res;
                const supplierMaps: SupplierMapList = data.result
                dispatch(SupplierMapAllSuccess(supplierMaps))
            }
        }).catch((e) => {
            if (e.response) {
                dispatch(SupplierMapFailure(e.response.data.message));
            }
            else {
                dispatch(SupplierMapFailure(e.message));
            }
        })
    }
}


export const SupplierMapAddRequest = (supplierMap: SupplierMap, setMessageHandler: any, setSubmitting: any , resetForm:any):
    ThunkAction<Promise<void>, {}, {}, supplierMapAction> => {
    setMessageHandler({
        hasMessage: false,
        type: '',
        message: '',
    });
    return (dispatch: ThunkDispatch<{}, {}, supplierMapAction>) => {
        return AXIOS.post(`${Prefix.api}/admin/supplierMap/add`, supplierMap, { headers: Headers() }).then(res => {
            if (res) {
                const { data } = res;
                const supplierMap: SupplierMap = data.result;
                dispatch(SupplierMapAddSuccess(supplierMap))
                setSubmitting(false);
                setMessageHandler({
                    hasMessage: true,
                    type: 'success',
                    message: data.message,
                });
                resetForm();
            }
        }).catch((e) => {
            if (e.response) {
                setSubmitting(false);
                dispatch(SupplierMapFailure(e.response.data.message));
                setMessageHandler({
                    hasMessage: true,
                    type: 'error',
                    message: e.response.data.message,
                });
            }
            else {
                setSubmitting(false);
                dispatch(SupplierMapFailure(e.message));
                setMessageHandler({
                    hasMessage: true,
                    type: 'error',
                    message: e.message,
                });
            }
        })
    }
}


export const SupplierMapAddAllRequest = (projectid: string, setMessageHandler: any):
    ThunkAction<Promise<void>, {}, {}, supplierMapAction> => {
    setMessageHandler({
        hasMessage: false,
        type: '',
        message: '',
    });
    return (dispatch: ThunkDispatch<{}, {}, supplierMapAction>) => {
        return AXIOS.post(`${Prefix.api}/admin/supplierMap/add/all/${projectid}`, {}, { headers: Headers() }).then(res => {
            if (res) {
                const { data } = res;
                const supplierMaps: SupplierMapList = data.result;
                dispatch(SupplierMapSuccess(supplierMaps))
                setMessageHandler({
                    hasMessage: true,
                    type: 'success',
                    message: data.message,
                });
            }
        }).catch((e) => {
            if (e.response) {
                dispatch(SupplierMapFailure(e.response.data.message));
                setMessageHandler({
                    hasMessage: true,
                    type: 'error',
                    message: e.response.data.message,
                });
            }
            else {
                dispatch(SupplierMapFailure(e.message));
                setMessageHandler({
                    hasMessage: true,
                    type: 'error',
                    message: e.message,
                });
            }
        })
    }
}

export const SupplierMapUpdateRequest = (catid: string, supplierMap: SupplierMap, setMessageHandler: any, setSubmitting: any,resetForm:any):
    ThunkAction<Promise<void>, {}, {}, supplierMapAction> => {
    setMessageHandler({
        hasMessage: false,
        type: '',
        message: '',
    });
    return (dispatch: ThunkDispatch<{}, {}, supplierMapAction>) => {
        return AXIOS.put(`${Prefix.api}/admin/supplierMap/update/${catid}`, supplierMap, { headers: Headers() }).then(res => {
            if (res) {
                const { data } = res;
                const supplierMap: SupplierMap = data.result;
                dispatch(SupplierMapUpdateSuccess(supplierMap))
                setSubmitting(false);
                setMessageHandler({
                    hasMessage: true,
                    type: 'success',
                    message: data.message,
                });
                resetForm();
            }
        }).catch((e) => {
            if (e.response) {
                setSubmitting(false);
                setMessageHandler({
                    hasMessage: true,
                    type: 'error',
                    message: e.response.data.message,
                });
                dispatch(SupplierMapFailure(e.response.data.message));
            }
            else {
                setSubmitting(false);
                setMessageHandler({
                    hasMessage: true,
                    type: 'error',
                    message: e.message,
                });
                dispatch(SupplierMapFailure(e.message));
            }
        })
    }
}



export const SupplierMapStatusUpdateRequest = (supplierMapid: string, supplierMap: SupplierMap, setMessageHandler: any):
    ThunkAction<Promise<void>, {}, {}, supplierMapAction> => {
    setMessageHandler({
        hasMessage: false,
        type: '',
        message: '',
    });
    return (dispatch: ThunkDispatch<{}, {}, supplierMapAction>) => {
        return AXIOS.put(`${Prefix.api}/admin/supplierMap/update/${supplierMapid}`, supplierMap, { headers: Headers() }).then(res => {
            if (res) {
                const { data } = res;
                const supplierMap: SupplierMap = data.result;
                dispatch(SupplierMapUpdateSuccess(supplierMap))
                setMessageHandler({
                    hasMessage: true,
                    type: 'success',
                    message: data.message,
                });
            }
        }).catch((e) => {
            if (e.response) {
                setMessageHandler({
                    hasMessage: true,
                    type: 'error',
                    message: e.response.data.message,
                });
                dispatch(SupplierMapFailure(e.response.data.message));
            }
            else {
                setMessageHandler({
                    hasMessage: true,
                    type: 'error',
                    message: e.message,
                });
                dispatch(SupplierMapFailure(e.message));
            }
        })
    }
}


export const SupplierMapSingleCancel = ():
    ThunkAction<Promise<void>, {}, {}, supplierMapAction> => {
    return (dispatch: ThunkDispatch<{}, {}, supplierMapAction>) => {
        return AXIOS.get(`${Prefix.api}/admin/supplierMap`, { headers: Headers() }).then(res => {
            if (res) {
                dispatch(SupplierMapSingleSuccess({}));
            }
        }).catch((e) => {
            if (e.response) {
                dispatch(SupplierMapFailure(e.response.data.message));
            }
            else {
                dispatch(SupplierMapFailure(e.message));
            }
        })
    }
}


export const SupplierMapSingleRequest = (catid: string):
    ThunkAction<Promise<void>, {}, {}, supplierMapAction> => {
    return (dispatch: ThunkDispatch<{}, {}, supplierMapAction>) => {
        return AXIOS.get(`${Prefix.api}/admin/supplierMap/${catid}`, { headers: Headers() }).then(res => {
            if (res) {
                const { data } = res;
                const supplierMap: SupplierMap = data.result;
                dispatch(SupplierMapSingleSuccess(supplierMap));
            }
        }).catch((e) => {
            if (e.response) {
                dispatch(SupplierMapFailure(e.response.data.message));
            }
            else {
                dispatch(SupplierMapFailure(e.message));
            }
        })
    }
}


export const SupplierMapDeleteRequest = (id: string, body: SupplierMap, setMessageHandler: any):
    ThunkAction<Promise<void>, {}, {}, supplierMapAction> => {
    setMessageHandler({
        hasMessage: false,
        type: '',
        message: '',
    });
    return (dispatch: ThunkDispatch<{}, {}, supplierMapAction>) => {
        return AXIOS.put(`${Prefix.api}/admin/supplierMap/update/${id}`, body, { headers: Headers() }).then(res => {
            if (res) {
                const { data } = res;
                const supplierMap: SupplierMap = data.result;
                dispatch(SupplierMapDeleteSuccess(supplierMap))
                setMessageHandler({
                    hasMessage: true,
                    type: 'success',
                    message: data.message,
                });
            }
        }).catch((e) => {
            if (e.response) {
                dispatch(SupplierMapFailure(e.response.data.message));
                setMessageHandler({
                    hasMessage: true,
                    type: 'error',
                    message: e.response.data.message,
                });
            }
            else {
                dispatch(SupplierMapFailure(e.message));
                setMessageHandler({
                    hasMessage: true,
                    type: 'error',
                    message: e.message,
                });
            }
        })
    }
}


export const SupplierAllSummaryData = (body:any):
    ThunkAction<Promise<void>, {}, {}, supplierMapAction> => {
    return (dispatch: ThunkDispatch<{}, {}, supplierMapAction>) => {
        dispatch({ type:supplierMapActionTypes.SUPPLIER_MAP_REQUEST,payload: "" });
        return AXIOS.post(`${Prefix.api}/admin/suppliers/summary`,body, { headers: Headers() }).then(res => {
            if (res) {
                const { data } = res;
                const supplierMaps: SupplierMapList = data.result
                dispatch(SupplierMapAllSuccess(supplierMaps))
            }
        }).catch((e) => {
            if (e.response) {
                dispatch(SupplierMapFailure(e.response.data.message));
            }
            else {
                dispatch(SupplierMapFailure(e.message));
            }
        })
    }
}

export const SupplierAllSummaryDataReset = (body:any):
    ThunkAction<Promise<void>, {}, {}, supplierMapAction> => {
    return (dispatch: ThunkDispatch<{}, {}, supplierMapAction>) => {
        return AXIOS.post(`${Prefix.api}/admin/suppliers/summary`,body, { headers: Headers() }).then(res => {
            if (res) {
                const { data } = res;
                const supplierMaps: SupplierMapList = data.result
                dispatch(SupplierMapAllSuccess(supplierMaps))
            }
        }).catch((e) => {
            if (e.response) {
                dispatch(SupplierMapFailure(e.response.data.message));
            }
            else {
                dispatch(SupplierMapFailure(e.message));
            }
        })
    }
}


export const supplierSummaryApprovedAction = (body:any,setMessageHandler:any):
    ThunkAction<Promise<void>, {}, {}, supplierMapAction> => {
    return (dispatch: ThunkDispatch<{}, {}, supplierMapAction>) => {
        setMessageHandler({
            hasMessage: false,
            type: '',
            message: '',
        });
        return AXIOS.post(`${Prefix.api}/admin/suppliers/approve/summary`,body, { headers: Headers() }).then(res => {
            if (res) {
                const { data } = res;
                const supplierMap: SupplierMap = data.result;
                setMessageHandler({
                    hasMessage: true,
                    type: 'success',
                    message: data.message,
                });
            }
        }).catch((e) => {
            if (e.response) {
                dispatch(SupplierMapFailure(e.response.data.message));
                setMessageHandler({
                    hasMessage: true,
                    type: 'error',
                    message: e.response.data.message,
                });
            }
            else {
                dispatch(SupplierMapFailure(e.message));
                setMessageHandler({
                    hasMessage: true,
                    type: 'error',
                    message:e.messagee,
                });
            }
        })
    }
}

