import * as React from 'react';
import { SwipeableDrawer, Box } from '@mui/material';

interface DrawerLayoutProps {
  toggleDrawer: any,
  openDrawer: boolean,
  children: React.ReactNode,
  window?: () => Window;
}


export default function DrawerLayout({window, toggleDrawer, openDrawer, children }: DrawerLayoutProps) {

  const drawerBleeding = 56;
  const container = window !== undefined ? () => window().document.body : undefined;
  return (
      <SwipeableDrawer
       container={container}
        anchor="bottom"
        open={openDrawer}
        onClose={() => toggleDrawer('bottom', false)}
        onOpen={() => toggleDrawer('bottom', true)}
        swipeAreaWidth={drawerBleeding}
        disableSwipeToOpen={false}
        ModalProps={{
          keepMounted: true,
        }}
      >
        <Box>
          {children}
        </Box>
      </SwipeableDrawer>
  );
}