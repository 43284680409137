import { Card, Container, Grid, List, FormGroup, FormControlLabel, Switch, Divider } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { MessageContext } from 'src/context';
import { useSelector } from 'src/reducers';
import { Project } from 'src/types/projectTypes';
import QuotaListRender from '../quota/QuotaListRender';
import QuotaOptionRender from '../quota/QuotaOptionRender';
import { QuotaAddRequest, QuotaAllRequestData, QuotaDeleteRequest, QuotaUpdateRequest,QuotaSampleUpdateRequest } from 'src/actions/quotaActions';
import { Qualification } from 'src/types/qualificationTypes';
import { QualificationAllRequestData } from 'src/actions/qualificationActions';
import QuotaSizeRender from '../quota/QuotaSizeRender';

const CardStyle = styled(Card)(({ }) => ({
    width: '100%',
    padding: 24,
}));

export interface FormValues {
    initialValues: Project,
    submitForm: any,
}

export default function ChildProjectQuotaForm({ initialValues, submitForm }: FormValues) {

    const { projectid }: any = useParams<any>();
    const [ quotaSizeItem, setQuotaSizeItem] = useState<any>([]);
    const { setMessageHandler } = useContext<any>(MessageContext);
    const dispatch = useDispatch();
    const { qualifications } = useSelector(state => state.qualification);
    const { quotas } = useSelector(state => state.quota);

    useEffect(() => {
        dispatch(QuotaAllRequestData(projectid))
    }, [projectid])

    useEffect(() => {
        dispatch(QualificationAllRequestData())
    }, [])

    const addQuotaForm = (qualificationId: string) => {
        dispatch(QuotaAddRequest(projectid, { quotaId: qualificationId }, setMessageHandler))
    }

    const updateQuota = (quotaId: string, value: any) => {
        dispatch(QuotaUpdateRequest(quotaId, projectid, value, setMessageHandler))
    }

    const updateQuotaSample  = (quotaId: string, value: any,setSubmitting:any) => {
        dispatch(QuotaSampleUpdateRequest(quotaId, projectid, value,setSubmitting, setMessageHandler))
    }
    const removeQuota = (quotaId: string) => {
        dispatch(QuotaDeleteRequest(quotaId, projectid, { isDeleted: true }, setMessageHandler))
    }

    const quotaSizeItemAction  =(event: React.ChangeEvent<HTMLInputElement> ,value:string | undefined )=>{
     if(event.target.checked) setQuotaSizeItem([...quotaSizeItem ,value]);
     else setQuotaSizeItem(quotaSizeItem.filter((qu:string)=>qu != value));
    }
    
    return (
        <Container maxWidth={false}>
            <Grid
                container
                spacing={3}
                mt={2}
            >
                <Grid sx={{pl: "0 !important", pt: "0 !important" }} item md={3} xs={12}>
                    <CardStyle>
                        <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
                            {qualifications.map((qualification: Qualification) =>
                                <QuotaListRender defaultQuota={qualification?.isDefaultQualification} selected={quotas.some(qValue => qValue.quotaId._id == qualification._id)} key={qualification?._id} addQuotaForm={addQuotaForm} qualification={qualification} />
                            )}
                        </List>
                    </CardStyle>
                </Grid>
                <Grid sx={{ pr: "0 !important", pt: "0 !important" }} item md={9} xs={12}>
                    {quotas.map(quota => <CardStyle sx={{ mb: 2 }} key={quota._id}>
                        <QuotaOptionRender removeQuota={removeQuota} updateQuota={updateQuota} quota={quota} />
                        {quotaSizeItem.includes(quota._id)  && quota?.quotaValue && quota?.quotaValue?.length > 1 && <QuotaSizeRender quotaOptions={quota.quotaId.qualificationOptions} sampleSize={initialValues?.sampleSize} quotaValue={quota?.quotaValue} removeQuota={removeQuota} updateQuotaSample={updateQuotaSample} quota={quota} />}
                        <FormGroup sx={{mt:1 ,width:50}}>
                            <FormControlLabel  control={<Switch disabled={quota?.quotaValue && quota?.quotaValue?.length<=1 ? true :false} onChange={(e)=>quotaSizeItemAction(e,quota._id)}   size="small" />} label="Quota" />
                        </FormGroup>
                    </CardStyle>)}
                </Grid>
            </Grid>
        </Container>
    );
}
