import { styled } from '@mui/material/styles';
import { Card, Typography, Box } from '@mui/material';
import ResponsiveDialog from 'src/components/dialog/ResponsiveDialog';
import { DataGridRender } from 'src/components/table';
import { useEffect, useState } from 'react';
import { fShortenNumber } from 'src/utils/formatNumber';
import { useDispatch } from 'react-redux';
import { DashboardProjectListSecurityDataRequest } from 'src/actions/dashboardActions';

const RootStyle = styled(Card)(({ theme }) => ({
    boxShadow: 'none',
    textAlign: 'center',
    padding: theme.spacing(2, 0),
    color: theme.palette.info.dark,
    backgroundColor: theme.palette.info.light
}));



export default function DashboardProjectSecurityDialog({ securityFilters, setSecurityFilters, todaySecurityDrop, dashboardProjectListSecurityData }: any) {


    const dispatch = useDispatch();
    const [openDialog, setOpenDialog] = useState<boolean>(false);
    const [pageNo, setPageNo] = useState<number>(0);
    const [limit, setLimit] = useState<number>(10);

    useEffect(() => {
       if(openDialog) dispatch(DashboardProjectListSecurityDataRequest(securityFilters))
    }, [securityFilters,openDialog])


    const columns = [
        {
            field: 'projectCode', type: "string", headerName: 'Project #', filterable: false, headerAlign: "center", align: 'center', width: 150, pinnable: true, renderCell: ({ value }: any) => {
                return <Typography color="primary" >{value}</Typography>
            }
        },
        {
            field: 'projectName', type: "string", headerName: 'Project Name', filterable: false, headerAlign: "center", align: 'center', flex: 1, pinnable: true, renderCell: ({ value }: any) => {
                return <Typography color="primary" >{value}</Typography>
            }
        },
        {
            field: 'sca', type: "string", headerName: 'SCA', filterable: false, headerAlign: "center", width: 150, align: 'center', valueGetter: ({ row }: any) => {
                return `${fShortenNumber(row['sca'])}`;
            },
        },
        {
            field: 'security', type: "string", headerName: 'Security Terminate', filterable: false, headerAlign: "center", width: 150, align: 'center', valueGetter: ({ row }: any) => {
                return `${fShortenNumber(row['security'])}`;
            },
        },
    ];
    return (
        <RootStyle>
            <Box onClick={() => setOpenDialog(true)}>
                <Typography variant="h3">{todaySecurityDrop}</Typography>
                <Typography variant="subtitle2" sx={{ opacity: 0.72 }}>
                    Today Security
                </Typography>
            </Box>
            <ResponsiveDialog title="Project Security" subTitle="" openDialog={openDialog} setOpenDialog={setOpenDialog} >
                <DataGridRender
                    width={700}
                    filterTypes={['export', 'sortDate']}
                    exportFileName={"Today Security"}
                    hideFooterPagination={true}
                    data={dashboardProjectListSecurityData}
                    metadata=""
                    filters={securityFilters}
                    limit={limit}
                    pageNo={pageNo}
                    columns={columns}
                    setPageNo={setPageNo}
                    setLimit={setLimit}
                    setFilters={setSecurityFilters}
                />
            </ResponsiveDialog>
        </RootStyle>
    );
}
