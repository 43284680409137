import { ThunkAction, ThunkDispatch } from "redux-thunk";
import Prefix from 'src/helpers/ApiPrefix';
import AXIOS from 'src/helpers/Axios';
import Headers from 'src/helpers/Headers';
import { invoiceAction } from "src/reducers/invoiceReducer";
import { invoiceActionTypes } from "src/types/actionTypes";
import { Invoice, InvoiceList } from "src/types/invoiceTypes";
import { ErrorAction, SuccessAction } from "src/types/responseTypes";
import fileDownload from 'js-file-download'

export const InvoiceFailure = (message: string): ErrorAction => {
    return {
        type: invoiceActionTypes.INVOICE_FAILURE,
        payload: { message: message }
    }
}
export const InvoiceSuccess = (payload: InvoiceList): SuccessAction<InvoiceList> => {
    return {
        type: invoiceActionTypes.FETCH_INVOICE_SUCCESS,
        payload: payload,
    }
}
export const InvoiceSupplierSuccess = (payload: InvoiceList): SuccessAction<InvoiceList> => {
    return {
        type: invoiceActionTypes.FETCH_INVOICE_SUPPLIER_SUCCESS,
        payload: payload,
    }
}
export const InvoiceAllSuccess = (payload: InvoiceList): SuccessAction<InvoiceList> => {
    return {
        type: invoiceActionTypes.FETCH_INVOICE_ALL_SUCCESS,
        payload: payload,
    }
}

export const InvoiceAddSuccess = (payload: Invoice): SuccessAction<Invoice> => {
    return {
        type: invoiceActionTypes.ADD_INVOICE_SUCCESS,
        payload: payload,
    }
}
export const InvoiceSingleSuccess = (payload: Invoice): SuccessAction<Invoice> => {
    return {
        type: invoiceActionTypes.FETCH_SINGLE_INVOICE_SUCCESS,
        payload: payload,
    }
}

export const InvoiceUpdateSuccess = (payload: any): SuccessAction<any> => {
    return {
        type: invoiceActionTypes.UPDATE_INVOICE_SUCCESS,
        payload: payload,
    }
}


export const InvoiceDeleteSuccess = (payload: any): SuccessAction<any> => {
    return {
        type: invoiceActionTypes.DELETE_INVOICE_SUCCESS,
        payload: payload,
    }
}


export const InvoiceAllRequest = (filter: any, pageNo: number, limit: number):
    ThunkAction<Promise<void>, {}, {}, invoiceAction> => {
    return (dispatch: ThunkDispatch<{}, {}, invoiceAction>) => {
        dispatch({ type:invoiceActionTypes.INVOICE_REQUEST,payload: { loading:true,data:[]} });
        return AXIOS.post(`${Prefix.api}/admin/invoices`, { filter,  pageNo, limit }, { headers: Headers() }).then(res => {
            if (res) {
                const { data } = res;
                const invoices: InvoiceList = data.result
                dispatch(InvoiceSuccess(invoices))
            }
        }).catch((e) => {
            if (e.response) {
                dispatch(InvoiceFailure(e.response.data.message));
            }
            else {
                dispatch(InvoiceFailure(e.message));
            }
        })
    }
}

export const InvoiceSupplierAllRequest = (filter: any, pageNo: number, limit: number):
    ThunkAction<Promise<void>, {}, {}, invoiceAction> => {
    return (dispatch: ThunkDispatch<{}, {}, invoiceAction>) => {
        dispatch({ type:invoiceActionTypes.INVOICE_REQUEST,payload: { loading:true,data:[]} });
        return AXIOS.post(`${Prefix.api}/admin/invoices/supplier`, { filter,  pageNo, limit }, { headers: Headers() }).then(res => {
            if (res) {
                const { data } = res;
                const invoices: InvoiceList = data.result
                dispatch(InvoiceSupplierSuccess(invoices))
            }
        }).catch((e) => {
            if (e.response) {
                dispatch(InvoiceFailure(e.response.data.message));
            }
            else {
                dispatch(InvoiceFailure(e.message));
            }
        })
    }
}

export const InvoiceAllRequestData = ():
    ThunkAction<Promise<void>, {}, {}, invoiceAction> => {
    return (dispatch: ThunkDispatch<{}, {}, invoiceAction>) => {
        return AXIOS.get(`${Prefix.api}/admin/invoices/all`, { headers: Headers() }).then(res => {
            if (res) {
                const { data } = res;
                const invoices: InvoiceList = data.result
                dispatch(InvoiceAllSuccess(invoices))
            }
        }).catch((e) => {
            if (e.response) {
                dispatch(InvoiceFailure(e.response.data.message));
            }
            else {
                dispatch(InvoiceFailure(e.message));
            }
        })
    }
}




export const InvoiceAddRequest = (invoice: Invoice, setMessageHandler: any, setSubmitting: any):
    ThunkAction<Promise<void>, {}, {}, invoiceAction> => {
    setMessageHandler({
        hasMessage: false,
        type: '',
        message: '',
    });
    return (dispatch: ThunkDispatch<{}, {}, invoiceAction>) => {
        return AXIOS.post(`${Prefix.api}/admin/invoice/add`, invoice, { headers: Headers() }).then(res => {
            if (res) {
                const { data } = res;
                const invoice: any = data.result;
                fetch(invoice.apiUrl, {
                    method: 'POST',
                    headers: {
                        Accept: 'application.json',
                        'Content-Type': 'application/json'                    
                    },
                    body:JSON.stringify(invoice)
                  })
                  .then((response) => response.blob())
                  .then((blob) => {
                    const url = window.URL.createObjectURL(
                      new Blob([blob]),
                    );
                    const link:any = document.createElement('a');
                    link.href = url;
                    link.setAttribute(
                      'download',
                      `${"Invoice#"+invoice.invoice.invoiceCode}.pdf`,
                    );
                    document.body.appendChild(link);
                    link.click();
                    link.parentNode.removeChild(link);
                  });
                dispatch(InvoiceAddSuccess(invoice))
                setSubmitting(false);
                setMessageHandler({
                    hasMessage: true,
                    type: 'success',
                    message: data.message,
                });
            }
        }).catch((e) => {
            if (e.response) {
                setSubmitting(false);
                dispatch(InvoiceFailure(e.response.data.message));
                setMessageHandler({
                    hasMessage: true,
                    type: 'error',
                    message: e.response.data.message,
                });
            }
            else {
                setSubmitting(false);
                dispatch(InvoiceFailure(e.message));
                setMessageHandler({
                    hasMessage: true,
                    type: 'error',
                    message: e.message,
                });
            }
        })
    }
}

export const InvoiceSupplierAddRequest = (body:any, setMessageHandler: any):
    ThunkAction<Promise<void>, {}, {}, invoiceAction> => {
    setMessageHandler({
        hasMessage: false,
        type: '',
        message: '',
    });
    return (dispatch: ThunkDispatch<{}, {}, invoiceAction>) => {
        return AXIOS.post(`${Prefix.api}/admin/invoice/supplier/add`, body, { headers: Headers() }).then(res => {
            if (res) {
                const { data } = res;
                const invoice: any = data.result;
                fetch(invoice.apiUrl, {
                    method: 'POST',
                    headers: {
                        Accept: 'application.json',
                        'Content-Type': 'application/json'                    
                    },
                    body:JSON.stringify(invoice)
                  })
                  .then((response) => response.blob())
                  .then((blob) => {
                    const url = window.URL.createObjectURL(
                      new Blob([blob]),
                    );
                    const link:any = document.createElement('a');
                    link.href = url;
                    link.setAttribute(
                      'download',
                      `${"PO#"+invoice.invoice.invoiceCode}.pdf`,
                    );
                    document.body.appendChild(link);
                    link.click();
                    link.parentNode.removeChild(link);
                  });
                dispatch(InvoiceAddSuccess(invoice))
                setMessageHandler({
                    hasMessage: true,
                    type: 'success',
                    message: data.message,
                });
            }
        }).catch((e) => {
            if (e.response) {
                dispatch(InvoiceFailure(e.response.data.message));
                setMessageHandler({
                    hasMessage: true,
                    type: 'error',
                    message: e.response.data.message,
                });
            }
            else {
                dispatch(InvoiceFailure(e.message));
                setMessageHandler({
                    hasMessage: true,
                    type: 'error',
                    message: e.message,
                });
            }
        })
    }
}



export const InvoiceDownloadRequest = (id:string):
    ThunkAction<Promise<void>, {}, {}, invoiceAction> => {
    return (dispatch: ThunkDispatch<{}, {}, invoiceAction>) => {
        return AXIOS.post(`${Prefix.api}/admin/invoice/download/${id}`, {}, { headers: Headers() }).then(res => {
            if (res) {
                const { data } = res;
                const invoice: any = data.result;
                fetch(invoice.apiUrl, {
                    method: 'POST',
                    headers: {
                        Accept: 'application.json',
                        'Content-Type': 'application/json'                    
                    },
                    body:JSON.stringify(invoice)
                  })
                  .then((response) => response.blob())
                  .then((blob) => {
                    const url = window.URL.createObjectURL(
                      new Blob([blob]),
                    );
                    const link:any = document.createElement('a');
                    link.href = url;
                    link.setAttribute(
                      'download',
                      `${"Invoice#"+invoice.invoice.invoiceCode}.pdf`,
                    );
                    document.body.appendChild(link);
                    link.click();
                    link.parentNode.removeChild(link);
                    console.log(link)
                  });
            }
        }).catch((e) => {
            if (e.response) {
                dispatch(InvoiceFailure(e.response.data.message));
            }
            else {
                dispatch(InvoiceFailure(e.message));
            }
        })
    }
}

export const InvoiceSupplierDownloadRequest = (id:string):
    ThunkAction<Promise<void>, {}, {}, invoiceAction> => {
    return (dispatch: ThunkDispatch<{}, {}, invoiceAction>) => {
        return AXIOS.post(`${Prefix.api}/admin/invoice/supplier/download/${id}`, {}, { headers: Headers() }).then(res => {
            if (res) {
                const { data } = res;
                const invoice: any = data.result;
                fetch(invoice.apiUrl, {
                    method: 'POST',
                    headers: {
                        Accept: 'application.json',
                        'Content-Type': 'application/json'                    
                    },
                    body:JSON.stringify(invoice)
                  })
                  .then((response) => response.blob())
                  .then((blob) => {
                    const url = window.URL.createObjectURL(
                      new Blob([blob]),
                    );
                    const link:any = document.createElement('a');
                    link.href = url;
                    link.setAttribute(
                      'download',
                      `${"PO#"+invoice.invoice.invoiceCode}.pdf`,
                    );
                    document.body.appendChild(link);
                    link.click();
                    link.parentNode.removeChild(link);
                  });
            }
        }).catch((e) => {
            if (e.response) {
                dispatch(InvoiceFailure(e.response.data.message));
            }
            else {
                dispatch(InvoiceFailure(e.message));
            }
        })
    }
}



export const InvoiceUpdateRequest = (catid: string, invoice: Invoice, setMessageHandler: any, setSubmitting: any):
    ThunkAction<Promise<void>, {}, {}, invoiceAction> => {
    setMessageHandler({
        hasMessage: false,
        type: '',
        message: '',
    });
    return (dispatch: ThunkDispatch<{}, {}, invoiceAction>) => {
        return AXIOS.put(`${Prefix.api}/admin/invoice/update/${catid}`, invoice, { headers: Headers() }).then(res => {
            if (res) {
                const { data } = res;
                const invoice: Invoice = data.result;
                dispatch(InvoiceUpdateSuccess(invoice))
                setSubmitting(false);
                setMessageHandler({
                    hasMessage: true,
                    type: 'success',
                    message: data.message,
                });
            }
        }).catch((e) => {
            if (e.response) {
                setSubmitting(false);
                setMessageHandler({
                    hasMessage: true,
                    type: 'error',
                    message: e.response.data.message,
                });
                dispatch(InvoiceFailure(e.response.data.message));
            }
            else {
                setSubmitting(false);
                setMessageHandler({
                    hasMessage: true,
                    type: 'error',
                    message: e.message,
                });
                dispatch(InvoiceFailure(e.message));
            }
        })
    }
}



export const InvoiceSupplierUploadRequest = ( body: any, setMessageHandler: any):
    ThunkAction<Promise<void>, {}, {}, invoiceAction> => {
    setMessageHandler({
        hasMessage: false,
        type: '',
        message: '',
    });
    return (dispatch: ThunkDispatch<{}, {}, invoiceAction>) => {
        return AXIOS.post(`${Prefix.api}/admin/invoice/supplier/upload`, body, { headers: Headers() }).then(res => {
            if (res) {
                const { data } = res;
                const invoice: Invoice = data.result;
                dispatch(InvoiceUpdateSuccess(invoice))
                setMessageHandler({
                    hasMessage: true,
                    type: 'success',
                    message: data.message,
                });
            }
        }).catch((e) => {
            if (e.response) {
                setMessageHandler({
                    hasMessage: true,
                    type: 'error',
                    message: e.response.data.message,
                });
                dispatch(InvoiceFailure(e.response.data.message));
            }
            else {
                setMessageHandler({
                    hasMessage: true,
                    type: 'error',
                    message: e.message,
                });
                dispatch(InvoiceFailure(e.message));
            }
        })
    }
}

export const InvoiceUploadRequest = ( body: any, setMessageHandler: any):
    ThunkAction<Promise<void>, {}, {}, invoiceAction> => {
    setMessageHandler({
        hasMessage: false,
        type: '',
        message: '',
    });
    return (dispatch: ThunkDispatch<{}, {}, invoiceAction>) => {
        return AXIOS.post(`${Prefix.api}/admin/invoice/upload`, body, { headers: Headers() }).then(res => {
            if (res) {
                const { data } = res;
                const invoice: Invoice = data.result;
                dispatch(InvoiceUpdateSuccess(invoice))
                setMessageHandler({
                    hasMessage: true,
                    type: 'success',
                    message: data.message,
                });
            }
        }).catch((e) => {
            if (e.response) {
                setMessageHandler({
                    hasMessage: true,
                    type: 'error',
                    message: e.response.data.message,
                });
                dispatch(InvoiceFailure(e.response.data.message));
            }
            else {
                setMessageHandler({
                    hasMessage: true,
                    type: 'error',
                    message: e.message,
                });
                dispatch(InvoiceFailure(e.message));
            }
        })
    }
}



export const InvoiceStatusUpdateRequest = (invoiceid: string, invoice: Invoice, setMessageHandler: any):
    ThunkAction<Promise<void>, {}, {}, invoiceAction> => {
    setMessageHandler({
        hasMessage: false,
        type: '',
        message: '',
    });
    return (dispatch: ThunkDispatch<{}, {}, invoiceAction>) => {
        return AXIOS.put(`${Prefix.api}/admin/invoice/update/${invoiceid}`, invoice, { headers: Headers() }).then(res => {
            if (res) {
                const { data } = res;
                const invoice: Invoice = data.result;
                dispatch(InvoiceUpdateSuccess(invoice))
                setMessageHandler({
                    hasMessage: true,
                    type: 'success',
                    message: data.message,
                });
            }
        }).catch((e) => {
            if (e.response) {
                setMessageHandler({
                    hasMessage: true,
                    type: 'error',
                    message: e.response.data.message,
                });
                dispatch(InvoiceFailure(e.response.data.message));
            }
            else {
                setMessageHandler({
                    hasMessage: true,
                    type: 'error',
                    message: e.message,
                });
                dispatch(InvoiceFailure(e.message));
            }
        })
    }
}


export const InvoiceSingleRequest = (catid: string):
    ThunkAction<Promise<void>, {}, {}, invoiceAction> => {
    return (dispatch: ThunkDispatch<{}, {}, invoiceAction>) => {
        return AXIOS.get(`${Prefix.api}/admin/invoice/${catid}`, { headers: Headers() }).then(res => {
            if (res) {
                const { data } = res;
                const invoice: Invoice = data.result;
                dispatch(InvoiceSingleSuccess(invoice));
            }
        }).catch((e) => {
            if (e.response) {
                dispatch(InvoiceFailure(e.response.data.message));
            }
            else {
                dispatch(InvoiceFailure(e.message));
            }
        })
    }
}

export const InvoiceCodeRequest = ():
    ThunkAction<Promise<void>, {}, {}, invoiceAction> => {
    return (dispatch: ThunkDispatch<{}, {}, invoiceAction>) => {
        return AXIOS.get(`${Prefix.api}/admin/invoice/code/get`, { headers: Headers() }).then(res => {
            if (res) {
                const { data } = res;
                const invoice: any = data.result;
                dispatch(InvoiceSingleSuccess(invoice));
            }
        }).catch((e) => {
            if (e.response) {
                dispatch(InvoiceFailure(e.response.data.message));
            }
            else {
                dispatch(InvoiceFailure(e.message));
            }
        })
    }
}

export const InvoicePdfGenerateRequest = ():
    ThunkAction<Promise<void>, {}, {}, invoiceAction> => {
    return (dispatch: ThunkDispatch<{}, {}, invoiceAction>) => {
        return AXIOS.post(`${Prefix.api}/admin/invoice/pdf/generate`,{}, { headers: Headers() }).then(res => {
            if (res) {
                const { data } = res;
                var fileName="hsdh";
                fileDownload(data, `${fileName}.pdf`);
            }
        }).catch((e) => {
            if (e.response) {
                dispatch(InvoiceFailure(e.response.data.message));
            }
            else {
                dispatch(InvoiceFailure(e.message));
            }
        })
    }
}






export const InvoiceDeleteRequest = (id: string, body: Invoice, setMessageHandler: any):
    ThunkAction<Promise<void>, {}, {}, invoiceAction> => {
    setMessageHandler({
        hasMessage: false,
        type: '',
        message: '',
    });
    return (dispatch: ThunkDispatch<{}, {}, invoiceAction>) => {
        return AXIOS.put(`${Prefix.api}/admin/invoice/update/${id}`, body, { headers: Headers() }).then(res => {
            if (res) {
                const { data } = res;
                const invoice: Invoice = data.result;
                dispatch(InvoiceDeleteSuccess(invoice))
                setMessageHandler({
                    hasMessage: true,
                    type: 'success',
                    message: data.message,
                });
            }
        }).catch((e) => {
            if (e.response) {
                dispatch(InvoiceFailure(e.response.data.message));
                setMessageHandler({
                    hasMessage: true,
                    type: 'error',
                    message: e.response.data.message,
                });
            }
            else {
                dispatch(InvoiceFailure(e.message));
                setMessageHandler({
                    hasMessage: true,
                    type: 'error',
                    message: e.message,
                });
            }
        })
    }
}





