import { styled } from '@mui/material/styles';
import { Card, Stack, TextField, Grid, FormControl,ListItemText,Checkbox, Select, Input,InputLabel, MenuItem } from '@mui/material';
import { Form, Formik, FormikProps } from 'formik';
import * as Yup from 'yup';
import { useEffect } from 'react';
import { useSelector } from 'src/reducers';
import { useNavigate } from 'react-router-dom';
import { LoadingButton } from '@mui/lab';
import { Message } from 'src/types/messageTypes';
import { SupplierAllRequestData } from 'src/actions/supplierActions';
import { useDispatch } from 'react-redux';
import { Supplier } from 'src/types/supplierTypes';

const CardStyle = styled(Card)(({ }) => ({
    width: '100%',
    padding: 24
}));
const ITEM_HEIGHT: number = 48;
const ITEM_PADDING_TOP: number = 8;
const MenuProps: any = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};


export interface FormValues {
    initialValues: Message,
    submitForm: any,
}

export default function MessageForm({ initialValues, submitForm }: FormValues) {

    const SchemaCategoryForm = Yup.object().shape({
        type: Yup.string().required('Type  is required'),
        module: Yup.string().required('Module is required'),
        title: Yup.string().required('Title is required'),
        body: Yup.string().required('Body is required'),
    });
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { redirectTo } = useSelector(state => state.message);
    const { suppliers } = useSelector(state => state.supplier);

    useEffect(() => {
        if (redirectTo) navigate(redirectTo, { replace: true })
    }, [redirectTo])


    useEffect(() => {
        dispatch(SupplierAllRequestData());
    }, [])


    const handleChange = (event:any,formikProps:any) => {
        const value = event.target.value;
        if (value[value.length - 1] === "all") {
          formikProps.setFieldValue('supplierId',formikProps.values.supplierId.length === suppliers.length ? [] : suppliers.map(sup=>sup._id));
          return;
        }
        formikProps.setFieldValue('supplierId',value);
      };    


    return (
        <CardStyle>
            <Formik
                onSubmit={(values, { setSubmitting }) => {
                    submitForm(values, setSubmitting);
                }}
                validateOnBlur={false}
                validateOnChange={true}
                enableReinitialize={true}
                validationSchema={SchemaCategoryForm}
                initialValues={{
                    type: initialValues?.type || '',
                    module: initialValues?.module || '',
                    title: initialValues?.title || '',
                    body: initialValues?.body || '',
                    supplierId: initialValues?.supplierId || [],
                }}
            >
                {(formikProps: FormikProps<Message>) => (
                    <Form autoComplete="off" noValidate onSubmit={formikProps.handleSubmit}>
                        <Stack spacing={3}>
                            <Grid
                                container
                                spacing={3}
                            >
                                <Grid
                                    item
                                    md={2}
                                    xs={12}
                                >
                                    <TextField
                                        error={Boolean(formikProps.touched.type && formikProps.errors.type)}
                                        fullWidth
                                        helperText={formikProps.touched.type && formikProps.errors.type}
                                        label="Type"
                                        {...formikProps.getFieldProps('type')}
                                        variant="standard"
                                    />
                                </Grid>
                                <Grid
                                    item
                                    md={2}
                                    xs={12}
                                >
                                    <TextField
                                        error={Boolean(formikProps.touched.module && formikProps.errors.module)}
                                        fullWidth
                                        helperText={formikProps.touched.module && formikProps.errors.module}
                                        label="Module"
                                        {...formikProps.getFieldProps('module')}
                                        variant="standard"
                                    />
                                </Grid>
                                <Grid
                                    item
                                    md={4}
                                    xs={12}
                                >
                                    <FormControl sx={{ width: "100%" }}>
                                        <InputLabel id="demo-multiple-checkbox-label">Supplier</InputLabel>
                                        <Select
                                            labelId="demo-multiple-checkbox-label"
                                            id="demo-multiple-checkbox"
                                            multiple
                                            value={formikProps.values.supplierId}
                                            onChange={(e) =>handleChange(e,formikProps) }
                                            input={<Input placeholder="Suppliers" />}
                                            renderValue={(selected) => {
                                                return suppliers.filter((x: any) => { if (selected.includes(x._id)) return x }).map((x: Supplier) => x.supplierName).join(' ,');
                                            }}
                                            MenuProps={MenuProps}
                                        >
                                            <MenuItem value="all" >
                                                <>
                                                    <Checkbox  checked={suppliers.length > 0 && formikProps.values.supplierId.length === suppliers.length}/>
                                                    <ListItemText  secondary="Select All" />
                                                </>
                                            </MenuItem>
                                            {suppliers.map((supplier: any) => (
                                                <MenuItem key={supplier._id} value={supplier._id}>
                                                    <>
                                                        <Checkbox
                                                            checked={
                                                                formikProps.values && formikProps.values.supplierId.findIndex((rid: string) => rid === supplier._id) >= 0
                                                            }
                                                        />
                                                        <ListItemText primary={supplier.supplierName} />
                                                    </>
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid
                                    item
                                    md={4}
                                    xs={12}
                                >
                                    <TextField
                                        error={Boolean(formikProps.touched.title && formikProps.errors.title)}
                                        fullWidth
                                        helperText={formikProps.touched.title && formikProps.errors.title}
                                        label="Title"
                                        {...formikProps.getFieldProps('title')}
                                        variant="standard"
                                    />
                                </Grid>
                                <Grid
                                    item
                                    md={12}
                                    xs={12}
                                >
                                    <TextField
                                        minRows={4}
                                        maxRows={4}
                                        multiline
                                        error={Boolean(formikProps.touched.body && formikProps.errors.body)}
                                        fullWidth
                                        helperText={formikProps.touched.body && formikProps.errors.body}
                                        label="Body"
                                        {...formikProps.getFieldProps('body')}
                                        variant="standard"
                                    />
                                </Grid>
                            </Grid>
                            <Grid item xs={2}>
                                    <LoadingButton
                                        size="medium"
                                        type="submit"
                                        variant="contained"
                                        loading={formikProps.isSubmitting}
                                    >
                                        {initialValues ? 'Save Message' : 'Add Message'}
                                    </LoadingButton>
                                </Grid>
                        </Stack>
                    </Form>
                )}
            </Formik>
        </CardStyle>
    );
}
