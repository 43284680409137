import { useEffect, useState } from 'react';
import {  Container, Typography, Box, Tooltip,Tab } from '@mui/material';
import { useDispatch } from 'react-redux';
import { useSelector } from 'src/reducers';
import { DataGridRender } from 'src/components/table';
import { IdentifierExportRequest, ProjectByIdentifierRequest } from 'src/actions/projectActions';
import Label from 'src/components/Label';
import ReactCountryFlag from 'react-country-flag';
import moment from 'moment';
import TabLayout from 'src/components/tab/TabLayout';
import {  useNavigate } from 'react-router-dom';
import TopBar from 'src/components/shared/TopBar';

export default function IdentifierList() {

  const [pageNo, setPageNo] = useState(1);
  const [limit, setLimit] = useState(10);
  const [status] = useState(false);
  const [loading,setLoading] = useState(false);
  const [filters, setFilters] = useState<any>([]);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { metadata, data } = useSelector(state => state.project);

  useEffect(() => {
    dispatch(ProjectByIdentifierRequest(filters, pageNo, limit, status));
  }, [pageNo, limit, status, filters]);


  const exportIdentfier =()=>{
    dispatch(IdentifierExportRequest({identifierData:filters.search},setLoading));
  }
  const columns = [
    {
      field: 'projectCode', type: "string", headerName: 'Project #', filterable: false, headerAlign: "center", align: 'center', width: 120, pinnable: true, renderCell: ({ value }: any) => {
        return <Typography color="primary" >{value}</Typography>
      }
    },
    { field: 'projectName', type: "string", headerName: 'Project Name', filterable: false, headerAlign: "center", align: 'center', width: 140 },
    {
      field: 'supplierName', type: "string", headerName: 'Supplier Name', filterable: false, headerAlign: "center", align: 'center', width: 180, pinnable: true, renderCell: ({ value }: any) => {
        return <Typography color="primary" >{value}</Typography>
      }
    },
    { field: 'supplierIdentifier', type: "string", headerName: 'Supplier Identifier', filterable: false, headerAlign: "center", align: 'center', width: 180 },
    { field: 'userIdentifier', type: "string", headerName: 'User Identifier', filterable: false, headerAlign: "center", align: 'center', width: 350 },
    {
      field: 'createdAt', type: "date", headerName: 'Start Time', filterable: false, headerAlign: "center", align: 'center', width: 180, valueGetter: ({ value }: any) => {
        return moment(value).format("DD/MM/YYYY hh:mm:ss")
      }
    },
    {
      field: 'updatedAt', type: "string", headerName: 'End Time', filterable: false, headerAlign: "center", align: 'center', width: 180, valueGetter: ({ value }: any) => {
        return moment(value).format("DD/MM/YYYY hh:mm:ss")
      }
    },
    {
      field: 'countryname', type: "string", headerName: 'Country', filterable: false, width: 120, headerAlign: "center", align: 'center',
      renderCell: (params: any) => {
        return <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <ReactCountryFlag className="emojiFlag" countryCode={`${params.row.countryCode}`} /><Tooltip title={params.row.countryName}>
            <Typography sx={{ ml: 1 }} variant="subtitle2">{params.row.countryName}</Typography>
          </Tooltip>
        </Box>
      },
    },
    {
      field: 'country', type: "string", headerName: 'Geo Location', filterable: false, width: 120, headerAlign: "center", align: 'center',
      renderCell: (params: any) => {
        return <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Typography sx={{ ml: 1 }} variant="subtitle2">{params.row.country}</Typography>
        </Box>
      },
    },
    { field: 'ipAddress', type: "string", headerName: 'IP Address', filterable: false, headerAlign: "center", align: 'center', width: 120 },
    { field: 'deviceType', type: "string", headerName: 'Device Type', filterable: false, headerAlign: "center", align: 'center', width: 120 },
    { field: 'browserDetail', type: "string", headerName: 'Browser Detail', filterable: false, headerAlign: "center", align: 'center', width: 150 },
    {
      field: 'status', headerName: 'Status', type: "string", headerAlign: "center", align: 'center', width: 100, renderCell: (params: any) => {
        return <Label
          variant="ghost"
          color='primary'
        >
          {params?.value}
        </Label>
      }
    },
  ];

  return (
    <Container maxWidth={false}>
         <TopBar title="Support" link="" buttonText="" />
      <TabLayout handleChange=""  tabValue={1}>
        <Tab label="Identifiers" onClick={() => navigate("/admin/support")} value={1} />
        <Tab label="ShortCodes" onClick={() => navigate("/admin/support/shortcode")} value={2} />
        <Tab label="System Logs" onClick={() => navigate("/admin/support/log")} value={3} />
        <Tab label="Scheduled Jobs" onClick={() => navigate("/admin/support/job")} value={4} />
      </TabLayout>
      <DataGridRender
        searchwidth={400}
        searchheight={4}
        filterTypes={['searcharea', 'export','exportIdentifier']}
        exportFileName="identifiers"
        multilinesearch={true}
        data={data}
        exportIdentfier={exportIdentfier}
        exportIdentifierLoading={loading}
        metadata={metadata}
        limit={100}
        filters={filters}
        pageNo={pageNo}
        columns={columns}
        setPageNo={setPageNo}
        setLimit={setLimit}
        setFilters={setFilters}
        hideFooterPagination={true}
        hideEmptyTable={true}
      />
    </Container>
  );
}
