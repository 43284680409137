import { scheduleJobActionTypes } from "src/types/actionTypes";
import { ErrorAction, SuccessAction } from "src/types/responseTypes";
import { ScheduleJob, ScheduleJobList } from "src/types/scheduleJobTypes";

export type countryAction = SuccessAction<ScheduleJobList> | SuccessAction<ScheduleJob> | SuccessAction<any> | ErrorAction
export const defaultCountryState: ScheduleJobList = { loading: false, scheduleJob: {}, data: [], metadata: {}, redirectTo: '' };

const reducer = (state: ScheduleJobList = defaultCountryState, action: countryAction): ScheduleJobList => {
    switch (action.type) {
        case scheduleJobActionTypes.SCHEDULE_JOB_REQUEST:
            state = { ...state, loading: true }
            return state
        case scheduleJobActionTypes.FETCH_SCHEDULE_JOB_SUCCESS:
            action = <SuccessAction<ScheduleJobList>>action
            state = { ...state, redirectTo: "", loading: false, data: action.payload.data, metadata: action.payload.metadata }
            return state
        case scheduleJobActionTypes.SCHEDULE_JOB_ADD_SUCCESS:
            action = <SuccessAction<ScheduleJob>>action
            state = { ...state, redirectTo: '/admin/support/job' }
            return state
        case scheduleJobActionTypes.SCHEDULE_JOB_UPDATE_SUCCESS:
            action = <SuccessAction<any>>action
            state = { ...state, redirectTo: '/admin/support/job', data: state.data?.map(dat => [dat._id == action.payload._id && Object.assign(dat, action.payload)].find(o => o._id == dat._id) || dat) }
            return state
        case scheduleJobActionTypes.SCHEDULE_JOB_DELETE_SUCCESS:
            action = <SuccessAction<any>>action
            state = { ...state, data: state.data?.filter(dat => dat._id !== action.payload._id), metadata: { ...state.metadata, limit: Number(state.metadata?.limit) - 1, total: Number(state.metadata?.total) - 1 } }
            return state
        case scheduleJobActionTypes.SCHEDULE_JOB_FAILURE:
            action = <ErrorAction>action
            return { ...state }
        default:
            return state
    }
}

export default reducer