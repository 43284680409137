import { Card, styled } from '@mui/material';
import { FormikProps } from 'formik';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { CitiesAllRequest, RegionAllRequest } from 'src/actions/countryActions';
import { useSelector } from 'src/reducers';
import { Project } from 'src/types/projectTypes';
import ChildProjectInfoForm from './ChildProjectInfoForm';

export interface FormValues {
    initialValues: Project,
    submitForm: any,
}
const CardStyle = styled(Card)(({ }) => ({
    width: '100%',
    padding: 24
}));

export default function ChildProjectForm({ initialValues, submitForm }: FormValues) {

    const navigate = useNavigate();
    const [countryId, setCountryId] = useState<string>(initialValues.countryId || '');
    const [regionId, setRegionId] = useState<any>(initialValues.regionId || []);
    const [cityId, setCityId] = useState<any>(initialValues.cityId || []);
    
    const { redirectTo } = useSelector(state => state.project);

    const dispatch = useDispatch()

    const changeCountry = (value: string, formikProps: FormikProps<Project>) => {
        formikProps.setFieldValue('countryId', value)
        setCountryId(value);
        setCityId([])
        if(value) dispatch(RegionAllRequest({ countryId: value }));
    }
    const changeRegion = (value: string, formikProps: FormikProps<Project>) => {
        formikProps.setFieldValue('regionId', value)
        setRegionId(value);
        if(value) dispatch(CitiesAllRequest({ regionId: value }));
    }

    useEffect(() => {
        if (redirectTo) navigate(-1)
    }, [redirectTo]);

    return (
        <CardStyle>
            <ChildProjectInfoForm newProject={true} changeRegion={changeRegion} changeCountry={changeCountry} cityId={cityId} countryId={countryId} regionId={regionId} initialValues={initialValues} submitForm={submitForm} />
        </CardStyle>
    );
}
