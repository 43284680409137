import { Button, Grid, TextField } from '@mui/material';
import { CategoryQuestion } from 'src/types/surveyTypes';

export interface QuestionProps {
    categoryQuestions: CategoryQuestion[]
}

const QuestionMapRender =  ({ formikProps ,icon,languageCode }: any) => { 

    const setMapOptions = ( mapvalues:string ) =>{
        var mainoptions:any= [];
        var mapoptions=mapvalues.split('\n');
        mapoptions.map(mapopt=>{
            mainoptions.push({ title:mapopt ,enable:true,formvalidate: true, quota: 0 ,local:true,diy:true })
        });
        formikProps.setFieldValue('questionOption',mainoptions);
    }

    return (
        <Grid
            container
            spacing={3}
        >
            <TextField
                fullWidth
                multiline
                minRows={5}
                onChange={(e)=> setMapOptions(e.target.value)}
            />
            <Button />
        </Grid>
    );
};

export default QuestionMapRender;
