import { FC, useEffect } from 'react'
import { Card, Typography,  CardContent } from '@mui/material';
import {
  Timeline,
  TimelineItem,
  TimelineContent,
  TimelineConnector,
  TimelineSeparator,
  TimelineDot
} from '@mui/lab';
import { fDateTime } from 'src/utils/formatTime';
import { useSelector } from 'src/reducers';
import { useDispatch } from 'react-redux';
import { SystemLogRequest } from 'src/actions/serviceActions';


interface OrderItemProps {
  [item: string]: any,
  isLast:boolean,
  index:number
}

const  OrderItem: FC<OrderItemProps> = ({ item,index, isLast })=> {
  const { name, activity, createdAt } = item;
  return (
    <TimelineItem>
      <TimelineSeparator>
        <TimelineDot
          sx={{
            bgcolor:
              (index%2 == 0 && 'primary.main') ||
              (index%2 == 2 && 'success.main') ||
              'success.main'
          }}
        />
        {isLast ? null : <TimelineConnector />}
      </TimelineSeparator>
      <TimelineContent>
        <Typography variant="subtitle2">{activity}</Typography>
        <Typography variant="caption" sx={{ color: 'text.secondary' }}>
          {fDateTime(createdAt)}
        </Typography>
        <Typography variant="caption" sx={{ color: 'text.secondary' }}>
          {" by "+name}
        </Typography>
      </TimelineContent>
    </TimelineItem>
  );
}

export default function SystemLogTimeline({ projectid }:any) {

  const dispatch = useDispatch();
  const {  data } = useSelector(state => state.systemLog);

  useEffect(() => {
    dispatch(SystemLogRequest({projectId:projectid}));
  }, [projectid]);

  return (
    <Card
      sx={{
        boxShadow:'none',
        padding:0,
        '& .MuiTimelineItem-missingOppositeContent:before': {
          display: 'none'
        }
      }}
    >
      <CardContent sx={{padding:0}}>
        <Timeline>
          {data.map((item, index) => (
            <OrderItem index={index} key={item.activity} item={item} isLast={index === data.length - 1} />
          ))}
        </Timeline>
      </CardContent>
    </Card>
  );
}
