import { useContext, useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Container, Typography, Box, Tooltip, Tab, IconButton } from '@mui/material';
import { useDispatch } from 'react-redux';
import { useSelector } from 'src/reducers';
import moment from 'moment';
import swal from 'sweetalert';
import { MoreMenu, DataGridRender } from 'src/components/table';
import VisibilityIcon from '@mui/icons-material/Visibility';
import Label from 'src/components/Label';
import { ProjectApiTolunaRequest, ProjectMrktAddFromFetchRequest,ProjectApiTolunaQuestionAllRequest,  ProjectApiMrktRequest, ProjectApiMrtQuotaAllRequest } from 'src/actions/projectActions';
import TopBar from 'src/components/shared/TopBar';
import { MessageContext } from 'src/context';
import TabLayout from 'src/components/tab/TabLayout';
import ResponsiveDialog from 'src/components/dialog/ResponsiveDialog';

export default function MrktProjectList() {

  const [searchParams] = useSearchParams();
  const { setMessageHandler } = useContext<any>(MessageContext);
  var pageNo: any = searchParams.get("p") || 1
  const [limit, setLimit] = useState(20);
  const [filters, setFilters] = useState<any>({countryId:"62151c9cca53f1138fab528e"});
  const [dialog, setDialog] = useState<string>('');
  const [dialogQuestions, setDialogQuestions] = useState<string>('');
  const [dialogQuestion, setDialogQuestion] = useState<string>('');

  const dispatch = useDispatch();
  const { loading, fetchProjects, metadata,quotas,questions } = useSelector(state => state.project);

  const navigate = useNavigate();

  useEffect(() => {
    dispatch(ProjectApiMrktRequest(filters));
  }, [filters]);


  useEffect(() => {
    if (dialog) {
      dispatch(ProjectApiMrtQuotaAllRequest( filters , dialog));
    }
  }, [dialog]);

  useEffect(() => {
    if (dialogQuestion) {
      dispatch(ProjectApiTolunaQuestionAllRequest({ filters }, dialogQuestion));
    }
  }, [dialogQuestion]);


  const addToProject = (project: any) => {
    swal({
      title: "This action will create a new project",
      icon: "warning",
      dangerMode: false,
      buttons: ["No!", true],
    })
      .then((willDelete) => {
        if (willDelete) {
          project.clientId = filters.client;
          dispatch(ProjectMrktAddFromFetchRequest(project, setMessageHandler));
        } else {
        }
      });
  }


  const setPageNo = (page: any) => {
    navigate(`/admin/project?p=${page}`);
  }


  const reloadAction = () => {
    dispatch(ProjectApiTolunaRequest(filters));
  }



  const columnsToluna = [
    
    {
      field: 'quotaId', type: "string", headerName: 'Quota ID', filterable: false, headerAlign: "left", align: 'left', width: 100, renderCell: ({ id, value }: any) => {
        return <Tooltip title={value}>
          <Typography variant="subtitle2">{value}</Typography>
        </Tooltip>
      }
    },
    {
      field: 'remaining', type: "string", headerName: 'Required Completes', filterable: false, headerAlign: "left", align: 'left', width: 60, renderCell: ({ id, value }: any) => {
        return <Tooltip title={value}>
          <Typography variant="subtitle2">{value}</Typography>
        </Tooltip>
      }
    },
    {
      field: 'conditions', type: "string", headerName: 'Condition', filterable: false, headerAlign: "left", align: 'left', flex: 1, renderCell: ({ value }: any) => {
        return value.map((val: any,index:any) =>
          <Box>
            <Typography sx={{mr:2}}>{` (${index+1}) Question Id : ${val.questionId} , options : ${val.options}`}</Typography>
          </Box>
        );
      }
    },
    {
      field: 'Questions', type: "string", headerName: 'Quotas', disableExport: true, filterable: false, width: 110, headerAlign: "center", align: 'center', renderCell: ({ row }: any) => {
        return dialogActionsQuestions( row['QuotaID'],  row['QuotaID'], 'Questions');
      },
    },
  ];

  const columnsQuestions = [
    
    {
      field: 'QuestionID', type: "string", headerName: 'Question ID', filterable: false, headerAlign: "left", align: 'left', width: 120, renderCell: ({  value }: any) => {
        return <Tooltip title={value}>
          <Typography variant="subtitle2">{value}</Typography>
        </Tooltip>
      }
    },
    {
      field: 'AnswerIDs', type: "string", headerName: 'Answer IDs', filterable: false, headerAlign: "left", align: 'left', flex: 1, renderCell: ({ value }: any) => {
        return <Tooltip title={value}>
          <Typography variant="subtitle2">{value}</Typography>
        </Tooltip>
      }
    },
    {
      field: 'IsRoutable', type: "boolean", headerName: 'Routable', filterable: false, headerAlign: "left", align: 'left', flex: 1
    },
    {
      field: 'Question', type: "string", headerName: 'Question', disableExport: true, filterable: false, width: 110, headerAlign: "center", align: 'center', renderCell: ({ row }: any) => {
        return dialogActionsQuestion( row['QuestionID'],  row['QuestionID'], 'Question');
      },
    },
  ];


  const columnsQuestion = [
    
    {
      field: 'InternalName', type: "string", headerName: 'Name', filterable: false, headerAlign: "left", align: 'left', width: 120, renderCell: ({  value }: any) => {
        return <Tooltip title={value}>
          <Typography variant="subtitle2">{value}</Typography>
        </Tooltip>
      }
    },
    {
      field: 'TranslatedAnswers', type: "string", headerName: 'Answer IDs', filterable: false, headerAlign: "left", align: 'left', flex: 1, renderCell: ({ value }: any) => {
        return <Tooltip title={value}>
          <Typography variant="subtitle2">{value}</Typography>
        </Tooltip>
      }
    },
    {
      field: 'IsRoutable', type: "boolean", headerName: 'Routable', filterable: false, headerAlign: "left", align: 'left', flex: 1
    },
  ];



  const dialogActionsQuotas = (projectRedirects: any, id: string, name: string, title: string) => {
    return <Box>
      <IconButton onClick={() => setDialog(id)}>
        <VisibilityIcon />
      </IconButton>
      <ResponsiveDialog openDialog={dialog == id} setOpenDialog={setDialog} title={title} subTitle={name}  >
          <DataGridRender
            height={400}
            width={1160}
            filterTypes={['export']}
            data={quotas}
            filters="{}"
            exportFileName="Quotas"
            limit={limit}
            pageNo={pageNo}
            loading={loading}
            columns={columnsToluna}
            setPageNo={setPageNo}
            setLimit={setLimit}
            setFilters={setFilters}
            hideFooterPagination={true}
          /> 
      </ResponsiveDialog>
    </Box>
  }

  const dialogActionsQuestions = ( id: string, name: string, title: string) => {
    return <Box>
      <IconButton onClick={() => setDialogQuestions(id)}>
        <VisibilityIcon />
      </IconButton>
      <ResponsiveDialog openDialog={dialogQuestions == id} setOpenDialog={setDialogQuestions} title={title} subTitle={name}  >
          <DataGridRender
            height={400}
            width={1160}
            filterTypes={['export']}
            data={quotas}
            filters="{}"
            exportFileName="Questions"
            limit={limit}
            pageNo={pageNo}
            loading={loading}
            columns={columnsQuestions}
            setPageNo={setPageNo}
            setLimit={setLimit}
            setFilters={setFilters}
            hideFooterPagination={true}
          /> 
      </ResponsiveDialog>
    </Box>
  }

  const dialogActionsQuestion = ( id: string, name: string, title: string) => {
    return <Box>
      <IconButton onClick={() => setDialogQuestion(id)}>
        <VisibilityIcon />
      </IconButton>
      <ResponsiveDialog openDialog={dialogQuestion == id} setOpenDialog={setDialogQuestion} title={title} subTitle={name}  >
         <DataGridRender
            height={400}
            width={1160}
            filterTypes={['export']}
            data={questions}
            filters="{}"
            exportFileName="Questions"
            limit={limit}
            pageNo={pageNo}
            loading={loading}
            columns={columnsQuestion}
            setPageNo={setPageNo}
            setLimit={setLimit}
            setFilters={setFilters}
            hideFooterPagination={true}
          /> 
      </ResponsiveDialog>
    </Box>
  }


  const columns = [
    {
      field: 'surveyId', type: "string", headerName: 'Project Title', filterable: false, headerAlign: "left", align: 'left', flex: 1, renderCell: ({ row }: any) => {
        return <Tooltip title={row['surveyId']}>
          <Typography variant="subtitle2">{row['surveyId']}</Typography>
        </Tooltip>
      }
    },
    {
      field: 'total_completes', type: "string", headerName: 'Requirement', filterable: false, headerAlign: "center", align: 'center', width: 120, renderCell: ({ row }: any) => {
        return <Tooltip title={row['remaining']}>
          <Typography variant="subtitle2">{row['remaining']}</Typography>
        </Tooltip>
      }
    },
    {
      field: 'ir', type: "string", headerName: 'IR', filterable: false, headerAlign: "left", align: 'left', width: 60, renderCell: ({ row }: any) => {
        return row['IR'] || row['ir'];
      }
    },
    {
      field: 'loi', type: "string", headerName: 'LOI', filterable: false, headerAlign: "left", align: 'left', width: 60, renderCell: ({ row }: any) => {
        return row['LOI'] || row['loi'];
      }
    },
    { field: 'loi', type: "string", headerName: 'LOI', filterable: false, width: 60, headerAlign: "center", align: 'center' },
    {
      field: 'cpi', type: "string", headerName: 'Reward', filterable: false, width: 80, headerAlign: "center", align: 'center', renderCell: ({ row }: any) => {
        return row['cpi'];
      }
    },
    {
      field: 'Quotas', type: "string", headerName: 'Quotas', disableExport: true, filterable: false, width: 110, headerAlign: "center", align: 'center', renderCell: ({ row }: any) => {
        return dialogActionsQuotas(row.Quotas , row['surveyId'],  row['surveyId'], 'Quotas');
      },
    },
    {
      field: 'end_date', type: "string", headerName: 'End Date', filterable: false, headerAlign: "center", align: 'center', width: 150, renderCell: ({ value }: any) => {
        return <Label
          variant="ghost"
          color="primary"
        >
          {moment(value).format('DD/MM/YYYY')}
        </Label>
      }
    },
    { field: 'actions', headerName: '', width: 60, type: 'actions', getActions: ({ id, row }: any) => [<MoreMenu id={id} project={row} name={row['projectCode'] + "_" + row['projectName']} projectStatus={row['projectStatus']} exportFileName="Project" addToProject={addToProject} />] },
  ];

  return (
    <Container maxWidth={false}>
      <TopBar title="Projects" link="" buttonText="Add Project" />
      <TabLayout handleChange="" tabValue={5}>
        <Tab label="Precision" onClick={() => navigate("/admin/api")} value={1} />
        <Tab label="Logit" onClick={() => navigate("/admin/api/logit")} value={2} />
        <Tab label="Toulna" onClick={() => navigate("/admin/api/toluna")} value={3} />
        <Tab label="Circle" onClick={() => navigate("/admin/api/circle")} value={4} />
        <Tab label="UniMrkt" onClick={() => navigate("/admin/api/mrkt")} value={5} />
      </TabLayout>
      <DataGridRender
        reloadAction={reloadAction}
        getRowClassName={(params: any) => params.row['complete'] && params.row['complete'] >= params.row['sampleSize'] && params.row['sampleSize'] ? `data-grid-warning` : 'super-app-theme'}
        loading={loading}
        filterTypes={['export', 'countries']}
        data={fetchProjects}
        exportFileName="Projects"
        metadata={metadata}
        filters={filters}
        limit={limit}
        hideFooterPagination={true}
        pageNo={pageNo}
        columns={columns}
        setPageNo={setPageNo}
        setLimit={setLimit}
        setFilters={setFilters}
      />
    </Container>
  );
}
