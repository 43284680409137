import {  Container } from '@mui/material';
import { useDispatch } from 'react-redux';
import MessageContext from 'src/context/MessageContext';
import { useContext } from 'react';
import TopBarForm from 'src/components/shared/TopBarForm';
import { MessageAddRequest } from 'src/actions/messageActions';
import { MessageForm } from 'src/components/admin/_dashboard/service/Message';
import { Message } from 'src/types/messageTypes';

export default function AddMessage() {
    const { setMessageHandler } = useContext<any>(MessageContext);
    const dispatch = useDispatch();
    const initialValues: any = null;

    const submitForm = async (values: Message, setSubmitting: any) => {
        setTimeout(() => dispatch(MessageAddRequest(values, setMessageHandler, setSubmitting)), 200);
    }

    return (
        <Container maxWidth={false}>
            <TopBarForm title="Add Message"  link="/admin/library/message"/>
            <MessageForm initialValues={initialValues} submitForm={submitForm} />
        </Container>
    );
}
